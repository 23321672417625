import {ClickAnalyticsPlugin} from '@microsoft/applicationinsights-clickanalytics-js';
import {createBrowserHistory} from 'history';
import {getConfigValue} from './config';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';

const browserHistory = createBrowserHistory();
// Defining the Application Insights instance used for logging
let appInsightsInstance: ApplicationInsights | undefined = undefined;

const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {useDefaultContentNameOrId: true},
};

const reactPlugin = new ReactPlugin();
const getApplicationInsights = (consent: boolean) =>
  new ApplicationInsights({
    config: {
      instrumentationKey: getConfigValue('applicationInsightsKey'),
      extensions: [reactPlugin as any, clickPluginInstance],
      extensionConfig: {
        [reactPlugin.identifier]: {history: browserHistory},
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
      enableRequestHeaderTracking: consent,
      enableResponseHeaderTracking: consent,
      correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
      enableAjaxErrorStatusText: consent,
      enableAjaxPerfTracking: consent,
      disableCookiesUsage: !consent,
      enableAutoRouteTracking: consent,
    },
  });

// Remove Application Insights cookies
function removeAppInsightsCookies() {
  document.cookie = 'ai_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'ai_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

// Loading and unloading Application Insights based on cookie consent
const handleAnalyticsTrackingByConsent = (consent: boolean) => {
  if (consent) {
    const appInsights = getApplicationInsights(consent);
    appInsights.loadAppInsights();
    appInsightsInstance = appInsights;
    var referrer = document.referrer;
    appInsightsInstance.addTelemetryInitializer((envelope) => {
      if (envelope && envelope.data) {
        envelope.data.referrer = referrer;
      }
    });
  } else {
    appInsightsInstance?.unload();
    removeAppInsightsCookies();
    appInsightsInstance = undefined;
  }
};

// Get cookie consent for statistics
const getCookieConsent = () => (window as any)?.CookieInformation?.getConsentGivenFor('cookie_cat_statistic');

// Listen to changed consent-events to trigger loading and unloading of Application Insights
window.addEventListener('CookieInformationConsentGiven', function () {
  const cookieConsent = getCookieConsent();
  handleAnalyticsTrackingByConsent(cookieConsent);
});

export const traceSeverityLevel = {
  Information: 1,
  Critical: 4,
  Warning: 2,
  Error: 3,
  Verbose: 0,
};

export {reactPlugin, appInsightsInstance};
