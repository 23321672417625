import {Link} from 'react-router-dom';

const HomeLogo = ({resetError}: {resetError: () => void}) => {
  const isDecember = () => {
    const date = new Date();
    return date.getMonth() === 11;
  };

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <Link aria-label={'Gå til forsiden'} className={'topbar_page_title'} to='/' onClick={resetError}>
        <img
          alt='eByggesøk'
          src={
            isDecember()
              ? 'https://res.cloudinary.com/dgbxah2xy/image/upload/v1669627673/eBygges%C3%B8k%20UA/eByggesokChristmas.svg'
              : 'https://res.cloudinary.com/dgbxah2xy/image/upload/v1614161828/eBygges%C3%B8k%20UA/eBygges%C3%B8k.svg'
          }
        />
      </Link>
    </div>
  );
};
export default HomeLogo;
