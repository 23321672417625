import React from 'react';
import NkTypography from '../../../components/NkTypography/NkTypography';
import './FaqQuestion.scss';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

type Props = {header: string; content: string; initOpen?: boolean};
const FaqQuestion = ({header, content, initOpen}: Props) => {
  const [open, setOpen] = React.useState(initOpen || false);

  return (
    <div className='FaqQuestion'>
      <button onClick={() => setOpen(!open)} aria-expanded={open}>
        {open ? <RemoveIcon /> : <AddIcon />}
        <NkTypography tag='span' type='caption'>
          {header}
        </NkTypography>
      </button>

      {open && (
        <div className='content_container'>
          <span className='box'></span>
          <NkTypography type='markdown'>{content}</NkTypography>
        </div>
      )}
    </div>
  );
};
export default FaqQuestion;
