import React from 'react';
import './StepNumber.scss';

type Props = {stepNumber: number};
const StepNumber = ({stepNumber}: Props) => {
  return (
    <div className='StepNumber'>
      {stepNumber !== 1 ? <div className='line top'></div> : <div className='line top hidden'></div>}
      <span aria-hidden='true' className='circle'>
        {stepNumber}
      </span>
      {stepNumber !== 7 ? <div className='line bottom'></div> : <div className='line bottom hidden'></div>}
    </div>
  );
};
export default StepNumber;
