import './Personvern.scss';
import {Helmet} from 'react-helmet-async';
import layout from '../../components/hocs/layout';
import React from 'react';
import {setTitle} from '../../utils/setTitle';
import {Box, Container, Divider, Stack, Typography} from '@mui/material';
import NkTypography from '../../components/NkTypography/NkTypography';
import {Section} from '../../components/Section/Section';
import {Rettigheter} from './Rettigheter';
import {UnderleverandorTable} from './UnderleverandorTable';
import {nkColors} from '../../colors';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import {InfoPagesRoutes} from '../../utils/routes/InfoPagesRoutes';

const Personvern = () => {
  React.useEffect(() => {
    setTitle('Personvern');
  }, []);
  return (
    <div className='personvern'>
      <Helmet>
        <meta data-rh='true' name='keywords' content='' />
        <meta name='description' content='Personvernerklæring - eByggesøk for privatpersoner.' />
      </Helmet>{' '}
      <Container maxWidth='md'>
        <Box sx={{mt: '50px'}}>
          <Breadcrumb traces={[{name: 'Forside', path: InfoPagesRoutes.base.path}]} currentPage='Personvern' />
        </Box>
        <Section>
          <NkTypography style={{marginBottom: 10, width: '100%'}} tag='h1' type='heading1'>
            Personvernerklæring for eByggesøk
          </NkTypography>
          <NkTypography style={{margin: '20px 0'}} type='normalParagraph'>
            Norkart er behandlingsansvarlig for behandlingen av personopplysninger som beskrevet i denne
            personvernerklæringen. Vi forklarer her hva slags personopplysninger vi lagrer og hvordan de behandles.
            Denne personvernerklæringen gjelder for eByggesok.no og ditt kundeforhold med Norkart.
          </NkTypography>

          <Typography sx={{color: nkColors.norkart_gronn, mb: '10px'}}>Vår postadresse er:</Typography>
          <ul style={{listStyleType: 'none', padding: 0}}>
            <li>Norkart AS</li>
            <li>Hoffsveien 4</li>
            <li style={{marginBottom: '20px'}}>0275 Oslo</li>
            <li>Telefonnummeret vårt er 67 55 14 00.</li>
            <li>Du kan også ta kontakt med oss på: personvern@norkart.no</li>
          </ul>
        </Section>
        <Divider />
        <Section>
          <Stack gap='20px'>
            <NkTypography tag='h2' type='heading2'>
              Personopplysninger vi behandler om deg for å levere nettsiden eByggesok.no:
            </NkTypography>
            <ul>
              <li>IP-adresse</li>
              <li>Operativsystem</li>
              <li>Nettleserinformasjon</li>
              <li>Skjermoppløsning</li>
              <li>Type enhet</li>
            </ul>

            <NkTypography type='normalParagraph'>
              For å kunne analysere og forbedre våre digitale tjenester, og dersom du godtar bruk av
              informasjonskapsler, behandler vi i tillegg disse personopplysningene om deg:
            </NkTypography>

            <ul>
              <li>Navigasjon på nettsidene</li>
              <li>Din interaksjon med våre reklameannonser og våre andre nettsider</li>
            </ul>
            <NkTypography type='normalParagraph'>Disse opplysningene blir slettet etter 90 dager.</NkTypography>
          </Stack>
        </Section>
        <Divider />
        <Section>
          <Stack gap='20px'>
            <NkTypography tag='h2' type='heading2'>
              Personopplysninger vi behandler om deg som en del av vårt kundeforhold med deg når du kjøper produkter på
              eByggesok.no:
            </NkTypography>
            <NkTypography type='normalParagraph'>
              Hvis du er innlogget eller kjøper produkter av oss kan vi samle inn disse opplysningene:
            </NkTypography>
            <ul>
              <li>Navn</li>
              <li>Adresse</li>
              <li>Telefonnummer</li>
              <li>Fødselsnummer</li>
              <li>E-postadresse</li>
              <li>Betalingsinformasjon</li>
              <li>Opplysninger gitt i byggesaken</li>
              <li>Bruksmønster på våre nettsteder</li>
              <li>Ordrehistorikk</li>
              <li>Supporthenvendelser</li>
            </ul>
            <NkTypography type='normalParagraph'>Denne informasjonen får Norkart i disse tilfellene:</NkTypography>
            <ul>
              <li>Du har selv gitt Norkart opplysningene</li>
            </ul>
            <NkTypography type='normalParagraph'>
              Vi bruker dine personopplysninger til å oppfylle våre avtaler med deg, det vil si når du har bestilt et
              produkt eller en tjeneste fra oss. Det kan for eksempel være for å levere produktet til deg, og
              kundesupport knyttet til ditt kundeforhold. Det rettslige grunnlaget for å behandle personopplysninger til
              dette formålet er at behandlingen er nødvendig for å oppfylle en avtale med deg.
            </NkTypography>
            <NkTypography type='normalParagraph'>
              Vi arbeider kontinuerlig med å utvikle og forbedre våre tjenester og produkter. Dette innebærer å
              analysere ulike former for personopplysninger, som for eksempel kundeaktivitet, kundehistorikk og konto-
              og profilinformasjon. Det rettslige grunnlaget for å behandle personopplysninger til dette formålet er vår
              berettigede interesse.
            </NkTypography>
            <NkTypography type='normalParagraph'>
              Vi tilpasser brukeropplevelsen og kommunikasjonen til ditt kundeforhold og dette bruker vi
              personopplysninger til. Det rettslige grunnlaget for å behandle personopplysninger til dette formålet er
              vår berettigede interesse.
            </NkTypography>
            <NkTypography type='normalParagraph'>
              Vi bruker både anonymiserte data og personopplysninger for å kunne tilpasse og rette markedsføringen til
              våre eksisterende kunder. Eksempelvis ved å gi anbefalinger eller målrettet innhold i våre digitale
              kanaler. Det rettslige grunnlaget for å behandle personopplysninger til dette formålet er vår berettigede
              interesse.
            </NkTypography>
            <NkTypography type='normalParagraph'>
              Ved salg av våre produkter og tjenester er vi pålagt å beholde personopplysninger av hensyn til rettslige
              forpliktelser som følger av bokføringsloven.
            </NkTypography>
          </Stack>
        </Section>
        <Divider />
        <Section>
          <UnderleverandorTable />
        </Section>
        <Divider />
        <Section>
          <Rettigheter />
        </Section>
      </Container>
    </div>
  );
};
export default layout({hideStepper: true, noSpacing: true, showFooter: true})(Personvern);
