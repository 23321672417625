import {DialogActions, DialogTitle, IconButton} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from 'nka-icons/build/icons/Lukk';
import React from 'react';
import NkButton from '../../../components/Button/NkButton';
import {DesktopContainer, TabletAndMobileContainer} from '../../../components/containers/DeviceContainers';
import NkTypography from '../../../components/NkTypography/NkTypography';
import './ProcessIllustration.scss';
import {getProcessText} from './ProcessText';
import Step from './Step/Step';

export type ProcessStep = {text: string; illustration: string; moreInfoText: string};

const ProcessIllustration = ({style, background}: {style?; background: 'green' | 'white'}) => {
  const [activeStep, setActiveStep] = React.useState<ProcessStep | undefined>(undefined);
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const steps: ProcessStep[] = getProcessText();

  const onStepClicked = (index) => {
    setActiveStep(steps[index]);
    setOpen(true);
  };

  const getActiveStepNumber = () => {
    return getActiveStepIndex()! + 1;
  };
  const getActiveStepIndex = () => {
    return activeStep && steps.map((step) => step.text).indexOf(activeStep.text);
  };

  const handleClose = () => {
    setActiveStep(undefined);
    setOpen(false);
  };

  const goToNextStep = () => {
    setActiveStep(steps[getActiveStepIndex()! + 1]);
  };
  const goToPrevStep = () => {
    setActiveStep(steps[getActiveStepIndex()! + -1]);
  };

  const isLastStep = getActiveStepNumber() === steps.length;
  const isFirstStep = getActiveStepNumber() === 1;

  return (
    <section style={style} className={'ProcessIllustration ' + background}>
      <div className='content'>
        <NkTypography type='smallGreenHeader' center style={{marginBottom: 16}}>
          {'Slik søker du i eByggesøk'}
        </NkTypography>
        <NkTypography center type='heading2' style={{marginBottom: 40}}>
          Dette innebærer søknadsprosessen
        </NkTypography>
        <ol className='stepContainer'>
          {steps.map((step, index) => (
            <Step
              key={index + step.text}
              text={step.text}
              stepNumber={index + 1}
              illustration={step.illustration}
              onClick={onStepClicked}
              ariaExpanded={isOpen}
            />
          ))}
        </ol>

        <Dialog fullWidth={true} open={!!activeStep} onClose={handleClose} maxWidth={'md'}>
          <DialogTitle>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p className='stepIndication'>{getActiveStepNumber() + '/' + steps.length}</p>
              <p className='small-header'>Steg {getActiveStepNumber()}</p>
              <IconButton aria-label='close' onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <div style={{width: 560, maxWidth: '70vw', margin: 'auto', minHeight: 200}}>
              <NkTypography type='heading2' center>
                {activeStep?.text || ''}
              </NkTypography>
              <NkTypography
                center
                type='largeParagraph'
                style={{margin: 'auto', maxWidth: 540, marginTop: 20, marginBottom: 20}}
              >
                {activeStep?.moreInfoText || ''}
              </NkTypography>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 30,
            }}
          >
            <DesktopContainer>
              <NkButton disabled={isFirstStep} onClick={goToPrevStep} type='default'>
                Forrige steg
              </NkButton>
            </DesktopContainer>
            <TabletAndMobileContainer>
              <NkButton style={{minWidth: 100}} disabled={isFirstStep} onClick={goToPrevStep} type='default'>
                Forrige
              </NkButton>
            </TabletAndMobileContainer>
            <DesktopContainer>
              <NkButton disabled={isLastStep} onClick={goToNextStep} type='callToAction'>
                Neste steg
              </NkButton>
            </DesktopContainer>
            <TabletAndMobileContainer>
              <NkButton style={{minWidth: 100}} disabled={isLastStep} onClick={goToNextStep} type='callToAction'>
                Neste
              </NkButton>
            </TabletAndMobileContainer>
          </DialogActions>
        </Dialog>
      </div>
    </section>
  );
};
export default ProcessIllustration;
