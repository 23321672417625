import {Link} from 'react-router-dom';
import {appInsightsInstance} from '../../../AppInsightsSetup';
import NkTypography from '../../../components/NkTypography/NkTypography';
import {ApplicationRoutes} from '../../../utils/routes/ApplicationRoutes';
import getCanIApplyQuestionOptions from '../../CanIApply/optionLists/getCanIApplyQuestionOptions';
import './VeilederPreview.scss';

const VeilederPreview = () => {
  const trackChoice = (val) => {
    appInsightsInstance?.trackEvent({name: 'kom-i-gang'}, {buttonVersion: 'veiled-preview', choice: val});
  };

  const categories = getCanIApplyQuestionOptions(undefined, undefined, 'WHAT_TO_DO');

  return (
    <section className='VeilederPreview' style={{width: '100%', display: 'flex'}}>
      <div className='content' style={{display: 'flex', flexDirection: 'column'}}>
        <NkTypography type='smallGreenHeader' style={{marginBottom: 20}}>
          Veiledning
        </NkTypography>
        <NkTypography type='heading2' style={{marginBottom: 20}}>
          Hva vil du gjøre på din eiendom?
        </NkTypography>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className='boxContainer'>
            {categories?.map((cat) => {
              return (
                <Link
                  onClick={() => {
                    trackChoice(cat.value);
                  }}
                  to={ApplicationRoutes.kan_jeg_soke.path + '/' + cat.value}
                  key={cat.text}
                  className='choice-box'
                  style={{textAlign: 'left', display: 'grid'}}
                >
                  {cat.value === 'combination' ? (
                    <>
                      <NkTypography type='caption' style={{marginBottom: 10}}>
                        En kombinasjon av de andre
                      </NkTypography>
                      <NkTypography type='smallParagraph'>{cat.helpText}</NkTypography>
                    </>
                  ) : (
                    <>
                      <NkTypography tag='span' type='caption' style={{marginBottom: 10}}>
                        Jeg vil {cat.value === 'fasadeendring' ? 'endre fasade' : cat.text}
                      </NkTypography>
                      <NkTypography tag='span' type='smallParagraph'>
                        {cat.helpText}
                      </NkTypography>
                    </>
                  )}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
export default VeilederPreview;
