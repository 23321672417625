export default (ua: string) => {
  return isMsie(ua) || isTrident(ua);
};

function isMsie(ua: string) {
  const msie = ua.indexOf('MSIE ');
  if (msie === -1) {
    return false;
  }
  return !!parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
}

function isTrident(ua: string) {
  const trident = ua.indexOf('Trident/');
  if (trident === -1) {
    return false;
  }
  const rv = ua.indexOf('rv:');
  return !!parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
}
