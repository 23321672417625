import {DemolishedBuilding} from '../../state/buildingsState';
import {ApplicationId} from './../ebyggesokServices/userOrderService';
import {getAsyncAxiosInstanceWithIdportenHeader} from './axiosService';

export function saveBuildingsite(
  applicationId: ApplicationId,
  propertyId: string | null,
  teigGeometry: GeoJSON.Polygon | GeoJSON.MultiPolygon | null
) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/LagreByggested/`, {
      SoknadId: applicationId,
      Eiendomsident: propertyId,
      TeigGeometry: teigGeometry !== null ? JSON.stringify(teigGeometry) : null,
    })
  );
}

export async function saveKartverksId(applicationId: ApplicationId, kartverksId?: string) {
  const instance = await getAsyncAxiosInstanceWithIdportenHeader();
  return await instance.post('/LagreKartverksId/', {
    SoknadId: applicationId,
    kartverksId: kartverksId,
  });
}

export async function saveBuilding(applicationId: ApplicationId, buildingGeometry: GeoJSON.Geometry | null) {
  const instance = await getAsyncAxiosInstanceWithIdportenHeader();
  return await instance.post(`/LagreBygning/`, {
    SoknadId: applicationId,
    BuildingGeometry: buildingGeometry !== null ? JSON.stringify(buildingGeometry) : null,
  });
}

export async function addOrUpdateDemolishedBuilding(applicationId: ApplicationId, building: DemolishedBuilding) {
  const instance = await getAsyncAxiosInstanceWithIdportenHeader();
  return await instance.post(`/LeggTilEllerEndreRiving/`, {
    DemolishionId: building.demolishionId,
    BuildingId: building.BuildingId,
    Geometry: JSON.stringify(building.geometry),
    Type: building.BuildingType,
    PartialDemolishion: building.partialDemolishion,
    Area: building.area,
    ApplicationId: applicationId,
  });
}

export async function removeDemolishedBuilding(applicationId: ApplicationId, demolishionId: string) {
  const instance = await getAsyncAxiosInstanceWithIdportenHeader();
  return await instance.post(`/FjernRiving/`, {
    DemolishionId: demolishionId,
    ApplicationId: applicationId,
  });
}
