import {StepType} from '../components/Steps/stepsForApplicationTypeAndTiltak';
import {InnsendingType} from '../services/ebyggesokServices/innsendingService';
import {ApplicationId} from '../services/ebyggesokServices/userOrderService';
import {Brand} from '../utils/dibkSchemas/models/models';
import {InnsendingXmlId} from './innsendingState';

export type SuppleringId = Brand<number, 'SuppleringId'>;
export type AltinnDraftReference = Brand<string, 'AltinnDraftReference'>;

export type SoknadType = 'byggesoknad' | 'supplering' | 'ferdigattest';

export const soknadVersionInProd: number = 3;
export interface ApplicationState {
  applicationId?: ApplicationId;
  applicationName?: string;
  suppleringId?: SuppleringId;
  isFetchingData: boolean;
  hasFetchedData: boolean;
  displayAutomaticQuestions: boolean;
  soknadWithVersion3: boolean;
  applicationCreatedBeforeNewRules: boolean;
  userMustCheckForOutdatedData: boolean;
  stepType: StepType;
  soknadType: SoknadType;
  userConfirmations: {
    acceptCorrectInfoResponsibility: boolean;
    acceptCorrectNabovarselResponsibility: boolean;
    acceptCost: boolean;
    acceptPublicInfo: boolean;
    acceptCannotChange: boolean;
  };
}

export const initialState: ApplicationState = {
  isFetchingData: false,
  hasFetchedData: false,
  displayAutomaticQuestions: false,
  userMustCheckForOutdatedData: false,
  soknadWithVersion3: soknadVersionInProd === 3 ? true : false,
  userConfirmations: {
    acceptCorrectInfoResponsibility: false,
    acceptCorrectNabovarselResponsibility: false,
    acceptCost: false,
    acceptPublicInfo: false,
    acceptCannotChange: false,
  },
  applicationCreatedBeforeNewRules: false,
  stepType: 'byggesoknad', //Default value
  soknadType: 'byggesoknad',
};

export type Action =
  | {
      type: 'isFetchingData';
      value: boolean;
    }
  | {
      type: 'hasFetchedData';
    }
  | {
      type: 'setApplicationName';
      value: string;
    }
  | {
      type: 'tiltaksCheckboxChecked';
      value: boolean;
    }
  | {
      type: 'displayAutomaticQuestions';
      value: boolean;
    }
  | {
      type: 'setSoknadHasVersion3';
      value: boolean;
    }
  | {
      type: 'setUserMustCheckForOutdatedData';
      value: boolean;
    }
  | {
      type: 'setAcceptCost';
      value: boolean;
    }
  | {
      type: 'setAcceptCannotChange';
      value: boolean;
    }
  | {
      type: 'setAcceptPublicInfo';
      value: boolean;
    }
  | {
      type: 'setAcceptCorrectInfoResponsibility';
      value: boolean;
    }
  | {
      type: 'setAcceptCorrectNabovarselResponsibility';
      value: boolean;
    }
  | ResetSuppleringIdAction
  | {type: 'setApplicationCreatedBeforeNewRules'; value: boolean}
  | changeSuppleringAction
  | NewApplicationAction
  | ChangeApplicationAction;

export type NewApplicationAction = {
  type: 'setNewApplicationId';
  value: ApplicationId;
};

export type changeSuppleringAction = {
  type: 'changeSupplering';
  value: {innsendingType: InnsendingType; suppleringId: SuppleringId; innsendingXmlId?: InnsendingXmlId};
};

export type ResetSuppleringIdAction = {
  type: 'resetSuppleringId';
};

export type ChangeApplicationAction =
  | {
      type: 'changeApplication';
      value: {applicationId: ApplicationId; stepType: StepType; suppleringId?: SuppleringId};
    }
  | {
      type: 'unsetCurrentApplication';
    };

export const reducer = (state: ApplicationState, action: Action): ApplicationState => {
  switch (action.type) {
    case 'isFetchingData':
      return {...state, isFetchingData: action.value, hasFetchedData: false};
    case 'hasFetchedData':
      return {...state, isFetchingData: false, hasFetchedData: true};
    case 'changeApplication':
      return {
        ...state,
        applicationId: action.value.applicationId,
        suppleringId: action.value.suppleringId,
        stepType: action.value.stepType,
        soknadType:
          action.value.stepType === 'mangel' || action.value.stepType === 'ettersending'
            ? 'supplering'
            : action.value.stepType,
      };
    case 'changeSupplering':
      return {
        ...state,
        suppleringId: action.value.suppleringId,
        stepType: action.value.innsendingType,
      };
    case 'resetSuppleringId':
      return {
        ...state,
        suppleringId: undefined,
      };
    case 'setNewApplicationId':
      return {
        ...state,
        applicationId: action.value,
      };

    case 'setApplicationName':
      return {...state, applicationName: action.value};
    case 'setUserMustCheckForOutdatedData':
      return {...state, userMustCheckForOutdatedData: action.value};
    case 'unsetCurrentApplication':
      return {...initialState};
    case 'displayAutomaticQuestions':
      return {...state, displayAutomaticQuestions: action.value};
    case 'setSoknadHasVersion3':
      return {...state, soknadWithVersion3: action.value};

    case 'setAcceptCost':
      return {...state, userConfirmations: {...state.userConfirmations, acceptCost: action.value}};
    case 'setAcceptCannotChange':
      return {...state, userConfirmations: {...state.userConfirmations, acceptCannotChange: action.value}};
    case 'setAcceptPublicInfo':
      return {
        ...state,
        userConfirmations: {...state.userConfirmations, acceptPublicInfo: action.value},
      };
    case 'setAcceptCorrectInfoResponsibility':
      return {
        ...state,
        userConfirmations: {
          ...state.userConfirmations,
          acceptCorrectInfoResponsibility: action.value,
        },
      };
    case 'setAcceptCorrectNabovarselResponsibility':
      return {
        ...state,
        userConfirmations: {
          ...state.userConfirmations,
          acceptCorrectNabovarselResponsibility: action.value,
        },
      };
    case 'setApplicationCreatedBeforeNewRules':
      return {...state, applicationCreatedBeforeNewRules: action.value};
    default:
      return state;
  }
};
