import {ApplicationId} from './../ebyggesokServices/userOrderService';
import {getAsyncAxiosInstanceWithIdportenHeader} from './axiosService';

type ExtraInfoPdf = {PdfUrl: string; FileSize: number};

export function createExtraInfoPdf(applicationId: ApplicationId) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post<ExtraInfoPdf>(`/CreateExtraInfoPdf`, {
      SoknadId: applicationId,
    })
  );
}
