import axios, {AxiosInstance, AxiosRequestHeaders} from 'axios';
import {getConfigValue} from '../../config';

export const access_token_session_key = 'ua_access_token';

export const getIdportenHeader = () => {
  const accessToken = sessionStorage.getItem(access_token_session_key);
  return {
    Authorization: 'Bearer ' + accessToken,
    'X-WAAPI-Profile': 'ebyggesok-Client',
  };
};

const apiKey = getConfigValue('norkartApiKey');

export function getAxiosInstanceWithApiKeyHeader(url?: string): AxiosInstance {
  const serverUrl = getConfigValue('serverUrl');

  return axios.create({
    baseURL: url || serverUrl,
    headers: {'X-WAAPI-TOKEN': apiKey},
  });
}

export async function getAsyncAxiosInstanceWithIdportenHeader(
  nkServiceUrl?: string,
  extraHeaders?: AxiosRequestHeaders | undefined
): Promise<AxiosInstance> {
  const ebyggesokServerUrl = getConfigValue('serverUrl');
  const headers = getIdportenHeader();
  return axios.create({
    baseURL: nkServiceUrl || ebyggesokServerUrl,
    headers: extraHeaders ? {...headers, ...extraHeaders} : headers,
    withCredentials: nkServiceUrl ? undefined : true,
  });
}

export function getAxiosInstanceWithIdportenHeader(nkServiceUrl?: string, extraHeaders?): AxiosInstance {
  const ebyggesokServerUrl = getConfigValue('serverUrl');
  const headers = getIdportenHeader();
  return axios.create({
    baseURL: nkServiceUrl || ebyggesokServerUrl,
    headers: extraHeaders ? {...headers, ...extraHeaders} : headers,
    withCredentials: nkServiceUrl ? undefined : true,
  });
}

export function getNoHeaderAxiosInstance(url?: string): AxiosInstance {
  const ebyggesokServerUrl = getConfigValue('serverUrl');

  return axios.create({
    baseURL: url || ebyggesokServerUrl,
  });
}

export function getAltinnAxiosInstance(token?: string | null) {
  const baseUrl = getConfigValue('altinnUrl');
  const accessToken = token !== undefined && token !== null ? token : sessionStorage.getItem('access_token');
  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/hal+json',
      ApiKey: getConfigValue('altinnApiKey'),
      Authorization: 'Bearer ' + accessToken,
    },
  });
}
