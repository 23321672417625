import React from 'react';
import {goToIdPorten, signOutIdporten} from '../../services/idportenService';
import {Personnummer, UserContext} from '../../state/userStore';
import {ApplicationRoutes} from '../../utils/routes/ApplicationRoutes';
import NkButton from '../Button/NkButton';
import NkLink from '../NkLink/NkLink';
import UserActionsWindow from './UserActionsWindow/UserActionsWindow';

const UserInfo = () => {
  const {userState, userDispatch} = React.useContext(UserContext);
  const [displayUserActions, setDisplayUserActions] = React.useState(false);

  const signOut = () => {
    userDispatch({type: 'resetUser'});
    userDispatch({type: 'setRouteBeforeSignOut', value: window.location.pathname});
    signOutIdporten();
  };

  const prettifyBirthday = (pnr: Personnummer) => {
    const birth = pnr.substring(0, 6);
    return birth.substring(0, 2) + '.' + birth.substring(2, 4) + '.' + birth.substring(4, 6);
  };

  return (
    <>
      {userState.userId ? (
        <div className='user-div'>
          <NkLink
            className='frontpage-nav-link'
            onClick={() => {
              userDispatch({type: 'resetSevereError'});
            }}
            style={{minWidth: 106, alignSelf: 'center', marginRight: 40, padding: 5}}
            href={ApplicationRoutes.myApplications.path}
          >
            Mine søknader
          </NkLink>
          <div className='id-porten'>
            <img
              alt='Idporten logo'
              width='24px'
              height='24px'
              src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1606234577/eBygges%C3%B8k%20UA/idporten-logo.svg'
            />
          </div>
          <button
            className='userInfo'
            onClick={() => (displayUserActions ? setDisplayUserActions(false) : setDisplayUserActions(true))}
            onMouseEnter={() => setDisplayUserActions(true)}
            onMouseLeave={() => setDisplayUserActions(false)}
          >
            <p>{prettifyBirthday(userState.userId)}</p>
            <div className={displayUserActions ? 'triangle_up' : 'triangle_down'} />
            {displayUserActions && (
              <UserActionsWindow
                onCloseWindow={() => setDisplayUserActions(false)}
                onOpenWindow={() => setDisplayUserActions(true)}
                logoutIdporten={signOut}
              />
            )}
          </button>
        </div>
      ) : (
        <NkButton
          type='callToAction'
          className='login'
          onClick={() => {
            goToIdPorten(userDispatch);
          }}
          style={{minWidth: 90, maxHeight: 35}}
        >
          Logg inn
        </NkButton>
      )}
    </>
  );
};
export default UserInfo;
