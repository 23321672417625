import {TextObject, rawTextObject} from './textObject';

//To avoid having to create different text files just for changing words describing the chosen main category,
//we replace this dummy-word into the name of the chosen main categories

const wordToReplace = '\\[gjøre\\]'; //Have to add the backslash for [] to be read correctly in the regex
const regexWordToReplace = new RegExp(wordToReplace, 'g');
export type mainCategory = 'change' | 'create' | 'build' | 'demolish';

export const getTextObject = (): TextObject => {
  const convertedTextObject = sessionStorage.getItem('convertedTextObject');
  if (convertedTextObject && convertedTextObject !== null) {
    return JSON.parse(convertedTextObject);
  } else {
    //If not converted and saved yet, it means the current application has no chosen main category.
    //We can then safely use the raw version since no questions needing the replacement are visible before the main category is chosen
    return rawTextObject;
  }
};

export const resetConvertedTextObject = () => {
  sessionStorage.removeItem('convertedTextObject');
};

export const convertAndSaveTextObject = (selectedMainCategory: mainCategory) => {
  const textObj = getWordReplacedTextObject(rawTextObject, selectedMainCategory);
  sessionStorage.setItem('convertedTextObject', textObj);
};

export const getWordReplacedTextObject = (textObject: TextObject, selectedMainCategory: mainCategory, test?) => {
  const mainCategoriesString = getMainCategoryStrings(selectedMainCategory);
  const replacedTextObject = JSON.stringify(textObject).replace(regexWordToReplace, mainCategoriesString);
  return replacedTextObject;
};

export const getMainCategoryStrings = (mainCategory: mainCategory) => {
  if (mainCategory === 'change') return 'bruksendre';
  else if (mainCategory === 'demolish') return 'rive';
  else if (mainCategory === 'create') return 'opprette';
  /* (mainCategory === 'build') */ else return 'bygge';
};
