import {useContext, useEffect, useState} from 'react';
import {getStatusAlerts} from '../../services/ebyggesokServices/statusService';
import {StatusAlertContext} from '../../state/statusAlertState';

const StatusAlert = () => {
  const {statusAlertDispatch} = useContext(StatusAlertContext);

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (!intervalId) {
      getStatusAlerts(statusAlertDispatch);
      const timerId = setInterval(() => getStatusAlerts(statusAlertDispatch), 5 * 60 * 1000); // 5 min interval
      setIntervalId(timerId);
    } else if (intervalId) {
      clearInterval(intervalId);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  });

  return null;
};
export default StatusAlert;
