/*
    Copied from Kommunekart
*/
// tslint:disable:no-string-literal
// tslint:disable:triple-equals
// tslint:disable:max-line-length
// dictionary of norwegian and sami characters associated with rising numerical values according to the position 
// in the alphabeth.
// private part of norwegian sort implementation
var charVal = {};

charVal['A'] = 0;
charVal['Á'] = 0.5;
charVal['B'] = 1;
charVal['C'] = 2;
charVal['Č'] = 2.5;
charVal['D'] = 3;
charVal['Đ'] = 3;
charVal['E'] = 4;
charVal['F'] = 5;
charVal['G'] = 6;
charVal['H'] = 7;
charVal['I'] = 8;
charVal['J'] = 9;
charVal['K'] = 10;
charVal['L'] = 11;
charVal['M'] = 12;
charVal['N'] = 13;
charVal['Ŋ'] = 13.5;
charVal['O'] = 14;
charVal['P'] = 15;
charVal['Q'] = 16;
charVal['R'] = 17;
charVal['S'] = 18;
charVal['Š'] = 18.5;
charVal['T'] = 19;
charVal['Ŧ'] = 19.5;
charVal['U'] = 20;
charVal['V'] = 21;
charVal['W'] = 22;
charVal['X'] = 23;
charVal['Y'] = 24;
charVal['Z'] = 25;
charVal['Ž'] = 25;
charVal['Æ'] = 26;
charVal['Ø'] = 27;
charVal['Å'] = 28;
charVal[' '] = -12;
charVal['-'] = -11;

// add numbers
charVal['0'] = -10;
charVal['1'] = -9;
charVal['2'] = -8;
charVal['3'] = -7;
charVal['4'] = -6;
charVal['5'] = -5;
charVal['6'] = -4;
charVal['7'] = -3;
charVal['8'] = -2;
charVal['9'] = -1;

// private part of norwegian sort implementation
function _charCompareNO(charA, charB) {
    if (charVal[charA] > charVal[charB]) {
        return 1;
    } else if (charVal[charA] < charVal[charB]) {
        return -1;

    } else if (charVal[charA] === charVal[charB]) {
        return 0;
    }
    return 1;
}

/**
 *   returns 1(or more) when A i s greater than B, 0 when equal and -1(or less) when A smaller than B     
 *   1 if no known comparison exists
 */
export function norwegianWordComp(wordA, wordB) {
    var wA = wordA.toUpperCase();
    var wB = wordB.toUpperCase();
    if (wA === wB) { return 0; }

    // handle number case
    var aIsNumber = isNumber(wA);
    var bIsNumber = isNumber(wB);
    if (aIsNumber || bIsNumber) {
        if (!aIsNumber) { return 1; }
        if (!bIsNumber) { return -1; }
        return (wA) - (wB);
    }


    // start comparing each char
    var res;
    for (var i = 0; i < wA.length; i++) {
        if (wB[i] !== undefined) {
            res = _charCompareNO(wA[i], wB[i]);
            if (res === 1) { return 1; }
            if (res === -1) { return -1; }
    
        } else if (wB[i] === undefined) { // wA is equal character for character up til the previous character but is at least one character longer than wB and thus 'greater' (+1).
            return 1;
        }
    }

    // we have iterated through all characters in 
    // wA is shorter than wB and thus smaller (-1) 
    if (wB.length > wA.length) { return -1; }

    // unable to compare the words
    return 1;
}

// some inspiration from http://stackoverflow.com/a/4373037
/**
 *   sorts numeric parts as numbers -> [12a,9a] -> [9a, 12a]
 */
export function norwegianStringComp(stringA, stringB) {

    // regex
    var rIsNumberOrNotNumber = /(\d+)|(\D+)/g; // used to find elements in a string that either contains number or not. this is the case for '12', 'abc', but not for '12abc'

    // ex. 'Eksempelveien 1B' -> ['Eksempelveien ', '1', 'B']
    var stringElementsA = String(stringA).toLowerCase().match(rIsNumberOrNotNumber);
    var stringElementsB = String(stringB).toLowerCase().match(rIsNumberOrNotNumber);
    while (stringElementsA?.length && stringElementsB?.length) {

        // remove first element
        var aElement = stringElementsA.shift();
        var bElement = stringElementsB.shift();

        // compare next elements if current element for a and b is equal
        if (aElement === bElement) {
            continue;
        }

        return norwegianWordComp(aElement, bElement);         
    }

    // one is a sub element of the another. then the biggest comes last
    return stringA.length - stringB.length;
    
}


export function sortNorwegian(list) {
    return list.sort(norwegianStringComp);
}


function isNumber(value) {
    return value === parseFloat(value);
}

export default {
    sortNorwegian: sortNorwegian,
    norwegianStringComp: norwegianStringComp
}