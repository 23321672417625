import {SeverityLevel} from '@microsoft/applicationinsights-web';
import DOMPurify from 'dompurify';
import {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {appInsightsInstance} from '../../AppInsightsSetup';
import {nkColors} from '../../colors';
import {SubmissionType, getPaymentStatus, loadEasyScript} from '../../services/ebyggesokServices/paymentService';
import {ApplicationId} from '../../services/ebyggesokServices/userOrderService';
import {goToIdPorten} from '../../services/idportenService';
import {UserContext} from '../../state/userStore';
import {ApplicationRoutes} from '../../utils/routes/ApplicationRoutes';
import NkButton from '../Button/NkButton';
import NkTypography from '../NkTypography/NkTypography';
import './PaymentModule.scss';

enum PaymentStatus {
  FormNotSubmitted, // User has not submitted payment
  FormSubmitted, // User has submitted payment, but we have not gotten confirmation from Nets (webhook)
  PaymentAuthorized, // User has submitted payment and we have confirmation from nets that the payment is reserved/authorized
}

// Payment module that is loaded without the need for state/context to support vipps payment on mobile
const PaymentModule = () => {
  const history = useHistory();
  const {userState, userDispatch} = useContext(UserContext);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.FormNotSubmitted);
  const searchParams = new URLSearchParams(window.location.search);
  const applicationId = parseInt(searchParams.get('applicationId')!) as ApplicationId;
  const paymentId = searchParams.get('paymentId')!;
  const submissionType = searchParams.get('submissionType')!;
  const pathPaymentAuthorized = searchParams.get('paymentAuthorized') === 'true';

  const onPaymentFromCompleted = () => {
    setPaymentStatus(PaymentStatus.FormSubmitted);
  };

  useEffect(() => {
    const sanitizedPaymentId = DOMPurify.sanitize(paymentId);
    loadEasyScript(sanitizedPaymentId, applicationId, onPaymentFromCompleted);
  }, []);

  // Poll backend for success of transaction payment reservation
  /* eslint-disable */
  useEffect(() => {
    let intervalId;
    async function checkNetsResult(intervalId) {
      if (paymentStatus === PaymentStatus.FormSubmitted && !pathPaymentAuthorized) {
        const result = await getPaymentStatus(applicationId as any, intervalId);
        if (result?.data.PaymentStatus === 'PaymentAuthorized') {
          clearInterval(intervalId);
          setPaymentStatus(PaymentStatus.PaymentAuthorized);
          if (userState.isLoggedIn) {
            appInsightsInstance?.trackTrace(
              {
                message: 'nets payment module redirect logged inn user',
                severityLevel: SeverityLevel.Information,
              },
              {soknadId: applicationId, submissionType: submissionType, paymentId: paymentId}
            );
            redirectForward();
          } else {
            appInsightsInstance?.trackTrace(
              {
                message: 'nets payment module add payment authorized to path for none logged in user',
                severityLevel: SeverityLevel.Information,
              },
              {soknadId: applicationId, submissionType: submissionType, paymentId: paymentId}
            );
            // Add payment authorized to path to avoid having to poll for payment after login
            history.push(window.location.pathname + window.location.search + '&paymentAuthorized=true');
          }
        }
      }
    }
    if (paymentStatus === PaymentStatus.FormSubmitted && !pathPaymentAuthorized) {
      intervalId = setInterval(() => {
        checkNetsResult(intervalId);
      }, 5000);
    }

    return () => clearInterval(intervalId);
  }, [paymentStatus]);

  const redirectBack = () => {
    switch (submissionType) {
      case SubmissionType.Nabovarsel:
        history.push(ApplicationRoutes.nabovarsel_summary.path);
        break;
      case SubmissionType.UtenAnsvarsrett:
        history.push(ApplicationRoutes.soknad_summary.path);
        break;
      default:
        appInsightsInstance?.trackException(
          {
            error: new Error('redirectBack during payment failed'),
            severityLevel: SeverityLevel.Error,
          },
          {soknadId: applicationId, submissionType: submissionType}
        );
        break;
    }
  };

  const redirectForward = () => {
    history.push(ApplicationRoutes.finalizePayment.path + window.location.search);
  };

  const whiteBackgroundPage = (content) => {
    return (
      <div style={{backgroundColor: 'white', display: 'flex', justifyContent: 'center'}}>
        <div style={{marginTop: 200, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>{content}</div>
      </div>
    );
  };

  const loadingContent = (
    <>
      <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center'}}>
        <div>
          <img
            width='160px'
            alt='illustrasjon'
            src={
              'https://res.cloudinary.com/dgbxah2xy/image/upload/v1605796192/eBygges%C3%B8k%20UA/illustrations/woman_read_newspaper_article_news.svg'
            }
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div>
            <NkTypography type='smallGreenHeader'>Behandler din betaling, vennligst vent</NkTypography>
          </div>
          <div className='dot-flashing'></div>
        </div>
      </div>
    </>
  );

  if (paymentStatus === PaymentStatus.FormSubmitted) {
    return whiteBackgroundPage(loadingContent);
  }

  if (paymentStatus === PaymentStatus.PaymentAuthorized && !userState.isLoggedIn) {
    return (
      <div className='payment-background'>
        <div className='payment-login-container'>
          <img
            style={{marginTop: 44, width: 61.7, height: 49.86}}
            alt='Handlekurv'
            src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1708437600/eBygges%C3%B8k%20UA/shopping-cart.svg'
          ></img>
          <div style={{maxWidth: 160}}>
            <NkTypography style={{color: nkColors.uu_norkart_gronn, fontSize: 20, textAlign: 'center'}} type='heading4'>
              Betaling mottatt
            </NkTypography>
            <NkTypography
              type='largeParagraph'
              style={{marginTop: 8, color: '#000000', fontWeight: 400, fontSize: 16, textAlign: 'center'}}
            >
              Logg inn for å fullføre innsendingen
            </NkTypography>
          </div>
          <NkButton
            onClick={() => goToIdPorten(userDispatch, ApplicationRoutes.finalizePayment.path + window.location.search)}
            type={'callToAction'}
            style={{width: 220, height: 48}}
          >
            Logg inn
          </NkButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'left', marginLeft: '20%', marginTop: '42px'}}>
        <NkButton type='link' arrow='left' onClick={redirectBack}>
          Tilbake
        </NkButton>
      </div>
      <div id='dibs-complete-checkout'> </div>
    </>
  );
};

export default PaymentModule;
