import {AnswerValues} from '../../../../state/questionTypes';
import calculateAvailableArea from '../../../../utils/calculateAvailableArea';
import getSelectedTiltakstyper from '../../../../utils/tiltak/getSelectedTiltakstyper';

export const getMaxUASize = (answers: AnswerValues): number => {
  const tiltakstype = getSelectedTiltakstyper(answers)[0];
  if (!tiltakstype) return 10000; //Dummy value while waiting for load
  if (tiltakstype === 'nyttbyggunder70m2') {
    return 70;
  } else if (tiltakstype === 'tilbyggunder50m2') {
    return 50;
  } else if (tiltakstype === 'driftsbygningtilbyggunder1000m2' || tiltakstype === 'nyttbyggdriftsbygningunder1000m2') {
    return 1000;
  } else if (tiltakstype === 'rivingbygningunder70m2') {
    return 70;
  } else if (tiltakstype === 'rivingdriftsbygninginntill1000m2') {
    return 1000;
  } else if (tiltakstype === 'rivingtilbygginntil50m2') {
    return 50;
  } else if (tiltakstype === 'bruksendringhoveddel') {
    return 100000; //Dummy value, no limit for size of bruksendring room
  } else {
    console.error('unknown tiltakstype to set max size for');
    return 0;
  }
};

export const shouldPerformUgradSizeCheck = (answers: AnswerValues) => {
  const isBuildingWithinYellowArea =
    answers.AREAL_FORMAAL_COLOR_IS_YELLOW === true || answers.BUILD_WITHIN_BOLIGFORMAAL === true;
  return isBuildingWithinYellowArea;
};

export const getMaxInPlanBuildingSize = (answers: AnswerValues) => {
  if (answers.MAX_ALLOWED_AREA_IN_PLAN !== undefined && (answers.MAX_ALLOWED_AREA_IN_PLAN as number) > 0) {
    return answers.MAX_ALLOWED_AREA_IN_PLAN as number;
  }
};

export const getmaxSizeDueToUgrad = (answers: AnswerValues) => {
  return calculateAvailableArea(answers);
};

type sizeLimit = {type: 'plan' | 'ugrad' | 'ua'; limit: number};
export const getStrictestSizeLimit = (answers: AnswerValues): sizeLimit => {
  const planLimit = getMaxInPlanBuildingSize(answers) || 100000;
  const ugradLimit = getmaxSizeDueToUgrad(answers);
  const uaLimit = getMaxUASize(answers);

  let res: sizeLimit = {type: 'plan', limit: planLimit};
  if (ugradLimit < res.limit) {
    res = {type: 'ugrad', limit: ugradLimit};
  }
  if (uaLimit < res.limit) {
    res = {type: 'ua', limit: uaLimit};
  }
  return res;
};
