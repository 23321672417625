import {CustomModes} from '../scenes/PlacementComponents/Draw/DrawableMap/mapboxDrawExtension/modes/addCustomModes';

export interface State {
  buildingsForTeigGeometry?: {
    teigId: number | undefined;
    buildings: ExistingBuilding[];
  };
  demolished: DemolishedBuilding[];
  snapBuildingId?: string;
  newBuilding?: NewBuilding;
  surroundingBuildings?: ExistingBuilding[];
}

export interface ExistingBuilding {
  BuildingId?: string;
  geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon;
  BuildingType: string;
  Status?: string;
}
export interface ExistingBuildingResponse {
  BuildingId?: string;
  geometry: GeoJSON.Polygon;
  BuildingType: string;
  Status?: string;
}

export interface NewBuilding {
  geometry: GeoJSON.Polygon;
  drawMode: DrawMode;
}

export type DrawMode =
  | CustomModes
  | 'simple_select'
  | 'direct_select'
  | 'draw_line_string'
  | 'draw_polygon'
  | 'draw_point';

export type DemolishedBuilding = ExistingBuilding & {
  partialDemolishion: boolean;
  area: string; // is string to be able to edit float numbers
  demolishionId?: string;
};

export const initialState: State = {
  demolished: [],
};

export type Action =
  | {
      type: 'setBuildingsForTeigGeometry';
      value: State['buildingsForTeigGeometry'];
    }
  | {
      type: 'addDemolishedBuiling';
      value: DemolishedBuilding;
    }
  | {
      type: 'removeDemolishedBuilding';
      value: string;
    }
  | {
      type: 'updateDemolishedBuiling';
      value: DemolishedBuilding;
    }
  | {type: 'resetDemolishedBuildings'}
  | {
      type: 'setSnapBuildingId';
      value: string | undefined;
    }
  | {
      type: 'setNewBuilding';
      value: NewBuilding;
    }
  | {
      type: 'setSurroundingBuildings';
      value: ExistingBuilding[];
    };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setBuildingsForTeigGeometry': {
      return {
        ...state,
        buildingsForTeigGeometry: action.value,
      };
    }
    case 'addDemolishedBuiling': {
      return {
        ...state,
        demolished: [...state.demolished, action.value],
      };
    }
    case 'setNewBuilding': {
      return {
        ...state,
        newBuilding: action.value,
      };
    }
    case 'setSurroundingBuildings': {
      return {
        ...state,
        surroundingBuildings: action.value,
      };
    }
    case 'removeDemolishedBuilding': {
      return {
        ...state,
        demolished: state.demolished.filter((d) => d.demolishionId !== action.value),
      };
    }
    case 'updateDemolishedBuiling': {
      return {
        ...state,
        demolished: state.demolished.map((d) => {
          if (d.demolishionId === action.value.demolishionId) {
            return action.value;
          } else {
            return d;
          }
        }),
      };
    }
    case 'resetDemolishedBuildings': {
      return {
        ...state,
        demolished: [],
      };
    }
    case 'setSnapBuildingId': {
      return {
        ...state,
        snapBuildingId: action.value,
      };
    }
    default:
      return state;
  }
};
