import React from 'react';
import {
  defaultArticleSEOTags,
  getArticleMetaData,
  validateMetaHovedvalg,
  validateMetaSteg,
  validateRequiredMetadata,
} from './helpers';
import NkTypography from '../../../components/NkTypography/NkTypography';
import {nkColors} from '../../../colors';

type Props = {article_name: string};
const DisplayArticleMetadata = ({article_name}: Props) => {
  const tags = getArticleMetaData('tags', article_name) + defaultArticleSEOTags;
  const description = getArticleMetaData('description', article_name);
  const title = getArticleMetaData('title', article_name);
  const hovedvalg = getArticleMetaData('hovedvalg', article_name);
  const steg = getArticleMetaData('steg', article_name);

  React.useEffect(() => {
    hovedvalg && validateMetaHovedvalg(hovedvalg);
    steg && validateMetaSteg(steg);
    validateRequiredMetadata(article_name);
  }, []);

  const row = (metaName, metaContent) => (
    <div style={{display: 'flex'}}>
      <NkTypography type='normalParagraph' style={{fontWeight: 500, marginRight: 10, color: nkColors.testEnvColor}}>
        {metaName}:
      </NkTypography>
      <NkTypography type='normalParagraph'>{metaContent}</NkTypography>
    </div>
  );

  const header = (name: string) => (
    <NkTypography type='heading4' tag='p' style={{color: nkColors.testEnvColor, marginTop: 0}}>
      {name}:
    </NkTypography>
  );

  return (
    <div
      style={{
        margin: 'auto',
        padding: 10,
        backgroundColor: 'white',
        border: `3px solid ${nkColors.testEnvColor}`,
        flexDirection: 'row',
        display: 'flex',
      }}
    >
      <div style={{marginRight: 30, maxWidth: 500}}>
        {header('SEO data')}
        {row('Tags', tags)}
        {row('Description', description)}
        {row('Title', title)}
      </div>

      <div>
        {header('Filtreringstagger')}
        {row('Hovedvalg', hovedvalg)}
        {row('Steg', steg)}
      </div>
    </div>
  );
};
export default DisplayArticleMetadata;
