import {articleSteps, getArticleMetaData, hovedvalg, validateRequiredMetadata} from './ArticlePage/helpers';

export type ParsedArticle = {
  name: string;
  img: string;
  header: string;
  ingress?: string;
  steg?: articleSteps[];
  hovedvalg?: hovedvalg[];
};

export const parseArticlePreview = (articleName: string): ParsedArticle => {
  validateRequiredMetadata(articleName);

  getArticleMetaData('img', articleName);
  return {
    name: articleName,
    img: getArticleMetaData('img', articleName)!,
    header: getArticleMetaData('header', articleName)!,
    steg: getArticleMetaData('steg', articleName) as articleSteps[] | undefined,
    hovedvalg: getArticleMetaData('hovedvalg', articleName) as hovedvalg[] | undefined,
    ingress: getArticleMetaData('ingress', articleName),
  };
};

export type what_to_do_option = 'change' | 'build' | 'create' | 'demolish' | 'fasadeendring';
export const filterOnHovedvalg = (articles: ParsedArticle[], what_to_do: what_to_do_option | undefined) => {
  const filteredArticles = articles.filter((article) => {
    if (what_to_do === undefined || article.hovedvalg === undefined) {
      return true;
    } else if (what_to_do === 'change') {
      return article.hovedvalg?.includes('bruksendre');
    } else if (what_to_do === 'build') {
      return article.hovedvalg?.includes('bygge');
    } else if (what_to_do === 'demolish') {
      return article.hovedvalg?.includes('rive');
    } else if (what_to_do === 'create') {
      return article.hovedvalg?.includes('fradele');
    } else if (what_to_do === 'fasadeendring') {
      return article.hovedvalg?.includes('fasadeendring');
    } else {
      return false;
    }
  });
  return filteredArticles;
};
