import {DemolishedBuilding} from '../state/buildingsState';
import {AnswerValues} from '../state/questionTypes';

//Hvor mye areal skal være satt av til parkering
export const getParkingTerrainArea = (answers: AnswerValues) => {
  return (
    ((answers.PARKING_LOT_IN_TERRAIN_COUNT as number | undefined) || 0) * ((answers.PARKING_AREA_SIZE || 0) as number)
  );
};

export const validGradOfUtnyttingTypes = {
  '%annet': 'Annet i prosent',
  '%TU': 'Prosent tomteutnyttelse',
  '%BRA': 'Prosent bruksareal',
  '%BYA': 'Prosent bebygd areal',
  'T-BRA': 'Totalt bruksareal',
  BRA: 'Bruksareal',
  BYA: 'Bebygd areal',
};
// export type ugradOption = '%' | '%-BYA' | '%-TU' | '%-BRA' | 'BRA' | 'T-BRA' | 'BYA';

export type ugradOption =
  | 'U-grad'
  | 'Prosent tomteutnyttelse, %TU'
  | 'Prosent bruksareal, %BRA'
  | 'Prosent bebygd areal, %BYA'
  | 'Totalt bruksareal, T-BRA'
  | 'Bruksareal, BRA'
  | 'Bebygd areal, BYA'
  | 'Ingen av disse';

export const getTotalAvailableArea = (answers: AnswerValues, demolishedBuildings?: DemolishedBuilding[]) => {
  //Avhengig av type ugrad som er oppgitt i plan må det beregnes eller ikke:
  let totalAvailable;
  const needToCalculateUgrad = answers.UGRAD_LIMIT_PERCENT !== undefined;
  totalAvailable = needToCalculateUgrad
    ? calculateUgrad(answers)
    : (answers.TILLATT_TOMTEAREAL_WHEN_KVM_UGRAD as number);

  let demolishedArea = 0;
  try {
    if (demolishedBuildings) {
      demolishedArea = demolishedBuildings.reduce((a, b) => a + parseFloat(b.area), 0);
    }
  } catch {
    //do nothing
  }
  totalAvailable += demolishedArea;
  return totalAvailable;
};

export const calculateUgrad = (answers: AnswerValues) => {
  return ((answers.UGRAD_LIMIT_PERCENT as number) * (answers.TILLATT_TOMTEAREAL_WHEN_PERCENT_UGRAD as number)) / 100;
};
