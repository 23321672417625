import {tiltakstyperThatCanCauseSoknad} from '../supportedTiltakstyper';

//Godkjente beskrivelser (official value) https://register.geonorge.no/byggesoknad/tiltakstyperutenansvarsrett
export type tiltakTypeDescriptionsObj = {officialValue: string; displayToUser: string};

export const tiltakTypeDescription: {
  [key in tiltakstyperThatCanCauseSoknad]: tiltakTypeDescriptionsObj;
} = {
  /* First ones here are only used for søke-resultpage in order to tell user what the application is for */
  nyttbyggunder50m2: {
    officialValue: 'Frittliggende bygg under 50m²',
    displayToUser: '--', //Dummy-value, type is cast to valid ua type once application is started
  },
  rivingbygningunder50m2: {
    officialValue: 'Riving av frittliggende bygning under 15m²',
    displayToUser: '--', //Dummy-value, type is cast to valid ua type once application is started
  },
  tilbyggUnder15m2: {
    officialValue: 'Tilbygg under 15m²',
    displayToUser: '--', //Dummy-value, type is cast to valid ua type once application is started
  },
  /* ----- */
  annet: {
    officialValue: 'Annet - mindre tiltak på bebygd eiendom', //IKKE ENDRE
    displayToUser: 'Annet (kun etter avtale med kommunen)',
  },
  nyttbyggunder70m2: {
    officialValue: 'Nytt bygg - Under 70 m2 - ikke boligformål', //IKKE ENDRE
    displayToUser: 'Frittliggende bygg 50-70m²',
  },
  plasseringmidlertidig: {
    officialValue: 'Plassering av midlertidige bygninger, konstruksjoner og anlegg', //IKKE ENDRE
    displayToUser: 'Midlertidig plassering',
  },
  tilbyggunder50m2: {
    officialValue: 'Endring av bygg - utvendig - Tilbygg med samlet areal mindre enn 50 m2', //IKKE ENDRE
    displayToUser: 'Tilbygg på 15-50m²',
  },
  nyttbyggdriftsbygningunder1000m2: {
    officialValue: 'Nytt bygg - Driftsbygning i landbruk med samlet areal under 1000 m2', //IKKE ENDRE
    displayToUser: 'Driftsbygning i landbruk under 1000m²',
  },
  driftsbygningtilbyggunder1000m2: {
    officialValue: 'Tilbygg til driftsbygning i landbruket med samlet area under 1000 m2 (BRA)', //IKKE ENDRE - obs, skrivefeil er i geonorge
    displayToUser: 'Tilbygg til driftsbygning i landbruket med samlet areal under 1000m²',
  },
  bruksendringhoveddel: {
    officialValue: 'Bruksendring fra tilleggsdel til hoveddel', //IKKE ENDRE
    displayToUser: 'Endre fra tilleggsdel til hoveddel, eller fra hoveddel til tilleggsdel',
  },
  driftsbygningendringunder1000m2: {
    officialValue: 'Endring av driftsbygning i landbruket under 1000m2 (BRA)', //IKKE ENDRE
    displayToUser: 'Endre fra/til driftsbygning i landbruket på under 1000 m²',
  },
  anleggseiendom: {
    officialValue: 'Oppretting av matrikkelenhet - Anleggseiendom', //IKKE ENDRE
    displayToUser: 'Anleggseiendom',
  },
  arealoverføring: {
    officialValue: 'Oppretting av matrikkelenhet - Arealoverføring', //IKKE ENDRE
    displayToUser: 'Arealoverføring',
  },
  festegrunn: {
    officialValue: 'Oppretting av matrikkelenhet - Festegrunn over 10 år', //IKKE ENDRE
    displayToUser: 'Festegrunn',
  },
  grunneiendom: {
    officialValue: 'Oppretting av matrikkelenhet - Grunneiendom', //IKKE ENDRE
    displayToUser: 'Grunneiendom',
  },
  rivingbygningunder70m2: {
    officialValue: 'Riving av bygning under 70 m2', //IKKE ENDRE
    displayToUser: 'Frittliggende bygning mellom 50m² og 70m²',
  },
  rivingdriftsbygninginntill1000m2: {
    officialValue: 'Riving av driftsbygning i landbruket inntil 1000m2', //IKKE ENDRE
    displayToUser: 'Driftsbygning i landbruket inntil 1000m² (BRA)',
  },
  rivetilbyggunder15kvm: {
    officialValue: 'Riving av tilbygg under 50 m2', //IKKE ENDRE
    displayToUser: 'Areal uner 15m²',
  },
  rivingtilbygginntil50m2: {
    officialValue: 'Riving av tilbygg inntil 50 m2', //IKKE ENDRE
    displayToUser: 'Areal mellom 15m² og 50m²',
  },

  skiltreklamefrittstaendeliten: {
    officialValue: 'Skilt/reklame høyde med inntil 3,5m og bredde inntil 1,5m - frittstående', //IKKE ENDRE
    displayToUser: 'Skilt/reklame høyde med inntil 3,5m og bredde inntil 1,5m - frittstående',
  },
  skiltreklamefasadeliten: {
    officialValue: 'Skilt/reklame mindre enn 6,5 m2 - fasade', //IKKE ENDRE
    displayToUser: 'Skilt/reklame mindre enn 6,5m² - fasade',
  },
};
