import React from 'react';
import Draggable from 'react-draggable';
import './FullscreenImage.scss';

type Props = {closeFullscreen: () => void; fullscreenImg: string | undefined};
const FullscreenImage = ({closeFullscreen, fullscreenImg}: Props) => {
  if (!fullscreenImg) {
    return null;
  } else {
    return (
      <div className='FullscreenImage'>
        <div
          className='imgFullscreenWindow'
          onClick={() => {
            closeFullscreen();
          }}
        >
          <div className='imgFullscreenContainer'>
            {/*  <button
                className='closeFullscreenImg'
                onClick={() => {
                  closeFullscreen();
                }}
              >
                <CloseIcon size={1.2} />
              </button> */}
            <Draggable>
              <img
                onClick={(e) => {
                  e.stopPropagation();
                }}
                alt=''
                draggable={false}
                src={fullscreenImg}
              />
            </Draggable>
          </div>
        </div>
      </div>
    );
  }
};
export default FullscreenImage;
