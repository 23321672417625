import React, {useCallback, useReducer, useRef} from 'react';
import {loadStore, saveStore} from '../utils/sessionstorage';
import * as altinn from './altinnState';
import * as application from './applicationState';
import {ChangeApplicationAction} from './applicationState';
import * as attachment from './attachmentsState';
import * as buildingSite from './buildingSiteState';
import * as buildings from './buildingsState';
import * as datavarehusDatasets from './datavarehusDatasetsState';
import * as distances from './distancesState';
import * as errors from './errorState';
import * as innsending from './innsendingState';
import * as nabovarsel from './nabovarselState';
import * as payment from './paymentState';
import * as gfi from './plankartState';
import * as questions from './questionState';
import * as stepper from './stepperState';
import {updateDatabase} from '../utils/databasestorage';
import {appInsightsInstance} from '../AppInsightsSetup';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import getErrorMsg from '../utils/getErrorMsg';
import {AxiosError} from 'axios';
import {UserDispatch} from './userStore';

export const initialState: IContextProps['state'] = {
  stepper: stepper.initialState,
  buildings: buildings.initialState,
  errors: errors.initialState,
  questions: questions.initialState,
  buildingSite: buildingSite.initialState,
  datavarehusDatasets: datavarehusDatasets.initialState,
  distances: distances.initialState,
  application: application.initialState,
  attachment: attachment.initialState,
  nabovarsel: nabovarsel.initialState,
  payment: payment.initialState,
  altinn: altinn.initialState,
  gfi: gfi.initialState,
  innsending: innsending.initialState,
};

const indexReducer = (state: IContextProps['state'], action: IDispatchInput): IContextProps['state'] => {
  //console.log(action);
  //console.log(state);
  if (action.type === 'changeApplication' || action.type === 'unsetCurrentApplication') {
    const newApplicationState: application.ApplicationState = {
      ...initialState.application,
      applicationId: undefined,
      displayAutomaticQuestions: state.application.displayAutomaticQuestions,
      soknadWithVersion3: state.application.soknadWithVersion3,
    };

    let newState = {
      ...initialState,
      application: application.reducer(newApplicationState, action as ChangeApplicationAction),
    };
    saveStore(newState, 'applicationStore');
    return newState;
  }

  const newState: typeof initialState = {
    ...state,
    stepper: stepper.reducer(
      state.stepper,
      action as stepper.Action,
      state.questions.answers,
      state.application.stepType,
      !!state.innsending.isStructuredMangel
    ),
    buildings: buildings.reducer(state.buildings, action as buildings.Action),
    gfi: gfi.reducer(state.gfi, action as gfi.Action),
    errors: errors.reducer(state.errors, action as errors.Action),
    questions: questions.reducer(state.questions, action as questions.Action),
    buildingSite: buildingSite.reducer(state.buildingSite, action as buildingSite.Action),
    distances: distances.reducer(state.distances, action as distances.DistancesAction),
    datavarehusDatasets: datavarehusDatasets.reducer(
      state.datavarehusDatasets,
      action as datavarehusDatasets.DatavarehusDatasetsAction
    ),
    application: application.reducer(state.application, action as application.Action),
    attachment: attachment.reducer(state.attachment, action as attachment.Action),
    nabovarsel: nabovarsel.reducer(state.nabovarsel, action as nabovarsel.Action),
    payment: payment.reducer(state.payment, action as payment.Action),
    altinn: altinn.reducer(state.altinn, action as altinn.Action),
    innsending: innsending.reduce(state.innsending, action as innsending.InnsendingAction),
  };
  saveStore(newState, 'applicationStore');

  return newState;
};

export type IDispatchInput =
  | stepper.Action
  | buildings.Action
  | errors.Action
  | questions.Action
  | buildingSite.Action
  | distances.DistancesAction
  | datavarehusDatasets.DatavarehusDatasetsAction
  | application.Action
  | attachment.Action
  | nabovarsel.Action
  | payment.Action
  | altinn.Action
  | gfi.Action
  | innsending.InnsendingAction;

interface IContextProps {
  state: {
    stepper: stepper.StepperState;
    buildings: buildings.State;
    errors: errors.ErrorState;
    questions: questions.QuestionsState;
    buildingSite: buildingSite.BuildingSiteState;
    distances: distances.DistancesState;
    datavarehusDatasets: datavarehusDatasets.DatavarehusDatasetsState;
    application: application.ApplicationState;
    attachment: attachment.State;
    nabovarsel: nabovarsel.NabovarselState;
    payment: payment.PaymentState;
    altinn: altinn.State;
    gfi: gfi.PlankartState;
    innsending: innsending.InnsendingState;
  };
  dispatch: (dispatchInput: IDispatchInput) => void;
}

export const useReducerWithDatabaseUpdate = ({userDispatch}: {userDispatch: UserDispatch}) => {
  const existingStore = loadStore('applicationStore');
  const [state, dispatch] = useReducer(indexReducer, existingStore || initialState);
  const isFetchingDataRef = useRef(false);

  const dispatchWithDatabaseUpdate = useCallback<React.Dispatch<IDispatchInput>>(
    async (action) => {
      // Update context
      dispatch(action);

      if (action.type === 'isFetchingData') {
        isFetchingDataRef.current = action.value;
      }

      if (action.type === 'hasFetchedData') {
        isFetchingDataRef.current = false;
      }

      // Update database if needed
      if (state.application.applicationId && !isFetchingDataRef.current) {
        try {
          await updateDatabase(state.application.applicationId, state.application.suppleringId, action, state);
        } catch (error) {
          appInsightsInstance?.trackException(
            {
              error: new Error('saveAnswer'),
              severityLevel: SeverityLevel.Error,
            },
            {
              error: getErrorMsg(error as AxiosError),
              soknadId: state.application.applicationId,
            }
          );
          userDispatch({
            type: 'setSaveAnswerError',
            value: true,
          });
        }
      }
    },
    [state, userDispatch]
  );

  return {state, dispatch: dispatchWithDatabaseUpdate};
};

export type State = IContextProps['state'];
export type Dispatch = IContextProps['dispatch'];

export const Context = React.createContext({} as IContextProps);
