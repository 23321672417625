import {TiltakFormaalOptions} from './optionTypes';
import {tiltaksformaalId} from '../../../utils/tiltak/tiltaksformaal';
import {optionId} from './tiltaksOptions/optionIds';
import {QuestionOption} from '../../../services/utils/QuestionTypes';

// gyldige tiltaksformål koder https://register.geonorge.no/byggesoknad/tiltaksformal

const garage: QuestionOption = {
  value: 'Garasje' as tiltaksformaalId,
  text: 'Garasje',
  // imageUrl: '/images/cardIcons/garage.svg',
};
const bolig: QuestionOption = {
  value: 'Bolig' as tiltaksformaalId,
  text: 'Bolig',
  // imageUrl: '/images/cardIcons/eiendom_gronn.svg',
};
const fritidsbolig: QuestionOption = {
  value: 'Fritidsbolig' as tiltaksformaalId,
  text: 'Fritidsbolig',
  // imageUrl: '/images/cardIcons/fritidsbolig.svg',
};
const annet: QuestionOption = {
  value: 'Annet' as tiltaksformaalId,
  text: 'Annet',
  // imageUrl: '/images/cardIcons/annet.svg',
};
const naeringseiendom: QuestionOption = {
  //Ikke gyldig type, trigger med ansvarsrett
  value: 'naering' as optionId,
  text: 'Næringseiendom',
  // imageUrl: '/images/cardIcons/naeringsbygg.svg',
};
const frittliggende: QuestionOption = {
  //Ikke gyldig type, trigger med ansvarsrett
  value: 'frittliggende' as optionId,
  text: 'Frittliggende garasje, bod eller lekestue',
  // imageUrl: '/images/cardIcons/garage.svg',
};

const allTiltakFormaalOptions = [garage, bolig, fritidsbolig, annet];
const buildOptions = allTiltakFormaalOptions;
const demolishOptions = allTiltakFormaalOptions;
const createOptions = [annet];
const changeOptions = [bolig, fritidsbolig, naeringseiendom, frittliggende];

export default {
  questionId: 'TILTAK_FORMAAL',
  build: buildOptions,
  demolish: demolishOptions,
  create: createOptions,
  change: changeOptions,
} as TiltakFormaalOptions;
