import {Teig, MatrikkelNummer, SelectedPropertyOption, KommuneNummer} from '../utils/dibkSchemas/models/models';
import {AdresseFromServer} from '../services/matrikkelgrunnbok';
import {PlanAnalysisResult} from '../services/analysisInterfaces';
import {TeigOnProperty} from '../utils/addressProperty';
import {ExistingBuilding} from './buildingsState';

export type Address = AdresseFromServer;

export interface BuildingSiteState {
  chosenAddress?: Address | null;
  selectedPropertyOption?: SelectedPropertyOption;
  chosenTeig?: Teig;
  ownedProperties: PropertyObject[] | undefined;
  plans?: PlanAnalysisResult;
  isLoadingPlans: boolean;
  parkeringsNorms: {[knr: string]: {name: string; link: string}[]};
  selectedPropertyObject?: PropertyObject;
  propertyBuildings?: ExistingBuilding[];
  propertyReadyForAnalysis: boolean;
  teigerOnChosenProperty?: TeigOnProperty[];
  MFT_affected?: boolean;
}

export interface PropertyObject {
  displayValue: SelectedPropertyOption; //address or readable mnr - this value is displayed in property selector
  eierandel?: string;
  propertyId: MatrikkelNummer;
  readableMnr: string;
  isBorettslag?: boolean;
  adressList: Address[];
  knr: KommuneNummer;
  kommunenavn: string;
}

export const initialState: BuildingSiteState = {
  ownedProperties: undefined,
  parkeringsNorms: {},
  propertyReadyForAnalysis: false,
  isLoadingPlans: false,
};

export type Action =
  | {
      type: 'setChosenAddress';
      value: Address;
    }
  | {
      type: 'set_MFT_affected';
      value: boolean;
    }
  | {
      type: 'setSelectedPropertyOptionValue';
      value: SelectedPropertyOption;
    }
  | {
      type: 'setPropertyBuildings';
      value: ExistingBuilding[];
    }
  | {
      type: 'setTeigerOnChosenProperty';
      value: TeigOnProperty[];
    }
  | {
      type: 'setSelectedProperyObject';
      value: PropertyObject;
    }
  | {
      type: 'setPropertyReadyForAnalysis';
      value: boolean;
    }
  | {
      type: 'removeChosenAddress';
    }
  | {
      type: 'setChosenTeig';
      value: Teig;
    }
  | {
      type: 'removeChosenTeig';
    }
  | {
      type: 'setPlans';
      value: PlanAnalysisResult | undefined;
    }
  | {
      type: 'isLoadingPlans';
      value: boolean;
    }
  | {
      type: 'setOwnedProperties';
      value: BuildingSiteState['ownedProperties'];
    };

export const reducer = (state: BuildingSiteState, action: Action): BuildingSiteState => {
  switch (action.type) {
    case 'setChosenAddress': {
      return {
        ...state,
        chosenAddress: action.value,
      };
    }
    case 'set_MFT_affected': {
      return {
        ...state,
        MFT_affected: action.value,
      };
    }
    case 'setSelectedProperyObject': {
      return {
        ...state,
        selectedPropertyObject: action.value,
      };
    }
    case 'setPropertyBuildings': {
      return {
        ...state,
        propertyBuildings: action.value,
      };
    }
    case 'setTeigerOnChosenProperty': {
      return {
        ...state,
        teigerOnChosenProperty: action.value,
      };
    }
    case 'setSelectedPropertyOptionValue': {
      return {
        ...state,
        selectedPropertyOption: action.value,
      };
    }
    case 'setPropertyReadyForAnalysis': {
      return {
        ...state,
        propertyReadyForAnalysis: action.value,
      };
    }
    case 'removeChosenAddress': {
      return {
        ...state,
        chosenAddress: undefined,
      };
    }
    case 'setChosenTeig': {
      return {
        ...state,
        chosenTeig: action.value,
      };
    }
    case 'removeChosenTeig': {
      return {
        ...state,
        chosenTeig: undefined,
      };
    }
    case 'setPlans': {
      return {
        ...state,
        plans: action.value,
      };
    }
    case 'isLoadingPlans': {
      return {
        ...state,
        isLoadingPlans: action.value,
      };
    }
    case 'setOwnedProperties': {
      return {
        ...state,
        ownedProperties: action.value,
      };
    }
    default:
      return state;
  }
};

export const getKnr = (chosenTeig?: Teig) => {
  return chosenTeig && chosenTeig.propertyId.substr(0, 4);
};
