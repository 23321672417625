import {PixelPoint} from './dibkSchemas/models/models';

export function rotatePixelPoint(point: PixelPoint, angleDeg: number, pointCenter: PixelPoint): PixelPoint {
  const angleRad = (angleDeg * Math.PI) / 180;

  const x2 =
    Math.cos(angleRad) * (point.x - pointCenter.x) - Math.sin(angleRad) * (point.y - pointCenter.y) + pointCenter.x;

  const y2 =
    Math.sin(angleRad) * (point.x - pointCenter.x) + Math.cos(angleRad) * (point.y - pointCenter.y) + pointCenter.y;
  return {x: x2, y: y2};
}

export function pixelDistance(a: PixelPoint, b: PixelPoint): number {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

export function degToRad(deg: number) {
  return (deg * Math.PI) / 180;
}

export function uniquify(arr: any[]): any[] {
  return arr.filter((val, i) => arr.indexOf(val) === i);
}

export function setTimeoutPromise(time) {
  return new Promise((resolve: any) => {
    setTimeout(() => resolve(), time);
  });
}

export function groupBy<T>(array: T[], key: keyof T): {[key: string]: T[]} {
  return array.reduce((accumulator, currentValue) => {
    (accumulator[currentValue[key]] = accumulator[currentValue[key]] || []).push(currentValue);
    return accumulator;
  }, {} as any);
}

export const isEmail = (input: string) => {
  // regex from kommunekart. found at http://stackoverflow.com/a/46181
  /* eslint-disable */
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(input);
};

export const isPhonenumber = (input: string) => {
  // may start with optional plus sign, and then contain numbers, (, ) or -
  const re = /^[\+]?[\(\)\-0-9]*$/;
  return re.test(input);
};
