import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import {ToiSpinner} from '@norkart/toi-components';
import RightArrow from 'nka-icons/build/icons/Hoyrepil';
import LeftArrow from 'nka-icons/build/icons/Venstrepil';
import * as React from 'react';
import './NkButton.scss';

export type Buttontypes =
  | 'default'
  | 'none'
  | 'white'
  | 'disabled'
  | 'callToAction'
  | 'black'
  | 'grey'
  | 'link'
  | 'outlined';
interface Props {
  disabled?: boolean;
  onClick?: () => void;
  type: Buttontypes;
  spinner?: boolean;
  hoverTitle?: string;
  disabledHoverTitle?: string;
  arrow?: 'left' | 'right'; // Add arrows to back/next btns same as e-torg?
  id?: string;
  children?: any;
  spinnerTxt?: string;
  className?: string;
  tabIndex?: number;
  minWidth?: number;
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  autofocus?: boolean;
  navArrow?: 'left' | 'right';
  style?: React.CSSProperties;
  ariaLabel?: string;
  ref?;
  paragraphStyle?: 'smallParagraph' | 'normalParagraph' | 'largeParagraph';
  darkmode?: boolean;
  ariaExpanded?: boolean;
  ariaHasPopup?: React.AriaAttributes['aria-haspopup'];
}

const NkButton = ({
  style,
  arrow,
  disabled,
  onClick,
  type: btnType,
  spinner,
  id,
  children,
  hoverTitle,
  disabledHoverTitle,
  spinnerTxt,
  className,
  tabIndex,
  endIcon,
  startIcon,
  minWidth,
  autofocus,
  navArrow,
  ariaLabel,
  ref,
  paragraphStyle,
  darkmode,
  ariaExpanded,
  ariaHasPopup,
}: Props) => {
  if (paragraphStyle && btnType !== 'link') {
    console.error('Paragraph style is only supported for btntype link');
  }

  let buttonStyle;

  let arrowColor: 'nk-black' | 'white' = 'nk-black';
  let muiArrowAndSpinnerColor: 'primary' | 'secondary' | undefined = undefined;

  if (disabled) {
    buttonStyle = 'disabled';
    arrowColor = 'nk-black';
    muiArrowAndSpinnerColor = 'primary';
  } else if (btnType === 'white') {
    buttonStyle = 'white';
    arrowColor = 'white';
  } else if (btnType === 'default') {
    buttonStyle = 'default';
    arrowColor = 'nk-black';
    muiArrowAndSpinnerColor = 'primary';
  } else if (btnType === 'callToAction') {
    buttonStyle = 'callToAction';
    arrowColor = 'white';
    muiArrowAndSpinnerColor = 'secondary';
  } else if (btnType === 'none' || !btnType) {
    buttonStyle = 'transparent';
    muiArrowAndSpinnerColor = 'primary';
  } else if (btnType === 'grey') {
    buttonStyle = 'grey';
    arrowColor = 'white';
  } else if (btnType === 'black') {
    buttonStyle = 'black';
    arrowColor = 'white';
  } else if (btnType === 'link') {
    buttonStyle = 'link';
  } else if (btnType === 'outlined') {
    buttonStyle = 'outlined';
    muiArrowAndSpinnerColor = 'secondary';
  }

  return (
    <button
      ref={ref}
      aria-label={ariaLabel}
      autoFocus={autofocus}
      className={`NkButton ${className ? className : ''} ${buttonStyle} ${darkmode ? 'darkmode' : 'lightmode'}`}
      disabled={disabled || false}
      id={id}
      onClick={onClick}
      title={disabled && disabledHoverTitle ? disabledHoverTitle : hoverTitle}
      tabIndex={tabIndex ? tabIndex : 0}
      style={style ? style : minWidth ? {minWidth: minWidth} : undefined}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHasPopup}
    >
      {startIcon && <span style={{marginLeft: 5, display: 'flex', alignItems: 'center'}}>{startIcon}</span>}
      {arrow === 'left' && <LeftArrow color={arrowColor} />}
      {navArrow === 'left' && (
        <KeyboardBackspace color={muiArrowAndSpinnerColor} htmlColor={!muiArrowAndSpinnerColor ? 'white' : undefined} />
      )}
      {spinner ? (
        <>
          {spinnerTxt}
          <ToiSpinner size={20} color={muiArrowAndSpinnerColor} />
        </>
      ) : (
        <span className={btnType === 'link' ? paragraphStyle || 'normalParagraph' : undefined}>{children}</span>
      )}
      {arrow === 'right' && <RightArrow color={arrowColor} />}
      {navArrow === 'right' && (
        <ArrowRightAlt color={muiArrowAndSpinnerColor} htmlColor={!muiArrowAndSpinnerColor ? 'white' : undefined} />
      )}
      {endIcon && <span style={{marginRight: 5, display: 'flex', alignItems: 'center'}}>{endIcon}</span>}
    </button>
  );
};
export default NkButton;
