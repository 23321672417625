import {tiltakTypeDescription} from '../../../../utils/tiltak/tiltakstypeDescriptions';
import {TiltaksTypeOptions} from '../optionTypes';
import {allTiltakstypeId} from '../../../../utils/tiltak/tiltaktypeIds';
import {TiltakQuestionOption} from '../../../../services/utils/QuestionTypes';

// Endre tiltakstyper

const allChangeOptions: {[key in allTiltakstypeId]?: TiltakQuestionOption} = {
  bruksendringhoveddel: {
    value: 'bruksendringhoveddel',
    text: tiltakTypeDescription.bruksendringhoveddel.displayToUser,
    helpText:
      'f.eks gjøre om en bod (tilleggsdel) til et soverom (hoveddel). Hoveddel omfatter alle oppholdsrom som stue, kjøkken, soverom, bad, inkl. ganger/trapper. Tilleggsdel er rom som ikke er egnet som oppholdsrom, f.eks bod, garasje og andre oppbevaringsrom',
    //imageUrl: '/images/cardIcons/bruksendring_hoveddel.svg',
  },
  hoveddeltilhoveddel: {
    value: 'hoveddeltilhoveddel',
    text: 'Endre fra hoveddel til hoveddel',
    helpText: 'f.eks gjøre om et kjøkken (hoveddel) til et soverom (hoveddel)',
  },
  driftsbygningendringunder1000m2: {
    value: 'driftsbygningendringunder1000m2',
    text: tiltakTypeDescription.driftsbygningendringunder1000m2.displayToUser,
    helpText: 'f.eks endre fra driftsbygning i landbruket til bolig',
    //imageUrl: '/images/cardIcons/driftsbygning.svg',
  },
  driftsbygningendringover1000m2: {
    value: 'driftsbygningendringover1000m2',
    text: 'Endre fra/til driftsbygning i landbruket på over 1000 m²',
    helpText: 'f.eks endre fra driftsbygning i landbruket til bolig',
    //imageUrl: '/images/cardIcons/driftsbygning.svg',
  },
  //Ikke gyldig type, trigger med ansvarsrett
  selvstendigBoenhet: {
    value: 'selvstendigBoenhet',
    text: 'Endre eksisterende rom til en ny og selvstendig boenhet', //Trigger ansvarsrett
    helpText: 'f.eks endre slik at det blir en utleiedel',
    //imageUrl: '/images/cardIcons/eiendom_gronn.svg',
  },
  boligTilNæring: {
    value: 'boligTilNaering',
    text: 'Endre fra bolig til næring, eller næring til bolig', //Trigger ansvarsrett
    helpText: 'Næring kan f.eks være butikk eller café',
  },
  kontorTilLeilighet: {
    value: 'kontorTilLeilighet',
    text: 'Endre fra kontor til leilighet eller leilighet til kontor', //Trigger ansvarsrett
    helpText: '',
  },
  annet: {
    value: 'annet',
    text: 'Annet',
  },
};

const allOptions = [
  allChangeOptions.bruksendringhoveddel,
  allChangeOptions.hoveddeltilhoveddel,
  allChangeOptions.selvstendigBoenhet,
  allChangeOptions.boligTilNæring,
  allChangeOptions.kontorTilLeilighet,
  allChangeOptions.driftsbygningendringover1000m2,
  allChangeOptions.driftsbygningendringunder1000m2,
  allChangeOptions.annet,
];

//Same options for all since we removed tiltaksformal question for bruksendring
export default {
  questionId: 'TILTAK_TYPE_CHANGE',
  bolig_options: allOptions,
  fritidsbolig_options: allOptions,
  annet_options: allOptions,
  garasje_options: allOptions,
  all_options: allOptions,
} as TiltaksTypeOptions;
