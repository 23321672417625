export interface State {
  altinnProfilePending: boolean;
  altinnProfileRejected: boolean;
}

export const initialState: State = {
  altinnProfilePending: false,
  altinnProfileRejected: false,
};

export type Action =
  | {
      type: 'ALTINN_PROFILE_SUCCESS';
    }
  | {
      type: 'ALTINN_PROFILE_PENDING';
    }
  | {
      type: 'ALTINN_PROFILE_REJECTED';
    }
  | {
      type: 'RESET_ALTINN_STATE';
    };

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'RESET_ALTINN_STATE':
      return {
        ...initialState,
      };
    case 'ALTINN_PROFILE_PENDING':
      return {
        ...state,
        altinnProfilePending: true,
      };
    case 'ALTINN_PROFILE_REJECTED':
      return {
        ...state,
        altinnProfilePending: false,
        altinnProfileRejected: true,
      };
    case 'ALTINN_PROFILE_SUCCESS':
      return {
        ...state,
        altinnProfilePending: false,
        altinnProfileRejected: false,
      };
    default:
      return state;
  }
};
