import {questionCondition} from '../utils/QuestionTypes';
import {BuildingBorderExistOptions} from './getRegulationData';
import {AnswerValues} from '../../state/questionTypes';

export const performAllDistancesChecks = (
  dependentOnUtstikk: boolean = false
): {or?: questionCondition[]; and?: questionCondition[]} => {
  if (dependentOnUtstikk) {
    return {
      and: [{conditionType: 'any', question: 'UTSTIKKENDE_BYGNINGSDEL_SIZE'}],
      or: getNotWithinBuildingBorderCondition(),
    };
  }
  return {
    or: getNotWithinBuildingBorderCondition(),
  };
};

export const getNotWithinBuildingBorderCondition = (): questionCondition[] => {
  return [
    {conditionType: '!=', conditionValue: true as BuildingBorderExistOptions, question: 'BUILDING_BORDER_EXISTS'},
    {conditionType: '=', conditionValue: false, question: 'IS_WITHIN_BUILDING_BORDER'},
  ];
};

export const performUnlessWithinBuildingBorder = (): {or?: questionCondition[]; and?: questionCondition[]} => {
  return {
    or: getNotWithinBuildingBorderCondition(),
  };
};
export const performIfWithinBuildingBorder = (): {or?: questionCondition[]; and?: questionCondition[]} => {
  return {
    and: [
      {conditionType: '=', conditionValue: true as BuildingBorderExistOptions, question: 'BUILDING_BORDER_EXISTS'},
      {conditionType: '=', conditionValue: true, question: 'IS_WITHIN_BUILDING_BORDER'},
    ],
  };
};

export const withinBuildingBorder = (answers: AnswerValues) => {
  return (
    answers.BUILDING_BORDER_EXISTS === (true as BuildingBorderExistOptions) &&
    answers.IS_WITHIN_BUILDING_BORDER === true
  );
};

export const noBuildingBorder = (answers: AnswerValues) => {
  return answers.BUILDING_BORDER_EXISTS !== (true as BuildingBorderExistOptions);
};

export const outsideBuildingBorder = (answers: AnswerValues) => {
  return (
    answers.BUILDING_BORDER_EXISTS === (true as BuildingBorderExistOptions) &&
    answers.IS_WITHIN_BUILDING_BORDER === false
  );
};
