import {MangelId, StrukturertMangel} from '../services/ebyggesokServices/suppleringService';
import {AltinnReference} from '../services/ebyggesokServices/userOrderService';
import {Brand} from '../utils/dibkSchemas/models/models';
import {AltinnDraftReference, changeSuppleringAction, NewApplicationAction} from './applicationState';
import {AddAttachmentAction, RemoveAttachmentAction, ReplaceAttachmentAction} from './attachmentsState';
import {AddAnswerAction, RemoveAnswerAction} from './questionState';
export type InnsendingXmlId = Brand<string, 'InnsendingXmlId'>;

export type InnsendingState = {
  xmlId?: InnsendingXmlId;
  updateXml: boolean;
  draftRef?: AltinnDraftReference;
  draftUrl?: string;
  byggesoknadArReference?: AltinnReference;
  ferdigattestArReference?: AltinnReference;
  suppleringArReference?: AltinnReference;
  isValidated: boolean;
  draftOutdated?: boolean;
  isStructuredMangel?: boolean;
  receivedMangler?: StrukturertMangel[];
  selectedMangelId?: MangelId;
};

export const initialState: InnsendingState = {
  isValidated: false,
  updateXml: false,
};

export type InnsendingAction =
  | {
      type: 'setInnsendingXmlId';
      value: InnsendingXmlId;
    }
  | {
      type: 'setUpdateInnsendingXml';
      value: boolean;
    }
  | {
      type: 'setByggesoknadArReference';
      value: AltinnReference;
    }
  | {
      type: 'setFerdigattestArReference';
      value: AltinnReference;
    }
  | {
      type: 'setSuppleringArReference';
      value: AltinnReference;
    }
  | {
      type: 'setInnsendingDraftReference';
      value: AltinnDraftReference;
    }
  | {type: 'setInnsendingDraftUrl'; value: string | undefined}
  | {type: 'setInnsendingIsValidated'; value: boolean}
  | {type: 'setInnsendingDraftOutdated'}
  | {
      type: 'setIsStructuredMangel';
      value: boolean;
    }
  | {
      type: 'setSelectedMangelId';
      value: MangelId;
    }
  | {
      type: 'setReceivedMangler';
      value: StrukturertMangel[];
    }
  | AddAnswerAction
  | RemoveAnswerAction
  | AddAttachmentAction
  | RemoveAttachmentAction
  | ReplaceAttachmentAction
  | changeSuppleringAction
  | NewApplicationAction;

export const reduce = (state: InnsendingState, action: InnsendingAction): InnsendingState => {
  switch (action.type) {
    case 'setInnsendingXmlId':
      return {...state, xmlId: action.value};
    case 'setUpdateInnsendingXml':
      return {...state, updateXml: action.value};
    case 'setSuppleringArReference':
      return {
        ...state,
        suppleringArReference: action.value,
        draftRef: undefined,
      };
    case 'setByggesoknadArReference':
      return {
        ...state,
        byggesoknadArReference: action.value,
        draftRef: undefined,
      };
    case 'setFerdigattestArReference':
      return {
        ...state,
        ferdigattestArReference: action.value,
        draftRef: undefined,
      };
    case 'setInnsendingDraftReference':
      return {...state, draftRef: action.value, draftOutdated: false};
    case 'setInnsendingIsValidated':
      return {...state, isValidated: action.value};
    case 'setInnsendingDraftOutdated':
      return {...state, draftOutdated: true};
    case 'setInnsendingDraftUrl':
      return {...state, draftUrl: action.value, draftOutdated: false};
    case 'setNewApplicationId':
      return {...state, xmlId: undefined, draftRef: undefined};
    case 'setSelectedMangelId':
      return {...state, selectedMangelId: action.value};
    case 'changeSupplering':
      return {
        ...initialState,
        xmlId: action.value.innsendingXmlId,
        receivedMangler: state.receivedMangler,
        isStructuredMangel: state.isStructuredMangel,
      };
    case 'setIsStructuredMangel':
      return {...state, isStructuredMangel: action.value};
    case 'setReceivedMangler':
      return {...state, receivedMangler: action.value};
    case 'addAnswer':
      return {...state, updateXml: true, draftOutdated: true};
    case 'removeAnswer':
      return {...state, updateXml: true, draftOutdated: true};
    case 'addAttachment':
      return {...state, updateXml: true, draftOutdated: true};
    case 'removeAttachment':
      return {...state, updateXml: true, draftOutdated: true};

    default:
      return state;
  }
};
