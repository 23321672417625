import {ToiSpinner} from '@norkart/toi-components';
import React, {Suspense} from 'react';
import {useLocation} from 'react-router-dom';
import {appInsightsInstance} from '../../AppInsightsSetup';
import {goToIdPorten, signOutIdporten} from '../../services/idportenService';
import {UserContext} from '../../state/userStore';
import {InfoPagesRoutes} from '../../utils/routes/InfoPagesRoutes';
import {currentPathRequiresLogin} from '../../utils/routes/routesHelpers';
import {DesktopContainer, TabletAndMobileContainer} from '../containers/DeviceContainers';
import NkLink from '../NkLink/NkLink';
import PageAnnouncer from '../PageAnnouncer/PageAnnouncer';
import StatusAlert from '../StatusPopup/StatusAlert';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import HomeLogo from './HomeLogo';
import './topbar.scss';
import UserInfo from './UserInfo';

const AutomaticAnswersSecretButton = React.lazy(
  () => import('../AutomaticAnswersSecretButton/AutomaticAnswersSecretButton')
);

interface TopbarOptions {
  fixed?: boolean;
}

function Topbar(props: TopbarOptions) {
  const {userDispatch} = React.useContext(UserContext);

  const location = useLocation();
  const displaySkipToMainContentLink = document.getElementById('pageMainContent') !== null;

  const signOut = () => {
    userDispatch({type: 'resetUser'});
    userDispatch({type: 'setRouteBeforeSignOut', value: location.pathname});
    signOutIdporten();
  };

  return (
    <>
      <PageAnnouncer />
      <header className={`Topbar ${props.fixed ? 'fixed' : ''}`}>
        {displaySkipToMainContentLink && (
          <a tabIndex={0} href='#pageMainContent' className='skiplink'>
            Hopp til hovedinnhold
          </a>
        )}
        <div className='content'>
          {currentPathRequiresLogin() && <AutomaticAnswersSecretButton />}
          <div className='logo-burger'>
            <HomeLogo
              resetError={() => {
                userDispatch({type: 'resetSevereError'});
              }}
            />
          </div>
          <TabletAndMobileContainer>
            <Suspense fallback={<ToiSpinner />}>
              <BurgerMenu logoutIdporten={signOut} goToIdPorten={() => goToIdPorten(userDispatch)} />
            </Suspense>
          </TabletAndMobileContainer>
          <DesktopContainer>
            <nav aria-label='side-navigasjon-privat-proff' style={{display: 'flex', marginLeft: 180, gap: 20}}>
              <NkLink className='privat' href='/'>
                Privat
              </NkLink>
              <NkLink className='bedrift' href='https://www.ebyggesokproff.no/velkommen' newPage={true}>
                Bedrift
              </NkLink>
            </nav>
            <nav aria-label='side-navigasjon' className='right'>
              <NkLink
                className='frontpage-nav-link'
                onClick={() => {
                  userDispatch({type: 'resetSevereError'});
                  appInsightsInstance?.trackEvent({name: 'prisTopbar'});
                }}
                href={InfoPagesRoutes.article.path + '/pris'}
                style={{marginRight: 50}}
              >
                Pris
              </NkLink>
              <NkLink
                className='frontpage-nav-link'
                onClick={() => {
                  appInsightsInstance?.trackEvent({name: 'faqTopbar'});
                }}
                href={InfoPagesRoutes.faq.path}
                newPage
                style={{
                  marginRight: 20,
                  minWidth: 170,
                }}
              >
                Ofte stilte spørsmål
              </NkLink>
              <UserInfo />
            </nav>
          </DesktopContainer>
        </div>
        <StatusAlert />
      </header>
    </>
  );
}

export default Topbar;
