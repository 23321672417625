import React from 'react';
import CheckIcon from 'nka-icons/build/icons/Velg';
import NkTypography from '../../../../components/NkTypography/NkTypography';
import {DesktopContainer} from '../../../../components/containers/DeviceContainers';
import {TabletAndMobileContainer} from '../../../../components/containers/DeviceContainers';

type Props = {text};
const SellingPointRow = ({text}: Props) => {
  return (
    <>
      <DesktopContainer>
        <li
          className='SellingPointRow'
          style={{display: 'inline-flex', alignItems: 'center', marginBottom: 26, maxWidth: 480}}
        >
          <CheckIcon />
          <NkTypography type='largeParagraph'>{text}</NkTypography>
        </li>
      </DesktopContainer>
      <TabletAndMobileContainer>
        <li className='SellingPointRow' style={{display: 'inline-flex', marginBottom: 14}}>
          <CheckIcon />
          <NkTypography type='largeParagraph'>{text}</NkTypography>
        </li>
      </TabletAndMobileContainer>
    </>
  );
};

export default SellingPointRow;
