import {NabovarselStatus} from '../services/ebyggesokServices/nabovarselService';
import {VedleggDibk} from './dibkSchemas/models/nabovarsel';
import {validAttachmentTypes} from './dibkSchemas/models/models';
import {Attachment} from '../state/attachmentsState';

export const getMerknadAttachments = (
  neighbourAnswers: NabovarselStatus,
  createAttachmentMethod: (url: string, id?: string) => any
) => {
  let vedleggSvar = neighbourAnswers.Svar?.map((svar) => {
    let attachments: any = [];
    if (svar.MerknadsVedlegg) {
      attachments = svar.MerknadsVedlegg.map((url) => {
        return createAttachmentMethod(url);
      });
    }
    attachments.push(createAttachmentMethod(svar.PdfUrl));
    return attachments;
  });
  return vedleggSvar?.flat();
};

export const createValidSubmitAttachment = (url: string): VedleggDibk => {
  return {
    Url: url,
    TypeVedlegg: validAttachmentTypes.Nabomerknader,
    Filnavn: url.split('/').length ? url.split('/').pop()! : 'vedlegg.pdf',
  };
};

export const createValidTableAttachment = (url): Attachment => {
  return {
    url: url,
    type: validAttachmentTypes.Nabomerknader,
    name: url.split('/').length ? url.split('/').pop()! : 'vedlegg.pdf',
    includeInNabovarsel: false,
    fileSize: 0,
    addedAutomatic: true,
  } as Attachment;
};
