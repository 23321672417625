import {QuestionId} from '../../../services/utils/questionIds';
import {Question, QuestionGroup, QuestionOption, UserInputTypes} from '../../../services/utils/QuestionTypes';
import {Answers} from '../../../state/questionTypes';
import {tiltaksformaalId} from '../../../utils/tiltak/tiltaksformaal';
import options from './optionListMapper';
import tiltakFormaalOptions from './tiltakFormaalOptions';
import {buildOptions} from './tiltaksOptions/buildOptions';
import changeOptions from './tiltaksOptions/changeOptions';
import createOptions from './tiltaksOptions/createOptions';
import demolishOptions from './tiltaksOptions/demolishOptions';
import {optionId} from './tiltaksOptions/optionIds';
import {NewApplicationOptions, TiltaksTypeOptions} from './optionTypes';
import {mainCategory} from './mainCategoryOptionList';
import {getAnswerValues} from '../../../utils/getAnswerValues';

export default function getCanIApplyQuestionOptions(
  answers: Answers | undefined,
  questionGroup: QuestionGroup | undefined,
  activeQuestionId: QuestionId
): QuestionOption[] | undefined {
  // Questions with no conditions or has any condition type, default uses condition.
  if (activeQuestionId === 'WHAT_TO_DO') {
    return mainCategory.options;
  } else if (activeQuestionId === 'TILTAK_FORMAAL' && answers) {
    return getFormaalOptions(answers);
  } else if (activeQuestionId.includes('TILTAK_TYPE') && answers) {
    //Handle this different due to dependent on two questions
    return getTiltakTypeOptions(answers, activeQuestionId); //any
  } else {
    return answers && handleConditionalQuestion(questionGroup, answers, activeQuestionId);
  }
}

const getFormaalOptions = (answers: Answers): QuestionOption[] => {
  const whatToDo = answers.WHAT_TO_DO && (answers.WHAT_TO_DO.value as any);
  if (!whatToDo) {
    return [];
  }
  let optionsList: QuestionOption[] = [];
  if (whatToDo === 'build' || whatToDo?.value === 'build') {
    optionsList = optionsList.concat(tiltakFormaalOptions.build);
  } else if (whatToDo === 'demolish' || whatToDo?.value === 'demolish') {
    optionsList = optionsList.concat(tiltakFormaalOptions.demolish);
  } else if (whatToDo === 'change' || whatToDo?.value === 'change') {
    optionsList = optionsList.concat(tiltakFormaalOptions.change);
  } else if (whatToDo === 'create' || whatToDo?.value === 'create') {
    optionsList = optionsList.concat(tiltakFormaalOptions.create);
  } else if (whatToDo === 'annet' || whatToDo === 'fasadeendring') {
    // Do nothing - only used to give users option before showing the not allowed result page.
  } else {
    console.error(whatToDo + ' is a value not supported, add it here');
  }
  return removeDuplicatedOptions(optionsList);
};

const getTiltakTypeOptions = (answers: Answers, selectedMainCategory: QuestionId) => {
  const answerValues = getAnswerValues(answers);
  const tiltaksformaal = answerValues.TILTAK_FORMAAL as tiltaksformaalId;
  let optionsList: QuestionOption[] = [];
  const tiltakTypesWithinCategory = getMainCategoryTiltakOptions(selectedMainCategory);
  if (!tiltakTypesWithinCategory) return;
  switch (tiltaksformaal) {
    case 'Bolig':
      optionsList = optionsList.concat(tiltakTypesWithinCategory.bolig_options);
      break;
    case 'Fritidsbolig':
      optionsList = optionsList.concat(tiltakTypesWithinCategory.fritidsbolig_options);
      break;
    case 'Garasje':
      optionsList = optionsList.concat(tiltakTypesWithinCategory.garasje_options);
      break;
    case 'Annet':
      optionsList = optionsList.concat(tiltakTypesWithinCategory.annet_options);
      break;
    default:
      // Do not have tiltaksformål answer, then use all options.
      optionsList = optionsList.concat(tiltakTypesWithinCategory.all_options);
  }

  const extraAnswerCausingFilteringOfTiltakstyper =
    answers.WHAT_TO_DO?.value === 'build' ? answers.WHAT_TO_BUILD : answers.WHAT_TO_DEMOLISH;
  if (tiltakTypesWithinCategory.frittliggendeByggOptions && tiltakTypesWithinCategory.tilbyggOptions) {
    const tiltaksFilter = extraAnswerCausingFilteringOfTiltakstyper?.value as
      | 'frittliggende_bygg'
      | 'tilbygg'
      | 'driftsbygning';
    if (tiltaksFilter === 'frittliggende_bygg') {
      optionsList = optionsList.filter((option) =>
        tiltakTypesWithinCategory.frittliggendeByggOptions?.map((opt) => opt.value)?.includes(option.value)
      );
    } else if (tiltaksFilter === 'tilbygg') {
      optionsList = optionsList.filter((option) =>
        tiltakTypesWithinCategory.tilbyggOptions?.map((opt) => opt.value)?.includes(option.value)
      );
    } else if (tiltaksFilter === 'driftsbygning') {
      optionsList = optionsList.filter((option) =>
        tiltakTypesWithinCategory.driftsbygningOptions?.map((opt) => opt.value)?.includes(option.value)
      );
    }
  }
  return removeDuplicatedOptions(optionsList);
};

const removeDuplicatedOptions = (optionsList: QuestionOption[]) => {
  const updatedOptionsList: QuestionOption[] = [];
  optionsList.forEach((option) => {
    const optionAlreadyExist = updatedOptionsList.find((opt) => opt.value === option.value) ? true : false;
    if (!optionAlreadyExist) updatedOptionsList.push(option);
  });
  return updatedOptionsList;
};

const getMainCategoryTiltakOptions = (selectedMainCategory: QuestionId): TiltaksTypeOptions | undefined => {
  switch (selectedMainCategory) {
    case 'TILTAK_TYPE_BUILD':
      return buildOptions;
    case 'TILTAK_TYPE_CHANGE':
      return changeOptions;
    case 'TILTAK_TYPE_CREATE':
      return createOptions;
    case 'TILTAK_TYPE_DEMOLISH':
      return demolishOptions;
  }
};

const handleConditionalQuestion = (
  questionGroup: QuestionGroup | undefined,
  answers: Answers,
  activeQuestionId: QuestionId
) => {
  const stepQuestions = questionGroup && questionGroup.questions;
  const activeQuestion = stepQuestions && stepQuestions.find((q) => q.id === activeQuestionId);
  const hasCondition: boolean = activeQuestion && activeQuestion.conditions ? true : false;
  if (hasCondition) {
    return getOptionListFromConditionQuestionAnswer(answers, activeQuestion);
  } else {
    console.error('Cannot get option for question ' + activeQuestionId);
  }
};

const getOptionListFromConditionQuestionAnswer = (
  answers: Answers,
  activeQuestion: Question | undefined
): QuestionOption[] | undefined => {
  let conditionAnswer;
  let options;
  const orConditions = activeQuestion && activeQuestion.conditions && activeQuestion.conditions.or;
  const andConditions = activeQuestion && activeQuestion.conditions && activeQuestion.conditions.and;

  if (orConditions && orConditions.length) {
    for (let index = 0; index < orConditions.length; index++) {
      const cond = orConditions[index];
      conditionAnswer = answers[cond.question];
      if (conditionAnswer && conditionAnswer.value) {
        options = getOptions(conditionAnswer.value);
        if (options) {
          break; // Found an answer that maps to a option list
        }
      }
    }
    return options;
  } else if (andConditions && andConditions.length) {
    console.error('Support for and conditions in this case is not yet implemented');
    // Implement if needed later.
  }
};

const getOptions = (answer: UserInputTypes): QuestionOption[] | undefined => {
  if (!answer) {
    return;
  }
  let optionsObject: NewApplicationOptions | undefined;
  if (answer instanceof Array) {
    if (!answer.length) return;
    optionsObject = getOptionsFromList(answer);
    return optionsObject && optionsObject.options;
  } else if (typeof answer === 'string') {
    optionsObject = answerOptionlistMapper[answer];
    return optionsObject && answerOptionlistMapper[answer]!.options;
  } else {
    console.error('ANSWER TYPE IS NOT SUPPORTED ', answer);
  }
};

const getOptionsFromList = (answer: string[] | number[]): NewApplicationOptions | undefined => {
  /* Loops through the answers and returns options list for
    the first match if any match. */
  let optionsObject: NewApplicationOptions | undefined;
  for (let index = 0; index < answer.length; index++) {
    const value = answer[index];
    optionsObject = answerOptionlistMapper[value];
    if (optionsObject && optionsObject.options) break; // Found a match, break.
  }
  return optionsObject;
};

const answerOptionlistMapper: {[key in optionId]?: NewApplicationOptions} = {
  nyttbyggunder70m2: options.garage,
  annet: options.garage,
  bruksendringhoveddel: options.roomEntranceOptions,
};
