import {useMemo} from 'react';
import {Helmet} from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
import layout from '../../components/hocs/layout';
import '../../main.scss';
import {setTitle} from '../../utils/setTitle';
import ArticlesPreviewSection from '../Articles/ArticlesPreview/ArticlesPreview';
import Header from './Header/Header';
import './LandingPage.scss';
import ProffSection from './ProffSection/ProffSection';
import ThisIsEbyggesok from './ThisIsEbyggesok/ThisIsEbyggesok';
import VeilederPreview from './VeilederPreview/VeilederPreview';

// RUN AB-TESTING ON FRONT PAGE, RESULT PAGE, SUMMARY PAGE - RANDOMLY SHOW EITHER PRICE A OR B TO USERS

const LandingPage = () => {
  setTitle('Enklere byggesøknad');

  function useQuery() {
    const {search} = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  /* eslint-disable */
  const knr = query.get('knr');

  /* 
    If we need kommunenummer deeplink at some point, this is how we fetch it from the url
    Url example: www.ebyggesok.no/?knr=4206
  */

  return (
    <div className='LandingPage'>
      <Helmet>
        <meta
          data-rh='true'
          name='keywords'
          content='byggesøknad, bygge søknad, tilbygg, søknad tilbygg, byggesøknad kommune'
        />
        <meta
          name='description'
          content='eByggesøk for privatpersoner veileder deg gjennom byggesøknaden når du skal søke om å gjøre noe på din egen eiendom.'
        />
        <link rel='canonical' href='https://www.ebyggesok.no/' />
      </Helmet>
      <Header showVeilederBtn={true} />
      <VeilederPreview />
      <ThisIsEbyggesok background={'green'} />
      {<ArticlesPreviewSection background={'white'} step='forside' />}
      {<ProffSection background='green' />}
    </div>
  );
};

export default layout({hideStepper: true, noSpacing: true, showFooter: true, whiteFooterCityBackground: false})(
  LandingPage
);
