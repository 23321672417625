import {ApplicationRoutes} from '../../utils/routes/ApplicationRoutes';
import {SupportedMangelbrevTypes} from '../../services/QuestionData/mangelbrev/mangelbrevTypes';

// IMPORTANT! DO NOT CHANGE ANY OF THE CAPITAL LETTERED IDS
// WITHOUT CHANGING IN DATABASE

export type mainStepId =
  | 'WHAT_TO_DO' //PART OF START APPLICATION PRE-STEP, NOT IN SIDEMENU
  | 'REGULATION'
  | 'PLASSERING'
  | 'AVSTAND_BEREGNING'
  | 'PLACE_BRUKSENDRING'
  | 'TEKNISKE_KRAV'
  | 'DISPENSASJON'
  | 'TILTAKSHAVER'
  | 'PREPARE_PLACEMENT'
  | 'FERDIGATTEST'
  | 'FERDIGATTEST_SUMMARY'
  | 'FERDIGATTEST_SUBMITTED'
  | 'MANGELBREV_TYPES'
  | 'MANGELBREV_SUMMARY'
  //Kun for klient:
  | 'applicationSubmitted' //svarer ikke på noen spm
  | 'kommunens_regelverk' //har understeg
  | 'what_to_do_summary'
  | 'nabovarsel' //Har understeg
  | 'submit_application' //Har understeg
  | 'AVAILABLE_AREA_TO_BUILD_CALCULATION'
  | 'mangel_utfylling_dynamiske_indresteg';

export type innerStepId =
  | 'ADD_APPLICATION_ATTACHMENTS'
  | 'ADD_NABOVARSEL_ATTACHMENTS'
  | 'NABOVARSEL_SVAR'
  | 'TILTAKSHAVER_INNER'
  | 'UGRAD_PARKERING'
  | 'UGRAD_AREAL'
  | 'UGRAD_UTNYTTING'
  | 'CHOOSE_NEIGHBOURS'
  | 'ANDREKRAV'
  | 'TEKNISKE_KRAV'
  | 'REGULATION'
  | 'VANNAVLOP'
  | 'PREPARE_PLACEMENT_INNERSTEP'
  | 'ROAD_DISTANCE'
  | 'PLACE_BUILDING'
  | 'AVKJORSEL'
  | 'SITUASJONSPLAN'
  | 'FOELGEBREV'
  | 'MANGELBREV_SAKSNUMMER'
  | SupportedMangelbrevTypes
  //Kun for klient:
  | 'ugrad_summary'
  | 'AVAILABLE_AREA_TO_BUILD_CALCULATION'
  | 'applicationSubmitted' //svarer ikke direkte på noen spm
  | 'nabovarsel_summary'
  | 'submit_summary'
  | 'MANGEL_DYNAMISK';

export type mainStep = {id: mainStepId; text: string; paths: string[]; innerSteps?: innerStep[]};
export type innerStep = {id: innerStepId; text: string};
export type StepIds = mainStepId | innerStepId;
//Setup for all existing main steps:
type MainStepsInStepper = Exclude<mainStepId, 'WHAT_TO_DO'>;
export const stepConfig: {[key in MainStepsInStepper]: mainStep} = {
  what_to_do_summary: {id: 'what_to_do_summary', text: 'Info', paths: [ApplicationRoutes.what_to_do_summary.path]},
  REGULATION: {id: 'REGULATION', text: 'Regulering', paths: [ApplicationRoutes.REGULATION.path]},
  kommunens_regelverk: {
    id: 'kommunens_regelverk',
    text: 'Hva har du lov å bygge',
    paths: [
      ApplicationRoutes.REGULATION.path,
      ApplicationRoutes.UGRAD_UTNYTTING.path,
      ApplicationRoutes.UGRAD_AREAL.path,
      ApplicationRoutes.UGRAD_PARKERING.path,
      ApplicationRoutes.ugrad_summary.path,
      ApplicationRoutes.ANDREKRAV.path,
      ApplicationRoutes.VANNAVLOP.path,
    ],
    innerSteps: [
      {id: 'REGULATION', text: 'Regulering'},
      {id: 'UGRAD_UTNYTTING', text: 'Grad av utnytting'},
      {id: 'UGRAD_AREAL', text: 'Eksisterende bygg'},
      {id: 'UGRAD_PARKERING', text: 'Parkeringsplasser'},
      {id: 'AVAILABLE_AREA_TO_BUILD_CALCULATION', text: 'Byggestørrelse'},
      {id: 'ANDREKRAV', text: 'Andre krav'},
      {id: 'VANNAVLOP', text: 'Vann og avløp'},
    ],
  },
  PREPARE_PLACEMENT: {
    id: 'PREPARE_PLACEMENT',
    text: 'Forbered plassering',
    paths: [ApplicationRoutes.PREPARE_PLACEMENT.path],
  },
  //Tilbygg og frittligende bygg:
  PLASSERING: {
    id: 'PLASSERING',
    text: 'Plassering',
    paths: [
      ApplicationRoutes.PLACE_BUILDING.path,
      ApplicationRoutes.ROAD_DISTANCE.path,
      ApplicationRoutes.AVKJORSEL.path,
    ],
    innerSteps: [
      {
        id: 'PLACE_BUILDING',
        text: 'Tegn og analyser',
      },
      {id: 'ROAD_DISTANCE', text: 'Avstand til vei'},
      {
        id: 'AVKJORSEL',
        text: 'Avkjørsel',
      },
    ],
  },
  //Bruksendring
  PLACE_BRUKSENDRING: {
    id: 'PLACE_BRUKSENDRING',
    text: 'Marker rommet og analyser',
    paths: [ApplicationRoutes.PLACE_BRUKSENDRING.path],
  },
  //Riving:
  AVSTAND_BEREGNING: {id: 'AVSTAND_BEREGNING', text: 'Plassering', paths: [ApplicationRoutes.chooseBuilding.path]},
  TEKNISKE_KRAV: {id: 'TEKNISKE_KRAV', text: 'Tekniske krav', paths: [ApplicationRoutes.TEKNISKE_KRAV.path]},
  DISPENSASJON: {id: 'DISPENSASJON', text: 'Dispensasjon og unntak', paths: [ApplicationRoutes.dispention.path]},
  AVAILABLE_AREA_TO_BUILD_CALCULATION: {
    id: 'AVAILABLE_AREA_TO_BUILD_CALCULATION',
    text: 'Byggestørrelse',
    paths: [ApplicationRoutes.ugrad_summary.path],
  },
  nabovarsel: {
    id: 'nabovarsel',
    text: 'Betal og send nabovarsel',
    paths: [
      ApplicationRoutes.CHOOSE_NEIGHBOURS.path,
      ApplicationRoutes.SITUASJONSPLAN.path,
      ApplicationRoutes.ADD_NABOVARSEL_ATTACHMENTS.path,
      ApplicationRoutes.TILTAKSHAVER.path,
      ApplicationRoutes.nabovarsel_summary.path,
    ],
    innerSteps: [
      {id: 'CHOOSE_NEIGHBOURS', text: 'Velg naboer'},
      {id: 'SITUASJONSPLAN', text: 'Situasjonsplan'},
      {id: 'ADD_NABOVARSEL_ATTACHMENTS', text: 'Vedlegg'},
      {id: 'TILTAKSHAVER_INNER', text: 'Tiltakshaver'},
      {id: 'nabovarsel_summary', text: 'Oppsummering og betaling'},
    ],
  },
  TILTAKSHAVER: {
    id: 'TILTAKSHAVER',
    text: 'Tiltakshaver',
    paths: [ApplicationRoutes.TILTAKSHAVER.path],
  },
  submit_application: {
    id: 'submit_application',
    text: 'Betal og send søknad',
    paths: [
      ApplicationRoutes.FOELGEBREV.path,
      ApplicationRoutes.SITUASJONSPLAN.path,
      ApplicationRoutes.NABOVARSEL_SVAR.path,
      ApplicationRoutes.ADD_APPLICATION_ATTACHMENTS.path,
      ApplicationRoutes.soknad_summary.path,
    ],
    innerSteps: [
      {id: 'FOELGEBREV', text: 'Følgebrev'},
      {id: 'SITUASJONSPLAN', text: 'Situasjonsplan'},
      {id: 'NABOVARSEL_SVAR', text: 'Status på nabovarsel'},
      {id: 'ADD_APPLICATION_ATTACHMENTS', text: 'Vedlegg'},
      {id: 'submit_summary', text: 'Oppsummering og send søknad'},
    ],
  },
  applicationSubmitted: {
    id: 'applicationSubmitted',
    text: 'Innsendt søknad',
    paths: [ApplicationRoutes.applicationSubmitted.path],
  },
  FERDIGATTEST: {
    id: 'FERDIGATTEST',
    text: 'Ferdigattest',
    paths: [ApplicationRoutes.ferdigattest.path],
  },
  FERDIGATTEST_SUMMARY: {
    id: 'FERDIGATTEST_SUMMARY',
    text: 'Oppsummering',
    paths: [ApplicationRoutes.ferdigattestSummary.path],
  },
  FERDIGATTEST_SUBMITTED: {
    id: 'FERDIGATTEST_SUBMITTED',
    text: 'Innsendt ferdigattest',
    paths: [ApplicationRoutes.ferdigattestSubmitted.path],
  },
  MANGELBREV_TYPES: {
    id: 'MANGELBREV_TYPES',
    text: 'Mangelbrev typene',
    paths: [ApplicationRoutes.MANGELBREV_TYPES.path],
  },
  mangel_utfylling_dynamiske_indresteg: {
    id: 'mangel_utfylling_dynamiske_indresteg',
    text: 'Svar på mangel',
    paths: [ApplicationRoutes.MANGELBREV_SAKSNUMMER.path],
    innerSteps: [{id: 'MANGELBREV_SAKSNUMMER', text: 'Saksnummer'}],
  },
  MANGELBREV_SUMMARY: {
    id: 'MANGELBREV_SUMMARY',
    text: 'Oppsummering og innsending',
    paths: [ApplicationRoutes.MANGELBREV_SUMMARY.path],
  },
};
