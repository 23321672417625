import {ToiSpinner} from '@norkart/toi-components';
import * as React from 'react';
import {currentPathRequiresLogin} from '../../utils/routes/routesHelpers';
import {DesktopContainer, TabletAndMobileContainer} from '../containers/DeviceContainers';
import Footer from '../Footer/footer';
import Topbar from '../TopBar/Topbar';
import './layout.scss';

type LayoutOptions = {
  hideStepper?: boolean;
  showFooter?: boolean;
  noSpacing?: boolean;
  white?: boolean;
  whiteFooterCityBackground?: boolean;
};

const ProgStepper = React.lazy(() => import('../Stepper/ProgressStepper'));

export default function layout(options: LayoutOptions) {
  return function <P extends Object>(BaseComponent: React.ComponentType<P>) {
    return function draw(props: P) {
      const content = (
        <div className='contentWidthWrapper'>
          <DesktopContainer>
            {(!options || !options.hideStepper) && currentPathRequiresLogin() && (
              <div className='sidePanel'>
                <React.Suspense fallback={<ToiSpinner />}>
                  <ProgStepper />
                </React.Suspense>
              </div>
            )}
          </DesktopContainer>
          <div className='contentWrapper'>
            <DesktopContainer>
              <main className={`mainSection`}>
                <div id={'pageMainContent'} className={`mainContentWrapper ${!options.noSpacing && 'withSpacing'}`}>
                  <BaseComponent {...props} />
                </div>
              </main>
            </DesktopContainer>
            <TabletAndMobileContainer>
              <main className={`mainSection tablet`}>
                <BaseComponent {...props} />
              </main>
            </TabletAndMobileContainer>
            {options && options.showFooter && <Footer whiteFooterCityBackground={options.whiteFooterCityBackground} />}
          </div>
        </div>
      );
      return (
        <div className='appContentWrapper'>
          <Topbar />
          <DesktopContainer>
            <div
              className={`layout ${!options || !options.hideStepper ? 'showStepper' : 'hideStepper'} ${
                options.white ? 'white' : ''
              }`}
            >
              {content}
            </div>
          </DesktopContainer>
          <TabletAndMobileContainer>
            <div className={`layout tablet hideStepper ${options.white ? 'white' : ''}`}>{content}</div>
          </TabletAndMobileContainer>
        </div>
      );
    };
  };
}
