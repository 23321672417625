import {QuestionId} from '../services/utils/questionIds';
import {AnswerValues} from '../state/questionTypes';
import {ugradOption} from './ugrad';

export function questionWithError(answers: AnswerValues) {
  return getErrorFromQuestions(answers).length > 0;
}

function getErrorFromQuestions(answerValues: AnswerValues): {id: QuestionId}[] {
  var questionsWithError: {id: QuestionId}[] = [];
  if ((answerValues.UGRAD_DEF as ugradOption) === 'Ingen av disse') {
    questionsWithError.push({
      id: 'UGRAD_DEF',
    });
  }
  if ((answerValues.UGRAD_DEF as ugradOption) === 'U-grad') {
    questionsWithError.push({
      id: 'UGRAD_DEF',
    });
  }
  return questionsWithError;
}

export function discoveredErrorForQuestion(answers: AnswerValues, question: QuestionId): boolean {
  return getErrorFromQuestions(answers).filter((item) => item.id === question).length > 0;
}
