import {MessageError, PrepareNabovarselResponse} from '../services/ebyggesokServices/nabovarselService';
import {AttachmentValidationString} from '../services/ebyggesokServices/soknadService';
import {DeactivateAnswerAction} from './questionState';

export interface ErrorState {
  blockableErrors: {
    type: 'Ansvarsrett' | 'Ugyldig input';
  }[];
  expiredNabovarsel: boolean;
  nabovarselSummaryValidationError?: ValidationError;
  applicationSummaryValidationError?: ValidationError;
  innsendingSummaryValidationError?: ValidationError;
  inputFieldsValidationState: {inputId: string; isValid: boolean}[];
  readyToValidateInputFields: boolean;
}

export const initialState: ErrorState = {
  blockableErrors: [],
  expiredNabovarsel: false,
  inputFieldsValidationState: [],
  readyToValidateInputFields: false,
};

export type ValidationError = {
  requestError?;
  nabovarselResponse?: PrepareNabovarselResponse;
  soknadErrors?: MessageError[];
  innsendingErrors?: MessageError[];
  soknadAttachmentErrors?: AttachmentValidationString[];
  ferdigattestAttachmentErrors?: AttachmentValidationString[];
  innsendingAttachmentErrors?: AttachmentValidationString[];
};

export type Action =
  | {
      type: 'addAnsvarsrettError';
    }
  | {
      type: 'removeAnsvarsrettError';
    }
  | {
      type: 'expiredNabovarsel';
      value: boolean;
    }
  | {
      type: 'setNabovarselSummaryValidationError';
      value: ValidationError | undefined;
    }
  | {
      type: 'setInnsendingSummaryValidationError';
      value: ValidationError | undefined;
    }
  | {
      type: 'resetValidationErrors';
    }
  | {
      type: 'setAttachmentErrors';
      value: AttachmentValidationString[] | undefined;
    }
  | {
      type: 'updateInputFieldValidationState';
      value: {inputId: string; isValid: boolean};
    }
  | {
      type: 'removeInputFieldValidationState';
      value: string;
    }
  | {
      type: 'setReadyToValidate';
      value: boolean;
    }
  | DeactivateAnswerAction;

export const reducer = (state: ErrorState, action: Action): ErrorState => {
  switch (action.type) {
    case 'addAnsvarsrettError':
      const blockableErrors = [...state.blockableErrors];
      if (!blockableErrors.find((e) => e.type === 'Ansvarsrett')) {
        blockableErrors.push({type: 'Ansvarsrett'});
      }
      return {...state, blockableErrors};
    case 'removeAnsvarsrettError':
      const blockableErrors2 = state.blockableErrors.filter((e) => e.type !== 'Ansvarsrett');
      return {...state, blockableErrors: blockableErrors2};

    case 'expiredNabovarsel':
      return {
        ...state,
        expiredNabovarsel: action.value,
      };
    case 'setNabovarselSummaryValidationError':
      return {
        ...state,
        nabovarselSummaryValidationError: action.value,
      };
    case 'setInnsendingSummaryValidationError':
      return {
        ...state,
        innsendingSummaryValidationError: action.value,
      };
    case 'resetValidationErrors':
      return {
        ...state,
        nabovarselSummaryValidationError: initialState.nabovarselSummaryValidationError,
        innsendingSummaryValidationError: initialState.innsendingSummaryValidationError,
      };
    case 'setAttachmentErrors':
      return {
        ...state,
        applicationSummaryValidationError: {
          ...state.applicationSummaryValidationError,
          soknadAttachmentErrors: action.value,
        },
      };
    case 'updateInputFieldValidationState':
      return {
        ...state,
        inputFieldsValidationState: [
          ...state.inputFieldsValidationState.filter((input) => input.inputId !== action.value.inputId),
          action.value,
        ],
      };
    case 'removeInputFieldValidationState':
      return {
        ...state,
        inputFieldsValidationState: [
          ...state.inputFieldsValidationState.filter((input) => input.inputId !== action.value),
        ],
      };
    case 'setReadyToValidate':
      return {
        ...state,
        readyToValidateInputFields: action.value,
      };
    case 'deactivateAnswer':
      return {
        ...state,
        inputFieldsValidationState: [
          ...state.inputFieldsValidationState.filter((input) => input.inputId !== action.value.id),
        ],
      };
    default:
      return state;
  }
};
