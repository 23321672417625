import React, {CSSProperties} from 'react';
import Markdown from 'react-markdown';
import FullscreenImage from './FullscreenImage';
import './NkTypography.scss';
import {Tooltip} from '@material-ui/core';
import {DesktopContainer} from '../containers/DeviceContainers';
import {TabletAndMobileContainer} from '../containers/DeviceContainers';
import NkLink from '../NkLink/NkLink';
import {InfoPagesRoutes} from '../../utils/routes/InfoPagesRoutes';
import {useLocation} from 'react-router-dom';

type typographyHeadingTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
type typographyHeadingTypes = 'heading2' | 'heading1' | 'heading3' | 'heading4' | 'heading5';

type Props = {
  type:
    | 'smallGreenHeader'
    | typographyHeadingTypes
    | 'legend' //Used for question-header
    | 'label'
    | 'smallParagraph'
    | 'normalParagraph'
    | 'largeParagraph'
    | 'caption'
    | 'markdown'
    | 'error';
  tag?: //Used to overwrite html tag, but keep styling
  typographyHeadingTags | 'p' | 'legend' | 'label' | 'span';
  markdownParagraphStyle?: 'largeParagraph' | 'smallParagraph' | 'normalParagraph';
  children: any;
  center?: boolean;
  style?: CSSProperties;
  htmlFor?: string;
  color?: 'grey' | 'green' | 'white' | 'blackBackgroundGreen' | 'redUU';
  onClick?;
  className?: string;
  id?: string;
  tabIndex?: number;
  overrideHeaders?: boolean; //Ignore headers in markdown
};

const NkTypography = ({
  type,
  tag,
  children,
  center,
  style,
  markdownParagraphStyle,
  color,
  htmlFor,
  onClick,
  className,
  id,
  tabIndex,
  overrideHeaders,
}: Props) => {
  const [fullscreenImg, setFullscreenImg] = React.useState<string | undefined>();
  const location = useLocation();
  const onImageClick = (e) => {
    const imgSrc = e.target.getAttribute('src');
    setFullscreenImg(imgSrc);
  };

  const FullscreenImageRender = (
    <FullscreenImage
      closeFullscreen={() => {
        setFullscreenImg(undefined);
      }}
      fullscreenImg={fullscreenImg}
    />
  );

  const getTagElement = () => {
    if (tag && type !== 'markdown') {
      return tag;
    } else {
      if (type === 'heading1') return 'h1';
      if (type === 'heading2') return 'h2';
      if (type === 'heading3') return 'h3';
      if (type === 'heading4') return 'h4';
      if (type === 'heading5') return 'h5';
      if (type === 'label') return 'label';
      if (type === 'legend') return 'legend';
      if (type === 'caption') return 'p';
      if (type === 'largeParagraph' || type === 'normalParagraph' || type === 'smallParagraph') return 'p';
      if (type === 'smallGreenHeader') return 'p';
      if (type === 'markdown') return 'markdown';
      if (type === 'error') return 'span';
    }
  };

  const getTypography = () => {
    const TagElement = getTagElement()!;
    color = type === 'error' ? 'redUU' : color;
    const classname = `nktypography ${type}${center ? ' center' : ''} ${color ? color : ''} ${
      className ? className : ''
    }`;

    const getMdHeadingType = (props, tag: typographyHeadingTags): typographyHeadingTypes | 'normalParagraph' => {
      let headingStyle = tag;
      if (overrideHeaders) {
        return 'normalParagraph';
      }
      if (props.children && props.children.length === 1) {
        headingStyle = (props.children[0] as string).includes('style:')
          ? ((props.children[0] as string).split('style:')[1].substring(0, 2) as typographyHeadingTags)
          : tag;
      }
      return headingStyle.replace('h', 'heading') as typographyHeadingTypes;
    };

    const getMdHeadingContent = (text) => {
      if (text && text.length === 1) {
        return text[0]
          .replace('style:h1', '')
          .replace('style:h2', '')
          .replace('style:h3', '')
          .replace('style:h4', '')
          .replace('style:h5', '');
      } else {
        return text;
      }
    };

    const H = (props: any) => {
      const {htmlTag, htmlType, ...rest} = props;

      return (
        <NkTypography
          tag={!overrideHeaders ? htmlTag : 'span'}
          type={overrideHeaders ? htmlType : getMdHeadingType(rest, htmlTag)}
        >
          {getMdHeadingContent(rest.children)}
        </NkTypography>
      );
    };

    if (TagElement === 'label') {
      return (
        <label style={style} htmlFor={htmlFor} onClick={onClick} className={classname} id={id}>
          {children}
        </label>
      );
    } else if (TagElement === 'markdown') {
      return (
        <div style={style} className={`markdownTypography ${markdownParagraphStyle || 'normalParagraph'}`} id={id}>
          <DesktopContainer>
            <Markdown
              linkTarget={'_blank'}
              components={{
                p({...props}) {
                  return (
                    <NkTypography type={`${markdownParagraphStyle || 'normalParagraph'}`}>
                      {props.children}
                    </NkTypography>
                  );
                },
                h1({...props}) {
                  return <H htmlTag='h1' htmlType='heading1' {...props} />;
                },
                h2({...props}) {
                  return <H htmlTag='h2' htmlType='heading2' {...props} />;
                },
                h3({...props}) {
                  return <H htmlTag='h3' htmlType='heading3' {...props} />;
                },
                h4({...props}) {
                  return <H htmlTag='h4' htmlType='heading4' {...props} />;
                },
                h5({...props}) {
                  return <H htmlTag='h5' htmlType='heading5' {...props} />;
                },
                img: ({alt, src}: any) => (
                  <Tooltip
                    title={<p style={{fontSize: 14}}>Trykk for fullskjerm</p>}
                    aria-label='add'
                    placement='right'
                  >
                    <img alt={alt} src={src} onClick={onImageClick} />
                  </Tooltip>
                ),
                a: ({href, children}) => {
                  const hasEbyggesokLink = href?.includes('ebyggesoklink');
                  const currentPathIsArticle = location.pathname.includes(InfoPagesRoutes.article.path);
                  const linkUrl = hasEbyggesokLink ? href!.replace('ebyggesoklink', '') : href!;
                  return (
                    <NkLink
                      newPage={currentPathIsArticle && hasEbyggesokLink ? false : true} //Link from one article to another does not open new page
                      href={linkUrl}
                      size={
                        markdownParagraphStyle === 'largeParagraph'
                          ? 'large'
                          : markdownParagraphStyle === 'smallParagraph'
                          ? 'small'
                          : 'normal'
                      }
                    >
                      {children}
                    </NkLink>
                  );
                },
              }}
            >
              {children}
            </Markdown>
          </DesktopContainer>
          <TabletAndMobileContainer>
            <Markdown
              linkTarget={'_blank'}
              components={{
                h1({...props}) {
                  return <H htmlTag='h1' htmlType='heading1' {...props} />;
                },
                h2({...props}) {
                  return <H htmlTag='h2' htmlType='heading2' {...props} />;
                },
                h3({...props}) {
                  return <H htmlTag='h3' htmlType='heading3' {...props} />;
                },
                h4({...props}) {
                  return <H htmlTag='h4' htmlType='heading4' {...props} />;
                },
                h5({...props}) {
                  return <H htmlTag='h5' htmlType='heading5' {...props} />;
                },
              }}
            >
              {children}
            </Markdown>
          </TabletAndMobileContainer>
        </div>
      );
    } else {
      return (
        <TagElement style={style} className={classname} id={id} tabIndex={tabIndex}>
          {children}
        </TagElement>
      );
    }
  };

  return (
    <>
      {FullscreenImageRender}
      {getTypography()}
    </>
  );
};
export default NkTypography;
