import {AdresseFromServer, InfoForAdresseResponse} from '../services/matrikkelgrunnbok';

/**
 * AddresseId(20char) -> VegAdresseId(14char)
 * VegAdresseId: formatet KKKKAAAAAHHHHB hvor K=Knr, A=Adr.Kode (=Gatenr), H=Husnr, B=Bokstav
 */
export function addressIdToRoadAddressId(addressId: string) {
  return addressId.substr(0, 13) + addressId[16];
}

export const vegAdresseToAdresseFromServer = (infoForAddress: InfoForAdresseResponse, kartverksId: string) => {
  if (infoForAddress.InfoForVegadresse && infoForAddress.InfoForVegadresse.length > 0) {
    const vegAdresse = infoForAddress.InfoForVegadresse[0];
    const converted: AdresseFromServer = {
      VegadresseIdent: {
        Kommunenummer: vegAdresse.Kommunenummer,
        Gatenummer: vegAdresse.Gatenummer,
        Gatenavn: vegAdresse.Gatenavn,
        Husnummer: vegAdresse.Husnummer,
        Bokstav: vegAdresse.Bokstav,
      },
      AdresseType: vegAdresse.AdresseType,
      Postnummer: vegAdresse.Postnummer,
      Poststed: vegAdresse.Poststed,
      X: vegAdresse.X,
      Y: vegAdresse.Y,
      Unikid: kartverksId,
    };

    return converted;
  }
};
