import {OrderType} from '../services/ebyggesokServices/userOrderService';

export interface PaymentState {
  id: string | undefined;
  pending: boolean;
  payed: boolean;
  paymentType?: OrderType;
  hasSkippedInTest?: boolean;
  skipPayment: boolean;
  hasCancelledPayment: boolean;
  isPendingHookSuccess: boolean;
  failed: boolean;
}

export const initialState: PaymentState = {
  id: undefined,
  pending: false,
  payed: false,
  skipPayment: false,
  hasCancelledPayment: false,
  isPendingHookSuccess: false,
  failed: false,
};

export type Action =
  | {
      type: 'setPaymentId';
      value?: string;
    }
  | {
      type: 'setPaymentPending';
      value: boolean;
    }
  | {
      type: 'setPaymentType';
      value: OrderType;
    }
  | {
      type: 'setSkipPayment';
      value: boolean;
    }
  | {
      type: 'setPaymentSuccess';
      value: boolean;
    }
  | {
      type: 'setHasCancelledPayment';
      value: boolean;
    }
  | {
      type: 'setHasSkippedPaymentInTest';
    }
  | {
      type: 'setPendingHookSuccess';
    }
  | {
      type: 'setPaymentFailed';
      value: boolean;
    };

export const reducer = (state: PaymentState = initialState, action: Action): PaymentState => {
  if (action.type === 'setPaymentId') {
    return {
      ...state,
      id: action.value,
      pending: true,
      payed: false,
    };
  } else if (action.type === 'setPaymentPending') {
    return {
      ...state,
      pending: action.value,
      payed: false,
      hasSkippedInTest: false,
    };
  } else if (action.type === 'setPaymentType') {
    return {
      ...state,
      paymentType: action.value,
    };
  } else if (action.type === 'setPaymentSuccess') {
    return {
      ...state,
      pending: false,
      payed: action.value,
      hasSkippedInTest: false,
      isPendingHookSuccess: false,
    };
  } else if (action.type === 'setHasCancelledPayment') {
    return {
      ...state,
      hasCancelledPayment: action.value,
    };
  } else if (action.type === 'setHasSkippedPaymentInTest') {
    return {
      ...state,
      hasSkippedInTest: true,
    };
  } else if (action.type === 'setSkipPayment') {
    return {
      ...state,
      skipPayment: action.value,
    };
  } else if (action.type === 'setPendingHookSuccess') {
    return {
      ...state,
      isPendingHookSuccess: true,
    };
  } else if (action.type === 'setPaymentFailed') {
    return {
      ...state,
      payed: false,
      isPendingHookSuccess: false,
      pending: false,
      failed: action.value,
    };
  } else {
    return state;
  }
};
