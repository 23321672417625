import {DatavarehusDatasetName} from './datavarehusDatasetsState';

export interface DistancesState {
  toDataset: Distance[];
  isUpdatingDistances: boolean;
  hasUpdatedDistances: boolean;
  canCalculateFromBuildingDistances: boolean;
}

export type CustomDatasetName = 'border' | 'ownBuilding' | 'surroundingBuilding';

export type Distance = {
  name: DatavarehusDatasetName | CustomDatasetName;
  distanceObj: DistanceInstance;
  minDistance: number;
  fetchFor: 'property' | 'bufferedProperty';
};

export type DistanceInstance = {
  distance: number | undefined;
  line?: GeoJSON.LineString;
  debugLayer?: any;
};

export const getDistance = (
  datasetName: DatavarehusDatasetName | CustomDatasetName | 'anyBuilding',
  distances: DistancesState
): Distance => {
  if (datasetName === 'anyBuilding') {
    const ownBuilding = distances.toDataset.filter((dataset) => dataset.name === 'ownBuilding')[0];
    const otherBuilding = distances.toDataset.filter((dataset) => dataset.name === 'surroundingBuilding')[0];
    if (ownBuilding.distanceObj?.distance !== undefined && otherBuilding.distanceObj?.distance !== undefined) {
      return ownBuilding.distanceObj?.distance < otherBuilding.distanceObj?.distance ? ownBuilding : otherBuilding;
    } else {
      return ownBuilding.distanceObj?.distance !== undefined ? ownBuilding : otherBuilding; // If one is undefined, return the one that is not.
    }
  } else {
    return distances.toDataset.filter((dataset) => dataset.name === datasetName)[0];
  }
};

export const getMinDistance = (
  datasetName: DatavarehusDatasetName | CustomDatasetName | 'anyBuilding',
  distances: DistancesState
) => {
  if (datasetName === 'anyBuilding') {
    return getDistance('ownBuilding', distances).minDistance;
  }
  return getDistance(datasetName, distances).minDistance;
};

const initDist: DistanceInstance = {distance: undefined};

export const initialState: DistancesState = {
  toDataset: [
    {name: 'border', distanceObj: initDist, minDistance: 4, fetchFor: 'property'},
    {name: 'ownBuilding', distanceObj: initDist, minDistance: 8, fetchFor: 'property'},
    {name: 'surroundingBuilding', distanceObj: initDist, minDistance: 8, fetchFor: 'bufferedProperty'},
    {name: 'hoyspent_powerline', distanceObj: initDist, minDistance: 6, fetchFor: 'bufferedProperty'},
    {name: 'railway', distanceObj: initDist, minDistance: 30, fetchFor: 'bufferedProperty'},
    {name: 'coastline', distanceObj: initDist, minDistance: 0, fetchFor: 'property'},
    {name: 'flom', distanceObj: initDist, minDistance: 0, fetchFor: 'property'},
    {name: 'kulturminner', distanceObj: initDist, minDistance: 0, fetchFor: 'property'},
    {name: 'skred', distanceObj: initDist, minDistance: 0, fetchFor: 'property'},
    {name: 'truede_vernede_arter', distanceObj: initDist, minDistance: 0, fetchFor: 'property'},
    {name: 'grunnforurensning', distanceObj: initDist, minDistance: 0, fetchFor: 'property'},
  ],
  isUpdatingDistances: false,
  hasUpdatedDistances: false,
  canCalculateFromBuildingDistances: false,
};

export type DistancesAction =
  | {
      type: 'setDistances';
      value: Distance[];
    }
  | {
      type: 'isUpdatingDistances';
    }
  | {
      type: 'hasUpdatedDistances';
    }
  | {
      type: 'resetDistances';
    }
  | {
      type: 'setCanCalculateFromBuildingDistances';
      value: boolean;
    };

const roundToOneDecimal = (dist) => {
  if (dist !== undefined) {
    return Math.round(dist * 10) / 10;
  } else {
    return dist;
  }
};

export const reducer = (state: DistancesState, action: DistancesAction): DistancesState => {
  switch (action.type) {
    case 'setDistances':
      const datasetsToChange = action.value.map((d) => d.name);
      const unchangedDistances = state.toDataset.filter((d) => !datasetsToChange.includes(d.name));
      const newDistState: Distance[] = action.value.concat(unchangedDistances);
      const oneDecimalDistances: Distance[] = newDistState.map((dist) => ({
        ...dist,
        minDistance: roundToOneDecimal(dist.minDistance),
        distanceObj: {...dist.distanceObj, distance: roundToOneDecimal(dist.distanceObj.distance)},
      }));
      return {...state, toDataset: oneDecimalDistances, hasUpdatedDistances: true, isUpdatingDistances: false};
    case 'isUpdatingDistances':
      return {...state, isUpdatingDistances: true, hasUpdatedDistances: false};
    case 'hasUpdatedDistances':
      return {...state, isUpdatingDistances: false, hasUpdatedDistances: true};
    case 'setCanCalculateFromBuildingDistances':
      return {...state, canCalculateFromBuildingDistances: action.value};
    case 'resetDistances':
      return {...initialState, canCalculateFromBuildingDistances: state.canCalculateFromBuildingDistances};
    default:
      return state;
  }
};
