import {InfoPagesRoutes} from './InfoPagesRoutes';
import {ApplicationRoutes, UaRoutes} from './ApplicationRoutes';

export type UaRoutesConfig = {
  [key in UaRoutes]: UaRoute;
};

export type UaRoute = {path: string; hasActiveApplication: boolean; requiresLogin: boolean; isInfoPage?: boolean};

const routes: UaRoutesConfig = {...ApplicationRoutes, ...InfoPagesRoutes};

export const shouldDisplayStepper = (path: string): boolean => {
  const routeNames = Object.keys(routes);
  let foundRoute: UaRoute | undefined;
  routeNames.forEach((name) => {
    if (routes[name].path === path) {
      foundRoute = routes[name] as UaRoute;
    }
  });
  const onSubmittedPage = [routes.applicationSubmitted.path, routes.ferdigattestSubmitted.path].includes(path);
  return (foundRoute?.hasActiveApplication && !onSubmittedPage) ?? false;
};

export const getCurrentRoute = () => {
  const currentPath = window.location.pathname;
  const routeNames = Object.keys(routes);
  let res: UaRoute | undefined;

  routeNames.forEach((name) => {
    if (routes[name].path === currentPath) {
      res = routes[name];
    }
  });

  //Path in route has a dynamic created path, so have to check for inluces instead of equals
  if (currentPath.includes(routes.dynamic_mangel.path)) {
    res = routes.dynamic_mangel;
  }

  return res;
};

export const currentPathRequiresLogin = (): boolean => {
  const route = getCurrentRoute();
  return !!route?.requiresLogin;
};
