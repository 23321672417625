import React from 'react';
import layout from '../../components/hocs/layout';
import NkTypography from '../../components/NkTypography/NkTypography';
import {setTitle} from '../../utils/setTitle';

const NotFound = () => {
  React.useEffect(() => {
    setTitle('Ukjent side');
  }, []);

  return (
    <div>
      <NkTypography type='heading1'>Wops!</NkTypography>
      <NkTypography type='largeParagraph' style={{marginTop: 50}}>
        Denne siden finnes ikke.
      </NkTypography>
    </div>
  );
};
export default layout({hideStepper: true, showFooter: true})(NotFound);
