export const charsToIgnore = [
  ',',
  '?',
  '!',
  '.',
  ';',
  '[',
  ']',
  '(',
  ')',
  '*',
  'det',
  'av',
  'på',
  'om',
  'hvis',
  'fra',
  'for',
  'får',
  'jeg',
  'kan',
  'være',
  'er',
  'når',
  'så',
  'da',
  'bare',
  'vel',
  'også',
  'et',
  'en',
  'å',
  'jo',
];

export const getFilteredSearchWords = (searchText: string) => {
  //Remove extra signs
  charsToIgnore.forEach((char) => {
    searchText = searchText.toLowerCase().replaceAll(char, '');
  });
  return searchText.split(' ');
};
