import {getConfigValue} from '../../config';

export type FaqQuestionType = {header: string; content: string; displayOnFrontPage?: boolean};

export const faqText: {
  sectionName: string;
  questions: FaqQuestionType[];
}[] = [
  {
    sectionName: 'Kommunikasjon med kommunen ',
    questions: [
      {
        header: 'Jeg glemte å legge ved et dokument som skulle til kommunen ',
        content:
          'Foreløpig er det ikke støtte for å supplere en søknad. Du må derfor sende dette på e-post eller pr. post til kommunen.',
      },
      {
        header: 'Jeg har fått et mangelbrev fra kommunen – hva gjør jeg? ',
        content:
          'Hvis du har fått et mangelbrev av kommunen må du rette deg etter dette og sende de dokumentene som mangler til kommunen på e-post eller pr. post.',
      },
      {
        header: 'Hvor kan jeg se innsendt søknad i kommunens system? ',
        content:
          'Når søknaden er sendt til kommunen vil denne havne på kommunens postlister. Det kan ta noen dager før søknaden din blir registrert hos kommunen og legges ut på postlistene.',
      },
      {
        header: 'Når blir saken min synlig på kommunens postlister? ',
        content:
          'Når søknaden din sendes fra eByggesøk vil den havne i kommunens arkivsystem. Hvor lang tid det tar fra arkivavdelingen registrerer denne og til dette legges ut på postlistene, varierer fra kommune til kommune. Ta kontakt med din kommune for å vite mer om dette. ',
      },
    ],
  },
  {
    sectionName: 'Hjelp med søknaden',
    questions: [
      {
        header: 'Kan jeg få hjelp til å lage byggesøknaden? ',
        displayOnFrontPage: true,
        content:
          'Vi hjelper dessverre ikke med utforming av byggesøknader. eByggesøk er en digital veileder som gjør deg som innbygger mer selvbetjent ved å veilede deg igjennom stegene mot en komplett søknad, i tillegg til å sende søknaden digitalt til kommunen.',
      },
    ],
  },
  {
    sectionName: 'Tidsbruk',
    questions: [
      {
        header: 'Hvor lang tid tar det å lage en byggesøknad?',
        displayOnFrontPage: true,
        content:
          'Å utforme en byggesøknad kan være tidkrevende. Det er mye å sette seg inn i, og ofte mange dokumenter som skal utarbeides. eByggesøk veileder deg gjennom prosessen slik at du får informasjon om hva du skal gjøre, og hvor du eventuelt skal henvende deg dersom det er nødvendig. Jo mer grundig søknaden er, jo enklere vil det være for kommunen å behandle den. I mange tilfeller starter man på en søknad, og underveis i prosessen må man hente inn tegninger, dokumentasjon eller uttalelser fra andre. ',
      },
    ],
  },
  {
    sectionName: 'Pris',
    questions: [
      {
        header: 'Hva koster det å sende byggesøknaden via eByggesøk?',
        content: `Prisen for innsending av søknad via eByggesøk varierer fra kommune til kommune, men vil koste maks kr ${getConfigValue(
          'ebyggesokPrice'
        )}. Gå til forsiden for å se pris for din kommune. Det er kun innsending av søknaden som koster penger – veilederen vår er helt gratis.`,
      },
      {
        header: 'Hvor mye koster byggesaksgebyret?',
        displayOnFrontPage: true,
        content:
          'Byggesaksgebyret kan variere i pris fra et par tusen til flere titusener avhengig av type søknad og kommune. Ved å gå inn på kommunens hjemmeside og søke etter gebyrregulativet i kommunen kan du finne en oversikt over hva din kommune tar i byggesaksgebyr for det du ønsker å gjøre. ',
      },
    ],
  },
  {
    sectionName: 'Dokumentasjon',
    questions: [
      {
        header: 'Hvor kan jeg finne byggetegninger på min eiendom? ',
        content:
          'På [e-torg.no](https://e-torg.no/) kan du finne eksisterende tegninger på din eiendom. Finner du ikke produkter her må du ta kontakt med din kommune. ',
      },
    ],
  },
  {
    sectionName: 'Nabovarsel',
    questions: [
      {
        header: 'Jeg har ikke sendt nabovarsel til riktige naboer - hva gjør jeg? ',
        content:
          'Når nabovarsel er sendt er det ikke mulig å legge til flere naboer på det digitale varselet. Foreløpig er det ikke støtte for å sende nytt nabovarsel i eByggesøk. Du må derfor varsle naboen manuelt. Det kan du gjøre ved å skrive ut nabovarselet og vedleggene fra Altinn og deretter sende disse på e-post eller rekommandert til naboen. Husk å få bekreftet at nabovarselet er mottatt. Denne bekreftelsen må sendes til kommunen som en kvittering på at naboen er varslet. ',
      },
      {
        header: 'Jeg vil fjerne en eller flere naboer fra varselet etter at nabovarselet er sendt ut',
        content:
          'Det er dessverre ikke mulig å fjerne naboer etter at nabovarselet er sendt. Dersom du får melding om å sende nabovarsel manuelt, og du av ulike omstendigheter ikke får varslet naboen, kan du laste opp en fil under "Naboens merknad" der du informerer kommunen hvorfor naboen ikke er varslet. Deretter setter du dato for når naboen ble varslet til den datoen nabovarselet ble sendt ut, slik at 14-dagersfristen starter å løpe fra riktig dato. Varslingsmetode kan settes til "e-post".',
      },
      {
        header: 'Jeg glemte å legge ved et dokument som skulle til naboen',
        content:
          'Foreløpig er det ikke støtte for å supplere en søknad. Du må derfor sende dette på e-post eller pr. post til naboen ',
      },
      {
        header: 'Jeg glemte å varsle en nabo',
        content:
          'Foreløpig er det ikke støtte for å sende nytt nabovarsel i eByggesøk. Du må derfor varsle naboen manuelt. Det kan du gjøre ved å skrive ut nabovarselet og vedleggene fra Altinn og deretter sende disse på e-post eller rekommandert til naboen. Husk å få bekreftet at nabovarselet er mottatt. Denne bekreftelsen må sendes til kommunen som en kvittering på at naboen er varslet.',
      },
      {
        header: 'Kan jeg sende søknaden før fristen for å varsle naboene har gått ut?',
        content:
          'Det er ikke mulig å sende inn en digital søknad til kommunen før fristen på 14 dager er utløpt, med mindre alle naboene har svart på nabovarselet i Altinn. ',
      },
      {
        header: 'Jeg har allerede varslet naboene, må jeg betale for nytt nabovarsel?',
        content:
          'Prisen for å benytte eByggesøk til å sende inn en digital søknad til kommunen er det samme, uavhengig av om du sender med et nabovarsel eller ikke. Det er dermed ikke mulig å trekke fra nabovarselet i prisen. ',
      },
      {
        header: 'Hvem kan signere nabovarselet?',
        content:
          'Den som er registrert som eier av eiendommen må signere nabovarselet. Dersom det er registrert to eller flere eiere, må alle signere. ',
      },
    ],
  },
  {
    sectionName: 'Dispensasjon',
    questions: [
      {
        header: 'Jeg trenger bare å sende inn en dispensasjon – hva gjør jeg?',
        displayOnFrontPage: true,
        content:
          'Dersom du kun behøver å sende inn en dispensasjon, er ikke det noe spesifikt skjema. Dispensasjonssøknaden kan du skrive i et vanlig dokument, og deretter sende inn til kommunen på e-post. På lovdata.no kan du lese litt om [hvordan man skriver en dispensasjonssøknad](https://lovdata.no/lov/2008-06-27-71/§19-1). Kommunen har også veiledningsplikt og kan bistå deg i forbindelse med dispensasjonssøknaden. Husk også at du må varsle naboene om dispensasjonssøknaden. Elektronisk nabovarsling kan du gjøre via [e-Torg](https://e-torg.no)',
      },
    ],
  },
];
