import garageOptions from './garageOptions';
import roomEntranceOptions from './roomEntranceOptions';
import changeOptions from './tiltaksOptions/changeOptions';
import createOptions from './tiltaksOptions/createOptions';
import demolishOptions from './tiltaksOptions/demolishOptions';
import {buildOptions} from './tiltaksOptions/buildOptions';
import tiltakFormaalOptions from './tiltakFormaalOptions';

export default {
  build: buildOptions,
  garage: garageOptions,
  change: changeOptions,
  create: createOptions,
  demolish: demolishOptions,
  tiltakFormaal: tiltakFormaalOptions,
  roomEntranceOptions: roomEntranceOptions,
};
