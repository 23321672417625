import {AxiosError} from 'axios';
export default (error: AxiosError) => {
  let errorRes;
  if (!error) {
    return '';
  }
  if (error.response) {
    if (error.response.data) {
      errorRes = error.response.data;
    }
    errorRes = error.response;
  } else if (error.message) {
    errorRes = error.message;
  } else {
    errorRes = error;
  }
  return JSON.stringify(errorRes);
};
