import React from 'react';
import './Chat.scss';

const Chat = () => {
  React.useEffect(() => {
    if (window.location.search !== '?avoidcookieconsent=true') {
      loadChat();
      setTimeout(setNorwegianLanguageInChat, 5000); // wait 5 sec
    }
  }, []);
  const setNorwegianLanguageInChat = () => {
    try {
      (window as any)?.zE('webWidget', 'setLocale', 'no');
    } catch {
      // do nothing
    }
  };

  const loadChat = () => {
    /* const Tawk_API = {};//Tawk_API || {};
            const Tawk_LoadStart = new Date(); */
    var s1 = document.createElement('script');
    var s0 = document.getElementsByTagName('script')[0]!;
    s1.id = 'ze-snippet';
    s1.type = 'text/javascript';
    s1.async = true;
    s1.src = 'https://static.zdassets.com/ekr/snippet.js?key=53d1a297-916b-4c35-819a-e1f1b793bad2';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode!.insertBefore(s1, s0);
  };

  return (
    <div className='chat-div'>
      {/* <button onClick={() => (window as any)?.CookieConsent.renew()}>Trenger du hjelp?</button> */}
    </div>
  );
};

export default Chat;
