import {ApplicationRoutes} from '../../utils/routes/ApplicationRoutes';
import {InnsendingType} from '../../services/ebyggesokServices/innsendingService';
import {
  getSelectedMangelbrevTypes,
  supportedMangelCategories,
} from '../../services/QuestionData/mangelbrev/getMangelbrevTypesData';
import {SupportedMangelbrevTypes} from '../../services/QuestionData/mangelbrev/mangelbrevTypes';
import {AnswerValues} from '../../state/questionTypes';
import getSelectedTiltakstyper from '../../utils/tiltak/getSelectedTiltakstyper';
import {tiltaktypeUaId} from '../../utils/tiltak/tiltaktypeIds';
import {innerStep, mainStep, stepConfig} from './stepConfig';
import {addInnerStep, removeInnerStep} from './steps';
//Select what steps to be displayed for each tiltakstype

export type StepType = InnsendingType;

export const getMainSteps = (
  answers: AnswerValues,
  stepType: StepType = 'byggesoknad',
  isStructuredMangel?: boolean
): mainStep[] | undefined => {
  if (stepType === 'mangel') {
    const mangelBrevTypes = getSelectedMangelbrevTypes(answers);
    return stepsForMangelbrev(mangelBrevTypes, !!isStructuredMangel);
  }
  const selectedTiltakstyper = getSelectedTiltakstyper(answers);
  if (selectedTiltakstyper.length !== 1) {
    return []; //We do not support combination of several tiltakstyper yet
  }
  if (stepType === 'ferdigattest') {
    //Return ferdigattest stepper
    return stepsForFerdigattest();
  }
  return stepsForTiltak[selectedTiltakstyper[0]];
};

export const stepsForTiltak: {[key in tiltaktypeUaId]: mainStep[]} = {
  bruksendringhoveddel: bruksendringSteps(),
  tilbyggunder50m2: tilbyggSteps(),
  driftsbygningtilbyggunder1000m2: tilbyggSteps(),
  nyttbyggdriftsbygningunder1000m2: buildingSteps(),
  nyttbyggunder70m2: buildingSteps(),
  rivingbygningunder70m2: demolishSteps(),
  rivingdriftsbygninginntill1000m2: demolishSteps(),
  rivingtilbygginntil50m2: demolishSteps(),
  /* Not supported yet: */
  driftsbygningendringunder1000m2: defaultDummySteps(),
  skiltreklamefrittstaendeliten: defaultDummySteps(),
  skiltreklamefasadeliten: defaultDummySteps(),
  plasseringmidlertidig: defaultDummySteps(),
  grunneiendom: defaultDummySteps(),
  anleggseiendom: defaultDummySteps(),
  festegrunn: defaultDummySteps(),
  arealoverføring: defaultDummySteps(),
  annet: defaultDummySteps(),
};

const stepsForFerdigattest = () => {
  return [
    stepConfig.what_to_do_summary,
    stepConfig.FERDIGATTEST,
    stepConfig.FERDIGATTEST_SUMMARY,
    stepConfig.FERDIGATTEST_SUBMITTED,
  ];
};
const stepsForMangelbrev = (selectedMangelTypes: SupportedMangelbrevTypes[], isStructuredMangel: boolean) => {
  let mangelSteps = [
    stepConfig.what_to_do_summary,
    stepConfig.mangel_utfylling_dynamiske_indresteg,
    stepConfig.MANGELBREV_SUMMARY,
  ];
  const parrentMainStepId = 'mangel_utfylling_dynamiske_indresteg';
  // Remove saksnummerstep for structured mangelbrevs.
  if (isStructuredMangel) {
    mangelSteps = removeInnerStep(
      [...mangelSteps],
      parrentMainStepId,
      'MANGELBREV_SAKSNUMMER',
      ApplicationRoutes.MANGELBREV_SAKSNUMMER.path
    );
  }
  // Add a innerStep for each mangeltype:
  selectedMangelTypes.forEach((mangel) => {
    const category = getMangelCategory(mangel)!;
    const newInnerStep: innerStep = {id: 'MANGEL_DYNAMISK', text: category?.name};
    const innerStepPath = ApplicationRoutes.dynamic_mangel.path + '/' + category.path;
    mangelSteps = addInnerStep([...mangelSteps], parrentMainStepId, newInnerStep, innerStepPath);
  });
  return mangelSteps;
};

const getMangelCategory = (mangel: SupportedMangelbrevTypes) => {
  return supportedMangelCategories.find((cat) => cat.id === mangel);
};

function defaultDummySteps(): mainStep[] {
  return [stepConfig.what_to_do_summary];
}

function bruksendringSteps(): mainStep[] {
  return [
    stepConfig.what_to_do_summary,
    stepConfig.REGULATION,
    stepConfig.PREPARE_PLACEMENT,
    stepConfig.PLACE_BRUKSENDRING,
    stepConfig.TEKNISKE_KRAV,
    stepConfig.DISPENSASJON,
    stepConfig.nabovarsel,
    stepConfig.TILTAKSHAVER,
    stepConfig.submit_application,
    stepConfig.applicationSubmitted,
  ];
}

function tilbyggSteps(): mainStep[] {
  return [
    stepConfig.what_to_do_summary,
    stepConfig.kommunens_regelverk,
    stepConfig.PLASSERING,
    stepConfig.DISPENSASJON,
    stepConfig.nabovarsel,
    stepConfig.TILTAKSHAVER,
    stepConfig.submit_application,
    stepConfig.applicationSubmitted,
  ];
}
function buildingSteps(): mainStep[] {
  return [
    stepConfig.what_to_do_summary,
    stepConfig.kommunens_regelverk,
    stepConfig.PLASSERING,
    stepConfig.DISPENSASJON,
    stepConfig.nabovarsel,
    stepConfig.TILTAKSHAVER,
    stepConfig.submit_application,
    stepConfig.applicationSubmitted,
  ];
}

function demolishSteps(): mainStep[] {
  return [
    stepConfig.what_to_do_summary,
    stepConfig.REGULATION,
    stepConfig.AVSTAND_BEREGNING,
    stepConfig.DISPENSASJON,
    stepConfig.nabovarsel,
    stepConfig.TILTAKSHAVER,
    stepConfig.submit_application,
    stepConfig.applicationSubmitted,
  ];
}
