import {MatrikkelnummerObject} from '../matrikkelgrunnbok';

export function parseEiendomsident(ident): MatrikkelnummerObject {
  var fnr = parseInt(ident.substring(13, 17), 10);
  var snr = parseInt(ident.substring(17), 10);
  return {
    Kommunenummer: ident.substring(0, 4),
    Gardsnummer: parseInt(ident.substring(4, 9), 10),
    Bruksnummer: parseInt(ident.substring(9, 13), 10),
    Festenummer: fnr !== 0 ? fnr : 0,
    Seksjonsnummer: snr !== 0 ? snr : 0,
  } as MatrikkelnummerObject;
}

export default (propertyObj: MatrikkelnummerObject, skipKnr?: boolean): string => {
  const knr =
    propertyObj.Kommunenummer.toString().length === 4 ? propertyObj.Kommunenummer : '0' + propertyObj.Kommunenummer;
  if (propertyObj.Seksjonsnummer) {
    return `${!skipKnr ? `${knr} - ` : ''}${propertyObj.Gardsnummer}/${propertyObj.Bruksnummer}/${
      propertyObj.Festenummer
    }/${propertyObj.Seksjonsnummer}`;
  } else if (propertyObj.Festenummer) {
    return `${!skipKnr ? `${knr} - ` : ''}${propertyObj.Gardsnummer}/${propertyObj.Bruksnummer}/${
      propertyObj.Festenummer
    }`;
  }
  return `${!skipKnr ? `${knr} - ` : ''}${propertyObj.Gardsnummer}/${propertyObj.Bruksnummer}`;
};
