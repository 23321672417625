type stores =
  | 'applicationStore'
  | 'userStore'
  | 'statusAlertStore'
  | 'municipalityStore'
  | 'updatedPropertyIdApplications';

const storeKeys: {[key in stores]: string} = {
  applicationStore: 'nk_ebyggesok_application_state',
  userStore: 'nk_ebyggesok_user_state',
  statusAlertStore: 'nk_ebyggesok_status_alert_state',
  municipalityStore: 'nk_ebyggesok_municipality_state',
  updatedPropertyIdApplications: 'nk_ebyggesok_updated_propertyId_state',
};

export const saveStore = (state, store: stores) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(storeKeys[store], serializedState);
  } catch (err) {
    // ignore
  }
};

export const loadStore = (store: stores) => {
  try {
    const serializedState = sessionStorage.getItem(storeKeys[store]);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
