import {useHistory} from 'react-router-dom';
import {appInsightsInstance} from '../../AppInsightsSetup';
import NkButton, {Buttontypes} from '../../components/Button/NkButton';
import {ApplicationRoutes} from '../../utils/routes/ApplicationRoutes';

type Props = {
  text?: string;
  buttonVersion: 'top' | 'middle' | 'bottom';
  type?: Buttontypes;
  style?: React.CSSProperties;
};
const StartNewApplicationButton = ({text, buttonVersion, type, style}: Props) => {
  const history = useHistory();
  const start = () => {
    history.push(ApplicationRoutes.kan_jeg_soke.path);
    appInsightsInstance?.trackEvent({name: 'kom-i-gang'}, {buttonVersion, text});
  };

  return (
    <NkButton onClick={start} type={type || 'callToAction'} style={style}>
      {text || 'Kom i gang med søknaden'}
    </NkButton>
  );
};
export default StartNewApplicationButton;
