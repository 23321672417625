import {FeatureCollection} from '@turf/helpers';

export type DatavarehusDatasetName =
  | 'coastline'
  | 'railway'
  | 'hoyspent_powerline'
  | 'kulturminner'
  | 'flom'
  | 'skred'
  | 'truede_vernede_arter'
  | 'grunnforurensning'
  | 'friluftsområder';

export type DatavarehusDataset = {name: DatavarehusDatasetName; dataset: FeatureCollection};

export type DatavarehusDatasetsState = {
  datasets: DatavarehusDataset[];
  hasFetchedDatasets: boolean;
  isFetchingDatasets: boolean;
  fetchingPropertyDataPending: boolean;
  errorFetchingDatasets: boolean;
};

export const initialState: DatavarehusDatasetsState = {
  datasets: [],
  hasFetchedDatasets: false,
  isFetchingDatasets: false,
  fetchingPropertyDataPending: false,
  errorFetchingDatasets: false,
};

export type DatavarehusDatasetsAction =
  | {
      type: 'setDataset';
      value: DatavarehusDataset;
    }
  | {
      type: 'isFetchingDatasets';
    }
  | {
      type: 'hasFetchedDatasets';
    }
  | {
      type: 'errorFetchingDatasets';
    }
  | {
      type: 'resetErrorFetchingDatasets';
    }
  | {
      type: 'fetchingPropertyDataPending';
      value: boolean;
    }
  | {
      type: 'resetDatasets';
    };

export const reducer = (
  state: DatavarehusDatasetsState,
  action: DatavarehusDatasetsAction
): DatavarehusDatasetsState => {
  switch (action.type) {
    case 'setDataset':
      return {
        ...state,
        datasets: [...state.datasets.filter((dataset) => dataset.name !== action.value.name), action.value],
      };
    case 'isFetchingDatasets':
      return {...state, isFetchingDatasets: true, hasFetchedDatasets: false, errorFetchingDatasets: true};
    case 'hasFetchedDatasets':
      return {...state, isFetchingDatasets: false, hasFetchedDatasets: true};
    case 'errorFetchingDatasets':
      return {...state, isFetchingDatasets: false, hasFetchedDatasets: false, errorFetchingDatasets: true};
    case 'resetErrorFetchingDatasets':
      return {...state, errorFetchingDatasets: false};
    case 'fetchingPropertyDataPending':
      return {...state, fetchingPropertyDataPending: action.value};
    case 'resetDatasets':
      return initialState;
    default:
      return state;
  }
};
