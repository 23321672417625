import {tiltakTypeDescription} from '../../../../utils/tiltak/tiltakstypeDescriptions';
import {TiltaksTypeOptions} from '../optionTypes';
import {allTiltakstypeId} from '../../../../utils/tiltak/tiltaktypeIds';
import {TiltakQuestionOption} from '../../../../services/utils/QuestionTypes';
// RIVE TILTAKSTYPER

//LEGG TIL for hver hovedkategori

const allDemolishOptions: {[key in allTiltakstypeId]?: TiltakQuestionOption} = {
  rivingbygningunder70m2: {
    value: 'rivingbygningunder70m2',
    text: tiltakTypeDescription.rivingbygningunder70m2.displayToUser,
    helpText: 'Kan være en garasje eller annet frittliggende bygg (eks. bod/drivhus)',
    imageUrl:
      'https://res.cloudinary.com/dgbxah2xy/image/upload/v1606483821/eBygges%C3%B8k%20UA/frittliggende_50-70.svg',
  },
  rivingdriftsbygninginntill1000m2: {
    value: 'rivingdriftsbygninginntill1000m2',
    text: tiltakTypeDescription.rivingdriftsbygninginntill1000m2.displayToUser,
    helpText: 'Kan være driftsbygning for f.eks  jordbruk, husdyrbruk, seterdrift, hagebruk, gartneri, skogsdrift',
    //imageUrl: '/images/cardIcons/driftsbygning.svg',
  },
  rivingtilbygginntil50m2: {
    value: 'rivingtilbygginntil50m2',
    text: tiltakTypeDescription.rivingtilbygginntil50m2.displayToUser,
    imageUrl: 'https://res.cloudinary.com/dgbxah2xy/image/upload/v1606484239/eBygges%C3%B8k%20UA/tilbygg_50.svg',
  },
  //Not legal types
  rivingbygningunder50m2: {
    value: 'rivingbygningunder50m2',
    text: 'Frittliggende bygg under 50 m²',
    helpText: 'Kan være en garasje eller annet frittliggende bygg (eks. bod/drivhus)',
    imageUrl: 'https://res.cloudinary.com/dgbxah2xy/image/upload/v1606484017/eBygges%C3%B8k%20UA/firttliggende_50.svg',
  },
  rivingbygningover70m2: {
    value: 'rivingbygningover70m2',
    text: 'Frittliggende bygg over 70 m²',
    helpText: 'Kan være en garasje eller annet frittliggende bygg (eks. bod/drivhus)',
    imageUrl:
      'https://res.cloudinary.com/dgbxah2xy/image/upload/v1606483821/eBygges%C3%B8k%20UA/frittliggende_over70.svg',
  },
  rivingdriftsbygningOver1000m2: {
    value: 'rivingdriftsbygningOver1000m2',
    text: 'Driftsbygning i landbruket over 1000 m² (BRA)',
    helpText: 'Kan være driftsbygning for f.eks  jordbruk, husdyrbruk, seterdrift, hagebruk, gartneri, skogsdrift',
  },
  rivingtilbyggOver50: {
    value: 'rivingtilbyggOver50',
    text: 'Areal over 50 m²',
    imageUrl: 'https://res.cloudinary.com/dgbxah2xy/image/upload/v1606484239/eBygges%C3%B8k%20UA/tilbygg_over50.svg',
  },
  rivetilbyggunder15kvm: {
    value: 'rivetilbyggunder15kvm',
    text: 'Areal under 15 m²',
    imageUrl: 'https://res.cloudinary.com/dgbxah2xy/image/upload/v1606484238/eBygges%C3%B8k%20UA/tilbygg_15.svg',
  },
  annet: {
    value: 'riveAnnet',
    text: 'Annet',
  },
};

const garageOptions = [
  allDemolishOptions.rivingbygningover70m2,
  allDemolishOptions.rivingbygningunder70m2,
  allDemolishOptions.rivingbygningunder50m2,
  allDemolishOptions.rivetilbyggunder15kvm,
  allDemolishOptions.rivingtilbygginntil50m2,
  allDemolishOptions.rivingtilbyggOver50,
];

const boligOptions = [
  allDemolishOptions.rivingbygningover70m2,
  allDemolishOptions.rivingbygningunder70m2,
  allDemolishOptions.rivingbygningunder50m2,
  allDemolishOptions.rivetilbyggunder15kvm,
  allDemolishOptions.rivingtilbygginntil50m2,
  allDemolishOptions.rivingtilbyggOver50,
];

const fritidsboligOptions = [
  allDemolishOptions.rivingbygningover70m2,
  allDemolishOptions.rivingbygningunder70m2,
  allDemolishOptions.rivingbygningunder50m2,
  allDemolishOptions.rivetilbyggunder15kvm,
  allDemolishOptions.rivingtilbygginntil50m2,
  allDemolishOptions.rivingtilbyggOver50,
];

const tilbyggOptions = [
  allDemolishOptions.rivetilbyggunder15kvm,
  allDemolishOptions.rivingtilbygginntil50m2,
  allDemolishOptions.rivingtilbyggOver50,
];

const frittliggendeByggOptions = [
  allDemolishOptions.rivingbygningover70m2,
  allDemolishOptions.rivingbygningunder70m2,
  allDemolishOptions.rivingbygningunder50m2,
];

const driftsbygningOptions = [
  allDemolishOptions.rivingdriftsbygninginntill1000m2,
  allDemolishOptions.rivingdriftsbygningOver1000m2,
];

const allOptions = [
  allDemolishOptions.rivingbygningover70m2,
  allDemolishOptions.rivingbygningunder70m2,
  allDemolishOptions.rivingbygningunder50m2,
  allDemolishOptions.rivetilbyggunder15kvm,
  allDemolishOptions.rivingtilbygginntil50m2,
  allDemolishOptions.rivingtilbyggOver50,
  allDemolishOptions.rivingdriftsbygninginntill1000m2,
  allDemolishOptions.rivingdriftsbygningOver1000m2,
  allDemolishOptions.annet,
];

const options: TiltaksTypeOptions = {
  questionId: 'TILTAK_TYPE_DEMOLISH',
  bolig_options: boligOptions,
  fritidsbolig_options: fritidsboligOptions,
  annet_options: allOptions,
  garasje_options: garageOptions,
  all_options: allOptions,
  tilbyggOptions: tilbyggOptions,
  frittliggendeByggOptions: frittliggendeByggOptions,
  driftsbygningOptions: driftsbygningOptions,
} as TiltaksTypeOptions;

export default options;
