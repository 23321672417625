import {dispIds, QuestionId} from '../../services/utils/questionIds';
import {AnswerValues} from '../../state/questionTypes';
import {dispType} from './dispensasjon';

export function requiresUnntakForQuestion(answers: AnswerValues, question: QuestionId): boolean {
  return getRequiredUnntakFromQuestions(answers).filter((item) => item.id === question).length > 0;
}

export function getRequiredUnntakFromQuestions(answerValues: AnswerValues) {
  let questionsWithUnntak: {
    id: dispIds;
    type: dispType;
    dispHeader?: string;
    description?: string;
  }[] = [];
  if (answerValues.TEKNISKE_KRAV_OPPFYLT_GAMMELT === false) {
    questionsWithUnntak.push({
      id: 'TEKNISKE_KRAV_OPPFYLT_GAMMELT',
      type: 'UTEK',
    });
  }
  if (answerValues.TEKNISKE_KRAV_OPPFYLT_NYTT === false) {
    questionsWithUnntak.push({
      id: 'TEKNISKE_KRAV_OPPFYLT_NYTT',
      type: 'UTEK',
    });
  }
  if (answerValues.TEKNISKE_KRAV_OPFYLT_NYTT_BYGG === false) {
    questionsWithUnntak.push({
      id: 'TEKNISKE_KRAV_OPFYLT_NYTT_BYGG',
      type: 'UTEK',
    });
  }
  return questionsWithUnntak;
}
