import {AxiosPromise} from 'axios';
import {getConfigValue} from '../config';
import {KommuneNummer, MatrikkelNummer} from '../utils/dibkSchemas/models/models';
import {getAxiosInstanceWithApiKeyHeader} from './ebyggesokServices/axiosService';

function getMatrikkelGrunnbokAxiosInstance() {
  const url = getConfigValue('matrikkelGrunnbokUrl');
  return getAxiosInstanceWithApiKeyHeader(url);
}

export interface AdresseFromServer {
  VegadresseIdent: VegadresseIdent;
  Unikid: string;
  AdresseType: string;
  Postnummer: string;
  Poststed: string;
  X: number;
  Y: number;
}

interface VegadresseIdent {
  Kommunenummer: string;
  Gatenummer: number | string;
  Gatenavn: string;
  Husnummer: number;
  Bokstav: string;
}

export function addresseFromServerToString(address: AdresseFromServer) {
  const vegIdent = address.VegadresseIdent;
  return vegIdent.Gatenavn + ' ' + vegIdent.Husnummer + (vegIdent.Bokstav || '');
}

export function addresseFromServerToLongString(address: AdresseFromServer) {
  return addresseFromServerToString(address) + ', ' + address.Postnummer + ' ' + address.Poststed;
}

// Ignores the Matrikkeladdresses, maybe consider them later
export function getStreetAddressesFromProperty(propertyId: MatrikkelNummer) {
  return getMatrikkelGrunnbokAxiosInstance().get<{Adresser: AdresseFromServer[]}>(
    `/adresser/matrikkelnummer/${propertyId}?srs=4326&type=KombinasjonViaAdresseOgBruksenhet`
  );
}

export interface MatrikkelnummerObject {
  Kommunenummer: KommuneNummer;
  Gardsnummer: number;
  Bruksnummer: number;
  Festenummer: number;
  Seksjonsnummer: number;
}

// Use this func to find UnikId (kartverksId).
export function getAddressFromAddressId(roadAddressId: string) {
  const aidPath = `/adresser/aid/${roadAddressId}?SRS=4326`;
  return getMatrikkelGrunnbokAxiosInstance().get<{Adresse: AdresseFromServer}>(aidPath);
}

export function addressFromServerToText(address: AdresseFromServer) {
  return address.VegadresseIdent.Gatenavn + ' ' + address.VegadresseIdent.Husnummer + address.VegadresseIdent.Bokstav;
}

export type KandidatResponse = {
  Kandidater: [Kandidat];
};

export type Kandidat = {
  FullAdresse: AdresseFromServer;
  Matrikkelnummer: MatrikkelnummerObject & {Komplett: MatrikkelNummer};
};

export function getKandidatFromBorettslagsId(
  borettOrgnr: string,
  andelsnummer: number
): AxiosPromise<KandidatResponse> {
  const path = 'rapporter/kandidater/borett/' + borettOrgnr + '/' + andelsnummer + '?format=json';
  return getMatrikkelGrunnbokAxiosInstance().get<KandidatResponse>(path);
}

export type InfoVegadresse = {
  X: number;
  Y: number;
  Z?: number;
  SRS: number;
  Postnummer: string;
  Poststed: string;
  LiggerPaaMatrikkelenhet?: string;
  Adressenummer: string;
  AdresseType: string;
  Kommunenummer: string;
  Gatenummer: number;
  Gatenavn: string;
  Husnummer: number;
  Bokstav: string;
  AdresseIdentType: string;
};

export type InfoMatrikkeladresse = {
  X: number;
  Y: number;
  Z?: number;
  SRS: number;
  Postnummer?: string;
  Poststed?: string;
  Matrikkelnummer?: string;
  Undernummer: number;
  AdresseIdentType?: string;
};

export type InfoForAdresseResponse = {
  InfoForVegadresse?: InfoVegadresse[];
};

// Ignores the Matrikkeladdresses, maybe consider them later...
export async function getInfoForAdresse(kartverksId: string) {
  const path = `/infoforadresseid/${kartverksId}?SRS=4326`;
  return await getMatrikkelGrunnbokAxiosInstance().get<InfoForAdresseResponse>(path);
}

type MatrikkelEnhet = {
  Matrikkelenhetid: number;
  Unikid: number;
  Utgatt: boolean;
};

export type SeksjonerForMatrikkelenhetsId = {
  AntallMatrikkelenheterSomIkkeErSeksjoner: number;
  AntallSeksjoner: number;
  MatrikkelenheterSomIkkeErSeksjoner: MatrikkelEnhet[];
  Seksjoner: MatrikkelEnhet[];
};

export const getSeksjonerForMatrikkelenhet = async (porpertyId: MatrikkelNummer) => {
  const path = `/seksjonerformatrikkelenhetsid/${porpertyId}`;
  return getMatrikkelGrunnbokAxiosInstance()
    .get<SeksjonerForMatrikkelenhetsId>(path)
    .then((res) => res.data);
};
