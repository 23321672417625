import {InfoPagesRouteKeys} from './InfoPagesRoutes';
import {UaRoute} from './routesHelpers';

export type UaRoutes = InfoPagesRouteKeys | ApplicationRouteKeys;

type ApplicationRouteKeys =
  | 'myApplications'
  | 'oldMyApplications'
  | 'buildingSite'
  | 'what_to_do_summary'
  | 'REGULATION'
  | 'UGRAD_UTNYTTING'
  | 'UGRAD_AREAL'
  | 'UGRAD_PARKERING'
  | 'PLACE_BUILDING'
  | 'PLACE_BRUKSENDRING'
  | 'chooseBuilding'
  | 'TEKNISKE_KRAV'
  | 'ANDREKRAV'
  | 'VANNAVLOP'
  | 'dispention'
  | 'ADD_NABOVARSEL_ATTACHMENTS'
  | 'TILTAKSHAVER'
  | 'CHOOSE_NEIGHBOURS'
  | 'nabovarsel_summary'
  | 'sendNabovarsel'
  | 'NABOVARSEL_SVAR'
  | 'ADD_APPLICATION_ATTACHMENTS'
  | 'soknad_summary'
  | 'submitSoknad'
  | 'applicationSubmitted'
  | 'ROAD_DISTANCE'
  | 'AVKJORSEL'
  | 'finalizePayment'
  | 'PREPARE_PLACEMENT'
  | 'SITUASJONSPLAN'
  | 'FOELGEBREV'
  | 'ugrad_summary'
  | 'idporten'
  | 'idportenFailed'
  | 'idportenConsentRequired'
  | 'kan_jeg_soke'
  | 'kan_jeg_soke_deprecated'
  | 'kan_jeg_soke_resultat'
  | 'start_soknad'
  | 'informationWrapper'
  | 'idportenLogin'
  | 'contact'
  | 'plankart'
  | 'melding_result'
  | 'ferdigattest'
  | 'ferdigattestSummary'
  | 'ferdigattestSubmitted'
  | 'ubebygdInfo'
  | 'nyhetsbrev'
  | 'MANGELBREV_TYPES'
  | 'MANGELBREV_SAKSNUMMER'
  | 'MANGELBREV_SUMMARY'
  | 'ETTERSENDING_INFO'
  | 'supplering_status'
  | 'avtaleTerms'
  | 'avtaleTerms'
  | 'dynamic_mangel'
  | 'logout'
  | 'ansvarsrett'
  | 'notfound';

export type ApplicationRoutesConfig = {
  [key in ApplicationRouteKeys]: UaRoute;
};

export const ApplicationRoutes: ApplicationRoutesConfig = {
  myApplications: {path: '/mine-soknader', hasActiveApplication: false, requiresLogin: true},
  oldMyApplications: {path: '/minesoknader', hasActiveApplication: false, requiresLogin: true},
  buildingSite: {path: '/bygningssted', hasActiveApplication: true, requiresLogin: true}, // Søk opp eiendom
  what_to_do_summary: {path: '/info', hasActiveApplication: true, requiresLogin: true}, // Oppsummering av valg på start-søknad side
  ansvarsrett: {
    path: '/ansvarsrett',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  REGULATION: {
    path: '/regulerings-sporsmal',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  UGRAD_UTNYTTING: {
    path: '/grad-av-utnytting',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  UGRAD_AREAL: {
    path: '/eksisterende-bebyggelse',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  UGRAD_PARKERING: {
    path: '/parkeringsplasser',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  PLACE_BUILDING: {
    path: '/plasser-bygning',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  PLACE_BRUKSENDRING: {
    path: '/plassering-bruksendring',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  chooseBuilding: {
    path: '/plassering-velg-bygning',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  TEKNISKE_KRAV: {
    path: '/tekniske-krav',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  ANDREKRAV: {
    path: '/andre-krav',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  VANNAVLOP: {
    path: '/vann-og-avlop',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  dispention: {
    path: '/dispensasjon-og-unntak',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  ADD_NABOVARSEL_ATTACHMENTS: {
    path: '/vedlegg',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  TILTAKSHAVER: {
    path: '/tiltakshaver',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  CHOOSE_NEIGHBOURS: {
    path: '/nabovarsel',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  nabovarsel_summary: {
    path: '/oppsummering-nabovarsel',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  sendNabovarsel: {
    path: '/send-nabovarsel',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  NABOVARSEL_SVAR: {
    path: '/status-nabovarsel',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  ADD_APPLICATION_ATTACHMENTS: {
    path: '/vedlegg-til-soknad',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  soknad_summary: {
    path: '/soknad-oppsummering',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  submitSoknad: {
    path: '/sender-soknad',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  applicationSubmitted: {
    path: '/soknad-status',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  ROAD_DISTANCE: {
    path: '/avstand-til-vei',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  AVKJORSEL: {
    path: '/avkjorsel',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  finalizePayment: {
    path: '/finalize-payment',
    hasActiveApplication: false,
    requiresLogin: true,
  },
  PREPARE_PLACEMENT: {
    path: '/forbered-plassering',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  SITUASJONSPLAN: {
    path: '/situasjonsplan',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  FOELGEBREV: {
    path: '/foelgebrev',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  ugrad_summary: {
    path: '/byggestorrelse',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  idporten: {
    path: '/redirect',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  idportenLogin: {
    path: '/request-login',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  idportenFailed: {
    path: '/redirect/error-response',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  idportenConsentRequired: {
    path: '/redirect/consent-required',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  kan_jeg_soke: {
    path: '/veileder',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  kan_jeg_soke_deprecated: {
    path: '/kom-i-gang',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  kan_jeg_soke_resultat: {
    path: '/veileder-resultat',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  start_soknad: {
    path: '/start-soknad',
    hasActiveApplication: false,
    requiresLogin: true,
  },
  informationWrapper: {
    path: '/informasjon',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  contact: {
    path: '/kontakt',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  plankart: {
    path: '/plankart',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  melding_result: {
    path: '/melding',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  ferdigattest: {
    path: '/ferdigattest',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  ferdigattestSummary: {
    path: '/ferdigattest-oppsummering',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  ferdigattestSubmitted: {
    path: '/ferdigattest-sendt',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  ubebygdInfo: {
    path: '/ubebygd-info',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  nyhetsbrev: {
    path: 'https://share.hsforms.com/1auZOr4LZTOOVP0HvkS0A2g533kr',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  MANGELBREV_TYPES: {
    path: '/supplering/typer',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  MANGELBREV_SAKSNUMMER: {
    path: '/saksnummer',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  MANGELBREV_SUMMARY: {
    path: '/supplering/oppsummering',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  ETTERSENDING_INFO: {
    path: '/ettersending/info',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  supplering_status: {
    path: '/supplering/status',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  avtaleTerms: {
    path: '/avtale-vilkar',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  dynamic_mangel: {
    path: '/mangel/utfylling',
    hasActiveApplication: true,
    requiresLogin: true,
  },
  notfound: {
    path: '/404',
    hasActiveApplication: false,
    requiresLogin: false,
  },
  logout: {
    path: '/logg-ut',
    hasActiveApplication: false,
    requiresLogin: false,
  },
};
