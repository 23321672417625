import React from 'react';
import './Step.scss';
import StepNumber from './StepNumber/StepNumber';
import StepBox from './StepBox/StepBox';
import {DesktopContainer} from '../../../../components/containers/DeviceContainers';
import {TabletAndMobileContainer} from '../../../../components/containers/DeviceContainers';

type Props = {text: string; illustration: string; stepNumber: number; onClick: (index) => void; ariaExpanded: boolean};
const Step = ({text, illustration, stepNumber, onClick, ariaExpanded}: Props) => {
  const side = stepNumber % 2 === 0 ? 'right' : 'left';

  return (
    <li className={`Step`}>
      <DesktopContainer>
        {side === 'left' ? (
          <>
            <StepBox
              small={false}
              text={text}
              illustration={illustration}
              side={side}
              onClick={onClick}
              stepNumber={stepNumber}
              ariaExpanded={ariaExpanded}
            />
            <StepNumber stepNumber={stepNumber} />
            <div className='stepBoxSpace' />
          </>
        ) : (
          <>
            <div className='stepBoxSpace' />
            <StepNumber stepNumber={stepNumber} />
            <StepBox
              small={false}
              text={text}
              illustration={illustration}
              side={side}
              onClick={onClick}
              stepNumber={stepNumber}
              ariaExpanded={ariaExpanded}
            />
          </>
        )}
      </DesktopContainer>
      <TabletAndMobileContainer>
        <>
          <StepBox
            small={true}
            text={text}
            illustration={illustration}
            side={'right'}
            onClick={onClick}
            stepNumber={stepNumber}
            ariaExpanded={ariaExpanded}
          />
          <StepNumber stepNumber={stepNumber} />
        </>
      </TabletAndMobileContainer>
    </li>
  );
};
export default Step;
