import {SeverityLevel} from '@microsoft/applicationinsights-web';
import turfBbox from '@turf/bbox';
import turfBuffer from '@turf/buffer';
import {Polygon} from '@turf/helpers';
import {FeatureCollection, MultiPolygon} from 'geojson';
import {appInsightsInstance} from '../AppInsightsSetup';
import {getConfigValue} from '../config';
import {Dispatch} from '../state/applicationStore';
import {DatavarehusDatasetName} from '../state/datavarehusDatasetsState';
import {UserDispatch} from '../state/userStore';
import getErrorMsg from '../utils/getErrorMsg';
import {getAxiosInstanceWithIdportenHeader} from './ebyggesokServices/axiosService';
import {ApplicationId} from './ebyggesokServices/userOrderService';

function getDatavarehusAxiosInstance() {
  var url = getConfigValue('datavarehusUrl');
  return getAxiosInstanceWithIdportenHeader(url);
}

type dvEndpoints = 'view';
export const datasetMapping = (
  dataset: DatavarehusDatasetName
): {id: string | number; query?: string; endpoint: dvEndpoints}[] => {
  switch (dataset) {
    case 'coastline':
      return [{id: 'sv_norkart_14_100metersbelte', endpoint: 'view'}];
    case 'railway':
      return [{id: 'v_kommunene_104_spormidt', endpoint: 'view'}];
    case 'hoyspent_powerline':
      return [
        {
          id: 'sv_nve_205_distribusjonsnett',
          endpoint: 'view',
        },
        {
          id: 'sv_nve_205_regionalnett',
          endpoint: 'view',
        },
        {
          id: 'sv_nve_205_sentralnett',
          endpoint: 'view',
        },
      ];
    case 'grunnforurensning':
      return [{id: 'sv_miljodir_32_forurensetgrunn', endpoint: 'view'}];
    case 'kulturminner':
      return [
        {id: 'sv_ra_256_enkeltminne', endpoint: 'view'},
        {id: 'sv_ra_49_tettetrehusmiljoe', endpoint: 'view'},
        {id: 'sv_ra_50_brannsmitteomraade', endpoint: 'view'},
        {id: 'sv_ra_51_kulturmiljoe', endpoint: 'view'},
        {id: 'sv_ra_256_sikringssone', endpoint: 'view'},
        {id: 'sv_ra_256_lokalitet', endpoint: 'view'},
        {id: 'sv_ra_69_fredabygg', endpoint: 'view'},
        {id: 'sv_ra_73_sefrakbygninger', endpoint: 'view'},
      ];
    /* case 'kulturminner-sefrak': //Id 73 = sefrak registrert. Be bruker kontakte kommunen
      return [{id: 73}]; */
    case 'flom': //Finnes også aktsomhetsområde for flom
      return [
        {id: 'sv_nve_4_flomsone', endpoint: 'view'},
        {id: 'sv_nve_93_aktsomhetsomraade', endpoint: 'view'},
        {id: 'sv_nve_242_naa_20aar', endpoint: 'view'},
        {id: 'sv_nve_242_2100_200aar', endpoint: 'view'},
      ];
    /*  case 'flom-aktsomhetsområde': //Mest sansynnlig ok, men spør kommunen om ok? Tror vi dropper denne, men la oss tenke på det..
      return [{id: 93}];
    case 'flom-stormflo': //Be de kontakte kommunen? Må skille på 10-års flom og 1000-års flom, la kommunen vurdere det
      return [{id: 242}]; */
    case 'skred': //65(skred-ras) Sjekk også 6 (jord og flomskred), 10 (snøskred), 9 (kvikkleire), 8(steinsprang), 11 (finmaska snø og steinskred)
      return [
        {id: 'sv_nve_6_potensieltsskredfareomraade', endpoint: 'view'},
        {id: 'sv_nve_8_utloepsomraade', endpoint: 'view'},
        {id: 'sv_nve_8_utloesningsomraade', endpoint: 'view'},
        {id: 'sv_nve_9_kvikkleiresone', endpoint: 'view'},
        {id: 'sv_nve_10_utloepsomraade', endpoint: 'view'},
        {id: 'sv_nve_10_utloesningsomraade', endpoint: 'view'},
        {id: 'sv_nve_11_potensieltskredfareomraade', endpoint: 'view'},
        {id: 'sv_nve_65_skredfaresone', endpoint: 'view'},
      ];
    case 'truede_vernede_arter': //37 (naturvernområder), kan kalles vernede områder
      return [
        {id: 'sv_miljodir_37_naturvernomraade', endpoint: 'view'}, //Trenger vi hele området?
        {id: 'sv_miljodir_37_naturvernpunkt', endpoint: 'view'},
        {id: 'sv_miljodir_39_naturtype', endpoint: 'view'},
        {id: 'sv_miljodir_40_fremmedearteromraade', endpoint: 'view'},
        {id: 'sv_miljodir_40_fremmedearterpunkt', endpoint: 'view'},
        {id: 'sv_miljodir_40_saerligstorforvaltningsinteresseomraade', endpoint: 'view'},
        {id: 'sv_miljodir_40_saerligstorforvaltningsinteressepunkt', endpoint: 'view'},
        {id: 'sv_miljodir_40_storforvaltningsinteresseomraade', endpoint: 'view'},
        {id: 'sv_miljodir_40_storforvaltningsinteressepunkt', endpoint: 'view'},
        {id: 'sv_miljodir_5_utvalgtekulturlandskap', endpoint: 'view'},
        {id: 'sv_miljodir_38_kulturlandskapomraade', endpoint: 'view'},
      ];
    /* case 'truede_vernede_arter': // 39 (viktige naturtyper på land)
      return [{id: 39}]; */
    /* case 'kulturlandskap': //5 (utvalgte kulturlandskap), 38 (verdifulle kulturlandskap)
      return [{id: 5}, {id: 38}]; */
    case 'friluftsområder': //33 (statlige sikra friluftsområder)
      return [{id: 'sv_miljodir_33_sikrafriluftslivsomraade', endpoint: 'view'}];
  }
};

export const bufferzoneForDatasets = 50; //meter, random number larger than max buffer check

const getBufferedBbox = (geometry: Polygon) => {
  const expandedArea = turfBuffer(geometry, bufferzoneForDatasets, {units: 'meters'});
  return turfBbox(expandedArea);
};

export type DatavarehusDatasets = {[key: number]: FeatureCollection}; //{features: GeoJSON.Feature[]; type: string};

export const fetchDatavarehusDatasetsForBufferedProperty = async (
  datasetNames: DatavarehusDatasetName[],
  teigGeometry: Polygon,
  applicationId: ApplicationId,
  userDispatch: UserDispatch
): Promise<DatavarehusDatasets> => {
  const allDatasets = getDatasets(datasetNames);
  const datasetsWithViewEndpoint = allDatasets.filter((d) => d.endpoint === 'view');

  var pathViewEndpoint = '/v2/views/features/bboxquery';

  const responseForViewEndpoint = await performDatavarehusRequest(
    pathViewEndpoint,
    getBufferedBbox(teigGeometry).toString(),
    undefined,
    datasetsWithViewEndpoint,
    undefined,
    applicationId,
    userDispatch
  );
  return {...responseForViewEndpoint};
};

export const fetchDatavarehusDatasetsForProperty = async (
  datasetNames: DatavarehusDatasetName[],
  propertyGeometry: Polygon,
  applicationId: ApplicationId,
  userDispatch: UserDispatch
) => {
  const allDatasets = getDatasets(datasetNames);

  const datasetsWithViewEndpoint = allDatasets.filter((d) => d.endpoint === 'view');

  var pathViewEndpoint = '/v2/views/features/intersectionquery';

  const responseForViewEndpoint = await performDatavarehusRequest(
    pathViewEndpoint,
    undefined,
    propertyGeometry,
    datasetsWithViewEndpoint,
    undefined,
    applicationId,
    userDispatch
  );

  return {...responseForViewEndpoint};
};

//Gjør denne til en generell som henter for begge, sett de feltene i body som er relevant basert på input (eks teigId)
const performDatavarehusRequest = (
  path: string,
  geom: string | undefined,
  propertyGeometry: undefined | Polygon,
  allDatasets: {
    id: string | number;
    endpoint: dvEndpoints;
  }[],
  queries: undefined | {},
  applicationId: ApplicationId,
  userDispatch: UserDispatch
) => {
  if (allDatasets.length === 0) {
    return;
  }
  const uniqueIds = Array.from(new Set(allDatasets.map((d) => d.id)));
  return getDatavarehusAxiosInstance()
    .post<DatavarehusDatasets>(path, {
      SRS: '4326',
      Bbox: geom,
      Geometry: propertyGeometry,
      Queries: queries,
      Ids: uniqueIds,
      CaseInsensitive: true,
    })
    .then<DatavarehusDatasets>(
      (res) => {
        return res.data;
      },
      (error) => {
        console.error(error);
        userDispatch({
          type: 'setSevereError',
          value: {
            msg: 'Vi har dessverre problemer med å hente informasjon om omgivelsene til din tomt.',
          },
        });
        appInsightsInstance?.trackException(
          {
            error: new Error('getDatavarehusDatasetFailed'),
            severityLevel: SeverityLevel.Error,
          },
          {error: getErrorMsg(error), soknadId: applicationId, Queries: queries, Ids: uniqueIds}
        );
        return error;
      }
    );
};

const getDatasets = (datasetNames: DatavarehusDatasetName[]) => {
  const datasets: {id: string | number; endpoint: dvEndpoints; query?: {}}[] = [];
  datasetNames.forEach((datasetName) => {
    const datasetGroup = datasetMapping(datasetName);
    datasets.push(...datasetGroup);
  });
  return datasets;
};

const BygningViewId = 'v_kommunene_104_bygning';
const AnnenBygningViewId = 'v_kommunene_104_annenbygning';
const PblTiltakViewId = 'v_kommunene_104_BygningTiltak';
type IntersectionqueryResponse = {
  data: {
    [BygningViewId]: FeatureCollection;
    [AnnenBygningViewId]: FeatureCollection;
    [PblTiltakViewId]: FeatureCollection;
  };
};
const buildingViewIds = [BygningViewId, AnnenBygningViewId, PblTiltakViewId];
const handleintersectionqueryViewResp = (resp: IntersectionqueryResponse): FeatureCollection => {
  const features = [
    ...resp[BygningViewId].features,
    ...resp[AnnenBygningViewId].features,
    ...resp[PblTiltakViewId].features,
  ];
  return {type: 'FeatureCollection', features: features};
};

export const fetchBuildingsInGeometry = (
  geometry: Polygon | MultiPolygon,
  applicationId: ApplicationId | undefined,
  dispatch: Dispatch,
  userDispatch: UserDispatch
) => {
  //Fetch annen bygning, bygning, pbl-tiltak
  const datasetsIds = buildingViewIds;
  var path = '/v2/views/features/intersectionquery';

  return getDatavarehusAxiosInstance()
    .post<IntersectionqueryResponse>(path, {
      SRS: '4326',
      Geometry: geometry,
      Ids: datasetsIds,
    })
    .then<FeatureCollection>(
      (res) => {
        return handleintersectionqueryViewResp(res.data);
      },
      (error) => {
        console.error(error);
        dispatch({type: 'errorFetchingDatasets'});
        userDispatch({
          type: 'setSevereError',
          value: {
            msg: 'Vi har dessverre problemer med å hente informasjon om bygninger på din eiendom.',
          },
        });
        appInsightsInstance?.trackException(
          {
            error: new Error('datavarehusDatasetFailed'),
            severityLevel: SeverityLevel.Error,
          },
          {
            error: JSON.stringify(getErrorMsg(error)),
            soknadId: applicationId,
            Ids: datasetsIds,
          }
        );
        return error;
      }
    );
};
