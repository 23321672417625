// uten ansvarsrett tiltakstyper geonorge: https://register.geonorge.no/byggesoknad/tiltakstyperutenansvarsrett
// modell felter med beskrivelse http://dibk.metakat.no/Objekttype/Index/EAID_E6A0A25F_E9DA_42f3_946F_2CDF0B7D7483

export type tiltaktypeUaId =
  | 'annet'
  | 'bruksendringhoveddel'
  | 'tilbyggunder50m2'
  | 'driftsbygningendringunder1000m2'
  | 'nyttbyggdriftsbygningunder1000m2'
  | 'nyttbyggunder70m2'
  | 'anleggseiendom'
  | 'arealoverføring'
  | 'festegrunn'
  | 'grunneiendom'
  | 'plasseringmidlertidig'
  | 'rivingbygningunder70m2'
  | 'rivingdriftsbygninginntill1000m2'
  | 'rivingtilbygginntil50m2'
  | 'skiltreklamefrittstaendeliten'
  | 'skiltreklamefasadeliten'
  | 'driftsbygningtilbyggunder1000m2';

export type allTiltakstypeId = tiltaktypeUaId | meldingTiltakType | maTiltakType | andreTiltakstyper;

export type meldingTiltakType =
  | 'tilbyggUnder15m2' //Dependent on distance to neighbours border
  | 'nyttbyggunder50m2'
  | 'hoveddeltilhoveddel'
  | 'rivingbygningunder50m2'
  | 'rivetilbyggunder15kvm'; //dependent on other questions, can be soknadspliktig

export type maTiltakType =
  | 'nyttbyggover70m2'
  | 'tilbyggOver50m2'
  | 'nyttbyggdriftsbygningover1000m2'
  | 'driftsbygningtilbyggOver1000m2'
  | 'driftsbygningendringover1000m2'
  | 'selvstendigBoenhet'
  | 'boligTilNæring'
  | 'kontorTilLeilighet'
  | 'rivingbygningover70m2'
  | 'rivingdriftsbygningOver1000m2'
  | 'rivingtilbyggOver50';

//Usikker hvordan type søknader dette blir
export type andreTiltakstyper = 'arealoverforing';

export const riveTiltakstyper: tiltaktypeUaId[] = [
  'rivingbygningunder70m2',
  'rivingdriftsbygninginntill1000m2',
  'rivingtilbygginntil50m2',
];

export const tilbyggTiltakstyper: tiltaktypeUaId[] = ['driftsbygningtilbyggunder1000m2', 'tilbyggunder50m2'];

export const frittligendeTiltakstyper: tiltaktypeUaId[] = ['nyttbyggdriftsbygningunder1000m2', 'nyttbyggunder70m2'];
