import {AnswerValues} from '../../state/questionTypes';
import {tiltaktypeUaId} from './tiltaktypeIds';

const getSelectedTiltakstyper = (answers: AnswerValues): tiltaktypeUaId[] => {
  let tiltakstyper: tiltaktypeUaId[] = [];
  if (answers.TILTAK_TYPE_BUILD) {
    tiltakstyper = tiltakstyper.concat(answers.TILTAK_TYPE_BUILD as string[] as tiltaktypeUaId[]);
  }
  if (answers.TILTAK_TYPE_CHANGE) {
    tiltakstyper = tiltakstyper.concat(answers.TILTAK_TYPE_CHANGE as string[] as tiltaktypeUaId[]);
  }
  if (answers.TILTAK_TYPE_CREATE) {
    tiltakstyper = tiltakstyper.concat(answers.TILTAK_TYPE_CREATE as string[] as tiltaktypeUaId[]);
  }
  if (answers.TILTAK_TYPE_DEMOLISH) {
    tiltakstyper = tiltakstyper.concat(answers.TILTAK_TYPE_DEMOLISH as string[] as tiltaktypeUaId[]);
  }
  return tiltakstyper;
};

export default getSelectedTiltakstyper;
