import {createTheme} from '@material-ui/core/styles';
import {nkColors} from '../colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: nkColors.norkart_sort,
    },
    secondary: {
      main: nkColors.uu_norkart_gronn,
    },
    error: {
      main: nkColors.norkart_orange,
    },
  },
  typography: {
    fontSize: 16,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '0',
        radius: 'none',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: '0',
      },
    },
  },
});
