import {DispensasjonServer, Kodetype, TiltakBruk, Vedlegg} from './nabovarsel';

// @Route("/ForberedSoknadV3", "GET,POST")
export interface TiltakUAnsvarSchemaV3 {
  KundeId: string;
  Hovedeiendom: string;
  Adresse?: Gateadresse;
  BeskrivelseAvTiltak: {bruk: TiltakBruk; type: {type: Kodetype}; foelgebrev: string};
  Bygningsnummer?: string;
  Tiltakshaver: PartType;
  Dispensasjoner: DispensasjonServer[];
  Varsling?: Varsling | undefined;
  ProsjektNavn?: string;
  SoekesOmDispensasjon: boolean;
  Rammebetingelser: RammebetingelserV3;
  Kommunenavn: string;
  Vedlegg: Vedlegg[];
  Lagre: boolean;
  LagreKladd?: boolean;
  SoknadXmlId?: string;
}

interface Adresse {
  postCode?: string;
  postPlace?: string;
}

export interface Gateadresse extends Adresse {
  text?: string;
}

export interface KodeType {
  kodeverdi?: string;
  kodebeskrivelse?: string;
}

interface PartType {
  partstype?: KodeType;
  foedselsnummer?: string;
  organisasjonsnummer?: string;
  navn?: string;
  adresse?: EnkelAdresseType;
  telefonnummer?: string;
  mobilnummer?: string;
  epost?: string;
}

interface EnkelAdresseType {
  adresselinje1?: string;
  adresselinje2?: string;
  adresselinje3?: string;
  postnr?: string;
  poststed?: string;
  landkode?: string;
}

// interface DispensasjonType {
//   dispensasjonstype?: KodeType;
//   begrunnelse?: string;
//   beskrivelse?: string;
// }

export interface Varsling {
  foreliggerMerknader?: boolean;
  antallMerknader?: number;
  fritattFraNabovarsling?: boolean;
  vurderingAvMerknader?: string;
  soeknadensHjemmeside?: string;
  soeknadSeesKontaktperson?: string;
}

export type TypeEnaboVedlegg =
  | 'Annet'
  | 'Folgebrev'
  | 'RedegjoerelseEstetikk'
  | 'Situasjonsplan'
  | 'TegningNyFasade'
  | 'TegningNyPlan'
  | 'TegningNyttSnitt';

export type TypeSøknadVedlegg =  //Typer hentet herfra https://fellesbygg.dibk.no/Metadata?ServiceCode=4373&ServiceEdition=2
  | 'Annet'
  | 'AvkjoeringstillatelseVegmyndighet'
  | 'Avkjoerselsplan'
  | 'AvklaringVA'
  | 'AvklaringHoyspent'
  | 'Folgebrev'
  | 'KommentarNabomerknader'
  | 'KvitteringNabovarsel'
  // | 'Nabovarsel' // bruker underskjema "GjenpartNabovarsel istedenfor"
  | 'Nabomerknader'
  | 'RedegjoerelseAndreNaturMiljoeforhold'
  | 'RedegjoerelseEstetikk'
  | 'RedegjoerelseSkredOgFlom'
  | 'Situasjonsplan'
  | 'SamtykkePlassering'
  // | 'Utomhusplan' //Maybe needed later
  | 'TegningEksisterendeFasade'
  | 'TegningEksisterendePlan'
  | 'TegningEksisterendeSnitt'
  | 'TegningNyFasade'
  | 'TegningNyPlan'
  | 'TegningNyttSnitt'
  | 'UttalelseVedtakAnnenOffentligMyndighet';

export type TypeSuppleringVedlegg = TypeSøknadVedlegg;

export const typeSoknadVedleggOptions: {key: TypeSøknadVedlegg; value: string}[] = [
  {key: 'Annet', value: 'Annet'},
  {key: 'AvkjoeringstillatelseVegmyndighet', value: 'Avkjøringstillatelse fra vegmyndigheten'},
  {key: 'Avkjoerselsplan', value: 'Avkjørselsplan'},
  {key: 'AvklaringVA', value: 'Avklaring av plassering nær VA-ledninger'},
  {key: 'AvklaringHoyspent', value: 'Avklaring av plassering nær høyspentledning'},
  {key: 'Folgebrev', value: 'Følgebrev'},
  {key: 'KommentarNabomerknader', value: 'Kommentar til nabomerknader'},
  {key: 'KvitteringNabovarsel', value: 'Kvittering for nabovarsel'},
  // {key: 'Nabovarsel', value: 'Nabovarsel'},
  {key: 'Nabomerknader', value: 'Nabomerknader'},
  {key: 'RedegjoerelseAndreNaturMiljoeforhold', value: 'Redegjørelse andre natur og miljøforhold'},
  {key: 'RedegjoerelseSkredOgFlom', value: 'Redegjoerelse for Skred/Flom'},
  {key: 'RedegjoerelseEstetikk', value: 'Redegjørelse Estetikk'},
  {key: 'SamtykkePlassering', value: 'Samtykke til plassering nær nabogrense'},
  {key: 'Situasjonsplan', value: 'Situasjonsplan'},
  {key: 'TegningEksisterendeFasade', value: 'Tegning eksisterende fasade'},
  {key: 'TegningEksisterendePlan', value: 'Tegning eksisterende plan'},
  {key: 'TegningEksisterendeSnitt', value: 'Tegning eksisterende snitt'},
  {key: 'TegningNyFasade', value: 'Tegning ny fasade'},
  {key: 'TegningNyPlan', value: 'Tegning ny plan'},
  {key: 'TegningNyttSnitt', value: 'Tegning nytt snitt'},
  {key: 'UttalelseVedtakAnnenOffentligMyndighet', value: 'Uttalelse vedtak annen offentlig myndighet'},
  // {key: 'Utomhusplan', value: 'Utomhusplan'},
];

interface RammebetingelserV3 {
  adkomst?: Veg;
  arealdisponering?: Arealdisponering;
  generelleVilkaar?: GenerelleVilkaar;
  plan?: PlanV3;
  vannforsyning?: Vannforsyning;
  avloep?: Avloep;
  kravTilByggegrunn?: KravTilByggegrunn;
  plassering?: PlasseringV3;
}

interface Veg {
  nyeEndretAdkomst?: boolean;
  vegrett?: Vegrett[];
}
interface Vegrett {
  vegtype?: KodeType;
  erTillatelseGitt?: boolean;
}

export interface Arealdisponering {
  tomtearealByggeomraade?: number;
  tomtearealSomTrekkesFra?: number;
  tomtearealSomLeggesTil?: number;
  tomtearealBeregnet?: number;
  beregnetMaksByggeareal?: number;
  arealBebyggelseEksisterende?: number;
  arealBebyggelseSomSkalRives?: number;
  arealBebyggelseNytt?: number;
  parkeringsarealTerreng?: number;
  arealSumByggesak?: number;
  beregnetGradAvUtnytting?: number;
}

interface GenerelleVilkaar {
  oppfyllesVilkaarFor3Ukersfrist?: boolean;
  beroererTidligere1850?: boolean;
  fravikFraByggtekniskForskrift?: boolean;
  prosjekteringTEK10?: boolean;
  behovForTillatelse?: boolean;
  norskSvenskDansk: boolean;
  norskSvenskDanskSpecified: boolean;
}

interface PlanV3 {
  gjeldendePlan: {
    utnyttingsgrad?: number;
    plantype?: KodeType;
    navn?: string;
    formaal?: string;
    andreRelevanteKrav?: string;
    beregningsregelGradAvUtnytting?: KodeType;
  };
  andrePlaner?: AndrePlanerV3[];
}
export interface AndrePlanerV3 {
  planType: KodeType;
  navn: string;
}
export interface Vannforsyning {
  tilknytningstype?: KodeType;
  beskrivelse?: string;
  krysserVannforsyningAnnensGrunn?: boolean;
  tinglystErklaering?: boolean;
}

export const VannforsyningMapping = {
  AnnenPrivatIkkeInnlagt: 'Ikke innlagt vann',
  TilknyttetOffVannverk: 'Offentlig vannverk',
  TilknyttetPrivatVannverk: 'Privat vannverk',
  BerorerIkkeVannverk: 'Tiltaket berører ikke nye vanninnstallasjoner',
};

export interface Avloep {
  tilknytningstype?: KodeType;
  installereVannklosett?: boolean;
  utslippstillatelse?: boolean;
  krysserAvloepAnnensGrunn?: boolean;
  tinglystErklaering?: boolean;
  overvannTerreng?: boolean;
  overvannAvloepssystem?: boolean;
}

export interface KravTilByggegrunn {
  flomutsattOmraade?: boolean;
  skredutsattOmraade?: boolean;
  miljoeforhold?: boolean;
}

export interface PlasseringV3 {
  konfliktHoeyspentkraftlinje?: boolean;
  minsteAvstandNabogrense?: number | string;
  konfliktVannOgAvloep?: boolean;
  minsteAvstandTilAnnenBygning?: number;
  minsteAvstandTilMidtenAvVei?: number;
  bekreftetInnenforByggegrense: boolean;
}

export const VegtypeMappingBeskrivelse = {
  KommunalVeg: 'Kommunal vei',
  PrivatVeg: 'Privat vei',
  Riksvei: 'Riksvei/fylkesvei',
  Fylkesvei: 'Riksvei/fylkesvei',
};
export const VegtypeMappingValue = {
  KommunalVeg: 'KommunalVeg',
  PrivatVeg: 'PrivatVeg',
  Riksvei: 'RiksFylkesveg',
  Fylkesvei: 'RiksFylkesveg',
};
