import {ArrowRightAlt} from '@material-ui/icons';
import React from 'react';
import {Link} from 'react-router-dom';
import './NkLink.scss';

type Props = {
  id?: string;
  href: string;
  newPage?: boolean;
  children;
  size?: 'small' | 'normal' | 'large';
  underline?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  tabIndex?: number;
  buttonStyle?: boolean;
  darkmode?: boolean;
  arrow?: 'left' | 'right';
};

const NkLink = ({
  id,
  buttonStyle,
  href,
  newPage,
  children,
  size = 'normal',
  underline = true,
  style,
  onClick,
  className,
  tabIndex,
  darkmode,
  arrow,
}: Props) => {
  const newPageProps = newPage ? {target: '_blank', rel: 'noopener norefferer'} : {};
  const isExternal = href?.includes('www') || href?.includes('http') || href?.includes('mailto');

  return isExternal ? (
    <a
      id={id}
      {...newPageProps}
      tabIndex={tabIndex}
      onClick={onClick}
      style={style}
      href={href}
      className={`NkLink ${size}${underline ? ' underline' : ''} ${darkmode ? 'darkmode' : 'lightmode'} ${
        className || ''
      } ${buttonStyle ? 'button_style' : 'link_style'}`}
    >
      {arrow === 'left' && <ArrowRightAlt style={{marginRight: 5}} />}
      {children}
      {arrow === 'right' && <ArrowRightAlt style={{marginLeft: 5}} />}
    </a>
  ) : (
    <Link
      {...newPageProps}
      tabIndex={tabIndex}
      onClick={onClick}
      style={style}
      to={href}
      className={`NkLink ${size}${underline ? ' underline' : ''} ${darkmode ? 'darkmode' : 'lightmode'} ${
        className || ''
      } ${buttonStyle ? 'button_style' : 'link_style'}`}
    >
      {arrow === 'left' && <ArrowRightAlt style={{marginRight: 5}} />}
      {children}
      {arrow === 'right' && <ArrowRightAlt style={{marginLeft: 5}} />}
    </Link>
  );
};
export default NkLink;
