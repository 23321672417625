import {ExternalPlanLinkType} from '../services/configs/customerConfig';
import {AdresseFromServer} from '../services/matrikkelgrunnbok';

export type Address = AdresseFromServer;

export interface ChosenMunicipalityState {
  fetchPlanbestemmelserForMunicipality: boolean;
  externalPlanLink?: ExternalPlanLinkType;
  produceSitkartForMunicipality: boolean;
  name: string;
}

export const initialChosenMunicipalityState: ChosenMunicipalityState = {
  fetchPlanbestemmelserForMunicipality: false,
  produceSitkartForMunicipality: false,
  name: 'placeholder',
};

export type Action =
  | {
      type: 'setMunicipalityConfig';
      value: {
        fetchPlanbestemmelser: boolean;
        fetchSitkart: boolean;
        externalPlanLink?: ExternalPlanLinkType;
      };
    }
  | {
      type: 'setMunicipalityName';
      value: string;
    };

export const reducer = (state: ChosenMunicipalityState, action: Action): ChosenMunicipalityState => {
  switch (action.type) {
    case 'setMunicipalityConfig': {
      return {
        ...state,
        fetchPlanbestemmelserForMunicipality: action.value.fetchPlanbestemmelser,
        produceSitkartForMunicipality: action.value.fetchSitkart,
        externalPlanLink: action.value.externalPlanLink,
      };
    }
    case 'setMunicipalityName': {
      return {
        ...state,
        name: action.value,
      };
    }

    default:
      return state;
  }
};
