import {optionId} from '../../scenes/CanIApply/optionLists/tiltaksOptions/optionIds';
import {getMaxUASize} from '../../scenes/PlacementComponents/components/SizeInfo/sizeHelper';
import {QuestionId} from '../../services/utils/questionIds';
import {AnswerValues} from '../../state/questionTypes';
import getSelectedTiltakstyper from '../tiltak/getSelectedTiltakstyper';
import {riveTiltakstyper} from '../tiltak/tiltaktypeIds';

export function requiresAnsvarsrett(answers: AnswerValues) {
  return getRequieredAnsvarsrettFromQuestions(answers).length > 0;
}

const prefixAnsvarsrettText = 'Som privatperson kan du ikke søke selv om ';

export function getRequieredAnsvarsrettFromQuestions(
  answerValues: AnswerValues
): {id: QuestionId; explanation: string}[] {
  let questionsWithAnsvarsrett: {id: QuestionId; explanation: string}[] = [];
  const selectedTiltakstype = getSelectedTiltakstyper(answerValues)[0];
  if (answerValues.BUILDINGS_EXIST === false) {
    questionsWithAnsvarsrett.push({
      id: 'BUILDINGS_EXIST',
      explanation: prefixAnsvarsrettText + 'å gjøre endringer på en eiendom som ikke allerede har bygninger.',
    });
  }
  if (answerValues.BEBYGD === false) {
    questionsWithAnsvarsrett.push({
      id: 'BEBYGD',
      explanation: prefixAnsvarsrettText + 'å gjøre endringer på en eiendom som ikke allerede har bygninger.',
    });
  }
  if (answerValues.NUMBER_OF_BUILDINGS_TO_CHANGE === 'flere') {
    questionsWithAnsvarsrett.push({
      id: 'NUMBER_OF_BUILDINGS_TO_CHANGE',
      explanation: prefixAnsvarsrettText + 'å bruksendre i flere bygninger i en og samme søknad.',
    });
  }
  if (answerValues.HIMLING_ABOVE_1_POINT_5_TILBYGG === true) {
    questionsWithAnsvarsrett.push({
      id: 'HIMLING_ABOVE_1_POINT_5_TILBYGG',
      explanation:
        prefixAnsvarsrettText +
        'å bygge et tilbygg med kjeller hvor himlingen er 1.5 meter over gjennomsnittlig terrengnivå.',
    });
  }
  if (answerValues.HIMLING_ABOVE_1_POINT_5_BUILD === true) {
    questionsWithAnsvarsrett.push({
      id: 'HIMLING_ABOVE_1_POINT_5_BUILD',
      explanation:
        prefixAnsvarsrettText +
        'å bygge et bygg med kjeller hvor himlingen er 1.5 meter over gjennomsnittlig terrengnivå.',
    });
  }
  if (answerValues.HIMLING_ABOVE_1_POINT_5_DEMOLISH === true) {
    questionsWithAnsvarsrett.push({
      id: 'HIMLING_ABOVE_1_POINT_5_DEMOLISH',
      explanation:
        prefixAnsvarsrettText +
        'å rive et bygg med kjeller hvor himlingen er 1.5 meter over gjennomsnittlig terrengnivå.',
    });
  }
  if (answerValues.BUILDING_EXTRA_USAGES && (answerValues.BUILDING_EXTRA_USAGES as optionId) === 'BEDROOM') {
    questionsWithAnsvarsrett.push({
      id: 'BUILDING_EXTRA_USAGES',
      explanation: prefixAnsvarsrettText + 'å bygge en garasje med ekstra soverom', //Kontroller om tekst er ok, og når dette spm dukker opp
    });
  }

  const hasSizeRelatedAnswers = answerValues.TILTAK_AREA_VALUE_BRA !== undefined || answerValues.RIVE_AREA_VALUE_BYA;
  if (hasSizeRelatedAnswers) {
    const maxSizeUA = getMaxUASize(answerValues);
    if (
      answerValues.TILTAK_AREA_VALUE_BRA !== undefined &&
      (answerValues.TILTAK_AREA_VALUE_BRA as number) > maxSizeUA
    ) {
      questionsWithAnsvarsrett.push({
        id: 'TILTAK_AREA_VALUE_BRA',
        explanation: prefixAnsvarsrettText + 'å bygge denne størrelsen.',
      });
    }
    if (answerValues.RIVE_AREA_VALUE_BRA !== undefined && (answerValues.RIVE_AREA_VALUE_BRA as number) > maxSizeUA) {
      questionsWithAnsvarsrett.push({
        id: 'RIVE_AREA_VALUE_BRA',
        explanation: prefixAnsvarsrettText + 'å bygge denne størrelsen.',
      });
    }

    if (riveTiltakstyper.includes(selectedTiltakstype)) {
      if (
        answerValues.RIVE_AREA_VALUE_BYA !== undefined &&
        (answerValues.RIVE_AREA_VALUE_BYA as number) > maxSizeUA &&
        answerValues.DEMOLISH_ENTIRE_BUILDING === true
      ) {
        questionsWithAnsvarsrett.push({
          id: 'DEMOLISH_ENTIRE_BUILDING',
          explanation: prefixAnsvarsrettText + 'å bygge denne størrelsen.',
        });
      }
      if (
        answerValues.RIVE_AREA_VALUE_BYA !== undefined &&
        (answerValues.RIVE_AREA_VALUE_BYA as number) > maxSizeUA &&
        answerValues.DEMOLISH_ENTIRE_BUILDING === false
      ) {
        questionsWithAnsvarsrett.push({
          id: 'RIVE_AREA_VALUE_BYA',
          explanation: prefixAnsvarsrettText + 'å bygge denne størrelsen.',
        });
      }
    } else {
      if (
        answerValues.TILTAK_AREA_VALUE_BYA !== undefined &&
        (answerValues.TILTAK_AREA_VALUE_BYA as number) > maxSizeUA
      ) {
        questionsWithAnsvarsrett.push({
          id: 'TILTAK_AREA_VALUE_BYA',
          explanation: prefixAnsvarsrettText + 'å bygge denne størrelsen.',
        });
      }
    }
  }

  if (
    answerValues.TILTAK_TYPE_CHANGE &&
    (answerValues.TILTAK_TYPE_CHANGE as optionId[]).indexOf('selvstendigBoenhet') > -1
  ) {
    questionsWithAnsvarsrett.push({
      id: 'TILTAK_TYPE_CHANGE',
      explanation: prefixAnsvarsrettText + 'å bygge en selvstendig boenhet.',
    });
  }
  if (
    answerValues.TILTAK_TYPE_CHANGE &&
    (answerValues.TILTAK_TYPE_CHANGE as optionId[]).indexOf('kontorTilLeilighet') > -1
  ) {
    questionsWithAnsvarsrett.push({
      id: 'TILTAK_TYPE_CHANGE',
      explanation: prefixAnsvarsrettText + 'å bruksendre fra kontor til leilighet.',
    });
  }
  if (
    answerValues.TILTAK_TYPE_CHANGE &&
    (answerValues.TILTAK_TYPE_CHANGE as optionId[]).indexOf('boligTilNaering') > -1
  ) {
    questionsWithAnsvarsrett.push({
      id: 'TILTAK_TYPE_CHANGE',
      explanation: prefixAnsvarsrettText + 'å bruksendre fra bolig til næring.',
    });
  }
  if (
    answerValues.TILTAK_TYPE_CHANGE &&
    (answerValues.TILTAK_TYPE_CHANGE as optionId[]).indexOf('driftsbygningendringover1000m2') > -1
  ) {
    questionsWithAnsvarsrett.push({
      id: 'TILTAK_TYPE_CHANGE',
      explanation: prefixAnsvarsrettText + 'å bruksendre en driftsbygning i landbruket på over 1000 m².',
    });
  }
  if (
    answerValues.TILTAK_TYPE_BUILD &&
    (answerValues.TILTAK_TYPE_BUILD as optionId[]).indexOf('nyttbyggdriftsbygningover1000m2') > -1
  ) {
    questionsWithAnsvarsrett.push({
      id: 'TILTAK_TYPE_BUILD',
      explanation: prefixAnsvarsrettText + 'å bygge en driftsbygning i landbruket på over 1000 m².',
    });
  }
  if (
    answerValues.TILTAK_TYPE_BUILD &&
    (answerValues.TILTAK_TYPE_BUILD as optionId[]).indexOf('nyttbyggover70m2') > -1
  ) {
    questionsWithAnsvarsrett.push({
      id: 'TILTAK_TYPE_BUILD',
      explanation: prefixAnsvarsrettText + 'å bygge et nytt bygg på over 70 m².',
    });
  }
  if (
    answerValues.BULDING_IS_BOLIG_OR_FRITIDSBOLIG &&
    (answerValues.BULDING_IS_BOLIG_OR_FRITIDSBOLIG as boolean) === true
  ) {
    questionsWithAnsvarsrett.push({
      id: 'BULDING_IS_BOLIG_OR_FRITIDSBOLIG',
      explanation: prefixAnsvarsrettText + 'å bygge et bygg som skal brukes som bolig eller fritidsbolig.',
    });
  }
  if ((answerValues.ATTIC_AS_FLOOR_UNDER_50_WITH_BASEMENT as boolean) === true) {
    questionsWithAnsvarsrett.push({
      id: 'ATTIC_AS_FLOOR_UNDER_50_WITH_BASEMENT',
      explanation: prefixAnsvarsrettText + 'å bygge et bygg med et loft som blir å anse som en etasje.',
    });
  }
  if ((answerValues.ATTIC_AS_FLOOR_UNDER_50_NO_BASEMENT as boolean) === true) {
    questionsWithAnsvarsrett.push({
      id: 'ATTIC_AS_FLOOR_UNDER_50_NO_BASEMENT',
      explanation: prefixAnsvarsrettText + 'å bygge et bygg med et loft som blir å anse som en etasje.',
    });
  }
  if ((answerValues.ATTIC_AS_FLOOR_UNDER_70 as boolean) === true) {
    questionsWithAnsvarsrett.push({
      id: 'ATTIC_AS_FLOOR_UNDER_70',
      explanation: prefixAnsvarsrettText + 'å bygge et bygg med et loft som blir å anse som en etasje.',
    });
  }

  if (answerValues.ATTIC_IS_FLOOR_DEMOLISH_UNDER_50 === true) {
    questionsWithAnsvarsrett.push({
      id: 'ATTIC_IS_FLOOR_DEMOLISH_UNDER_50',
      explanation: prefixAnsvarsrettText + 'å rive et bygg med loftsetasje.',
    });
  }
  if (answerValues.ATTIC_IS_FLOOR_DEMOLISH_OVER_50 === true) {
    questionsWithAnsvarsrett.push({
      id: 'ATTIC_IS_FLOOR_DEMOLISH_OVER_50',
      explanation: prefixAnsvarsrettText + 'å rive et bygg med loftsetasje.',
    });
  }

  if (
    answerValues.TILTAK_TYPE_BUILD &&
    (answerValues.TILTAK_TYPE_BUILD as optionId[]).indexOf('tilbyggOver50m2') > -1
  ) {
    questionsWithAnsvarsrett.push({
      id: 'TILTAK_TYPE_BUILD',
      explanation: prefixAnsvarsrettText + 'å bygge et tilbygg på over 50 m².',
    });
  }
  if (
    answerValues.TILTAK_TYPE_BUILD &&
    (answerValues.TILTAK_TYPE_BUILD as optionId[]).indexOf('driftsbygningtilbyggOver1000m2') > -1
  ) {
    questionsWithAnsvarsrett.push({
      id: 'TILTAK_TYPE_BUILD',
      explanation: prefixAnsvarsrettText + 'å bygge et tilbygg på driftsbygning på over 1000 m².',
    });
  }

  if (answerValues.WHAT_TO_BUILD && answerValues.WHAT_TO_BUILD === 'paabygg') {
    questionsWithAnsvarsrett.push({
      id: 'WHAT_TO_BUILD',
      explanation: prefixAnsvarsrettText + 'å bygge påbygg.',
    });
  }

  if (answerValues.TO_OR_FROM_DRIFTSBYGNING && answerValues.TO_OR_FROM_DRIFTSBYGNING === 'fromDriftsbygning') {
    questionsWithAnsvarsrett.push({
      id: 'TO_OR_FROM_DRIFTSBYGNING',
      explanation: prefixAnsvarsrettText + 'å endre fra driftsbygning til annet bruk.',
    });
  }

  if (answerValues.ROOM_ENTRANCE && (answerValues.ROOM_ENTRANCE as optionId[]).indexOf('annen_ingangsdør') > -1) {
    questionsWithAnsvarsrett.push({
      id: 'ROOM_ENTRANCE',
      explanation:
        prefixAnsvarsrettText + 'å bruksendre et rom som får en egen inngangsdør uavhengig av eksisterende boenhet.',
    });
  }
  if (answerValues.ROOM_ENTRANCE && (answerValues.ROOM_ENTRANCE as optionId[]).indexOf('fellesareal') > -1) {
    questionsWithAnsvarsrett.push({
      id: 'ROOM_ENTRANCE',
      explanation: prefixAnsvarsrettText + 'å bruksendre et rom som får inngangsdør via fellesareal.',
    });
  }

  if (answerValues.BUILDING_TO_SLEEP_IN === true) {
    questionsWithAnsvarsrett.push({
      id: 'BUILDING_TO_SLEEP_IN',
      explanation: prefixAnsvarsrettText + 'å bygge et bygg hvor det legges opp til bo og/eller sovemuligheter.',
    });
  }
  if (answerValues.BUILDING_TO_SLEEP_IN_TILBYGG === true) {
    questionsWithAnsvarsrett.push({
      id: 'BUILDING_TO_SLEEP_IN_TILBYGG',
      explanation: prefixAnsvarsrettText + 'å bygge et tilbygg hvor det legges opp til bo og/eller sovemuligheter.',
    });
  }
  if (answerValues.BUILDING_TO_SLEEP_IN_TILBYGG_UNDER_15 === true) {
    questionsWithAnsvarsrett.push({
      id: 'BUILDING_TO_SLEEP_IN_TILBYGG_UNDER_15',
      explanation:
        prefixAnsvarsrettText +
        'å bygge et tilbygg med garasje eller annet som formål hvor det legges opp til bo og/eller sovemuligheter.',
    });
  }
  if (answerValues.TILBYGG_UNDERSTOTTE === false) {
    questionsWithAnsvarsrett.push({
      id: 'TILBYGG_UNDERSTOTTE',
      explanation: prefixAnsvarsrettText + 'å bygge et tilbygg som ikke vil være understøttet.',
    });
  }
  if (answerValues.SELVSTENDIG_BOENHET_TILBYGG === true) {
    questionsWithAnsvarsrett.push({
      id: 'SELVSTENDIG_BOENHET_TILBYGG',
      explanation: prefixAnsvarsrettText + 'å bygge et tilbygg som skal ha en selvstendig boenhet.',
    });
  }
  if (answerValues.TILBYGG_DEMOLISH_SELVSTENDIG_BOENHET === true) {
    questionsWithAnsvarsrett.push({
      id: 'TILBYGG_DEMOLISH_SELVSTENDIG_BOENHET',
      explanation: prefixAnsvarsrettText + 'å rive et tilbygg som har en selvstendig boenhet.',
    });
  }
  if (answerValues.DEMOLISH_DRIFTSBYGNING_BUILDING_IS_APPROVED === true) {
    questionsWithAnsvarsrett.push({
      id: 'DEMOLISH_DRIFTSBYGNING_BUILDING_IS_APPROVED',
      explanation: prefixAnsvarsrettText + 'å rive et bygg som er godkjent som bolig eller for overnatting.',
    });
  }

  const riveOptionsWithAnsvarsrett: optionId[] = [
    'riveAnnet',
    'rivingbygningover70m2',
    'rivingtilbyggOver50',
    'rivingdriftsbygningOver1000m2',
  ];
  if (riveOptionsWithAnsvarsrett.indexOf(answerValues.TILTAK_TYPE_DEMOLISH as optionId) > -1) {
    questionsWithAnsvarsrett.push({
      id: 'TILTAK_TYPE_DEMOLISH',
      explanation: prefixAnsvarsrettText + 'å rive valgt størrelse (m²).',
    });
  }
  if (answerValues.BUILDING_IS_APPROVED === true) {
    questionsWithAnsvarsrett.push({
      id: 'BUILDING_IS_APPROVED',
      explanation: prefixAnsvarsrettText + 'å rive et bygg som er godkjent som bolig eller for overnatting.',
    });
  }
  if (answerValues.TILBYGG_IS_APPROVED_UNDER_15 === true) {
    questionsWithAnsvarsrett.push({
      id: 'TILBYGG_IS_APPROVED_UNDER_15',
      explanation: prefixAnsvarsrettText + 'å rive et tilbygg som er godkjent som bolig eller for overnatting.',
    });
  }
  if (answerValues.TILBYGG_IS_APPROVED_OVER_15 === true) {
    questionsWithAnsvarsrett.push({
      id: 'TILBYGG_IS_APPROVED_OVER_15',
      explanation: prefixAnsvarsrettText + 'å rive et tilbygg som er godkjent som bolig eller for overnatting.',
    });
  }
  if (answerValues.TILBYGG_DEMOLISH_HIGHER_THAN_ADJACENT_BUILDING === true) {
    questionsWithAnsvarsrett.push({
      id: 'TILBYGG_DEMOLISH_HIGHER_THAN_ADJACENT_BUILDING',
      explanation: prefixAnsvarsrettText + 'å rive et tilbygg som er høyere enn bygget det er plassert inntil.',
    });
  }
  if (answerValues.TILBYGG_DEMOLISH_HIMLING_ABOVE_1_POINT_5 === true) {
    questionsWithAnsvarsrett.push({
      id: 'TILBYGG_DEMOLISH_HIMLING_ABOVE_1_POINT_5',
      explanation:
        prefixAnsvarsrettText +
        'å rive et tilbygg med kjeller hvor himlingen er 1.5 meter over gjennomsnittlig terrengnivå.',
    });
  }
  if (answerValues.TILBYGG_DEMOLISH_UNDERSTØTTET === false) {
    questionsWithAnsvarsrett.push({
      id: 'TILBYGG_DEMOLISH_UNDERSTØTTET',
      explanation: prefixAnsvarsrettText + 'å rive et tilbygg som ikke er understøttet.',
    });
  }
  if (answerValues.WHAT_TO_DO === ('create' as optionId)) {
    //Bebygd false to not trigger ansvarsrett if user wants to dele/opprette
    questionsWithAnsvarsrett = questionsWithAnsvarsrett.filter((a) => a.id !== 'BEBYGD');
  }
  if (answerValues.TERRASSE_SIZE === 'terrasse_over_50') {
    questionsWithAnsvarsrett.push({
      id: 'TERRASSE_SIZE',
      explanation: prefixAnsvarsrettText + 'å bygge en terrasse over 50 kvm.',
    });
  }
  return questionsWithAnsvarsrett;
}

export function requiresAnsvarsrettForQuestion(answers: AnswerValues, question: QuestionId): boolean {
  return getRequieredAnsvarsrettFromQuestions(answers).filter((item) => item.id === question).length > 0;
}
