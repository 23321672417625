import {Route, Switch} from 'react-router';
import {Redirect} from 'react-router-dom';
import StatusAlertFetcher from '../../components/StatusPopup/StatusAlertFetcher';
import ArticlePage from '../../scenes/Articles/ArticlePage/ArticlePage';
import ArticlesPage from '../../scenes/Articles/ArticlesPage/ArticlesPage';
import FAQ from '../../scenes/FAQ/faq';
import LandingPage from '../../scenes/LandingPage/LandingPage';
import NotFound from '../../scenes/NotFound/NotFound';
import {InfoPagesRoutes as routes} from '../../utils/routes/InfoPagesRoutes';
import PaymentModule from '../../components/Payment/PaymentModule';
import Personvern from '../../scenes/Personvern/Personvern';

const InfoPages = () => {
  return (
    <div className='App'>
      <StatusAlertFetcher />
      <Switch>
        <Route path={routes.faq.path} render={() => <FAQ />} />
        <Route
          path={routes.base.path + '/:kommune?'}
          render={() => {
            return <LandingPage />;
          }}
        />
        <Route path={routes.articles.path} render={() => <ArticlesPage />} />
        <Route path={routes.article.path + '/:artikkel'} render={() => <ArticlePage />} />
        <Route
          path={routes.notfound.path}
          render={() => {
            return <NotFound />;
          }}
        />
        <Route path={routes.payment.path} render={() => <PaymentModule />} />
        <Route path={routes.personvern.path} render={() => <Personvern />} />
        <Redirect path={routes.garasjeInfo.path} to={routes.article.path + '/garasje-og-uthus'} />
        <Redirect to={routes.notfound.path} />
      </Switch>
    </div>
  );
};

export default InfoPages;
