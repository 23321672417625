import React from 'react';
import './StepBox.scss';
import NkTypography from '../../../../../components/NkTypography/NkTypography';
import NkButton from '../../../../../components/Button/NkButton';
import {appInsightsInstance} from '../../../../../AppInsightsSetup';

type Props = {
  text: string;
  illustration: string;
  side: 'left' | 'right';
  onClick: (index) => void;
  stepNumber: number;
  small: boolean;
  ariaExpanded: boolean;
};
const StepBox = ({text, illustration, side, onClick, stepNumber, small, ariaExpanded}: Props) => {
  const onReadMore = () => {
    onClick(stepNumber - 1);
    setOpen(!isOpen);
    appInsightsInstance?.trackEvent({name: 'frontpage readProcessStepInfo'}, {processStep: stepNumber + 1});
  };
  const [isOpen, setOpen] = React.useState(false);

  return (
    <div className={`StepBox ${small && 'small'}`}>
      {side === 'right' && <img alt='' src={illustration}></img>}
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <NkTypography
          style={{
            width: 260,
            textAlign: side === 'left' ? 'right' : 'left',
          }}
          type='largeParagraph'
        >
          {text}
        </NkTypography>
        <NkButton
          type='link'
          paragraphStyle='largeParagraph'
          onClick={onReadMore}
          style={{
            justifyContent: side === 'left' ? 'flex-end' : 'flex-start',
            textAlign: side === 'left' ? 'right' : 'left',
          }}
          ariaExpanded={ariaExpanded}
        >
          Les mer
        </NkButton>
      </div>
      {side === 'left' && <img alt='' src={illustration}></img>}
    </div>
  );
};
export default StepBox;
