/* eslint-disable no-throw-literal */
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import isPointInGeometry from '@turf/boolean-point-in-polygon';
import {appInsightsInstance} from '../AppInsightsSetup';
import {norwegianStringComp} from '../components/SortNorwegian';
import {ApplicationId} from '../services/ebyggesokServices/userOrderService';
import {
  addresseFromServerToString,
  AdresseFromServer,
  getAddressFromAddressId,
  getStreetAddressesFromProperty,
} from '../services/matrikkelgrunnbok';
import {teigsInPropertyId, TeigsInPropertyIdSuccess} from '../services/matrikkelkart';
import {MatrikkelNummer, Teig} from './dibkSchemas/models/models';
import {addressIdToRoadAddressId} from './addressHelpers';
import {AxiosError} from 'axios';
import {useQuery} from '@tanstack/react-query';

export interface TeigOnProperty {
  addresses: AdresseFromServer[];
  propertyId: string;
  teigId: number | undefined;
  geometry: GeoJSON.Polygon;
}

export async function findTeigId(propertyId: MatrikkelNummer, geometry: Teig['geometry']) {
  let propertyInfo: {teigs: any; isSeksjonert?: boolean};
  propertyInfo = await findTeigerFromPropertyId(propertyId);

  const buildingSites = propertyInfo.teigs;
  if (buildingSites.length === 0) {
    return undefined;
  } else if (buildingSites.length === 1) {
    return buildingSites[0].teigId;
  } else {
    const match = buildingSites.find((b) => JSON.stringify(b.geometry) === JSON.stringify(geometry));
    if (match) {
      return match.teigId;
    } else {
      return undefined;
    }
  }
}

export async function findTeigerFromPropertyId(
  propertyId: MatrikkelNummer,
  applicationId?: ApplicationId
): Promise<{teigs: TeigOnProperty[]}> {
  // Add main property instead of seksjon - a byggesak should always use hovedeiendom geometry

  const trimmedSnrPropertyId = (propertyId.slice(0, -3) + '000') as MatrikkelNummer;
  let teigInfoOnProperty: TeigsInPropertyIdSuccess;
  teigInfoOnProperty = await teigsInPropertyId(trimmedSnrPropertyId);

  if (!teigInfoOnProperty) {
    appInsightsInstance?.trackException(
      {
        error: new Error('getTeigPropIdFailed'),
        severityLevel: SeverityLevel.Error,
      },
      {
        soknadId: applicationId,
        propertyId: trimmedSnrPropertyId,
        message: 'Teig-error, could not find teig geometry for mnr: ' + trimmedSnrPropertyId,
      }
    );
    throw 'Vi klarte ikke å hente nødvendig informasjon for den valgte eiendommen';
  }
  if (teigInfoOnProperty.teigs.length === 0) {
    appInsightsInstance?.trackException(
      {
        error: new Error('teigsInPropertyIdEmpty'),
        severityLevel: SeverityLevel.Error,
      },
      {
        soknadId: applicationId,
        propertyId: trimmedSnrPropertyId,
        message: 'Teig-error, could not find teig geometry for mnr: ' + trimmedSnrPropertyId,
      }
    );
    throw 'Vi klarte ikke å hente nødvendig informasjon for den valgte eiendommen';
  }

  let teigs: Teig[] = [];
  teigs = teigInfoOnProperty.teigs;
  const addresses = (await getStreetAddressesFromProperty(propertyId as MatrikkelNummer)).data.Adresser;

  const buildingsites = teigs.map((teig) => {
    let filteredAddresses = addresses.filter((addr) => isPointInGeometry([addr.X, addr.Y], teig.geometry));
    filteredAddresses.sort((a, b) => norwegianStringComp(addresseFromServerToString(a), addresseFromServerToString(b)));

    return {
      addresses: filteredAddresses,
      propertyId: propertyId,
      teigId: teig.teigId,
      geometry: teig.geometry,
    } as TeigOnProperty;
  });

  return {teigs: buildingsites};
}

export function addressToAddressId(address: AdresseFromServer): string {
  const ident = address.VegadresseIdent;
  const addressId =
    ident.Kommunenummer.padStart(4, '0') +
    ident.Gatenummer.toString().padStart(5, '0') +
    ident.Husnummer.toString().padStart(4, '0') +
    '000' +
    ident.Bokstav.padEnd(4, '0');

  return addressId;
}

export function addressToRoadAddressId(address: AdresseFromServer): string {
  const addressId = addressToAddressId(address);
  return addressIdToRoadAddressId(addressId);
}

export async function getKartverksIdFromChosenAddress(chosenAddress: AdresseFromServer) {
  // UnikId (kartverksId) can be ex. 0 if there is a bug in MatrikkelGrunnbok. Retrive it then from roadAddressId.
  if (chosenAddress.Unikid === '0' || !chosenAddress.Unikid) {
    const addressRes = await getAddressFromAddressId(addressToRoadAddressId(chosenAddress)).then(
      (res) => {
        return res.data;
      },
      (err: AxiosError) => {
        appInsightsInstance?.trackException({
          error: new Error('getAddressFromAddressId'),
          severityLevel: SeverityLevel.Error,
        });
        throw err;
      }
    );
    return addressRes.Adresse.Unikid;
  }
  return chosenAddress.Unikid;
}

export function useKartverksIdQuery(chosenAddress?: AdresseFromServer | null) {
  const query = useQuery(['KartverksIdQueryKey', chosenAddress ? addressToRoadAddressId(chosenAddress) : 'noadress'], {
    queryFn: () => {
      return getKartverksIdFromChosenAddress(chosenAddress!);
    },
    enabled: !!chosenAddress,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {kartverksId: query.data, isFetchingKartverksId: query.isFetching};
}
