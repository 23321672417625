import {Stack} from '@mui/material';
import NkTypography from '../../components/NkTypography/NkTypography';

export const Rettigheter = () => {
  return (
    <Stack gap='20px'>
      <NkTypography tag='h2' type='heading2'>
        Dine rettigheter som bruker av våre nettsteder eller som kunde av oss:
      </NkTypography>
      <Stack gap='10px'>
        <NkTypography type='normalParagraph'>
          Dersom du ønsker å bruke noen av dine rettigheter, ta kontakt med oss på personvern@norkart.no{' '}
        </NkTypography>
        <NkTypography type='normalParagraph'>
          Personopplysninger som finnes i offentlige eiendomsregistre slik som matrikkel og grunnbok, kan Norkart ikke
          endre eller slette. Hvis du mener informasjonen om deg i slike registre ikke er korrekt, ta kontakt med din
          kommune.
        </NkTypography>
      </Stack>

      <NkTypography tag='h3' type='heading3'>
        Rett til innsyn i egne opplysninger
      </NkTypography>
      <NkTypography type='normalParagraph'>Du kan be om en kopi av alle opplysninger vi behandler om deg.</NkTypography>

      <NkTypography tag='h3' type='heading3'>
        Rett til sletting og korrigering av personopplysninger
      </NkTypography>
      <NkTypography type='normalParagraph'>
        Du har rett til å be oss rette eller supplere opplysninger som er feilaktige eller misvisende. Du kan også ha
        rett til å få dine personopplysninger slettet uten ugrunnet opphold. Men merk at informasjon som vi er pålagt å
        beholde av hensyn til andre rettslige forpliktelser (som for eksempel bokføringsloven) ikke vil bli slettet.
      </NkTypography>
      <NkTypography tag='h3' type='heading3'>
        Begrensning av behandling av personopplysninger
      </NkTypography>
      <NkTypography type='normalParagraph'>
        I noen situasjoner kan du også be oss begrense behandlingen av opplysninger om deg. Dette gjør du ved å
        administrere samtykker eller reservasjoner i våre løsninger.
      </NkTypography>
      <NkTypography tag='h3' type='heading3'>
        Protestere mot en behandling av personopplysninger
      </NkTypography>
      <NkTypography type='normalParagraph'>
        Dersom vi behandler opplysninger om deg med grunnlag i våre oppgaver eller på bakgrunn av en interesseavveining,
        har du rett til å protestere på vår behandling av opplysninger om deg. Dette gjør du ved å administrere
        samtykker eller reservasjoner i våre løsninger (nyhetsbrev).
      </NkTypography>
      <NkTypography tag='h3' type='heading3'>
        Du kan klage på vår behandling av personopplysninger
      </NkTypography>
      <NkTypography type='normalParagraph'>
        Vi håper du vil si ifra dersom du mener vi ikke overholder reglene i personopplysningsloven. Si da gjerne først
        i fra gjennom den kontakten eller kanalen du allerede har etablert med oss. Du kan også klage over vår
        behandling av personopplysninger, skriftlig til Datatilsynet: Datatilsynet, Postboks 458 Sentrum, 0105 Oslo.
      </NkTypography>
    </Stack>
  );
};
