import {AddAnswerAction, RemoveAnswerAction} from './questionState';
import {getVisibleSteps} from '../components/Steps/steps';
import {updateLastFinishedStep} from '../services/ebyggesokServices/applicationService';
import {StepType} from '../components/Steps/stepsForApplicationTypeAndTiltak';
import {StepIds} from '../components/Steps/stepConfig';
import {mainStepId, innerStepId, Answers, mainStep} from './questionTypes';
import {getAnswerValues} from '../utils/getAnswerValues';
import {ApplicationId} from '../services/ebyggesokServices/userOrderService';

export interface StepperState {
  activeInnerStep: number;
  activeMainStep: number;
  lastFinishedMainStepIndex: number;
  lastFinishedInnerStepIndex: number;
  forwardBlockers: {id: string; errorText: string}[];
}

export const initialState: StepperState = {
  activeInnerStep: 0,
  lastFinishedMainStepIndex: 0, //First step is info step which is always "finished"
  activeMainStep: 1,
  lastFinishedInnerStepIndex: -1,
  forwardBlockers: [],
};

export type Action =
  | AddAnswerAction
  | RemoveAnswerAction
  | {
      type: 'setActiveInnerStep';
      value: number;
    }
  | {
      type: 'setLastFinishedMainStep';
      value: {stepIndex: number; stepId?: mainStepId};
    }
  | {
      type: 'setActiveMainStep';
      value: number;
    }
  | {
      type: 'setLastFinishedInnerStep';
      value: {stepIndex: number; stepId?: innerStepId};
    }
  | {type: 'resetStepperState'}
  | {
      type: 'addForwardBlocker';
      value: {id: string; errorText: string};
    }
  | {
      type: 'removeForwardBlocker';
      value: string;
    }
  | {
      type: 'resetForwardBlockers';
    };

export const reducer = (
  state: StepperState,
  action: Action,
  answers: Answers,
  stepType: StepType,
  isStructuredMangel: boolean
): StepperState => {
  switch (action.type) {
    case 'addAnswer':
      return checkIfResettingLastFinishedStepsIsNeeded(
        action.value.application.applicationId,
        action.value.step,
        action.value.doNotResetStepper,
        answers,
        state,
        stepType,
        isStructuredMangel
      );
    case 'removeAnswer':
      return checkIfResettingLastFinishedStepsIsNeeded(
        action.value.applicationId,
        action.value.step,
        false,
        answers,
        state,
        stepType,
        isStructuredMangel
      );
    case 'setActiveInnerStep':
      return {...state, activeInnerStep: action.value};
    case 'setActiveMainStep':
      return {...state, activeMainStep: action.value};
    case 'setLastFinishedMainStep':
      return {...state, lastFinishedMainStepIndex: action.value.stepIndex};
    case 'setLastFinishedInnerStep':
      return {...state, lastFinishedInnerStepIndex: action.value.stepIndex};
    case 'addForwardBlocker':
      return {...state, forwardBlockers: [...state.forwardBlockers, action.value]};
    case 'removeForwardBlocker':
      return {...state, forwardBlockers: [...state.forwardBlockers.filter((blocker) => blocker.id !== action.value)]};
    case 'resetForwardBlockers':
      return {...state, forwardBlockers: []};
    case 'resetStepperState':
      return {...initialState};
    default:
      return state;
  }
};
/* 
applicationId
step
*/
const checkIfResettingLastFinishedStepsIsNeeded = (
  applicationId: ApplicationId | undefined,
  step: StepIds,
  doNotResetStepper: boolean | undefined,
  answers: Answers,
  state: StepperState,
  stepType: StepType,
  isStructuredMangel: boolean
): StepperState => {
  // -- if user is changing answer on previous completed step, then last finished steps should be updated
  const soknadId = applicationId;
  if (doNotResetStepper || soknadId === undefined) return state;

  const visibleSteps = getVisibleSteps(getAnswerValues(answers), stepType, isStructuredMangel);
  const answerStep = step;
  let innerStepIndex = findInnerStepIndex(visibleSteps, answerStep);
  if (innerStepIndex > -1) {
    // Answer is from a inner step
    return handleInnerStepAnswer(visibleSteps, answerStep, innerStepIndex, state, applicationId);
  } else {
    // Answer is from a main step
    return handleMainStepAnswer(visibleSteps, answerStep, state, applicationId);
  }
};

const handleInnerStepAnswer = (
  visibleSteps: mainStep[],
  answerStep: StepIds,
  answerInnerStepIndex: number,
  state: StepperState,
  applicationId: ApplicationId | undefined
): StepperState => {
  let currentInnerStepIndex = getCurrentInnerStepIndex(visibleSteps, window.location.pathname);
  const mainStepForInnerStep = getMainStepForInnerStep(visibleSteps, answerStep);
  if (answerInnerStepIndex !== currentInnerStepIndex) {
    //Do not update state if answer is from another inner step than the current one
    return state;
  }
  if (
    state.lastFinishedInnerStepIndex === -1 ||
    state.lastFinishedInnerStepIndex >= answerInnerStepIndex ||
    state.lastFinishedMainStepIndex >= mainStepForInnerStep
  ) {
    //Last finished main step is then the main step behind the inner step
    const newLastFinishedMainStep = mainStepForInnerStep - 1;
    const newLastFinishedInnerStep = answerInnerStepIndex - 1;
    //Update main step in database
    const newFinishedMainStep = visibleSteps[newLastFinishedMainStep];
    updateLastFinishedStep(applicationId!, newLastFinishedMainStep, true, newFinishedMainStep.id); //Main step
    //Update inner step in database
    if (newLastFinishedInnerStep > -1) {
      const newInnerStepId = visibleSteps[mainStepForInnerStep].innerSteps![newLastFinishedInnerStep].id;
      updateLastFinishedStep(applicationId!, newLastFinishedInnerStep, false, newInnerStepId); //Inner step
    }

    return {
      ...state,
      lastFinishedInnerStepIndex: newLastFinishedInnerStep,
      lastFinishedMainStepIndex: newLastFinishedMainStep,
    };
  }
  return state;
};
const handleMainStepAnswer = (
  visibleSteps: mainStep[],
  answerStep: StepIds,
  state: StepperState,
  applicationId: ApplicationId | undefined
): StepperState => {
  const mainStepAnswerIndex = visibleSteps.findIndex((step) => step.id === answerStep);
  let currentMainStep = getCurrentMainStep(visibleSteps, window.location.pathname);
  const currentMainStepIndex = visibleSteps.findIndex((step) => step.id === currentMainStep?.id);
  if (mainStepAnswerIndex !== currentMainStepIndex || mainStepAnswerIndex === -1) {
    //Do not update state if answer is from another main step than the current one
    return state;
  }
  if (state.lastFinishedMainStepIndex >= mainStepAnswerIndex) {
    //Last finished main step is then the main step behind the answer step
    const newLastFinishedMainStep = mainStepAnswerIndex - 1;
    const newMainStepId = visibleSteps[newLastFinishedMainStep].id;
    updateLastFinishedStep(applicationId!, newLastFinishedMainStep, true, newMainStepId);
    return {...state, lastFinishedInnerStepIndex: -1, lastFinishedMainStepIndex: newLastFinishedMainStep};
  }
  return state;
};

const findInnerStepIndex = (visibleSteps: mainStep[], answerStep: StepIds): number => {
  let innerStepIndex: number = -1;
  //Er svaret på et inner step?
  visibleSteps.forEach((step) => {
    const index = step.innerSteps?.findIndex((innerStep) => innerStep.id === answerStep);
    if (index !== undefined && index > -1) {
      innerStepIndex = index;
    }
  });
  return innerStepIndex;
};

const getMainStepForInnerStep = (visibleSteps: mainStep[], answerStep: StepIds): number => {
  return visibleSteps.findIndex((s) => s.innerSteps?.find((innerStep) => innerStep.id === answerStep));
};

const getCurrentMainStep = (visibleSteps: mainStep[], windowPath: string): mainStep | undefined => {
  let mainStep: mainStep | undefined = undefined;
  visibleSteps.forEach((step) => {
    const pathIndex = step.paths?.findIndex((path) => {
      return path === windowPath;
    });
    if (pathIndex !== undefined && pathIndex > -1) {
      mainStep = step;
    }
  });
  return mainStep;
};
const getCurrentInnerStepIndex = (visibleSteps: mainStep[], windowPath: string): number | undefined => {
  let innerStep: number | undefined = undefined;
  visibleSteps.forEach((step) => {
    const pathIndex = step.paths?.findIndex((path) => {
      return path === windowPath;
    });
    if (pathIndex !== undefined && pathIndex > -1 && step.innerSteps) {
      innerStep = pathIndex;
    }
  });
  return innerStep;
};
