import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {StepIds} from '../../components/Steps/stepConfig';
import {AltinnDraftReference, SoknadType, SuppleringId} from '../../state/applicationState';
import {AttachmentId} from '../../state/attachmentsState';
import {InnsendingXmlId} from '../../state/innsendingState';
import {NabovarselXmlId} from '../../state/nabovarselState';
import {AnswerType, AnswerValues, innerStepId, mainStepId} from '../../state/questionTypes';
import {Teig, ValidAttachmentType} from '../../utils/dibkSchemas/models/models';
import {QuestionId} from '../utils/questionIds';
import {getAsyncAxiosInstanceWithIdportenHeader} from './axiosService';
import {IncompleteNeighbour} from './nabovarselService';
import {ApplicationId, AltinnReference} from './userOrderService';
import {useContext} from 'react';
import {UserContext} from '../../state/userStore';

export type CreateApplicationRequest = {
  Navn: string;
  ProduktKode: string;
  Email: string;
  QuestionAnswerPairs: QuestionAnswerPair[];
  Eiendomsident?: string;
  TeigGeometry?: string;
  KartverksId?: string;
  SkipNabovarsel: boolean;
};

export type QuestionAnswerPair = {
  Question: string;
  Answer: QuestionId;
};

export function createApplication(
  name: string,
  email: string,
  answerVaules: AnswerValues,
  produktKode?: string,
  teig?: Teig,
  kartverksId?: string
) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post<{SoknadId: ApplicationId}>(`/OpprettSoknad/`, {
      Navn: name,
      ProduktKode: produktKode,
      Email: email,
      QuestionAnswerPairs: mapAnswerValuesToAnswerRequest(answerVaules),
      Eiendomsident: teig?.propertyId,
      TeigGeometry: teig?.geometry !== undefined ? JSON.stringify(teig?.geometry) : undefined,
      KartverksId: kartverksId,
      SkipNabovarsel: answerVaules.SKIP_NABOVARSEL as boolean,
    } as CreateApplicationRequest)
  );
}

function mapAnswerValuesToAnswerRequest(answers: AnswerValues) {
  return Object.keys(answers)
    .map((key) => {
      const questionId: QuestionId = key as QuestionId;
      const answer = answers[questionId];
      if (answer !== undefined) {
        return {Question: questionId, Answer: answer} as QuestionAnswerPair;
      } else {
        return undefined;
      }
    })
    .filter((x) => x !== undefined) as QuestionAnswerPair[];
}

export const useChangeApplicationNameMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(({id, name}: {id: number; name: string}) => changeApplicationName(id, name), {
    onMutate: ({id, name}) => {
      queryClient.setQueryData<Application[]>(getApplicationsQueryKey, (oldData) => {
        return (oldData ?? []).map((a) => (a.Id === id ? {...a, Name: name} : a));
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(getApplicationsQueryKey);
    },
  });
};

export function changeApplicationName(id: number, name: string) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post<{Soknad: {id: number; name: string}}>(`/EndreSoknadNavn/`, {
      Id: id,
      NyttNavn: name,
    })
  );
}

export const useRemoveApplicationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteApplication(id).then((res) => res.data.Soknad), {
    onMutate: (id) => {
      queryClient.setQueryData<Application[]>(getApplicationsQueryKey, (oldData) => {
        return (oldData ?? []).filter((a) => a.Id !== id);
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(getApplicationsQueryKey);
    },
  });
};

export function deleteApplication(id: number) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post<{Soknad: {id: number; name: string}}>(`/SlettSoknad/`, {
      Id: id,
    })
  );
}
export type Application = {
  Id: ApplicationId;
  Name: string;
  PropertyId?: string;
  TeigGeometry?: string;
  NewBuildingGeometry?: string;
  LastFinishedMainStepIndex?: number;
  LastFinishedMainStepId?: string;
  LastFinishedInnerStepIndex?: number;
  LastFinishedInnerStepId?: string;
  SitasjonsplanOrderId?: string;
  ArCode?: AltinnDraftReference | AltinnReference;
  SoknadArCode?: AltinnDraftReference | AltinnReference;
  NabovarselXmlId?: NabovarselXmlId;
  SoknadXmlId?: InnsendingXmlId;
  SkipNabovarsel?: boolean;
  CreationDate?: string;
  FerdigattestArCode?: AltinnReference | AltinnDraftReference;
  HasMangel?: boolean;
  NabovarselDate?: string;
  KartverksId?: string;
};

export const getApplicationsQueryKey = ['getApplicationsQueryKey'];

export function useGetApplications() {
  const {userDispatch} = useContext(UserContext);

  const onGetApplicationsError = (err) => {
    if (err.response?.status === 401) {
      userDispatch({type: 'displayLoginErrorPopup', value: {reason: 'get all applications 401 unauthorized'}});
    } else {
      userDispatch({
        type: 'setSevereError',
        value: {
          msg: 'Vi klarte ikke å hente eksisterende søknader.',
        },
      });
    }
  };

  const query = useQuery(
    getApplicationsQueryKey,
    () => getApplications().then((res) => res.data.Soknader, onGetApplicationsError),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    applications: query.data ?? [],
    isLoadingApplications: query.isLoading,
    isFetchingApplications: query.isFetching,
  };
}

export async function getApplications() {
  const instance = await getAsyncAxiosInstanceWithIdportenHeader();
  return await instance.get<{Soknader: Application[]}>(`/HentSoknader/`);
}

export type ApplicationData = {
  Answers: {
    QuestionName: QuestionId;
    Value: string;
    AnswerType: AnswerType | undefined;
    Step: StepIds;
  }[];
  DemolishedBuildings: {
    demolishion_id: string;
    building_id: string;
    geometry: string;
    type: string;
    partial_demolishion: boolean;
    area: string;
  }[];
  Attachments: {
    Type: ValidAttachmentType;
    Name: string;
    Url: string;
    IncludeInNabovarsel: boolean;
    FileSize: number;
    AttachmentId: AttachmentId;
    QuestionId?: QuestionId;
    addedAutomatic: boolean;
    SoknadType: 'Supplering' | 'Byggesoknad' | 'Ferdigattest';
  }[];
  IncompleteNeighbours: IncompleteNeighbour[];
  NeighbourTeigs: {
    Geometry: string;
    PropertyId: string;
    PropertyIds: string;
    TeigId: number;
    GidText: string;
  }[];
};

export function getApplicationData(applicationId: number, soknadType: SoknadType, suppleringId?: SuppleringId) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.get<ApplicationData>(
      `/HentSoknadsData/?SoknadsId=${applicationId}&SoknadType=${soknadType}${
        suppleringId ? `&SuppleringId=${suppleringId}` : ''
      }`
    )
  );
}

export function updateLastFinishedStep(
  applicationId: ApplicationId,
  step: number,
  isMainStep: boolean,
  stepId: mainStepId | innerStepId
) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/OppdaterFullfortSteg/`, {
      SoknadId: applicationId,
      Steg: step,
      ErHovedsteg: isMainStep,
      StegId: stepId,
    })
  );
}

export function checkUserOwnsApplication(applicationId: ApplicationId) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/SjekkBrukerEierSoknad/`, {
      SoknadId: applicationId,
    })
  );
}

export type CurrentPropertyId = {
  CurrentPropertyId: string;
};

export async function getCurrentPropertyId(applicationId: ApplicationId) {
  const instance = await getAsyncAxiosInstanceWithIdportenHeader();
  return await instance.get<CurrentPropertyId>(`/GetCurrentPropertyId?SoknadId=${applicationId}`);
}
