import LoggutIcon from 'nka-icons/build/icons/LoggUt';
import CloseIcon from 'nka-icons/build/icons/Lukk';
import './UserActionsWindow.scss';

interface UserActionsWindowProps {
  onCloseWindow: () => void;
  onOpenWindow: () => void;
  logoutIdporten: () => void;
}

const UserActionsWindow = (props: UserActionsWindowProps) => {
  return (
    <div className='userActionsWindow' onMouseEnter={props.onOpenWindow} onMouseLeave={props.onCloseWindow}>
      <div className='userActions'>
        <div className='triangel_up' />
        <div className='box'>
          <button className='closeActionWindow' onClick={props.onCloseWindow} title='Lukk' tabIndex={0}>
            <CloseIcon />
          </button>
          <button className='user_btn' onClick={props.logoutIdporten}>
            <LoggutIcon />
            <p>Logg ut</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserActionsWindow;
