import {Lukk} from 'nka-icons';
import {Sok} from 'nka-icons';
import React from 'react';
import './ContentSearch.scss';

type Props = {onSearchChanged: (val: string) => void; id: string; contentName: string; placeholder?: string};
const ContentSearch = ({onSearchChanged, id, contentName, placeholder}: Props) => {
  const [searchText, setSearchText] = React.useState('');

  const inputSearchTextRef = React.useRef(searchText);
  inputSearchTextRef.current = searchText;

  React.useEffect(() => {
    registerUserTyping();
    /* eslint-disable */
  }, []);

  const registerUserTyping = () => {
    const inputElement: HTMLElement | null = document.getElementById(id);
    let timeout;
    if (inputElement) {
      inputElement!.onkeyup = (e) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          onSearchChanged(inputSearchTextRef.current);
        }, 500);
      };
    } else {
      console.error('Could not find element to listen for keystrokes');
    }
  };

  return (
    <div className='content_search_container' style={{marginBottom: 40}}>
      <input
        type='search'
        id={id}
        placeholder={placeholder || 'Hva lurer du på?'}
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        aria-label={`Søk i ${contentName}`}
      />
      <Sok size={1.5} />
      {searchText !== '' && (
        <button
          aria-label='Tøm søket'
          className='clear-search'
          onClick={() => {
            setSearchText('');
            onSearchChanged('');
          }}
        >
          <Lukk />
        </button>
      )}
    </div>
  );
};

export default ContentSearch;
