import {NewApplicationOptions} from './optionTypes';

export type roomEntranceIds = 'innvending' | 'annen_ingangsdør' | 'fellesareal';

export default {
  questionId: 'ROOM_ENTRANCE',
  options: [
    {
      value: 'innvending',
      text: 'Innvendig fra resten av boligen',
      helpText: 'Du kan gå til rommet uten å måtte ta på deg sko',
      //imageUrl: '/images/cardIcons/bruksendring_hoveddel.svg',
    },
    {
      //Ikke gyldig type, trigger med ansvarsrett
      value: 'fellesareal',
      text: 'Via fellesareal',
      helpText: 'Du kan møte på naboen når du skal til rommet',
      //imageUrl: '/images/cardIcons/bruksendring_hoveddel.svg',
    },
    {
      //Ikke gyldig type, trigger med ansvarsrett
      value: 'annen_ingangsdør',
      text: 'Gjennom egen inngangsdør',
      helpText: 'Du må gå ut av din egen boenhet for å komme deg til rommet',
      //imageUrl: '/images/cardIcons/bruksendring_hoveddel.svg',
    },
  ],
} as NewApplicationOptions;
