import {DemolishedBuilding} from '../state/buildingsState';
import {getParkingTerrainArea, getTotalAvailableArea} from './ugrad';
import {getExistingBuildingsArea} from './existingBuildingsArea';
import {AnswerValues} from '../state/questionTypes';

export default (answers: AnswerValues, demolishedBuildings?: DemolishedBuilding[]) => {
  const totalAvailable = getTotalAvailableArea(answers, demolishedBuildings);
  var totalUsed = getExistingBuildingsArea(answers) + getParkingTerrainArea(answers);
  return totalAvailable - totalUsed;
};
