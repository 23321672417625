import {getConfigValue} from '../config';
import {KommuneNummer} from '../utils/dibkSchemas/models/models';
import {Brand} from 'utility-types';

export type Kommuneinfo = {KommuneName: {nor: string}; KommuneNumber: KommuneNummer};

export type ProduktKode = Brand<string, 'produktKode'>;

export type Product = {
  BestillingsSkjema: string[];
  Betingelser: Betingelse[];
  ProduktKode: ProduktKode;
  Beskrivelse: string;
  Detaljert: string;
  Pris: number;
};

type Betingelse = {
  For: string[];
  SendtMed: string[];
  BegrensetTil?: {Liste: KommuneNummer[]};
};

export const getMunicipalityProduct = (
  availableProducts: Product[],
  chosenKommune: KommuneNummer
) => {
  return availableProducts.find((p) =>
    p.Betingelser.some((b) => b.BegrensetTil?.Liste.includes(chosenKommune))
  );
};
export const getMunicipalityPrice = (
  availableProducts: Product[],
  chosenKommune: KommuneNummer
): number | undefined => {
  return getMunicipalityProduct(availableProducts, chosenKommune)?.Pris ?? Number(getConfigValue('ebyggesokPrice'));
};
