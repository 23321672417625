import {tiltakTypeDescription} from '../../../../utils/tiltak/tiltakstypeDescriptions';
import {TiltaksTypeOptions} from '../optionTypes';
import {allTiltakstypeId} from '../../../../utils/tiltak/tiltaktypeIds';
import {TiltakQuestionOption} from '../../../../services/utils/QuestionTypes';

// OPPRETT TILTAKSTYPER

const allCreateOptions: {[key in allTiltakstypeId]?: TiltakQuestionOption} = {
  anleggseiendom: {
    value: 'anleggseiendom',
    text: tiltakTypeDescription.anleggseiendom.displayToUser,
    helpText: 'Jeg skal dele/fradele en anleggseiendom',
    //imageUrl: '/images/cardIcons/anleggseiendom.svg',
  },
  arealoverforing: {
    value: 'arealoverføring',
    helpText: 'Jeg skal overføre areal fra en eiendom til en annen, eller justere en eiendomsgrense',
    text: tiltakTypeDescription.arealoverføring.displayToUser,
    //imageUrl: '/images/cardIcons/arealoverføring.svg',
  },
  grunneiendom: {
    value: 'grunneiendom',
    helpText: 'Jeg skal dele/fradele en grunneiendom',
    text: tiltakTypeDescription.grunneiendom.displayToUser,
    //imageUrl: '/images/cardIcons/grunneiendom.svg',
  },
  festegrunn: {
    value: 'festegrunn',
    helpText: 'Jeg skal dele/fradele festegrunn',
    text: tiltakTypeDescription.festegrunn.displayToUser,
  },
  annet: {
    value: 'annet',
    text: 'Annet',
  },
};

const allOptions = [
  allCreateOptions.anleggseiendom,
  allCreateOptions.arealoverforing,
  allCreateOptions.grunneiendom,
  allCreateOptions.festegrunn,
  allCreateOptions.annet,
];

export default {
  questionId: 'TILTAK_TYPE_CREATE',
  bolig_options: allOptions,
  fritidsbolig_options: allOptions,
  annet_options: allOptions,
  garasje_options: allOptions,
  all_options: allOptions,
} as TiltaksTypeOptions;
