import LukkIcon from 'nka-icons/build/icons/Lukk';
import React, {useContext} from 'react';
import {StatusAlertId} from '../../services/ebyggesokServices/statusService';
import {StatusAlertContext} from '../../state/statusAlertState';
import NkTypography from '../NkTypography/NkTypography';
import './StatusAlertBanner.scss';

type Props = {statusType: 'Error' | 'Info' | 'Warning'; message: string; statusAlertId: StatusAlertId};

const StatusAlertBanner = (props: Props) => {
  const {statusAlertDispatch} = useContext(StatusAlertContext);

  const hideInfo = () => {
    statusAlertDispatch({type: 'hideStatusAlert', value: props.statusAlertId});
  };

  return (
    <div className={`StatusAlertBanner`} key={'status'}>
      <div
        className={`alert-content ${
          props.statusType === 'Error' ? 'error' : props.statusType === 'Warning' ? 'warning' : 'info'
        }`}
      >
        <div className='textContainer'>
          <NkTypography type='markdown'>{props.message}</NkTypography>
        </div>
        <button aria-label='Lukk info boks' onClick={hideInfo} id='close'>
          <LukkIcon />
        </button>
      </div>
    </div>
  );
};

export default StatusAlertBanner;
