import {appInsightsInstance} from '../AppInsightsSetup';
import {removeAnswers, saveAnswer} from '../services/ebyggesokServices/answerService';
import {updateLastFinishedStep} from '../services/ebyggesokServices/applicationService';
import {
  addOrUpdateDemolishedBuilding,
  removeDemolishedBuilding,
  saveBuilding,
  saveBuildingsite,
  saveKartverksId,
} from '../services/ebyggesokServices/locationService';
import {
  addNeighbourTeigs,
  removeNeighbourTeigs,
  setIncompleteNeighbours,
  updateManualNeighbour,
} from '../services/ebyggesokServices/nabovarselService';
import {ApplicationState, initialState, SuppleringId} from '../state/applicationState';
import {AddAnswerAction} from '../state/questionState';
import {IDispatchInput, State} from '../state/applicationStore';
import {requiresAnsvarsrett} from './triggerHelpers/ansvarsrett';
import isProd from './isProd';
import {requiresMelding} from './triggerHelpers/melding';
import {mainStepId, innerStepId} from '../state/questionTypes';
import {getAnswerValues} from './getAnswerValues';
import {ApplicationId} from '../services/ebyggesokServices/userOrderService';
import {getKartverksIdFromChosenAddress} from './addressProperty';

export const updateDatabase = async (
  applicationId: ApplicationId,
  suppleringId: SuppleringId | undefined,
  action: IDispatchInput,
  newState: State
) => {
  switch (action.type) {
    case 'addAnswer':
      const saveAnswerResponse = await saveAnswer(applicationId, action);
      // Do not need to reset nabovarsel, since it is created each time user navigates to summary.
      if (!newState.innsending.updateXml && newState.application.stepType === 'byggesoknad') {
        updateTriggeredStates(action.value.application, newState, action);
      }
      return saveAnswerResponse;
    case 'setDispNote':
      const dispAction: AddAnswerAction = {
        type: 'addAnswer',
        value: {
          id: 'DISP_NOTES',
          step: 'DISPENSASJON',
          application: action.value.application,
          answer: newState.questions.dispNotes,
          answerType: 'longText',
          dispatch: action.value.dispatch,
          userDispatch: action.value.userDispatch,
        },
      };
      return saveAnswer(applicationId, dispAction);
    case 'removeAnswer':
      const removeAnswerAction: AddAnswerAction = {
        type: 'addAnswer',
        value: {
          id: action.value.id,
          step: action.value.step,
          application: {applicationId: applicationId, ...initialState},
          answer: undefined,
          answerType: 'custom', // This value doesn't matter, we are deleting the answer
          dispatch: action.value.dispatch,
          userDispatch: action.value.userDispatch,
        },
      };
      updateTriggeredStates({applicationId: applicationId, ...initialState}, newState, action);
      if (!newState.innsending.updateXml || !newState.innsending.draftOutdated) {
        action.value.dispatch({type: 'setUpdateInnsendingXml', value: true});
        action.value.dispatch({type: 'setInnsendingDraftOutdated'});
      }
      return saveAnswer(applicationId, removeAnswerAction);
    case 'deactivateAnswer':
      // When deactivating answer we want to delete from db.
      const removeAction: AddAnswerAction = {
        type: 'addAnswer',
        value: {
          id: action.value.id,
          step: action.value.step,
          application: {applicationId: applicationId, suppleringId: suppleringId, ...initialState},
          answer: undefined,
          answerType: 'custom', // This value doesn't matter, we are deleting the answer.
          dispatch: action.value.dispatch,
          userDispatch: action.value.userDispatch,
        },
      };
      return saveAnswer(applicationId, removeAction);
    case 'clearAnswers':
      return removeAnswers(applicationId, action.ignoreSteps || []);
    case 'removeChosenTeig':
      return saveBuildingsite(applicationId, null, null);
    case 'setChosenTeig':
      return saveBuildingsite(applicationId, action.value.propertyId, action.value.geometry);
    case 'setLastFinishedMainStep':
      return updateLastFinishedStep(applicationId, action.value.stepIndex, true, action.value.stepId as mainStepId);
    case 'setLastFinishedInnerStep':
      return updateLastFinishedStep(applicationId, action.value.stepIndex, false, action.value.stepId as innerStepId);
    case 'addDemolishedBuiling':
    case 'updateDemolishedBuiling':
      return addOrUpdateDemolishedBuilding(applicationId, action.value);
    case 'removeDemolishedBuilding':
      return removeDemolishedBuilding(applicationId, action.value);
    case 'setNewBuilding':
      return saveBuilding(applicationId, action.value.geometry);
    case 'setChosenAddress':
      const addressId = action.value !== null && (await getKartverksIdFromChosenAddress(action.value));
      if (!action.value || !addressId) {
        if (!isProd())
          alert('TEST-BESKJED: Setter chosen address til null-verdi. Dette skal ikke skje, kontakt Andrea eller Thea');
        appInsightsInstance?.trackEvent({name: 'settingEmptyAddress databasestorage'});
      }
      if (addressId) {
        return saveKartverksId(applicationId, addressId);
      }
      return;
    case 'removeChosenAddress':
      return saveKartverksId(applicationId);
    case 'setIncompleteNeighbours':
      return setIncompleteNeighbours(applicationId, action.value);
    case 'removeNeighbour':
      return removeNeighbourTeigs(applicationId, [action.teigId]);
    case 'addNeighbours':
    case 'setNeighbours':
      return addNeighbourTeigs(applicationId, action.value);
    case 'updateManualNeighbour':
      return updateManualNeighbour(
        action.value.applicationId,
        action.value.updatedNeighbour,
        action.value.arCode,
        action.value.dispatch
      );
  }
};

const updateTriggeredStates = (application: ApplicationState, newState: State, action) => {
  const hasTriggeredAnsvarsrett = requiresAnsvarsrett(getAnswerValues(newState.questions.answers));
  if (hasTriggeredAnsvarsrett !== newState.questions.answers.ANSVARSRETT_TRIGGERED?.value) {
    const ansvarsrettAnswer: AddAnswerAction = {
      type: 'addAnswer',
      value: {
        id: 'ANSVARSRETT_TRIGGERED',
        step: 'WHAT_TO_DO',
        application,
        answer: hasTriggeredAnsvarsrett,
        answerType: 'radiobuttons',
        dispatch: action.value.dispatch,
        userDispatch: action.value.userDispatch,
      },
    };
    return saveAnswer(application.applicationId!, ansvarsrettAnswer);
  }
  const hasTriggeredMelding = requiresMelding(getAnswerValues(newState.questions.answers));
  if (hasTriggeredMelding !== newState.questions.answers.MELDING_TRIGGERED?.value) {
    const meldingAnswer: AddAnswerAction = {
      type: 'addAnswer',
      value: {
        id: 'MELDING_TRIGGERED',
        step: 'WHAT_TO_DO',
        application,
        answer: hasTriggeredMelding,
        answerType: 'radiobuttons',
        dispatch: action.value.dispatch,
        userDispatch: action.value.userDispatch,
      },
    };
    return saveAnswer(application.applicationId!, meldingAnswer);
  }
};
