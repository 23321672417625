import {Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery} from '@mui/material';
import NkTypography from '../../components/NkTypography/NkTypography';

type UnderleverandorTableItem = {
  name: string;
  purpose: string;
  dataSavingRegion: string;
  contact: string;
};
export const UnderleverandorTable = () => {
  const data: UnderleverandorTableItem[] = [
    {
      name: 'Zendesk',
      purpose: 'Kundestøtte',
      dataSavingRegion: 'EU',
      contact: 'Privacy Team and DPO, 989 Market Street, San Francisco, CA 94103, United States, euprivacy@zendesk.com',
    },
    {
      name: 'Nets',
      purpose: 'Håndtering av Vipps- og kortbetaling',
      dataSavingRegion: 'EU',
      contact: 'Klausdalsbrovej 601 DK-2750 Ballerup Denmark',
    },
    {
      name: 'Cookie Information',
      purpose: 'Informasjonskapsler og administrasjon av samtykke informasjonskapsler',
      dataSavingRegion: 'EU',
      contact: 'Købmagergade 19 1150 Copenhagen, Denmark',
    },
    {
      name: 'Microsoft',
      purpose: 'Skytjenester for å levere tjenesten og autentisering',
      dataSavingRegion: 'EU',
      contact:
        'Microsoft Ireland Operations Limited One Microsoft Place, South County Business Park, Leopardstown, Dublin 18 D18 P521',
    },
    {
      name: 'Digitaliseringsdirektoratet',
      purpose: 'Id-Porten innlogging',
      dataSavingRegion: 'EU',
      contact: 'Digitaliseringsdirektoratet avd. Oslo Postboks 1382 Vika 0114 OSLO',
    },
    {
      name: 'Cloudinary',
      purpose: 'Håndtering av mediafiler',
      dataSavingRegion: 'USA',
      contact: 'Shir Kodner General Counsel Cloudinary Inc 3400 Central Expressway, Suite 110 Santa Clara, CA 95051',
    },
  ];

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const rowStyling = {
    td: {padding: isSmallScreen ? '4px' : '12px 16px'},
    border: '1px solid rgb(220, 220, 220)',
  };

  return (
    <Stack gap='20px'>
      <NkTypography tag='h2' type='heading2'>
        Våre underleverandører for databehandling
      </NkTypography>

      <TableContainer>
        <Table sx={{maxWidth: '700px'}} aria-label='underleverandører table'>
          <TableHead>
            <TableRow sx={rowStyling}>
              <TableCell>Navn</TableCell>
              <TableCell>Formål</TableCell>
              <TableCell>Datalagringsregion</TableCell>
              <TableCell>Kontakt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow key={row.name} sx={rowStyling}>
                  <TableCell component='th' scope='row'>
                    {row.name}
                  </TableCell>
                  <TableCell>{row.purpose}</TableCell>
                  <TableCell>{row.dataSavingRegion}</TableCell>
                  <TableCell>{row.contact}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
