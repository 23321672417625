interface Config {
  altinnApiKey?: string;
  altinnUrl?: string;
  bygningUrl?: string;
  datavarehusUrl?: string;
  kodelisteUrl?: string;
  matrikkelGrunnbokUrl?: string;
  matrikkelKartUrl?: string;
  mapboxStyleUrl?: string;
  mapboxPlanmapStyleUrl?: string;
  mapboxToken?: string;
  nabolisteUrl?: string;
  nabovarselJs?: string;
  nabovarselCss?: string;
  norkartApiKey?: string;
  kommuneinfoUrl?: string;
  serverUrl?: string;
  isProd?: string;
  wmsOrtoUrl?: string;
  statiskKartUrl?: string;
  tjenestekatalogenUrl?: string;
  profile?: string;
  minEiendomUrl?: string;
  tjenesteKatalogAppId?: string;
  customerIdInnsendingstjenesten?: string;
  applicationInsightsKey?: string;
  easyCheckoutKey?: string;
  easyMerchantId?: string;
  easyScriptUrl?: string;
  ebyggesokPrice?: string;
  coordsApi?: string;
}

const config: Config = {
  altinnApiKey: getEnvValue('REACT_APP_ALTINN_API_KEY'),
  altinnUrl: getEnvValue('REACT_APP_ALTINN_URL'),
  datavarehusUrl: getEnvValue('REACT_APP_DATAVAREHUS_URL_NEW'),
  matrikkelGrunnbokUrl: getEnvValue('REACT_APP_MATRIKKEL_GRUNNBOK_URL'),
  matrikkelKartUrl: getEnvValue('REACT_APP_MATRIKKEL_KART_URL'),
  mapboxStyleUrl: getEnvValue('REACT_APP_MAPBOX_STYLE_URL'),
  mapboxPlanmapStyleUrl: getEnvValue('REACT_APP_MAPBOX_STYLE_URL_PLANKART'),
  mapboxToken: getEnvValue('REACT_APP_MAPBOX_TOKEN'),
  norkartApiKey: getEnvValue('REACT_APP_NORKART_API_KEY'),
  kommuneinfoUrl: getEnvValue('REACT_APP_KOMMUNEINFO'),
  serverUrl: getEnvValue('REACT_APP_SERVER_URL'),
  isProd: getEnvValue('REACT_APP_IS_PROD'),
  wmsOrtoUrl: getEnvValue('REACT_APP_WMS_ORTO_URL'),
  statiskKartUrl: getEnvValue('REACT_APP_STATISK_KART_URL'),
  profile: getEnvValue('REACT_APP_WAAPI_PROFILE'),
  tjenestekatalogenUrl: getEnvValue('REACT_APP_TJENESTEKATALOG_URL'),
  tjenesteKatalogAppId: getEnvValue('REACT_APP_TJENESTEKATALOG_APPID'),
  customerIdInnsendingstjenesten: getEnvValue('REACT_APP_CUSTOMER_ID_INNSENDINGSTJENESTEN'),
  applicationInsightsKey: getEnvValue('REACT_APP_APPLICATION_INSIGHTS_KEY'),
  easyCheckoutKey: getEnvValue('REACT_APP_EASY_PAYMENT_KEY'),
  easyMerchantId: getEnvValue('REACT_APP_MERCHANT_ID'),
  easyScriptUrl: getEnvValue('REACT_APP_EASY_SCRIPT_URL'),
  ebyggesokPrice: getEnvValue('REACT_APP_EBYGGESOK_PRICE'),
  coordsApi: getEnvValue('REACT_APP_COORDS_API'),
};

type ConfigKeys = keyof Config;

export function setConfigItem(key: ConfigKeys, value: string) {
  config[key] = value;
}

export function getConfigValue(key: ConfigKeys): string {
  const configValue = config[key];
  if (!configValue) {
    console.warn('Config value for key: ' + key + ' is missing value');
  }
  return configValue || '';
}

function getEnvValue(key: string, parseToBoolean?: boolean): string | undefined {
  const val = process.env[key];
  if (val && !parseToBoolean) {
    return val;
  } else {
    console.warn(key + ' is missing');
    return;
  }
}
