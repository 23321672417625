import React from 'react';
import Search, {AddressSearchResultItem, matrikkelSource} from '../../components/Search/search';
import {PropertyObject} from '../../state/buildingSiteState';
import createReadableMatrikkelnummer, {parseEiendomsident} from '../../services/utils/createReadableMatrikkelnummer';
import {
  MatrikkelnummerObject,
  AdresseFromServer,
  getStreetAddressesFromProperty,
} from '../../services/matrikkelgrunnbok';
import {SelectedPropertyOption, MatrikkelNummer} from '../../utils/dibkSchemas/models/models';

type Props = {
  onSelectedProperty: (property: PropertyObject, fromSearch: boolean) => any;
};

const PropertySearch = ({onSelectedProperty}: Props) => {
  const onChoseProperty = (source: matrikkelSource) => {
    getAdressList(source.MatrikkelNummer, (adresseObjects: AdresseFromServer[]) => {
      const mnrObj: MatrikkelnummerObject = parseEiendomsident(source.MatrikkelNummer);
      const readableMnr = createReadableMatrikkelnummer(mnrObj);
      const displayValue = readableMnr as SelectedPropertyOption;
      const propobj: PropertyObject = {
        readableMnr: readableMnr,
        propertyId: source.MatrikkelNummer,
        displayValue: displayValue,
        isBorettslag: false,
        knr: mnrObj.Kommunenummer,
        kommunenavn: source.KommuneNavn,
        adressList: adresseObjects,
      };
      onSelectedProperty(propobj, true);
    });
  }; // Matrikkelident.
  const onChoseAddress = (address: AddressSearchResultItem) => {
    getAdressList(address.AdresseMatrikkelenhetId, (adresseObjects: AdresseFromServer[]) => {
      const mnrObj: MatrikkelnummerObject = parseEiendomsident(address.AdresseMatrikkelenhetId);
      const readableMnr = createReadableMatrikkelnummer(mnrObj);
      const displayValue = readableMnr as SelectedPropertyOption;
      const propobj: PropertyObject = {
        readableMnr: readableMnr,
        propertyId: address.AdresseMatrikkelenhetId,
        displayValue: displayValue,
        isBorettslag: false,
        knr: mnrObj.Kommunenummer,
        kommunenavn: address.KommuneNavn,
        adressList: adresseObjects,
      };
      onSelectedProperty(propobj, true);
    });
  };

  async function getAdressList(mnr: MatrikkelNummer, callback: (adress: AdresseFromServer[]) => void) {
    let addressResp = await getStreetAddressesFromProperty(mnr);
    callback(addressResp.data.Adresser);
  }

  return <Search placeholderText='Søk opp eiendom' onChoseAddress={onChoseAddress} onChoseProperty={onChoseProperty} />;
};

export default PropertySearch;
