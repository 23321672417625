import {Box, SxProps} from '@mui/material';

enum MarginY {
  sm = 20,
  md = 40,
  lg = 60,
  xl = 80,
}

type SectionProps = {
  children: React.ReactNode;
  sx?: SxProps;
  marginY?: keyof typeof MarginY;
};

export const Section = ({children, sx, marginY = 'lg'}: SectionProps) => {
  return (
    <Box sx={{my: `${MarginY[marginY]}px`, ...sx}} component='section'>
      {children}
    </Box>
  );
};
