export const nkColors = {
  dark_grey: '#4d4d4d',
  light_grey_disabled: '#948f8f',
  norkart_sort: '#252527',
  norkart_gronn: ' #51a026',
  uu_norkart_gronn: '#1e7400',
  norkart_bla: '#87a8e0',
  norkart_orange: '#f5ad4a',
  norkart_hvit: '#f6f6f6',
  norkart_rod: '#e25653',
  red_uu: '#c43835', // UU ok on white background
  advarsel_rod: '#e32',
  lighter_green: '#d3e5ca',
  background_green: '#eff5f1',
  testEnvColor: '#A50365',
  background_grey: '#fafafa',
};
