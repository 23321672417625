import {getConfigValue} from '../../config';
import {bufferzoneForDatasets} from '../../services/datavarehus';
import {NabovarselStatus} from '../../services/ebyggesokServices/nabovarselService';
import {addressFromServerToText} from '../../services/matrikkelgrunnbok';
import {Attachment, removeAttachmentsBelongingToDeactivatedAnswers} from '../../state/attachmentsState';
import {BuildingSiteState} from '../../state/buildingSiteState';
import {ExistingBuilding} from '../../state/buildingsState';
import {NabovarselState as NeighbourListState} from '../../state/nabovarselState';
import {QuestionsState as QuestionState} from '../../state/questionState';
import {Answers, AnswerValues} from '../../state/questionTypes';
import {Personnummer} from '../../state/userStore';
import {getExistingBuildingsArea} from '../existingBuildingsArea';
import {getAnswerValues} from '../getAnswerValues';
import {createValidSubmitAttachment, getMerknadAttachments} from '../getMerknadAttachments';
import getSelectedTiltakstyper from '../tiltak/getSelectedTiltakstyper';
import {tiltaksformaalId} from '../tiltak/tiltaksformaal';
import {tiltakTypeDescription} from '../tiltak/tiltakstypeDescriptions';
import {tiltaktypeUaId} from '../tiltak/tiltaktypeIds';
import {getParkingTerrainArea, getTotalAvailableArea, ugradOption} from '../ugrad';
import {ugradOptionToKode} from '../ugradOptionToKode';
import {validPlanType, validPlanTypes} from './models/models';
import {Kodetype, TiltakBruk} from './models/nabovarsel';
import {
  AndrePlanerV3,
  Arealdisponering,
  Avloep,
  KravTilByggegrunn,
  PlasseringV3,
  TiltakUAnsvarSchemaV3,
  Vannforsyning,
  VannforsyningMapping,
  Varsling,
  VegtypeMappingBeskrivelse,
  VegtypeMappingValue,
} from './models/sendSoknadTypes';
import {determineGjeldendePlantype, getDispensasjon, getFormaal, getGjeldendePlan, getSoeker} from './nabovarsel';

export const toSoknadXmlInput = (
  buildingsiteState: BuildingSiteState,
  naboState: NeighbourListState,
  attachments: Attachment[],
  questionState: QuestionState,
  userId: Personnummer
): TiltakUAnsvarSchemaV3 => {
  return toSoknadXmlInputV3(naboState, attachments, questionState, buildingsiteState, userId);
};

export const toSoknadXmlInputV3 = (
  naboState: NeighbourListState,
  attachments: Attachment[],
  questionState: QuestionState,
  buildingsiteState: BuildingSiteState,
  userId: Personnummer
): TiltakUAnsvarSchemaV3 => {
  const answerValues = getAnswerValues(questionState.answers);
  const skipVarsel = answerValues.SKIP_NABOVARSEL;

  const address = buildingsiteState.chosenAddress
    ? {
        text: addressFromServerToText(buildingsiteState.chosenAddress),
        postCode: buildingsiteState.chosenAddress.Postnummer,
        postPlace: buildingsiteState.chosenAddress.Poststed,
      }
    : undefined;

  const customerId = getConfigValue('customerIdInnsendingstjenesten');

  return {
    KundeId: customerId,
    Adresse: address,
    Dispensasjoner: getDispensasjon(answerValues),
    Hovedeiendom: [buildingsiteState.chosenTeig!.propertyId][0],
    Kommunenavn: answerValues.MUNICIPALITY_NAME as string,
    Tiltakshaver: getSoeker(answerValues, userId),
    Vedlegg: getVedleggSoknad(attachments, questionState.answers, naboState.neighbourAnswers),
    Lagre: true,
    BeskrivelseAvTiltak: {
      bruk: getBeskrivelseAvTiltakV3(answerValues).bruk,
      type: {type: getBeskrivelseAvTiltakV3(answerValues).type},
      foelgebrev:
        (answerValues.FOELGEBREV as string | undefined) || 'Nærmere beskrivelse kan du lese i vedlagt følgebrev.',
    },
    Bygningsnummer:
      answerValues.BUILDING_TO_CHANGE !== undefined
        ? (answerValues.BUILDING_TO_CHANGE as any as ExistingBuilding).BuildingId
        : undefined,
    SoekesOmDispensasjon: getDispensasjon(answerValues).length > 0,
    Varsling: getVarsling(answerValues, skipVarsel),
    Rammebetingelser: {
      plan: {
        gjeldendePlan: {
          beregningsregelGradAvUtnytting: answerValues.UGRAD_DEF
            ? ugradOptionToKode(answerValues.UGRAD_DEF as ugradOption)
            : undefined,
          navn: getGjeldendePlan(answerValues)?.navn,
          plantype: getGjeldendePlan(answerValues)?.planType,
          //utnyttingsgrad: 0,
        },
        andrePlaner: getAndrePlaner(answerValues),
      },
      arealdisponering: getArealDisponering(answerValues),
      plassering: getPlasseringV3(answerValues),
      adkomst: {
        nyeEndretAdkomst: answerValues.NY_ENDRET_AVKJØRSEL as boolean,
        vegrett: [
          {
            erTillatelseGitt: (answerValues.TYPE_NY_ENDRET_AVKJØRSEL_TILLATELSE as string) === 'TillatelseVedlegges',
            vegtype: {
              kodeverdi: VegtypeMappingValue[answerValues.VEG_TILKNYTTET_AVKJORSEL as string],
              kodebeskrivelse: VegtypeMappingBeskrivelse[answerValues.VEG_TILKNYTTET_AVKJORSEL as string],
            },
          },
        ],
      },
      avloep: createAvlopObject(answerValues),
      vannforsyning: createVannForsyningObject(answerValues),
      kravTilByggegrunn: getKravTilByggegrunn(answerValues),
      generelleVilkaar: {norskSvenskDansk: true, norskSvenskDanskSpecified: true},
    },
  };
};

const createVannForsyningObject = (answerValues: AnswerValues): Vannforsyning | undefined => {
  if (answerValues.VANNFORSYNING_AT_PROPERTY === undefined) return;

  const vannforsyning: Vannforsyning = {
    tilknytningstype: {
      kodebeskrivelse: VannforsyningMapping[answerValues.VANNFORSYNING_AT_PROPERTY as string],
      kodeverdi: answerValues.VANNFORSYNING_AT_PROPERTY as string,
    },
  };
  if (answerValues.VANNFORSYNING_DESCRIPTION !== undefined) {
    vannforsyning.beskrivelse = answerValues.VANNFORSYNING_DESCRIPTION as string;
  }
  if (answerValues.VANNLEDNING_CROSSING_OTHER_PROPERTY !== undefined) {
    vannforsyning.krysserVannforsyningAnnensGrunn = answerValues.VANNLEDNING_CROSSING_OTHER_PROPERTY as boolean;
  }
  if (answerValues.VANNLEDNING_TINGLYST_OTHER_PROPERTY !== undefined) {
    vannforsyning.tinglystErklaering = answerValues.VANNLEDNING_TINGLYST_OTHER_PROPERTY as boolean;
  }
  return vannforsyning;
};

const createAvlopObject = (answerValues: AnswerValues): Avloep => {
  const avlop: Avloep = {
    tilknytningstype: {},
    overvannTerreng: false,
  };
  if (answerValues.TAKVANN_OVERVANN_TERRENG !== undefined) {
    avlop.overvannTerreng = answerValues.TAKVANN_OVERVANN_TERRENG as boolean;
  }
  if (answerValues.OVERVANN_EXISTING_AVLOPSSYSTEM !== undefined) {
    avlop.overvannAvloepssystem = answerValues.OVERVANN_EXISTING_AVLOPSSYSTEM as boolean;
  }
  if (answerValues.AVLOP_TILKNYTNING) {
    avlop.tilknytningstype = avlopTilknytningstype(answerValues);
  }
  if (answerValues.AVLOP_TINGLYST_ERKLAERING !== undefined) {
    avlop.tinglystErklaering = answerValues.AVLOP_TINGLYST_ERKLAERING as boolean;
  }
  if (answerValues.AVLOP_INSTALLERE_VANNKLOSSET !== undefined) {
    avlop.installereVannklosett = answerValues.AVLOP_INSTALLERE_VANNKLOSSET as boolean;
  }
  if (answerValues.AVLOP_UTSLIPPSTILLATELSE !== undefined) {
    avlop.utslippstillatelse = answerValues.AVLOP_UTSLIPPSTILLATELSE as boolean;
  }
  return avlop;
};

const avlopTilknytningstype = (answerValues: AnswerValues): Kodetype => {
  return {
    kodeverdi: answerValues.AVLOP_TILKNYTNING as string,
    kodebeskrivelse: answerValues.AVLOP_TILKNYTNING as string,
  };
};

const getAndrePlaner = (answerValues: AnswerValues): AndrePlanerV3[] => {
  const existingPlans: validPlanType[] = answerValues.EXISTING_PLANS as validPlanType[];
  if (!existingPlans || existingPlans.length === 0) return [];
  const gjeldendePlanType: validPlanType = determineGjeldendePlantype(existingPlans);
  const andrePlaner: validPlanType[] = existingPlans.filter((p) => p !== gjeldendePlanType);
  if (andrePlaner.length > 0) {
    return andrePlaner.map((plan) => {
      const planNavnQId = plan + '_NAME_ID';
      return {
        planType: {
          kodeverdi: plan as validPlanType,
          kodebeskrivelse: validPlanTypes[plan as validPlanType] as string,
        },
        navn: answerValues[planNavnQId] as string,
      };
    });
  } else {
    return [];
  }
};

const getPlasseringV3 = (answerValues: AnswerValues): PlasseringV3 | undefined => {
  return {
    bekreftetInnenforByggegrense: answerValues.IS_WITHIN_BUILDING_BORDER === true ? true : false,
    konfliktHoeyspentkraftlinje: answerValues.IN_CONFLICT_WITH_POWERLINE as boolean,
    konfliktVannOgAvloep: answerValues.VA_AFFECTED as boolean,
    minsteAvstandNabogrense: answerValues.DISTANCE_TO_NEIGHBOURS_BORDER as number,
    minsteAvstandTilAnnenBygning: getMinsteAvstandTilAnnenBygning(answerValues),
    minsteAvstandTilMidtenAvVei: getMinsteAvstandTilVeiValue(answerValues),
  };
};

const getMinsteAvstandTilAnnenBygning = (answerValues: AnswerValues) => {
  return (answerValues.BUILDING_DISTANCE_ANY_PROPERTY as number) || bufferzoneForDatasets;
};

const getMinsteAvstandTilVeiValue = (answers: AnswerValues) => {
  const hasRoadQuestions = answers.PLANER_HAS_RULES_FOR_DIST_TO_ROAD !== undefined;
  if (!hasRoadQuestions) return undefined;
  if (
    answers.HAS_FULFILLED_DISTANCE_GIVEN_IN_PLANER_TO_FYLKESVEG === false &&
    answers.HAS_FULFILLED_DISTANCE_GIVEN_IN_PLANER_TO_RIKSVEG === false &&
    answers.HAS_FULFILLED_DISTANCE_GIVEN_IN_PLANER_TO_KOMMUNALVEG === false &&
    answers.HAS_FULFILLED_DISTANCE_GIVEN_IN_PLANER_TO_SYKKEL_GANGVEG === false
  ) {
    // All road distances OK, setting high value that is always valid.
    return 100;
  } else {
    return -1; // Setting dummy value to let kommune know to ignore this value. Adding extra info in attachment.
  }
};

export const getVarsling = (answerValues: AnswerValues, fritattFraNabovarsling): Varsling => {
  if (fritattFraNabovarsling) {
    return {
      fritattFraNabovarsling: true,
    };
  }
  const antallMerknader = answerValues.NABOMERKNAD_COUNT as number;
  return {
    antallMerknader: antallMerknader ? antallMerknader : 0,
    foreliggerMerknader: antallMerknader > 0,
    fritattFraNabovarsling: false,
    vurderingAvMerknader: antallMerknader > 0 ? (answerValues.NABOMERKNAD_ANSWER as string) : undefined,
  };
};

const getKravTilByggegrunn = (answers: AnswerValues): KravTilByggegrunn | undefined => {
  const affectedByTruedeVernedeArter =
    (answers.AFFECTED_BY_VERNEDE_ARTER as boolean) && answers.CHOSEN_HANDLING_OF_TRUEDE_VERNEDE_ARTER !== 'skip';

  return {
    flomutsattOmraade: (answers.AFFECTED_BY_FLOM as boolean) && answers.CHOSEN_HANDLING_OF_AFFECTED_BY_FLOM !== 'skip',
    miljoeforhold: affectedByTruedeVernedeArter,
    skredutsattOmraade:
      (answers.AFFECTED_BY_SKRED as boolean) && answers.CHOSEN_HANDLING_OF_AFFECTED_BY_SKRED !== 'skip',
  };
};

export const getArealDisponering = (answerValues: AnswerValues): Arealdisponering | undefined => {
  // Rive skal ikke inneholde arealdisponering.
  if (answerValues.TILTAK_TYPE_DEMOLISH !== undefined) return;

  const arealBebyggelseEksisterende = getExistingBuildingsArea(answerValues);
  const selectedUgradBYA = answerValues.UGRAD_CALCULATE_BYA;
  const tiltakAreaValue = selectedUgradBYA
    ? (answerValues.TILTAK_AREA_VALUE_BYA as number | undefined)
    : (answerValues.TILTAK_AREA_VALUE_BRA as number | undefined);
  if (tiltakAreaValue === undefined) {
    // When we have no new area it is not neccessary to add arealdisponering to pdf
    return {};
  }
  const arealBebyggelseNytt = tiltakAreaValue || 0;
  const arealBebyggelseSomSkalRives = 0; // Change when tiltakstype rive is added
  const arealSumByggesak = arealBebyggelseEksisterende + arealBebyggelseNytt - arealBebyggelseSomSkalRives;

  const arealDisponering: Arealdisponering = {
    arealBebyggelseEksisterende: arealBebyggelseEksisterende,
    arealBebyggelseNytt: arealBebyggelseNytt,
    arealBebyggelseSomSkalRives: arealBebyggelseSomSkalRives,
    arealSumByggesak: arealSumByggesak,
    beregnetMaksByggeareal: getTotalAvailableArea(answerValues),
    parkeringsarealTerreng: getParkingTerrainArea(answerValues),
    beregnetGradAvUtnytting: calculateBeregnetGradAvUtnyttingValue(
      arealBebyggelseEksisterende,
      arealBebyggelseSomSkalRives,
      arealBebyggelseNytt,
      getParkingTerrainArea(answerValues),
      answerValues.TILLATT_TOMTEAREAL_WHEN_PERCENT_UGRAD as number,
      answerValues.UGRAD_LIMIT_PERCENT as number,
      answerValues
    ),
  };
  if (answerValues.UGRAD_LIMIT_PERCENT !== undefined) {
    arealDisponering.tomtearealByggeomraade = answerValues.TOMTEAREAL as number | undefined;
    arealDisponering.tomtearealBeregnet = answerValues.TILLATT_TOMTEAREAL_WHEN_PERCENT_UGRAD as number | undefined;
    arealDisponering.tomtearealSomTrekkesFra = answerValues.KVM_IKKE_TILLATT_Å_UTNYTTE as number | undefined;
  }
  return arealDisponering;
};

const calculateBeregnetGradAvUtnyttingValue = (
  arealBebyggelseEksisterende: number,
  arealBebyggelseSomSkalRives: number,
  arealBebyggelseNytt: number,
  parkeringsarealTerreng: number,
  tomtearealBeregnet: number,
  ugrad_percent: number,
  answerValues: AnswerValues
) => {
  // -- Utregningen er med m2 som utnyttning:
  const calculatedGradAvUtnytting =
    arealBebyggelseEksisterende - arealBebyggelseSomSkalRives + arealBebyggelseNytt + parkeringsarealTerreng;

  if (ugrad_percent !== undefined && tomtearealBeregnet !== undefined) {
    // -- Utregningen er med % som utnyttning:
    // (arealBebyggelseEksisterende - arealBebyggelseSomSkalRives + arealBebyggelseNytt + parkeringsarealTerreng)/(tomteareal) x 100

    //Hvis case 2 så del på hele tomtearealet (se figma)
    const allowedToBuildInNotBoligformalArea =
      answerValues.ALLOWED_TO_BUILD_OUTSIDE_BOLIGFORMAAL !== undefined &&
      answerValues.ALLOWED_TO_BUILD_OUTSIDE_BOLIGFORMAAL !== false;
    const noBoligformaalAtProperty = answerValues.AREAL_FORMAAL_COLOR_IS_YELLOW === false;
    if (!allowedToBuildInNotBoligformalArea && noBoligformaalAtProperty) {
      // DISP and no bebygd formål
      if (answerValues.TOMTEAREAL === 0) {
        return 0;
      }
      return (calculatedGradAvUtnytting / (answerValues.TOMTEAREAL as number)) * 100;
    }
    if (tomtearealBeregnet === 0) {
      return 0;
    } else {
      return (calculatedGradAvUtnytting / tomtearealBeregnet) * 100;
    }
  }
  return calculatedGradAvUtnytting;
};

export const getVedleggSoknad = (
  attachments: Attachment[],
  answers: Answers,
  neighbourAnswers?: NabovarselStatus
): TiltakUAnsvarSchemaV3['Vedlegg'] => {
  const relevantAttachments = removeAttachmentsBelongingToDeactivatedAnswers(attachments, answers);
  const uploadedAttachments = relevantAttachments.map((a) => {
    return {
      Url: a.url,
      TypeVedlegg: a.type,
      Filnavn: a.name,
      AttachmentId: a.attachmentId!,
    };
  });
  const merknadAttachments = neighbourAnswers
    ? getMerknadAttachments(neighbourAnswers, createValidSubmitAttachment)
    : [];
  return merknadAttachments ? uploadedAttachments.concat(merknadAttachments) : uploadedAttachments;
};

const getBeskrivelseAvTiltakV3 = (answerValues: AnswerValues): {bruk: TiltakBruk; type: Kodetype} => {
  const selectedTiltakstyper = getSelectedTiltakstyper(answerValues) as tiltaktypeUaId[];
  const selectedTiltaksformaal = answerValues.TILTAK_FORMAAL as tiltaksformaalId[] | tiltaksformaalId | undefined;

  let tiltakObj = {
    bruk: getFormaal(selectedTiltaksformaal),
    type: {
      kodeverdi: selectedTiltakstyper[0],
      kodebeskrivelse: tiltakTypeDescription[selectedTiltakstyper[0]].officialValue,
    },
  };

  if (answerValues.ANNET_DESCRIPTION) {
    tiltakObj.bruk = {
      ...tiltakObj.bruk,
      beskrivPlanlagtFormaal: answerValues.ANNET_DESCRIPTION as string,
    };
  }
  return tiltakObj;
};
