import * as React from 'react';
import './search.scss';
import NkmSearch from 'nkm-norkart-search';
import 'nkm-norkart-search/dist/utils/style.css';
import {getConfigValue} from '../../config';
import {MatrikkelNummer} from '../../utils/dibkSchemas/models/models';
import {getResult} from 'nkm-norkart-search/dist/utils/resultFunctions';
import {subSearch} from 'nkm-norkart-search/dist/utils/subSearchFunctions';

const searchTypes = {
  PROPERTY: 'matrikkelenhet',
  STREET: 'gate',
  STREET_ADDRESS: 'gateadresse',
};

interface SearchInterface {
  placeholderText: string;
  onChoseProperty: (propertyId: matrikkelSource) => any; //matrikkelident
  onChoseAddress: (address: AddressSearchResultItem) => any;
  limits?: string[];
}

export interface AddressSearchResultItem {
  AdresseHusNummer: number;
  AdresseBokstav: string;
  AdresseMatrikkelenhetId: MatrikkelNummer;
  GateId: string;
  GateNavn: string;
  KommuneNavn: string;
  Posisjon: {
    X: number;
    Y: number;
  };
  PostNummer: string;
  PostSted: string;
  VegAdresseId: string;
}

export type SearchResponse = {
  Posisjon: any;
  properties: SearchProps;
};

export type SearchProps = {
  Id: string;
  Source: addressSource | matrikkelSource;
  Text: string;
  Type: 'gateadresse' | 'matrikkelenhet';
};

export type addressSource = {
  AdresseAlternativtNavn: string;
  AdresseBokstav: string;
  AdresseHusNummer: number;
  AdresseId: string;
  AdresseKoordinatKvalitetKode: number;
  AdresseMatrikkelenhetId: MatrikkelNummer;
  FylkeNavn: string;
  FylkeNummer: number;
  GateId: string;
  GateNavn: string;
  KommuneNavn: string;
  KommuneNummer: number;
  Posisjon: {X: number; Y: number; SRS: number};
  PostNummer: string;
  PostSted: string;
  Text: string;
  VegAdresseId: string;
};

export type matrikkelSource = {
  BruksNavn: string;
  BruksNummer: number;
  FesteNummer: number;
  FylkeNavn: string;
  FylkeNummer: number;
  GardsNummer: number;
  KommuneNavn: string;
  KommuneNummer: number;
  MatrikkelEnhetsType: string;
  MatrikkelNummer: MatrikkelNummer;
  Posisjon: {X: number; Y: number; SRS: number};
  SeksjonsNummer: number;
  Text: string;
  UnderNummer: number;
  Utgatt: string;
};

// Type guards.
const isMatrikkelSource = (
  source: addressSource | matrikkelSource,
  type: SearchProps['Type']
): source is matrikkelSource => type === 'matrikkelenhet';
const isAdressSource = (source: addressSource | matrikkelSource, type: SearchProps['Type']): source is addressSource =>
  type === 'gateadresse';

function Search({placeholderText, onChoseProperty, onChoseAddress, limits}: SearchInterface) {
  const onHitSelected = (result: SearchResponse) => {
    const source = result.properties.Source;
    const type = result.properties.Type;
    if (isMatrikkelSource(source, type)) {
      onChoseProperty(source);
    } else if (isAdressSource(source, type)) {
      onChoseAddress(source);
    }
  };

  return (
    <div className={'searchContainer'}>
      <NkmSearch
        apiKey={getConfigValue('norkartApiKey')}
        placeholder={placeholderText}
        closeOnSelect={true}
        subSearch={subSearch}
        targets={[searchTypes.PROPERTY, searchTypes.STREET, searchTypes.STREET_ADDRESS]}
        numResults={10}
        hitSelected={(event) => {
          const res: SearchResponse = getResult(event);
          onHitSelected(res);
        }}
        limits={limits}
      />
    </div>
  );
}

export default Search;
