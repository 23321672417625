// https://michalzalecki.com/nominal-typing-in-typescript/#approach-4-intersection-types-and-brands
export type Brand<A, Brand> = A & {__brand: Brand};
export type MatrikkelNummer = Brand<string, 'MatrikkelNummer'>;
export type SelectedPropertyOption = Brand<string, 'SelectedPropertyOption'>;
export type OrganisasjonsNummer = Brand<string, 'OrganisasjonsNummer'>;
export type KommuneNummer = Brand<string, 'KommuneNummer'>;

export interface NorthEast {
  north: number;
  east: number;
}

export interface PixelPoint {
  x: number;
  y: number;
}

export interface Teig {
  geometry: GeoJSON.Polygon;
  propertyId: string;
  teigId?: number;
}

export interface NaboTeig {
  geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon;
  propertyIds: string[];
  teigId: number;
  gidText: string;
}

export interface StreetAddressWithOptionallyCoord {
  text: string;
  postCode: string;
  postPlace: string;
  coordinate?: NorthEast;
}

export interface StreetAddress extends StreetAddressWithOptionallyCoord {
  coordinate: NorthEast;
}

export interface PromiseReject {
  failedFunction: string;
  error: any;
  more?: any;
}

// Full list of attachment types https://dibk.atlassian.net/wiki/spaces/FB/pages/270139400/Vedlegg

export const validAttachmentTypes = {
  Annet: 'Annet',
  AvkjoeringstillatelseVegmyndighet: 'Avkjøringstillatelse fra vegmyndigheten',
  Avkjoerselsplan: 'Avkjørselsplan',
  AvklaringHoyspent: 'Avklaring av plassering nær høyspentledning',
  AvklaringVA: 'Avklaring av plassering nær VA-ledninger',
  //Dispensasjonssoeknad: 'Dispensasjonssøknad', // Denne legges ved søknaden som tekst felter, trenger ikke vedlegget.
  Folgebrev: 'Følgebrev',
  KommentarNabomerknader: 'Kommentar til nabomerknader',
  KvitteringNabovarsel: 'Kvittering for nabovarsel',
  //Nabovarsel: 'Nabovarsel', // bruker underskjema "GjenpartNabovarsel istedenfor"
  Nabomerknader: 'Nabomerknader',
  RedegjoerelseAndreNaturMiljoeforhold: 'Redegjørelse andre natur og miljøforhold',
  RedegjoerelseEstetikk: 'Redegjørelse estetikk',
  RedegjoerelseSkredOgFlom: 'Redegjørelse skred og flomfare',
  Situasjonsplan: 'Situasjonsplan',
  SamtykkePlassering: 'Samtykke for plassering',
  TegningEksisterendeFasade: 'Tegning eksisterende fasade',
  TegningEksisterendePlan: 'Tegning eksisterende plan',
  TegningEksisterendeSnitt: 'Tegning eksisterende snitt',
  TegningNyFasade: 'Tegning ny fasade',
  TegningNyPlan: 'Tegning ny plan',
  TegningNyttSnitt: 'Tegning nytt snitt',
  UttalelseVedtakAnnenOffentligMyndighet: 'Uttalelse vedtak fra annen offentlig myndighet',
  AndreRedegjoerelser: 'Andre redegjørelser',
  KvitteringForLevertAvfall: 'Kvittering for levert avfall', // KUN FOR FERDIGATTEST
  SluttrapportForBygningsavfall: 'Sluttrapport for bygningsavfall', // KUN FOR FERDIGATTEST
};

export const validNabovarselAttachmentTypes = {
  Annet: 'Annet',
  Folgebrev: 'Følgebrev',
  RedegjoerelseEstetikk: 'Redegjørelse estetikk',
  Situasjonsplan: 'Situasjonsplan',
  TegningNyFasade: 'Tegning ny fasade',
  TegningNyPlan: 'Tegning ny plan',
  TegningNyttSnitt: 'Tegning nytt snitt',
  TegningEksisterendeSnitt: 'Tegning eksisterende snitt',
  TegningEksisterendeFasade: 'Tegning eksisterende fasade',
  AndreRedegjoerelser: 'Andre redegjørelser',
};

export const validFerdigattestAttachmentTypes = {
  Annet: 'Annet',
  RedegjoerelseEstetikk: 'Redegjørelse estetikk',
  Situasjonsplan: 'Situasjonsplan',
  TegningNyFasade: 'Tegning ny fasade',
  TegningNyPlan: 'Tegning ny plan',
  TegningNyttSnitt: 'Tegning nytt snitt',
  AndreRedegjoerelser: 'Andre redegjørelser',
  KvitteringForLevertAvfall: 'Kvittering for levert avfall', // KUN FOR FERDIGATTEST
  SluttrapportForBygningsavfall: 'Sluttrapport for bygningsavfall', // KUN FOR FERDIGATTEST
};

export type ValidAttachmentType = keyof typeof validAttachmentTypes;

export interface Dict<T> {
  [key: string]: T;
}

// Url til gyldige koder: https://register.geonorge.no/byggesoknad/plantype
export const validPlanTypes = {
  KP: 'Kommuneplanens arealdel',
  RP: 'Reguleringsplan',
  BP: 'Bebyggelsesplan',
  '35': 'Detaljregulering',
  '34': 'Områderegulering',
};

export type validPlanType = keyof typeof validPlanTypes;
