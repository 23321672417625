import {UaRoute} from './routesHelpers';

export type InfoPagesRouteKeys =
  | 'base'
  | 'faq'
  | 'garasjeInfo'
  | 'articles'
  | 'article'
  | 'notfound'
  | 'payment'
  | 'personvern';

export type InfoPagesRoutesConfig = {
  [key in InfoPagesRouteKeys]: UaRoute;
};
export const InfoPagesRoutes: InfoPagesRoutesConfig = {
  base: {path: '/', hasActiveApplication: false, requiresLogin: false, isInfoPage: true},
  faq: {
    path: '/ofte-stilte-sporsmal',
    hasActiveApplication: false,
    requiresLogin: false,
    isInfoPage: true,
  },
  garasjeInfo: {
    path: '/byggesoknad-garasje-og-uthus', //Deprecated and redirects to article version
    hasActiveApplication: false,
    requiresLogin: false,
    isInfoPage: true,
  },
  articles: {
    path: '/artikler',
    hasActiveApplication: false,
    requiresLogin: false,
    isInfoPage: true,
  },
  article: {
    path: '/artikkel',
    hasActiveApplication: false,
    requiresLogin: false,
    isInfoPage: true,
  },
  notfound: {
    path: '/404',
    hasActiveApplication: false,
    requiresLogin: false,
    isInfoPage: true,
  },
  payment: {
    path: '/payment',
    hasActiveApplication: false,
    requiresLogin: false,
    isInfoPage: true,
  },
  personvern: {
    path: '/personvern',
    hasActiveApplication: false,
    requiresLogin: false,
    isInfoPage: true,
  },
};
