import {getConfigValue} from '../../config';
import {ApplicationId} from '../../services/ebyggesokServices/userOrderService';
import {addressFromServerToText} from '../../services/matrikkelgrunnbok';
import {QuestionId} from '../../services/utils/questionIds';
import {Attachment, removeAttachmentsBelongingToDeactivatedAnswers} from '../../state/attachmentsState';
import {BuildingSiteState} from '../../state/buildingSiteState';
import {ExistingBuilding} from '../../state/buildingsState';
import {getAllGids, NabovarselState as NeighbourListState} from '../../state/nabovarselState';
import {DispAnswer, QuestionsState as QuestionState} from '../../state/questionState';
import {AnswerValues, Answers} from '../../state/questionTypes';
import {Personnummer} from '../../state/userStore';
import {getAnswerValues} from '../getAnswerValues';
import getSelectedTiltakstyper from '../tiltak/getSelectedTiltakstyper';
import {tiltaksformaalId} from '../tiltak/tiltaksformaal';
import {tiltakTypeDescription} from '../tiltak/tiltakstypeDescriptions';
import {tiltaktypeUaId} from '../tiltak/tiltaktypeIds';
import {validPlanType, validPlanTypes} from './models/models';
import {
  BeskrivelseAvTiltakItem,
  DispensasjonServer,
  ForberedNabovarselRequest,
  JuridiskEntitet,
  Kodetype,
  Person,
  TiltakBruk,
} from './models/nabovarsel';

export const toNabovarselXmlInput = (
  buildingsiteState: BuildingSiteState,
  naboState: NeighbourListState,
  attachments: Attachment[],
  questionState: QuestionState,
  userId: Personnummer,
  applicationId: ApplicationId,
  onlyCheckAttachments?: boolean
): ForberedNabovarselRequest => {
  const attachmentsToInclude = removeAttachmentsBelongingToDeactivatedAnswers(attachments, questionState.answers);
  return nabovarselXml(
    buildingsiteState,
    naboState,
    attachmentsToInclude,
    questionState,
    userId,
    applicationId,
    onlyCheckAttachments
  );
};

const nabovarselXml = (
  buildingsiteState: BuildingSiteState,
  naboState: NeighbourListState,
  attachments: Attachment[],
  questionState: QuestionState,
  userId: Personnummer,
  applicationId: ApplicationId,
  onlyCheckAttachments?: boolean
): ForberedNabovarselRequest => {
  const answerValues = getAnswerValues(questionState.answers);
  var soeker = getSoeker(answerValues, userId);

  var address = buildingsiteState.chosenAddress
    ? {
        text: addressFromServerToText(buildingsiteState.chosenAddress),
        postCode: buildingsiteState.chosenAddress.Postnummer,
        postPlace: buildingsiteState.chosenAddress.Poststed,
      }
    : undefined;
  var customerId = getConfigValue('customerIdInnsendingstjenesten');

  return {
    Adresse: address,
    BeskrivelseAvTiltak: getBeskrivelseAvTiltak(answerValues),
    Dispensasjon: getDispensasjon(answerValues),
    Foelgebrev:
      (answerValues.FOELGEBREV as string | undefined) || 'Nærmere beskrivelse kan du lese i vedlagt følgebrev.',
    GjeldendePlan: getGjeldendePlan(answerValues),
    AnsvarligSoeker: soeker,
    Tiltakshaver: soeker,
    Hovedeiendommer: [buildingsiteState.chosenTeig!.propertyId],
    Naboeiendommer: onlyCheckAttachments ? [] : getAllGids(naboState.neighbours),
    Kommunenavn: answerValues.MUNICIPALITY_NAME as string,
    Bygningsnummer:
      answerValues.BUILDING_TO_CHANGE !== undefined
        ? (answerValues.BUILDING_TO_CHANGE as any as ExistingBuilding).BuildingId
        : undefined,
    HovedinnsendingsId: 'TODO',
    KundeID: customerId,
    Vedlegg: getVedleggNabovarsel(attachments, questionState.answers),
    Signatur: {
      signaturdato: new Date().toISOString(),
      signertAv: '',
      signertPaaVegneAv: '',
    },
    SoknadId: applicationId,
  };
};

export const getFormaal = (selectedTiltaksformaal): TiltakBruk => {
  //Some older applications have array of tiltaksformal while newer applications will have single value
  if (!selectedTiltaksformaal) {
    return;
  } else if (Array.isArray(selectedTiltaksformaal)) {
    const tiltaksformaal: Kodetype[] = selectedTiltaksformaal.map((formal) => {
      return {kodeverdi: formal, kodebeskrivelse: formal};
    });
    return {tiltaksformaal: tiltaksformaal};
  } else {
    return {
      tiltaksformaal: [{kodeverdi: selectedTiltaksformaal, kodebeskrivelse: selectedTiltaksformaal}],
    };
  }
};

export const getBeskrivelseAvTiltak = (answerValues: AnswerValues): BeskrivelseAvTiltakItem[] => {
  const selectedTiltakstyper = getSelectedTiltakstyper(answerValues) as tiltaktypeUaId[];
  const selectedTiltaksformaal = answerValues.TILTAK_FORMAAL as tiltaksformaalId[] | tiltaksformaalId | undefined;

  let tiltakObj: BeskrivelseAvTiltakItem = {
    bruk: getFormaal(selectedTiltaksformaal),
    type: selectedTiltakstyper.map((type) => {
      return {kodeverdi: type, kodebeskrivelse: tiltakTypeDescription[type].officialValue};
    }),
  };
  if (answerValues.ANNET_DESCRIPTION) {
    tiltakObj.bruk = {
      ...tiltakObj.bruk,
      beskrivPlanlagtFormaal: answerValues.ANNET_DESCRIPTION as string,
    };
  }
  return [tiltakObj];
};

export const getGjeldendePlan = (answerValues: AnswerValues): ForberedNabovarselRequest['GjeldendePlan'] => {
  const existingPlans: validPlanType[] = answerValues.EXISTING_PLANS as validPlanType[];
  if (answerValues.EXISTING_PLANS && existingPlans.length > 0) {
    const validPlanType = determineGjeldendePlantype(existingPlans);
    const planameQuestionId = (validPlanType + '_NAME_ID') as QuestionId;
    const planName: string = answerValues[planameQuestionId] as string;

    return {
      navn: planName,
      planType: {
        kodeverdi: validPlanType,
        kodebeskrivelse: validPlanTypes[validPlanType as validPlanType],
      },
    };
  } else if (answerValues.GJELDENDE_PLAN_NAVN_ID && answerValues.PLAN_TYPE) {
    return {
      navn: answerValues.GJELDENDE_PLAN_NAVN_ID as string,
      planType: {
        kodeverdi: answerValues.PLAN_TYPE as validPlanType as string,
        kodebeskrivelse: validPlanTypes[answerValues.PLAN_TYPE as validPlanType],
      },
    };
  }
};

export const determineGjeldendePlantype = (existingPlans: validPlanType[]): validPlanType => {
  if (existingPlans.includes('BP')) {
    return 'BP';
  } else if (existingPlans.includes('35')) {
    return '35';
  } else if (existingPlans.includes('34')) {
    return '34';
  } else if (existingPlans.includes('RP')) {
    return 'RP';
  } else {
    return 'KP';
  }
};

export const getSoeker = (answers: AnswerValues, userId: Personnummer): Person => {
  var juridiskEntitet: JuridiskEntitet = {
    navn: answers.TILTAKSHAVER_NAME as string,
    adresse: {
      adresselinje1: answers.TILTAKSHAVER_ADDRESS as string,
      postnr: answers.TILTAKSHAVER_POSTNR as string,
      poststed: answers.TILTAKSHAVER_POSTPLACE as string,
    },
    epost: answers.TILTAKSHAVER_EMAIL as string,
    telefonnummer: answers.TILTAKSHAVER_PHONE_NUMBER as string,
    kontaktperson: {
      navn: answers.TILTAKSHAVER_NAME as string,
      epost: answers.TILTAKSHAVER_EMAIL as string,
      telefonnummer: answers.TILTAKSHAVER_PHONE_NUMBER as string,
    },
  };
  return {
    ...juridiskEntitet,
    partstype: {
      kodebeskrivelse: 'Privatperson',
      kodeverdi: 'Privatperson',
    },
    foedselsnummer: userId,
  } as Person;
};

export const getDispensasjon = (answers: AnswerValues): DispensasjonServer[] => {
  const answerToDisp = (kodeVal: string, kodeDesc: string, answer: DispAnswer) => {
    return {
      dispensasjonstype: {
        kodeverdi: kodeVal,
        kodebeskrivelse: kodeDesc,
      },
      beskrivelse: answer.description,
      begrunnelse: answer.reason,
    };
  };

  var disps: DispensasjonServer[] = [];
  var arealDisp = answers['PLAN_DISP_ANSWER'] as DispAnswer;
  var pblDisp = answers['PBL_DISP_ANSWER'] as DispAnswer;
  var tekDisp = answers['TEK_DISP_ANSWER'] as DispAnswer;
  var vegDisp = answers['VEG_DISP_ANSWER'] as DispAnswer;
  var utekDisp = answers['UTEK_DISP_ANSWER'] as DispAnswer;

  arealDisp && disps.push(answerToDisp('PLAN', 'Arealplaner', arealDisp));
  pblDisp && disps.push(answerToDisp('PBL', 'Plan- og bygningslov med forskrifter (SAK og DOK)', pblDisp));
  tekDisp && disps.push(answerToDisp('TEK', 'Byggteknisk forskrift med veiledning', tekDisp));
  vegDisp && disps.push(answerToDisp('VEG', 'Vegloven', vegDisp));
  utekDisp && disps.push(answerToDisp('UTEK', 'Byggteknisk forskrift for tiltak på eksisterende bebyggelse', utekDisp));

  return disps;
};

export const getVedleggNabovarsel = (
  attachments: Attachment[],
  answers: Answers
): ForberedNabovarselRequest['Vedlegg'] => {
  const relevantAttachments = removeAttachmentsBelongingToDeactivatedAnswers(attachments, answers);
  return relevantAttachments
    .filter((a) => a.includeInNabovarsel)
    .map((a) => {
      return {
        Url: a.url,
        TypeVedlegg: a.type,
        Filnavn: a.name,
        AttachmentId: a.attachmentId!,
      };
    });
};
