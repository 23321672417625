import React from 'react';
import NkLink from '../../../components/NkLink/NkLink';
import NkTypography from '../../../components/NkTypography/NkTypography';
import './ProffSection.scss';

const ProffSection = ({background}: {background: 'green' | 'white'}) => {
  return (
    <section className={'Proff ' + background}>
      <div className='content'>
        <div className='content-left'>
          <NkTypography type='heading2' style={{marginBottom: 30}}>
            Er du en profesjonell aktør?
          </NkTypography>
          <NkTypography type='normalParagraph' style={{marginBottom: 30}}>
            Vi har en egen byggesøknadsløsning for profesjonelle aktører som skal sende digital byggesøknad på vegne av
            andre.
          </NkTypography>
          <NkLink newPage={true} href='https://www.ebyggesokproff.no' buttonStyle={true} className='btn'>
            Gå til eByggesøk Proff
          </NkLink>
        </div>
        <div className='content-right'>
          <img
            alt='illustrasjon'
            src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1605796191/eBygges%C3%B8k%20UA/illustrations/woman_presentation_graph_chart_arrow_increase.svg'
          />
        </div>
      </div>
    </section>
  );
};
export default ProffSection;
