import {NewApplicationOptions} from './optionTypes';

// BYGG GARASJE EKSTRA SPØRSMÅL
export default {
  questionId: 'BUILDING_EXTRA_USAGES',
  text: 'Velg eventuell ekstra bruk for det du skal bygge',
  type: 'checkboxImage',
  options: [
    {
      value: 'NOTHING',
      text: 'Ingen ekstra bruk',
      //imageUrl: '/images/cardIcons/garage.svg',
    },
    {
      value: 'BEDROOM',
      text: 'Soverom',
      //imageUrl: '/images/cardIcons/bedroom.svg',
    },
    {
      value: 'BASEMENT',
      text: 'Kjeller',
      //imageUrl: '/images/cardIcons/basement.svg',
    },
    {
      value: 'ATTIC',
      text: 'Loft',
      //imageUrl: '/images/cardIcons/loft.svg',
    },
  ],
} as NewApplicationOptions;
