import {MuiThemeProvider} from '@material-ui/core/styles';
import {AppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {HelmetProvider} from 'react-helmet-async';
import {reactPlugin} from '../../AppInsightsSetup';
import browserIsIE from '../../components/IEError/browserIsIE';
import IEError from '../../components/IEError/IEError';
import ScrollToTop from '../../components/ScrollToTop';
import {theme} from '../../scss/muiTheme';
import {UserDispatch} from '../../state/userStore';
import ErrorBoundary from '../ErrorBoundary';
import InfoPages from './InfoPagesApp';
import {ToiThemeProvider} from '@norkart/toi-components';

const InfoPagesAppContext = ({userDispatch}: {userDispatch: UserDispatch}) => {
  if (browserIsIE(window.navigator.userAgent)) {
    return <IEError />;
  }

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <HelmetProvider>
        <MuiThemeProvider theme={theme}>
          <ToiThemeProvider>
            <ScrollToTop>
              <ErrorBoundary userDispatch={userDispatch}>
                <InfoPages />
              </ErrorBoundary>
            </ScrollToTop>
          </ToiThemeProvider>
        </MuiThemeProvider>
      </HelmetProvider>
    </AppInsightsContext.Provider>
  );
};
export default InfoPagesAppContext;
