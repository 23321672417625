import React from 'react';
import layout from '../../../components/hocs/layout';

type Props = {children};
const whiteFooterBackground = ({children}: Props) => {
  return <>{children}</>;
};
export default layout({hideStepper: true, noSpacing: true, showFooter: true, whiteFooterCityBackground: true})(
  whiteFooterBackground
);
