import {ApplicationRoutes} from '../../utils/routes/ApplicationRoutes';
import {withinBuildingBorder} from '../../services/QuestionData/buildingBorderConditions';
import {AnswerValues, innerStep, innerStepId, mainStep, mainStepId} from '../../state/questionTypes';
import {StepperState} from '../../state/stepperState';
import {Dispatch} from '../../state/applicationStore';
import {getMainSteps, StepType} from './stepsForApplicationTypeAndTiltak';
import {History} from 'history';

export const getVisibleSteps = (answers: AnswerValues, stepType: StepType, isStructuredMangel: boolean): mainStep[] => {
  const mainSteps: mainStep[] | undefined = getMainSteps(answers, stepType, isStructuredMangel);
  if (stepType !== 'byggesoknad') {
    return mainSteps || [];
  }
  let filteredMainSteps = mainSteps ? [...mainSteps] : [];
  const skipNabovarsel = answers.SKIP_NABOVARSEL ? answers.SKIP_NABOVARSEL : false;
  //Each of these functions need to be altered to make correct steps occur for correct tiltakstyper
  filteredMainSteps = handleSKipNabovarsel(skipNabovarsel as boolean, filteredMainSteps);
  filteredMainSteps = removeKnownEmptyStep(filteredMainSteps, answers);
  return filteredMainSteps;
};

const handleSKipNabovarsel = (skipNabovarsel: boolean, filteredMainSteps: mainStep[]) => {
  let newList: mainStep[] = [];
  if (skipNabovarsel) {
    newList = filteredMainSteps.filter((step) => step.id !== 'nabovarsel');
    newList = removeInnerStep(newList, 'submit_application', 'NABOVARSEL_SVAR', ApplicationRoutes.NABOVARSEL_SVAR.path);
  } else {
    // Remove tiltakshaver as main step
    newList = filteredMainSteps.filter((step) => step.id !== 'TILTAKSHAVER');
    newList = newList.map((step) => {
      if (step.id === 'submit_application') {
        return {...step, text: 'Send søknad'};
      }
      return step;
    });
    //Fjern sitplan som inner step i submit_application for det vil dukke opp under nabovarsel steget istede
    newList = removeInnerStep(newList, 'submit_application', 'SITUASJONSPLAN', ApplicationRoutes.SITUASJONSPLAN.path);
    newList = removeInnerStep(newList, 'submit_application', 'FOELGEBREV', ApplicationRoutes.FOELGEBREV.path);
  }
  return newList;
};

const removeKnownEmptyStep = (steps: mainStep[], answers: AnswerValues) => {
  let filteredMainSteps = steps;
  if (withinBuildingBorder(answers)) {
    filteredMainSteps = removeInnerStep(
      filteredMainSteps,
      'PLASSERING',
      'ROAD_DISTANCE',
      ApplicationRoutes.ROAD_DISTANCE.path
    );
  }
  return filteredMainSteps;
};

export const removeInnerStep = (
  steps: mainStep[],
  mainStepId: mainStepId,
  innerStepId: innerStepId,
  pathToRemove: string
) => {
  const updatedSteps = steps.map((step) => {
    if (step.id === mainStepId) {
      const filteredInnerSteps = step!.innerSteps
        ? step!.innerSteps.filter((innerStep) => innerStep.id !== innerStepId)
        : undefined;
      const filteredPaths = step!.paths ? step!.paths.filter((path) => path !== pathToRemove) : [];
      return {...step, innerSteps: filteredInnerSteps, paths: filteredPaths};
    } else {
      return step;
    }
  });
  return updatedSteps;
};

export const addInnerStep = (
  steps: mainStep[],
  parrentMainStepId: mainStepId,
  newInnerStep: innerStep,
  pathToAdd: string
) => {
  const editedSteps = steps.map((step) => {
    if (step.id === parrentMainStepId) {
      const newStep: mainStep = {
        ...step,
        paths: [...step.paths, pathToAdd],
        innerSteps: step.innerSteps ? [...step.innerSteps, newInnerStep] : [newInnerStep],
      };
      return newStep;
    } else {
      return step;
    }
  });
  return editedSteps;
};

export var findNabovarselIndex = (answers: AnswerValues, stepType: StepType): number => {
  return getVisibleSteps(answers, stepType, false).findIndex(
    (item) => item.paths.indexOf(ApplicationRoutes.CHOOSE_NEIGHBOURS.path) !== -1
  );
};

export var getMainStepFromRoute = (
  route: string,
  answers: AnswerValues,
  stepType: StepType,
  isStructuredMangel: boolean
): number => {
  return getVisibleSteps(answers, stepType, isStructuredMangel).findIndex((i) => i.paths.indexOf(route) !== -1);
};

export var getLastInnerStep = (
  answers: AnswerValues,
  mainStepIndex: number | undefined,
  stepType: StepType,
  isStructuredMangel: boolean
) => {
  if (mainStepIndex === -1) {
    return 0;
  }
  mainStepIndex =
    mainStepIndex !== undefined
      ? mainStepIndex
      : getVisibleSteps(answers, stepType, isStructuredMangel).findIndex(
          (i) => i.paths.indexOf(window.window.location.pathname) !== -1
        );
  const innerSteps = getVisibleSteps(answers, stepType, isStructuredMangel)[mainStepIndex]?.innerSteps;
  return innerSteps ? Math.max(innerSteps.length - 1, 0) : 0;
};

export const getNextStep = (
  step: {main: number; inner: number},
  answers: AnswerValues,
  stepType: StepType,
  isStructuredMangel: boolean
): {main: number; inner: number} => {
  const activeSteps = getVisibleSteps(answers, stepType, isStructuredMangel);
  const maxStep = activeSteps.length - 1;
  let outerStep = Math.min(step.main, maxStep);
  let nextInnerStep = step.inner + 1;
  const lastInnerStep = getLastInnerStep(answers, outerStep, stepType, isStructuredMangel);
  const nextInnerStepOutOfRange = lastInnerStep < nextInnerStep;

  if (nextInnerStepOutOfRange) {
    if (outerStep + 1 > maxStep) {
      nextInnerStep = step.inner; //At last main and inner step, stay where you are
    } else {
      outerStep++;
      nextInnerStep = 0;
    }
  }
  return {
    main: outerStep,
    inner: nextInnerStep,
  };
};

export const getNextStepFromStateAndPath = (
  stepperState: StepperState,
  path: string,
  answers: AnswerValues,
  stepType: StepType,
  isStructuredMangel: boolean
): {main: number; inner: number} => {
  var outerStep = getVisibleSteps(answers, stepType, isStructuredMangel).findIndex((i) => i.paths.indexOf(path) !== -1);
  var innerStep = stepperState.activeInnerStep;

  return getNextStep({main: outerStep, inner: innerStep}, answers, stepType, isStructuredMangel);
};

export const getPrevStep = (
  step: {main: number; inner: number},
  answers: AnswerValues,
  stepType: StepType,
  isStructuredMangel: boolean
): {main: number; inner: number} => {
  var outerStep = step.main;
  var innerStep = step.inner - 1;

  if (innerStep < 0) {
    outerStep--;
    innerStep = getLastInnerStep(answers, outerStep, stepType, isStructuredMangel);
  }

  return {
    main: outerStep,
    inner: innerStep,
  };
};

export const getPrevStepFromStateAndPath = (
  stepperState: StepperState,
  path: string,
  answers: AnswerValues,
  stepType: StepType,
  isStructuredMangel: boolean
): {main: number; inner: number} => {
  var outerStep = getVisibleSteps(answers, stepType, isStructuredMangel).findIndex((i) => i.paths.indexOf(path) !== -1);
  var innerStep = stepperState.activeInnerStep;

  return getPrevStep({main: outerStep, inner: innerStep}, answers, stepType, isStructuredMangel);
};

export const goToStep = (
  dispatch: Dispatch,
  history: History,
  stepperState: StepperState,
  newStep: {main: number; inner: number},
  answers: AnswerValues,
  skipRedirect: boolean = false,
  stepType: StepType,
  isStructuredMangel: boolean
) => {
  const visibleSteps = getVisibleSteps(answers, stepType, isStructuredMangel);
  if (!skipRedirect) {
    if (visibleSteps[newStep.main].paths.length > 1) {
      history.push(visibleSteps[newStep.main].paths[newStep.inner]);
    } else {
      history.push(visibleSteps[newStep.main].paths[0]);
    }
  }
  dispatch({
    type: 'setActiveInnerStep',
    value: newStep.inner,
  });
  dispatch({type: 'setActiveMainStep', value: newStep.main});

  var goingPastLastVisitedMainStep = stepperState.lastFinishedMainStepIndex + 1 < newStep.main;
  var goingToLastVisitedMainStep = stepperState.lastFinishedMainStepIndex + 1 === newStep.main;
  if (goingPastLastVisitedMainStep) {
    const newFinishedMainstepIndex = newStep.main - 1;
    dispatch({
      type: 'setLastFinishedMainStep',
      value: {stepIndex: newFinishedMainstepIndex, stepId: visibleSteps[newFinishedMainstepIndex].id},
    });
    const newFinishedInnerstepIndex = newStep.inner - 1;
    let newInnerStepId: innerStepId | undefined = undefined;
    if (visibleSteps[newFinishedMainstepIndex].innerSteps !== undefined && newFinishedInnerstepIndex > -1) {
      newInnerStepId = visibleSteps[newFinishedMainstepIndex].innerSteps![newFinishedInnerstepIndex].id;
    }
    dispatch({
      type: 'setLastFinishedInnerStep',
      value: {stepIndex: newFinishedInnerstepIndex, stepId: newInnerStepId},
    });
  }

  if (
    goingPastLastVisitedMainStep ||
    (goingToLastVisitedMainStep && stepperState.lastFinishedInnerStepIndex + 1 < newStep.inner)
  ) {
    const newFinishedInnerstepIndex = newStep.inner - 1;
    let newInnerStepId: innerStepId | undefined = undefined;
    if (
      visibleSteps[newStep.main].innerSteps !== undefined &&
      visibleSteps[newStep.main].innerSteps!.length > newFinishedInnerstepIndex
    ) {
      const foundStep = visibleSteps[newStep.main].innerSteps![newFinishedInnerstepIndex];
      newInnerStepId = foundStep ? foundStep.id : undefined;
    }
    dispatch({
      type: 'setLastFinishedInnerStep',
      value: {stepIndex: newFinishedInnerstepIndex, stepId: newInnerStepId},
    });
  }
};
