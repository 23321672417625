import React from 'react';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import {DesktopContainer} from '../../../components/containers/DeviceContainers';
import ContentSearch from '../../../components/ContentSearch/ContentSearch';
import layout from '../../../components/hocs/layout';
import NkTypography from '../../../components/NkTypography/NkTypography';
import {getTextObject} from '../../../texts/textObjectHelpers';
import {InfoPagesRoutes} from '../../../utils/routes/InfoPagesRoutes';
import {setTitle} from '../../../utils/setTitle';
import ArticleCard from '../ArticleCard/ArticleCard';
import {parseArticlePreview} from '../helpers';
import './ArticlesPage.scss';
import {onSearchChanged} from './helpers';

const ArticlesPage = () => {
  setTitle('Artikler');

  const articles = getTextObject().articles;
  const initArticles = articles && Object.keys(articles);

  const [hasActiveSearchFilter, setHasActiveSearchFilter] = React.useState(false);
  const [articlesToDisplay, setArticlesToDisplay] = React.useState<string[]>(initArticles);

  return (
    <div className='article-page questionHelper'>
      <div className='content'>
        <Breadcrumb traces={[{name: 'Forside', path: InfoPagesRoutes.base.path}]} currentPage='Artikler' />
        <div className='header-row' style={{marginTop: 60}}>
          <div className='text'>
            <NkTypography type='smallGreenHeader' style={{marginBottom: 20}}>
              Kunnskapsbase
            </NkTypography>
            <NkTypography type='heading2' tag='h1' style={{marginBottom: 40}}>
              Her er noen artikler som kan hjelpe deg med det du lurer på rundt din byggesak
            </NkTypography>
            <NkTypography type='largeParagraph' style={{marginBottom: 40}}>
              Her finner du informasjon om forskjellige type søknader og tema i forbindelse med innsending av
              byggesøknader og lovverk rundt hva du kan gjøre på din eiendom.
            </NkTypography>
            <ContentSearch
              id={'article-searc'}
              contentName='artikler'
              placeholder='Søk i artikler'
              onSearchChanged={(searchText) => {
                onSearchChanged(searchText, initArticles, setArticlesToDisplay, setHasActiveSearchFilter);
              }}
            />
          </div>
          <DesktopContainer>
            <img
              alt=''
              src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1605796192/eBygges%C3%B8k%20UA/illustrations/woman_read_newspaper_article_news.svg'
            />
          </DesktopContainer>
        </div>
        <div className='articles-container' style={{marginTop: 60}}>
          {hasActiveSearchFilter && articlesToDisplay.length === 0 && (
            <NkTypography type='largeParagraph'>
              Søket ga ingen treff. Prøv å skrive stikkord, og ikke hele setninger.
            </NkTypography>
          )}
          {articlesToDisplay.map((article) => {
            const parsedArticle = parseArticlePreview(article);
            return (
              <ArticleCard
                name={article}
                key={parsedArticle.header}
                header={parsedArticle.header}
                imgSrc={parsedArticle.img}
                ingress={parsedArticle.ingress}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default layout({hideStepper: true, noSpacing: false, showFooter: true})(ArticlesPage);
