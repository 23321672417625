import {Coords} from '@norkart/nkm-mapbox-map/lib/utils/types';
import {gfiAttId, WmsFeature} from '../components/PlansList/GFI/fetchGfi';
import {CodeList} from '../services/plankart';

export interface PlankartState {
  gfiAttributesState?: GFIAttrState;
  drawnAttInMap?: gfiAttId;
  codelist?: CodeList;
  gfiMarkerCoord?: Coords;
}
export interface GFIAttrState {
  loading: boolean;
  notFound: boolean;
  planAttributes?: GFIPlanFeature[];
  visibleInMap?: boolean;
}

export type GFIPlanFeature = {
  wmsLayers: GFIAttribute[];
};

export type GFIAttribute = {
  wmsLayerName: string;
  attrList: WmsFeature[];
};

export const initialState: PlankartState = {};

export type Action =
  | {type: 'SET_GFI_ATTRIBUTES_STATE'; value: GFIAttrState}
  | {type: 'SET_VISIBLE_IN_MAP'; value: {visible: boolean; id: gfiAttId}}
  | {type: 'SET_GFI_CODELIST'; value: CodeList}
  | {type: 'SET_GFI_MARKER_COORDS'; value: Coords | undefined}
  | {type: 'RESET_GFI_ATTRIBUTES_STATE'};

export const reducer = (state: PlankartState, action: Action): PlankartState => {
  switch (action.type) {
    case 'SET_GFI_ATTRIBUTES_STATE': {
      return {
        ...state,
        gfiAttributesState: action.value,
      };
    }
    case 'RESET_GFI_ATTRIBUTES_STATE': {
      return {
        ...state,
        gfiAttributesState: initialState.gfiAttributesState,
      };
    }
    case 'SET_GFI_MARKER_COORDS': {
      return {
        ...state,
        gfiMarkerCoord: action.value,
      };
    }
    case 'SET_VISIBLE_IN_MAP': {
      return {
        ...state,
        drawnAttInMap: action.value.visible ? action.value.id : undefined,
      };
    }
    case 'SET_GFI_CODELIST': {
      return {
        ...state,
        codelist: action.value,
      };
    }
    default:
      return state;
  }
};
