import {SeverityLevel} from '@microsoft/applicationinsights-common';
import {ToiSpinner} from '@norkart/toi-components';
import {AxiosError} from 'axios';
import {AdvarselRoed} from 'nka-icons';
import React from 'react';
import {appInsightsInstance} from '../../AppInsightsSetup';
import {createExtraInfoPdf} from '../../services/ebyggesokServices/extraDocumentService';
import {ApplicationId} from '../../services/ebyggesokServices/userOrderService';
import {Context} from '../../state/applicationStore';
import {Attachment} from '../../state/attachmentsState';
import getErrorMsg from '../../utils/getErrorMsg';
import NkButton from '../Button/NkButton';
import NkLink from '../NkLink/NkLink';
import NkTypography from '../NkTypography/NkTypography';

type Props = {
  initialUpdateApplicationXml: boolean;
};
type ExtraDocument = {
  pending: boolean;
  error: boolean;
  url?: string;
};

export const extraInfoDocumentName = 'EkstraInformasjonForSoknad.pdf';

const ExtraInfoPdfHandler = ({initialUpdateApplicationXml}: Props) => {
  const {
    state: {
      application: {applicationId, soknadType},
      attachment: {attachments},
    },
    dispatch,
  } = React.useContext(Context);

  const existingExtraDocument = attachments.find((a) => a.name === extraInfoDocumentName && a.type === 'Annet');
  const [extraDocument, setExtraDocument] = React.useState<ExtraDocument>({
    pending: false,
    error: false,
    url: existingExtraDocument?.url,
  });

  React.useEffect(() => {
    if (
      (!extraDocument.url || initialUpdateApplicationXml) &&
      applicationId &&
      !extraDocument.pending &&
      !extraDocument.error
    ) {
      createExtraDocument(applicationId);
    }
  }, [applicationId, initialUpdateApplicationXml]);

  const createExtraDocument = (applicationId: ApplicationId) => {
    setExtraDocument({url: undefined, error: false, pending: true});
    createExtraInfoPdf(applicationId).then(
      (res) => {
        setExtraDocument({url: res.data.PdfUrl, pending: false, error: false});
        const attachment: Attachment = {
          type: 'Annet',
          name: extraInfoDocumentName,
          url: res.data.PdfUrl,
          includeInNabovarsel: false,
          fileSize: res.data.FileSize,
          addedAutomatic: true,
          soknadType: soknadType,
        };
        dispatch({
          type: 'replaceExtraInfoAttachment',
          value: attachment,
        });
      },
      (err: AxiosError) => {
        appInsightsInstance?.trackException(
          {
            error: new Error('createExtraDocument'),
            severityLevel: SeverityLevel.Error,
          },
          {
            error: getErrorMsg(err),
            soknadId: applicationId,
            message: 'Error creating extra document',
          }
        );
        dispatch({
          type: 'removeExtraInfoAttachment',
        });
        setExtraDocument({error: true, pending: false});
      }
    );
  };

  const commonInfo = () => {
    return (
      <>
        <NkTypography tag='h2' type='heading4' style={{marginTop: 30}}>
          Ekstra informasjon
        </NkTypography>
        <NkTypography type='normalParagraph'>
          Som en del av søknaden din legges det også ved et ekstra dokument med informasjon du har fylt ut som kan være
          nyttig for kommunen.
        </NkTypography>
      </>
    );
  };
  if (extraDocument.url) {
    return (
      <div className='ExtraInfoPdfHandler summaryItem altinn-draft'>
        {commonInfo()}
        <NkLink style={{marginTop: 20}} href={extraDocument.url} newPage>
          Ekstra informasjon (pdf)
        </NkLink>
      </div>
    );
  } else if (extraDocument.error) {
    return (
      <div className='ExtraInfoPdfHandler summaryItem altinn-draft'>
        {commonInfo()}
        <div style={{display: 'flex', marginBottom: 15, marginTop: 12}}>
          <AdvarselRoed />
          <NkTypography style={{marginLeft: 5}} type='normalParagraph'>
            Det oppsto en feil med oppretting av dokument med ekstra informasjon.
          </NkTypography>
        </div>
        <NkButton
          style={{marginBottom: 20}}
          onClick={() => {
            applicationId && createExtraDocument(applicationId);
          }}
          type='callToAction'
        >
          Prøv igjen
        </NkButton>
      </div>
    );
  } else {
    return (
      <div className='ExtraInfoPdfHandler summaryItem altinn-draft'>
        {commonInfo()}
        <div style={{display: 'flex', marginTop: 20}}>
          <ToiSpinner />
          <NkTypography style={{marginLeft: 10}} type='normalParagraph'>
            Oppretter dokument
          </NkTypography>
        </div>
      </div>
    );
  }
};
export default ExtraInfoPdfHandler;
