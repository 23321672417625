import React from 'react';
import {IStatusAlert, StatusAlertId} from '../services/ebyggesokServices/statusService';

export interface StatusAlertState {
  statusAlerts: IStatusAlert[];
}

export const initialStatusAlertState: StatusAlertState = {
  statusAlerts: [],
};

export type StatusAlertAction =
  | {
      type: 'setStatusAlerts';
      value: IStatusAlert[];
    }
  | {type: 'hideStatusAlert'; value: StatusAlertId};

export const statusAlertReducer = (state: StatusAlertState = initialStatusAlertState, action: StatusAlertAction) => {
  switch (action.type) {
    case 'setStatusAlerts':
      return {
        ...state,
        statusAlerts: action.value.map((newAlert) => ({
          ...newAlert,
          //Keep hide alert status
          hideAlert: state.statusAlerts.find((existingAlert) => existingAlert.Id === newAlert.Id)?.hideAlert || false,
        })),
      };
    case 'hideStatusAlert':
      const alertToUpdate = state.statusAlerts.find((alert) => alert.Id === action.value)!;
      return {
        ...state,
        statusAlerts: [
          ...state.statusAlerts.filter((alert) => alert.Id !== action.value),
          {...alertToUpdate, hideAlert: true},
        ],
      };
    default:
      return state;
  }
};

export type IDispatchInput = StatusAlertAction;

interface IStatusAlertContextProps {
  statusAlertState: StatusAlertState;
  statusAlertDispatch: (dispatchInput: IDispatchInput) => void;
}

export type StatusAlertDispatch = IStatusAlertContextProps['statusAlertDispatch'];

export const StatusAlertContext = React.createContext({} as IStatusAlertContextProps);
