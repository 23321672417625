import {ApplicationId} from './../ebyggesokServices/userOrderService';
import {AddAnswerAction} from '../../state/questionState';
import {getAsyncAxiosInstanceWithIdportenHeader} from './axiosService';
import {QuestionId} from '../utils/questionIds';
import {StepIds} from '../../components/Steps/stepConfig';
import {AnswerType} from '../../state/questionTypes';
import {SoknadType, SuppleringId} from '../../state/applicationState';

export type SaveAnswerRequest = {
  SoknadId: ApplicationId;
  Svar: string;
  SporsmalNavn: QuestionId;
  Steg: StepIds;
  AnswerType?: AnswerType;
  SoknadType: SoknadType;
  SuppleringId?: SuppleringId;
};

export async function saveAnswer(applicationId: ApplicationId, action: AddAnswerAction, attemptNumber: number = 0) {
  const SAVE_ANSWER_RETRY_LIMIT = 3;
  const answer = typeof action.value.answer !== 'string' ? JSON.stringify(action.value.answer) : action.value.answer;
  const instance = await getAsyncAxiosInstanceWithIdportenHeader();
  return await instance
    .post(`/LagreSvar/`, {
      SoknadId: applicationId,
      Svar: answer,
      SporsmalNavn: action.value.id,
      Steg: action.value.step,
      AnswerType: action.value.answerType,
      SoknadType: action.value.application.soknadType,
      SuppleringId: action.value.application.suppleringId,
    } as SaveAnswerRequest)
    .then(
      () => {
        if (action.value.id === 'SKIP_NABOVARSEL') {
          const boolAnswer: boolean = answer === 'true' ? true : false;
          saveSkipNabovarselAnswer(applicationId, boolAnswer);
        }
      },
      async (err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          action.value.userDispatch({
            type: 'displayLoginErrorPopup',
            value: {reason: `saveAnswer ${err.response?.status} unauthorized`},
          });
          return;
        }

        const isDoubleSaveError = err.response?.data?.Message?.includes('Violation of UNIQUE KEY constraint');
        if (isDoubleSaveError) {
          return;
        }

        // Retry
        if (attemptNumber < SAVE_ANSWER_RETRY_LIMIT) {
          return await saveAnswer(applicationId, action, attemptNumber + 1);
        } else {
          throw err;
        }
      }
    );
}

export function removeAnswers(applicationId: number, ignoreSteps: string[]) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/FjernSvar/`, {
      ApplicationId: applicationId,
      StegSomSkalBeholdes: ignoreSteps,
    })
  );
}

export function saveSkipNabovarselAnswer(applicationId: number, skipNabovarsel: boolean) {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.post(`/LagreSkipNabovarselAnswer/`, {
      SoknadId: applicationId,
      SkipNabovarsel: skipNabovarsel,
    })
  );
}
