import {StepIds} from '../components/Steps/stepConfig';
import {Answers, AnswerValues, Answer} from '../state/questionTypes';

export const getAnswerValues = (answers: Answers, step?: StepIds): AnswerValues => {
  var answerValues: AnswerValues = {};
  for (const key in answers) {
    const answer: Answer = answers[key];
    if (answer && !answer.deactivated && (!step || step === answer.step)) {
      answerValues[key] = answer.value;
    }
  }
  return answerValues;
};
