import React from 'react';
import NkButton from '../components/Button/NkButton';
import layout from '../components/hocs/layout';
import NkTypography from '../components/NkTypography/NkTypography';
import {UserContext} from '../state/userStore';
import {setTitle} from '../utils/setTitle';
import './SevereError.scss';

type Props = {
  msg?: string;
  excludeDefaultText?: boolean;
  hideTryAgainButton?: boolean;
};

const SevereError = ({msg, excludeDefaultText, hideTryAgainButton}: Props) => {
  const {userState, userDispatch} = React.useContext(UserContext);

  React.useEffect(() => {
    setTitle('Feil oppsto');

    return () => {
      userDispatch({
        type: 'setSevereError',
        value: {
          msg: undefined,
        },
      });
    };
  }, []);

  if (userState.displayLoginErrorPopup) {
    return null;
  }
  const refresh = () => {
    window.location.reload();
  };

  return (
    <div className={`SevereError`}>
      <div className='errorContent'>
        <NkTypography style={{marginBottom: 10, width: '100%'}} type='smallGreenHeader' tag='h1' tabIndex={-1}>
          Oj da!
        </NkTypography>
        <NkTypography style={{marginBottom: 40, width: '100%'}} type='heading2' tabIndex={-1}>
          Her var det noe som gikk galt!
        </NkTypography>
        <NkTypography type='markdown' style={{width: '100%'}}>
          {msg || ''}
        </NkTypography>
        <NkTypography type='normalParagraph' style={{width: '100%'}}>
          {`${excludeDefaultText ? '' : 'Dersom problemet vedvarer kan du sende oss en henvendelse via vår chat'} `}
        </NkTypography>
        {!hideTryAgainButton && (
          <NkButton style={{maxWidth: '100%', marginTop: 30, width: 200}} type='callToAction' onClick={refresh}>
            Prøv igjen
          </NkButton>
        )}
        <img
          src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1605793204/eBygges%C3%B8k%20UA/illustrations/man_coding_programming_laptop_computer_idea_lightbulb_code-1.svg'
          alt='Illustrasjon. feilmelding'
        ></img>
      </div>
    </div>
  );
};
export default layout({})(SevereError);
