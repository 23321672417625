import React from 'react';
import ContentSearch from '../../../components/ContentSearch/ContentSearch';
import NkLink from '../../../components/NkLink/NkLink';
import NkTypography from '../../../components/NkTypography/NkTypography';
import {getTextObject} from '../../../texts/textObjectHelpers';
import {InfoPagesRoutes} from '../../../utils/routes/InfoPagesRoutes';
import ArticleCard from '../ArticleCard/ArticleCard';
import {onSearchChanged} from '../ArticlesPage/helpers';
import {filterOnHovedvalg, parseArticlePreview, what_to_do_option} from '../helpers';
import './ArticlesPreview.scss';

type Props = {step: 'forside' | 'veileder-resultat'; what_to_do?: what_to_do_option; background: 'white' | 'green'};
const ArticlesPreviewSection = ({step, what_to_do, background}: Props) => {
  const articles = getTextObject().articles;
  const initArticlesList = articles && Object.keys(articles);

  const parsedArticles = initArticlesList.map((articleName) => parseArticlePreview(articleName));

  const initArticlesToDisplay = parsedArticles.filter((article) => article.steg?.includes(step));
  const [articlesToDisplay, setArticlesToDisplay] = React.useState(initArticlesToDisplay);
  const [hasActiveSearchFilter, setHasActiveSearchFilter] = React.useState(false);

  const setArticles = (articleNames: string[]) => {
    const parsedArticles = articleNames.map((article) => parseArticlePreview(article));
    setArticlesToDisplay(parsedArticles);
  };

  React.useEffect(() => {
    if (step === 'veileder-resultat') {
      const filteredArticles = filterOnHovedvalg(initArticlesToDisplay, what_to_do as any);
      setArticlesToDisplay(filteredArticles);
    }
  }, [what_to_do]);

  return (
    <section className={'ArticlesPreview ' + background}>
      <div className='content'>
        <NkTypography style={{marginBottom: 30, marginTop: 60}} type='smallGreenHeader'>
          Kunnskapsbase
        </NkTypography>
        <NkTypography type='heading2' style={{maxWidth: 730, marginBottom: 40}}>
          Her er noen artikler som kan hjelpe deg
        </NkTypography>

        <ContentSearch
          id={'article-searc'}
          contentName='artikler'
          placeholder='Søk i artikler'
          onSearchChanged={(searchText) => {
            onSearchChanged(searchText, initArticlesList, setArticles, setHasActiveSearchFilter);
          }}
        />
        <div
          className='articles-container'
          style={{
            marginTop: 60,
            marginBottom: 60,
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, 320px)',
            gridTemplateRows: 'repeat(auto-fill, 280px)',
            gap: 30,
          }}
        >
          {hasActiveSearchFilter && articlesToDisplay.length === 0 && (
            <NkTypography type='largeParagraph'>
              Søket ga ingen treff. Prøv å skrive stikkord, og ikke hele setninger.
            </NkTypography>
          )}
          {articlesToDisplay.map((parsedArticle) => {
            return parsedArticle.steg?.includes(step) ? (
              <ArticleCard
                name={parsedArticle.name}
                key={parsedArticle.header}
                header={parsedArticle.header}
                ingress={parsedArticle.ingress}
                imgSrc={parsedArticle.img}
              />
            ) : null;
          })}
        </div>
        <NkLink size='large' style={{margin: 'auto', width: 175}} href={InfoPagesRoutes.articles.path} arrow='right'>
          Se alle artikler
        </NkLink>
      </div>
    </section>
  );
};
export default ArticlesPreviewSection;
