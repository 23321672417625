import {QuestionOption} from '../../../services/utils/QuestionTypes';
import {AnswerValues} from '../../../state/questionTypes';
import {Question, QuestionGroup} from '../../utils/QuestionTypes';
import {MangelBrevCategoryName, MangelPathName, SupportedMangelbrevTypes} from './mangelbrevTypes';

export const supportedMangelCategories: {
  id: SupportedMangelbrevTypes;
  name: MangelBrevCategoryName;
  path: MangelPathName;
}[] = [
  {id: 'MISSING_DRAWING', name: 'Tegninger', path: 'tegninger'},
  {id: 'MISSING_ATTACHMENT', name: 'Vedlegg', path: 'vedlegg'},
  {id: 'MISSING_FOELGEBREV', name: 'Følgebrev', path: 'folgebrev'},
  {id: 'MISSING_DISP', name: 'Dispensasjon', path: 'dispensasjon'},
  {id: 'MISSING_NABOVARSLING', name: 'Nabovarsling', path: 'nabovarsel'},
  {id: 'MISSING_SAMTYKKENABO', name: 'Nabosamtykke', path: 'nabosamtykke'},
  {id: 'MISSING_PARKERING', name: 'Parkering', path: 'parkering'},
  {id: 'MISSING_ATKOMST', name: 'Atkomst', path: 'atkomst'},
  {id: 'MISSING_SAFETY', name: 'Sikkerhet', path: 'sikkerhet'},
  {id: 'MISSING_ARKITEKTONISK', name: 'Utforming', path: 'utforming'},
  {id: 'MISSING_OTHER', name: 'Annet', path: 'annet'},
];

const mangelbrevOptions: {text: string; value: SupportedMangelbrevTypes; helpText?: string}[] = [
  {
    text: 'Manglende vedlegg/tegning',
    value: 'MISSING_DRAWING',
    helpText: 'Eksempel: plantegning, fasadetegning, uttalelse fra myndighet',
  },
  {
    text: 'Manglende følgebrev',
    value: 'MISSING_FOELGEBREV',
  },
  {
    text: 'Dispensasjon',
    value: 'MISSING_DISP',
  },
  {
    text: 'Nabovarsling',
    value: 'MISSING_NABOVARSLING',
  },
  {
    text: 'Samtykke fra nabo',
    value: 'MISSING_SAMTYKKENABO',
  },
  {
    text: 'Parkering',
    value: 'MISSING_PARKERING',
  },
  {
    text: 'Krav til atkomst, vannforsyning, avløp, overvann eller fjernvarmetilknytning',
    value: 'MISSING_ATKOMST',
  },
  {
    text: 'Sikkerhet mot fare eller uvesentlig ulempe',
    value: 'MISSING_SAFETY',
    helpText: 'Eksempel: redegjørelse fra geotekniker',
  },
  {
    text: 'Krav til arkitektonisk utforming/visuelle kvaliteter',
    value: 'MISSING_ARKITEKTONISK',
  },
  {
    text: 'Annet',
    value: 'MISSING_OTHER',
    helpText: 'Eksempel: informasjon om areal og grad av utnytting',
  },
];

export const getMangelbrevTypesData = (isStructuredMangel): QuestionGroup => {
  const questions: Question[] = [
    {
      text: 'Hvilke type mangler har du fått fra kommunen?',
      id: 'MANGEL_TYPES',
      helpText: isStructuredMangel
        ? 'Velg kategorien som stemmer best med den mottatte mangelen fra kommune.'
        : 'Kryss av for __alle__ type mangler som er beskrevet i mangelbrevet fra kommunen.',
      answerType: isStructuredMangel ? 'radiobuttons' : 'checkboxes',
      options: mangelbrevOptions as QuestionOption[],
    },
  ];
  return {
    groupText: 'Svar på mangel',
    step: 'MANGELBREV_TYPES',
    groupHelpText:
      'I mangelbrevet du har fått fra kommunen finner du informasjon om hva som mangler i søknaden din. Videre vil du få veiledning i hvordan du skal forholde deg til mangelen. [stop-preview]',
    questions: questions.filter((el) => el !== null) as Question[], //Remove empty elements
  };
};

export const getSelectedMangelbrevTypes = (answers: AnswerValues): SupportedMangelbrevTypes[] => {
  let mangelTypes: SupportedMangelbrevTypes[] = [];
  if (answers.MANGEL_TYPES) {
    mangelTypes =
      typeof answers.MANGEL_TYPES === 'string'
        ? [answers.MANGEL_TYPES as SupportedMangelbrevTypes]
        : (answers.MANGEL_TYPES as SupportedMangelbrevTypes[]);
  }
  return mangelTypes;
};
