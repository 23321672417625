import React, {Component} from 'react';
import NkTypography from '../NkTypography/NkTypography';
import './IEError.scss';
import NkLink from '../NkLink/NkLink';
class IEError extends Component {
  render() {
    return (
      <div className='ieError'>
        <NkTypography tag='h1' type='heading2' tabIndex={-1}>
          Vennligst benytt en annen nettleser
        </NkTypography>
        <p>
          eByggesøk er ikke tilgjengelig i Internet Explorer ettersom nettleseren har dårlig støtte og har problemer
          rundt sikkerhet. Microsoft selv advarer mot bruk av nettleseren, se artikkel{' '}
          <NkLink
            newPage
            href='https://www.telegraph.co.uk/technology/2019/02/08/stop-using-internet-explorer-warns-microsofts-security-chief/'
          >
            her.
          </NkLink>
          Vennligst benytt en annen nettleser, for eksempel Google Chrome.
        </p>
        <NkLink newPage href='https://www.google.com/chrome/'>
          Last ned Google Chrome her
        </NkLink>
        <img
          alt='Illustrasjon. by'
          src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1553169371/e-torg-bilder/e-torg_illustrasjon_lang_gronn.png'
        />
      </div>
    );
  }
}
export default IEError;
