import {QuestionId} from '../../../services/utils/questionIds';
import {NewApplicationOptions} from './optionTypes';

export const mainCategory: NewApplicationOptions = {
  questionId: 'WHAT_TO_DO' as QuestionId,
  options: [
    {
      value: 'change',
      text: 'Bruksendre',
      helpText: 'F.eks gjøre om boden til et soverom',
    },
    {
      value: 'build',
      text: 'Bygge',
      helpText: 'F.eks et tilbygg, svømmebasseng, gjerde, terrasse',
    },
    {
      value: 'demolish',
      text: 'Rive',
      helpText: 'F.eks rive et eksisterende bygg eller tilbygg',
    },
    {
      value: 'create',
      text: 'Dele/fradele en matrikkelenhet',
      helpText: 'Opprette en eiendom eller skille ut en tomt',
    },
    {
      value: 'fasadeendring',
      text: 'Fasadeendring',
      helpText: 'Endre yttervegger eller tak, f.eks sette inn vindu',
    },
    {
      value: 'combination',
      text: 'En kombinasjon av alternativene over',
      helpText: 'F.eks bygge og rive',
    },
    {
      value: 'mikrohus',
      text: 'Sette opp mikrohus',
      helpText: 'Frittliggende boligbygning inntil 30 m2 BRA med én boenhet',
    },
  ],
  noBuildingOptions: [
    //Options to show if user has said there are no buildings on property
    {
      value: 'build',
      text: 'Bygge',
      helpText: 'Bygge et tilbygg eller frittliggende bygning',
    },
    {
      value: 'create',
      text: 'Dele/fradele en matrikkelenhet',
      helpText: 'Opprette en eiendom eller skille ut en tomt',
    },
    {
      value: 'annet',
      text: 'Annet',
    },
  ],
};
