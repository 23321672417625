import {getTextObject} from '../../../texts/textObjectHelpers';

type articelMetaKey = 'header' | 'img' | 'ingress' | 'tags' | 'description' | 'title' | 'hovedvalg' | 'steg';

export const getArticleMetaData = (key: articelMetaKey, article_name: string): string | undefined => {
  if (!article_name) return;
  const article_metadata = article_name && getTextObject().articles[article_name]?.split('[start]')[0];
  const key_split = '[' + key + ':';
  return article_metadata?.includes(key_split) ? article_metadata.split(key_split)[1].split(']')[0] : undefined;
};

export const defaultArticleSEOTags = ' byggesøknad byggetillatelse';

export type hovedvalg = 'bygge' | 'rive' | 'bruksendre' | 'fradele' | 'fasadeendring';
export const validateMetaHovedvalg = (metaName: string) => {
  const valgene = metaName.split(' ') as hovedvalg[];
  const validHovedvalg: hovedvalg[] = ['bygge', 'rive', 'bruksendre', 'fradele', 'fasadeendring'];
  valgene.forEach((valg) => {
    if (!validHovedvalg.includes(valg)) {
      alert(`Hovedvalg "${valg}" for artikkel er ikke gyldig. Velg mellom ${validHovedvalg.join(', ')}`);
    }
  });
};

export type articleSteps = 'veileder' | 'forside' | 'veileder-resultat';

export const validateMetaSteg = (metaName: string) => {
  const stegene = metaName.split(' ') as articleSteps[];
  const validSteg: articleSteps[] = ['veileder', 'forside', 'veileder-resultat'];
  stegene.forEach((steg) => {
    if (!validSteg.includes(steg)) {
      alert(`Steg "${steg}" for artikkel er ikke gyldig. Velg mellom ${validSteg.join(', ')}`);
    }
  });
};

export const validateRequiredMetadata = (article_name: string) => {
  if (!article_name) return;
  const tags = getArticleMetaData('tags', article_name) + defaultArticleSEOTags;
  const description = getArticleMetaData('description', article_name);
  const title = getArticleMetaData('title', article_name);
  const img = getArticleMetaData('img', article_name);
  const header = getArticleMetaData('header', article_name);

  if (!tags) {
    alert('Mangler tags i metadata for artikkel ' + article_name);
  }
  if (!description) {
    alert('Mangler description i metadata for artikkel ' + article_name);
  }
  if (!title) {
    alert('Mangler title i metadata for artikkel ' + article_name);
  }
  if (!img) {
    alert('Mangler img i metadata for artikkel ' + article_name);
  }
  if (!header) {
    alert('Mangler header i metadata for artikkel ' + article_name);
  }
};
