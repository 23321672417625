import {
  getMaxInPlanBuildingSize,
  getmaxSizeDueToUgrad,
  getStrictestSizeLimit,
  shouldPerformUgradSizeCheck,
} from '../../scenes/PlacementComponents/components/SizeInfo/sizeHelper';
import {OptionsForHandlingInvalidDistanceToRoad} from '../../services/QuestionData/getRoadDistanceData';
import {dispIds, QuestionId} from '../../services/utils/questionIds';
import {AnswerValues} from '../../state/questionTypes';
import {getTextObject} from '../../texts/textObjectHelpers';
import getSelectedTiltakstyper from '../tiltak/getSelectedTiltakstyper';
import {riveTiltakstyper} from '../tiltak/tiltaktypeIds';

export type dispType = 'PLAN' | 'TEK' | 'PBL' | 'VEG' | 'UTEK';

export const dispTypes: {[key in dispType]: string} = {
  PLAN: 'Planer',
  TEK: 'Byggteknisk forskrift (TEK), jf. pbl kapittel 19',
  PBL: 'Plan- og bygningsloven',
  VEG: 'Veglova § 29, jf. § 30',
  UTEK: 'Byggteknisk forskrift for tiltak på eksisterende bebyggelse',
};

export function requiresDispensasjonForQuestion(answers: AnswerValues, question: QuestionId): boolean {
  return getRequieredDispensionsFromQuestions(answers).filter((item) => item.id === question).length > 0;
}

export type TriggeredDispOrUnntak = {id: dispIds; type: dispType; dispHeader?: string; description?: string};

export function getRequieredDispensionsFromQuestions(answerValues: AnswerValues) {
  let questionsWithDispension: TriggeredDispOrUnntak[] = [];

  if (answerValues.HOYDEKRAV_NOT_OK_ACCORDING_TO_PLAN === true) {
    questionsWithDispension.push({
      id: 'HOYDEKRAV_NOT_OK_ACCORDING_TO_PLAN',
      type: 'PLAN',
    });
  }
  if (answerValues.HOYDEKRAV_NOT_OK_ACCORDING_TO_PBL === true) {
    questionsWithDispension.push({
      id: 'HOYDEKRAV_NOT_OK_ACCORDING_TO_PBL',
      type: 'PBL',
    });
  }
  if (answerValues.BEVARINGSSTATUS_EXISTS === true) {
    questionsWithDispension.push({
      id: 'BEVARINGSSTATUS_EXISTS',
      type: 'PLAN',
    });
  }

  if (answerValues.ALLOWED_TO_BUILD_OUTSIDE_BOLIGFORMAAL === false) {
    questionsWithDispension.push({
      id: 'ALLOWED_TO_BUILD_OUTSIDE_BOLIGFORMAAL',
      type: 'PLAN',
    });
  }

  if (answerValues.MAX_BUILDING_HEIGHT === true) {
    questionsWithDispension.push({
      id: 'MAX_BUILDING_HEIGHT',
      type: 'PBL',
    });
  }
  if (answerValues['CHOSEN_HANDLING_OF_DIST_TO_BORDER'] === 'disp') {
    questionsWithDispension.push({
      id: 'CHOSEN_HANDLING_OF_DIST_TO_BORDER',
      type: 'PBL',
    });
  }
  if (answerValues.OTHER_RESTRICTIONS !== undefined && answerValues.OTHER_RESTRICTIONS !== false) {
    questionsWithDispension.push({
      id: 'OTHER_RESTRICTIONS',
      type: 'PLAN',
    });
  }

  if (answerValues.IS_WITHIN_BUILDING_BORDER === false) {
    questionsWithDispension.push({
      id: 'IS_WITHIN_BUILDING_BORDER',
      type: 'PLAN',
    });
  }

  if (answerValues.PROPERTY_ALREADY_MAX_UTNYTTET) {
    questionsWithDispension.push({
      id: 'PROPERTY_ALREADY_MAX_UTNYTTET',
      type: 'PLAN',
    });
  }

  const hasSizeRelatedAnswers =
    answerValues.TILTAK_AREA_VALUE_BRA !== undefined || answerValues.TILTAK_AREA_VALUE_BYA !== undefined;
  const strictestSizeLimit = hasSizeRelatedAnswers ? getStrictestSizeLimit(answerValues) : undefined;

  if (strictestSizeLimit?.type !== 'ua') {
    //Means ansvarsrett is triggered if size is too big (no need to trigger disps)

    if (isUgradBYALimitDispTriggered(answerValues) || isBYAPlanLimitDispTriggered(answerValues)) {
      const description = getUnderMapDescriptionForBYA_disp(answerValues);

      questionsWithDispension.push({
        id: 'TILTAK_AREA_VALUE_BYA',
        type: 'PLAN',
        dispHeader: 'Størrelse overskrider det som er tillatt ifølge planene for din eiendom',
        description: description,
      });
    }

    if (isUgradBRALimitDispTriggered(answerValues) || isBRAPlanLimitDispTriggered(answerValues)) {
      questionsWithDispension.push({
        id: 'TILTAK_AREA_VALUE_BRA',
        type: 'PLAN',
      });
    }
  }

  if (answerValues.IS_CLOSE_TOO_SEA === true) {
    questionsWithDispension.push({
      id: 'IS_CLOSE_TOO_SEA',
      type: 'PBL',
      dispHeader: 'Det er mindre enn 100 meter til kystlinje',
      description: getTextObject().steps.PLASSERING.IS_CLOSE_TOO_SEA.dispHelpText,
    });
  }
  if (
    (answerValues.HANDLING_OF_INVALID_DISTANCE_TO_FYLKESVEG as OptionsForHandlingInvalidDistanceToRoad) ===
    'dispensasjon'
  ) {
    //Either veglov or planbestemmelse is the reason for the disp
    const dispType = answerValues.HAS_FULFILLED_VEGLOV_DISTANCE_TO_FYLKESVEG === false ? 'VEG' : 'PLAN';
    questionsWithDispension.push({
      id: 'HANDLING_OF_INVALID_DISTANCE_TO_FYLKESVEG',
      type: dispType,
    });
  }
  if (
    (answerValues.HANDLING_OF_INVALID_DISTANCE_TO_RIKSVEG as OptionsForHandlingInvalidDistanceToRoad) === 'dispensasjon'
  ) {
    //Either veglov or planbestemmelse is the reason for the disp
    const dispType = answerValues.HAS_FULFILLED_VEGLOV_DISTANCE_TO_RIKSVEG === false ? 'VEG' : 'PLAN';
    questionsWithDispension.push({
      id: 'HANDLING_OF_INVALID_DISTANCE_TO_RIKSVEG',
      type: dispType,
    });
  }
  if (answerValues.HAS_FULFILLED_DISTANCE_GIVEN_IN_PLANER_TO_KOMMUNALVEG === false) {
    questionsWithDispension.push({
      id: 'HAS_FULFILLED_DISTANCE_GIVEN_IN_PLANER_TO_KOMMUNALVEG',
      type: 'PLAN',
    });
  }
  if (answerValues.HAS_FULFILLED_VEGLOV_DISTANCE_TO_KOMMUNALVEG === false) {
    questionsWithDispension.push({
      id: 'HAS_FULFILLED_VEGLOV_DISTANCE_TO_KOMMUNALVEG',
      type: 'VEG',
    });
  }
  if (
    answerValues.HANDLING_OF_INVALID_DISTANCE_TO_SYKKEL_GANGVEG &&
    (answerValues.HANDLING_OF_INVALID_DISTANCE_TO_SYKKEL_GANGVEG as OptionsForHandlingInvalidDistanceToRoad[]).includes(
      'dispensasjon'
    )
  ) {
    //Either veglov or planbestemmelse is the reason for the disp
    const dispType = answerValues.HAS_FULFILLED_VEGLOV_DISTANCE_TO_SYKKEL_GANGVEG === false ? 'VEG' : 'PLAN';
    questionsWithDispension.push({
      id: 'HANDLING_OF_INVALID_DISTANCE_TO_SYKKEL_GANGVEG',
      type: dispType,
    });
  }

  return questionsWithDispension;
}

const isUgradBYALimitDispTriggered = (answerValues: AnswerValues) => {
  const ugradBYA = answerValues.UGRAD_CALCULATE_BYA as boolean;
  if (ugradBYA !== true) return false;

  const tiltakAreaToCompare = answerValues.TILTAK_AREA_VALUE_BYA as number | undefined;

  const UGRAD_LIMIT_DISP_TRIGGERED =
    tiltakAreaToCompare !== undefined &&
    tiltakAreaToCompare > getmaxSizeDueToUgrad(answerValues) &&
    answerValues.PROPERTY_ALREADY_MAX_UTNYTTET !== true && //Only trigger if disp is not already triggered due to no more space left on property
    shouldPerformUgradSizeCheck(answerValues);
  return UGRAD_LIMIT_DISP_TRIGGERED;
};

const isUgradBRALimitDispTriggered = (answerValues: AnswerValues) => {
  const ugradBYA = answerValues.UGRAD_CALCULATE_BYA as boolean;
  if (ugradBYA !== false) return false;

  const tiltakAreaToCompare = answerValues.TILTAK_AREA_VALUE_BRA as number | undefined;

  const UGRAD_LIMIT_DISP_TRIGGERED =
    tiltakAreaToCompare !== undefined &&
    tiltakAreaToCompare > getmaxSizeDueToUgrad(answerValues) &&
    answerValues.PROPERTY_ALREADY_MAX_UTNYTTET !== true && //Only trigger if disp is not already triggered due to no more space left on property
    shouldPerformUgradSizeCheck(answerValues);
  return UGRAD_LIMIT_DISP_TRIGGERED;
};

const isBYAPlanLimitDispTriggered = (answerValues: AnswerValues) => {
  const ugradBYA = answerValues.UGRAD_CALCULATE_BYA as boolean;
  if (ugradBYA !== true) return false;

  const tiltakAreaToCompare = answerValues.TILTAK_AREA_VALUE_BYA as number | undefined;
  const planLimit = getMaxInPlanBuildingSize(answerValues);

  const BYA_PLAN_LIMIT_DISP_TRIGGERED =
    tiltakAreaToCompare !== undefined && planLimit !== undefined && tiltakAreaToCompare > planLimit;

  return BYA_PLAN_LIMIT_DISP_TRIGGERED;
};

const isBRAPlanLimitDispTriggered = (answerValues: AnswerValues) => {
  const ugradBYA = answerValues.UGRAD_CALCULATE_BYA as boolean;
  if (ugradBYA !== false) return false;

  const tiltakAreaToCompare = answerValues.TILTAK_AREA_VALUE_BRA as number | undefined;
  const planLimit = getMaxInPlanBuildingSize(answerValues);

  const BRA_PLAN_LIMIT_DISP_TRIGGERED =
    tiltakAreaToCompare !== undefined &&
    answerValues.TILTAK_AREA_VALUE_BRA !== undefined &&
    planLimit !== undefined &&
    tiltakAreaToCompare > planLimit;

  return BRA_PLAN_LIMIT_DISP_TRIGGERED;
};

export const getBRADispReason = (answerValues: AnswerValues) => {
  //There are two reasons for this disp to occur.
  // 1. Overpasses limit in plan
  // 2. Overpasses ugrad limit
  const tiltakArea = answerValues.TILTAK_AREA_VALUE_BRA;

  const overpassedUgradLimit = isUgradBRALimitDispTriggered(answerValues);
  const overpassesPlanLimit = isBRAPlanLimitDispTriggered(answerValues);
  if (overpassesPlanLimit && overpassedUgradLimit) {
    return `Det du ønsker å bygge har et bruttoareal på ${(tiltakArea as number).toFixed(
      0
    )} m² BRA som er både større enn det tilgjengelige arealet på din eiendom (${getmaxSizeDueToUgrad(
      answerValues
    )} m² BRA), og større enn den oppgitte maks-størrelsen i din plan (${getMaxInPlanBuildingSize(
      answerValues
    )} m² BRA).`;
  } else if (overpassedUgradLimit) {
    return `Det du ønsker å bygge har et bruttoareal på ${(tiltakArea as number).toFixed(
      0
    )} m² BRA som er større enn det tilgjengelige arealet på din eiendom (${getmaxSizeDueToUgrad(
      answerValues
    )} m² BRA).`;
  } else if (overpassesPlanLimit) {
    return `Det du ønsker å bygge har et bruttoareal på ${(tiltakArea as number).toFixed(
      0
    )} m² BRA som er større enn den oppgitte maks-størrelsen i din plan (${getMaxInPlanBuildingSize(
      answerValues
    )} m² BRA).`;
  } else {
    return '';
  }
};

export const getBYADispReason = (answerValues: AnswerValues) => {
  //There are two reasons for this disp to occur.
  // 1. Overpasses limit in plan
  // 2. Overpasses ugrad limit
  const tiltakArea = answerValues.TILTAK_AREA_VALUE_BYA;

  const overpassedUgradLimit = isUgradBYALimitDispTriggered(answerValues);
  const overpassesPlanLimit = isBYAPlanLimitDispTriggered(answerValues);
  if (overpassesPlanLimit && overpassedUgradLimit) {
    return `Det du ønsker å bygge har et areal på ${(tiltakArea as number).toFixed(
      0
    )} m² BYA som er både større enn det tilgjengelige arealet på din eiendom (${getmaxSizeDueToUgrad(
      answerValues
    )} m² BYA), og større enn den oppgitte maks-størrelsen i din plan (${getMaxInPlanBuildingSize(
      answerValues
    )} m² BYA).`;
  } else if (overpassedUgradLimit) {
    return `Det du ønsker å bygge har et areal på ${(tiltakArea as number).toFixed(
      0
    )} m² BYA som er større enn det tilgjengelige arealet på din eiendom (${getmaxSizeDueToUgrad(
      answerValues
    )} m² BYA).`;
  } else if (overpassesPlanLimit) {
    return `Det du ønsker å bygge har et areal på ${(tiltakArea as number).toFixed(
      0
    )} m² BYA som er større enn den oppgitte maks-størrelsen i din plan (${getMaxInPlanBuildingSize(
      answerValues
    )} m² BYA).`;
  } else {
    return '';
  }
};

//For automatiske BYA utregninger
const getUnderMapDescriptionForBYA_disp = (answerValues: AnswerValues) => {
  //There are two reasons for this disp to occur.
  // 1. Overpasses limit in plan
  // 2. Overpasses ugrad limit
  const tiltakArea = answerValues.TILTAK_AREA_VALUE_BYA;
  if (!tiltakArea) return;
  const overpassedUgradLimit = isUgradBYALimitDispTriggered(answerValues);
  const overpassesPlanLimit = isBYAPlanLimitDispTriggered(answerValues);
  if (overpassesPlanLimit && overpassedUgradLimit) {
    return `Bygget du har tegnet har et areal på ${(tiltakArea as number).toFixed(
      0
    )} m² BYA som er større enn både det tilgjengelige arealet på din eiendom (${getmaxSizeDueToUgrad(
      answerValues
    )} m² BYA), og større enn den oppgitte maks-størrelsen i din plan (${getMaxInPlanBuildingSize(
      answerValues
    )} m² BYA). Du kan enten justere ned størrelsen på bygget, eller søke om dispensasjon.`;
  } else if (overpassedUgradLimit) {
    return `Bygget du har tegnet har et areal på ${(tiltakArea as number).toFixed(
      0
    )} m² BYA som er større enn det tilgjengelige arealet på din eiendom (${getmaxSizeDueToUgrad(
      answerValues
    )} m² BYA). Du kan enten justere ned størrelsen på bygget, eller søke om dispensasjon.`;
  } else if (overpassesPlanLimit) {
    return `Bygget du har tegnet har et areal på ${(tiltakArea as number).toFixed(
      0
    )} m² BYA som er større enn den oppgitte maks-størrelsen i din plan (${getMaxInPlanBuildingSize(
      answerValues
    )} m² BYA). Du kan enten justere ned størrelsen på bygget, eller søke om dispensasjon.`;
  } else {
    return '';
  }
};

export const getDispReason = (answerValues: AnswerValues): {[key in dispIds]: string} => {
  const isRiveTiltakstype = riveTiltakstyper.includes(getSelectedTiltakstyper(answerValues)[0]);
  return {
    CHOSEN_HANDLING_OF_DIST_TO_BORDER: getTextObject().steps.PLASSERING.DISTANCE_TO_NEIGHBOURS_BORDER.stepDispText,
    MAX_BUILDING_HEIGHT: getTextObject().steps.REGULATION.MAX_BUILDING_HEIGHT.stepDispText,
    OTHER_RESTRICTIONS: getTextObject().steps.REGULATION.OTHER_RESTRICTIONS.stepDispText,
    IS_CLOSE_TOO_SEA: getTextObject().steps.PLASSERING.IS_CLOSE_TOO_SEA.stepDispText,
    TILTAK_AREA_VALUE_BYA: getBYADispReason(answerValues),
    TILTAK_AREA_VALUE_BRA: getBRADispReason(answerValues),
    IS_WITHIN_BUILDING_BORDER: getTextObject().steps.REGULATION.IS_WITHIN_BUILDING_BORDER.stepDispText,
    TEKNISKE_KRAV_OPPFYLT_GAMMELT: getTextObject().steps.TEKNISKE_KRAV.TEKNISKE_KRAV_OPPFYLT.stepDispText,
    TEKNISKE_KRAV_OPPFYLT_NYTT: getTextObject().steps.TEKNISKE_KRAV.TEKNISKE_KRAV_OPPFYLT.stepDispText,
    TEKNISKE_KRAV_OPFYLT_NYTT_BYGG: getTextObject().steps.TEKNISKE_KRAV.TEKNISKE_KRAV_OPPFYLT.stepDispText,
    HOYDEKRAV_NOT_OK_ACCORDING_TO_PLAN: `Du har oppgitt at du skal ${
      isRiveTiltakstype ? 'rive' : 'bygge'
    } er høyere enn det høydekravet på din plan tillater.`,
    HOYDEKRAV_NOT_OK_ACCORDING_TO_PBL: `Du har oppgitt at du skal ${
      isRiveTiltakstype ? 'rive' : 'bygge'
    } høyere enn det som er tillatt i plan- og bygningsloven.`,
    BEVARINGSSTATUS_EXISTS: getTextObject().steps.REGULATION.BEVARINGSSTATUS_EXISTS.stepDispText,
    ALLOWED_TO_BUILD_OUTSIDE_BOLIGFORMAAL: `Å ${
      isRiveTiltakstype ? 'rive' : 'bygge'
    } i området som ikke regulert til boligformål/bebyggelse krever at du søker med dispensasjon.`,
    PROPERTY_ALREADY_MAX_UTNYTTET:
      'Din eiendom er allerede utnyttet maksimalt basert på din oppgitte informasjon, og du må derfor søke dispensasjon for å kunne bygge mer.',
    HANDLING_OF_INVALID_DISTANCE_TO_SYKKEL_GANGVEG: `Du har oppgitt at du skal ${
      isRiveTiltakstype ? 'rive' : 'bygge'
    } nærmere gang-/sykkelvei enn det som er tillatt på din eiendom.`,
    HANDLING_OF_INVALID_DISTANCE_TO_FYLKESVEG: `Du har oppgitt at du skal ${
      isRiveTiltakstype ? 'rive' : 'bygge'
    } nærmere fylkesvei enn det som er tillatt på din eiendom.`,
    HANDLING_OF_INVALID_DISTANCE_TO_RIKSVEG: `Du har oppgitt at du skal ${
      isRiveTiltakstype ? 'rive' : 'bygge'
    } nærmere riksvei enn det som er tillatt på din eiendom.`,
    HANDLING_OF_INVALID_DISTANCE_TO_KOMMUNALVEG: `Du har oppgitt at du skal ${
      isRiveTiltakstype ? 'rive' : 'bygge'
    } nærmere kommunal vei enn det som er tillatt på din eiendom.`,
    HAS_FULFILLED_DISTANCE_GIVEN_IN_PLANER_TO_KOMMUNALVEG: `Du har oppgitt at du skal ${
      isRiveTiltakstype ? 'rive' : 'bygge'
    } nærmere kommunal vei enn det som er tillatt på din eiendom.`,
    HAS_FULFILLED_VEGLOV_DISTANCE_TO_KOMMUNALVEG: `Du har oppgitt at du skal ${
      isRiveTiltakstype ? 'rive' : 'bygge'
    } nærmere kommunal vei enn det som er tillatt på din eiendom.`,
  };
};
