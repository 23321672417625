import React from 'react';
import NkLink from '../../../components/NkLink/NkLink';
import NkTypography from '../../../components/NkTypography/NkTypography';
import {getConfigValue} from '../../../config';
import {PropertyObject} from '../../../state/buildingSiteState';
import {getMunicipalityPrice} from '../../../state/productState';
import {InfoPagesRoutes} from '../../../utils/routes/InfoPagesRoutes';
import PropertySearch from '../../SelectProperty/PropertySearch';
import './AddressSearchPrice.scss';
import useProducts from '../../../services/ebyggesokServices/useProducts';

const AddressSearchPrice = () => {
  const {availableProducts} = useProducts();

  const defaultPrice = getConfigValue('ebyggesokPrice');
  const [address, setAdress] = React.useState<PropertyObject | undefined>(undefined);
  const [price, setPrice] = React.useState<string>(defaultPrice);

  const onSearch = (selected: PropertyObject, fromSearch: boolean = false) => {
    setAdress(selected);
    if (availableProducts?.length) {
      const price = getMunicipalityPrice(availableProducts, selected.knr);
      if (price !== undefined) setPrice(price.toString());
    }
  };

  return (
    <>
      <div className='AddressSearchPrice'>
        <NkTypography tag='span' type='heading5' style={{fontWeight: 700, marginBottom: 10, marginTop: 0}}>
          Se pris for deg
        </NkTypography>
        <PropertySearch onSelectedProperty={onSearch} />
        {address && (
          <>
            <NkTypography
              type='heading3'
              color='green'
              style={{marginTop: 17, marginBottom: 0, fontWeight: 550, fontSize: 30}}
            >{`${price},-`}</NkTypography>
            <NkTypography type='heading4' style={{marginTop: 12, fontSize: 18}}>
              For innsending av søknad i din kommune
            </NkTypography>
          </>
        )}
      </div>
      <div className='byggesaksgebyr-info'>
        <NkTypography type='normalParagraph'>{'I tillegg må du betale '}</NkTypography>
        <NkLink href={InfoPagesRoutes.article.path + '/byggesaksgebyr'} size='normal' style={{marginLeft: 5}}>
          kommunale byggesaksgebyrer
        </NkLink>
      </div>
    </>
  );
};

export default AddressSearchPrice;
