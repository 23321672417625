import {createSyncStoragePersister} from '@tanstack/query-sync-storage-persister';
import {QueryClient} from '@tanstack/react-query';
import {PersistQueryClientProvider} from '@tanstack/react-query-persist-client';
import {createBrowserHistory, History} from 'history';
import React, {Suspense, useReducer} from 'react';
import ReactDOM from 'react-dom';
import {Router, useLocation} from 'react-router-dom';
import './App/index.scss';
import InfoPagesAppContext from './App/InfoPagesApp/InfoPagesAppContext';
import Chat from './components/Chat/Chat';
import {useCustomers} from './services/ebyggesokServices/useCustomers';
import {
  initialMunicipalityState,
  MunicipalityContext,
  municipalityReducer,
  MunicipalityState,
} from './state/municipalityStore';
import {
  initialStatusAlertState,
  StatusAlertContext,
  statusAlertReducer,
  StatusAlertState,
} from './state/statusAlertState';
import {initialUserState, UserContext, userReducer, UserState} from './state/userStore';
import {ApplicationRoutes} from './utils/routes/ApplicationRoutes';
import {InfoPagesRoutes} from './utils/routes/InfoPagesRoutes';
import {loadStore} from './utils/sessionstorage';

const AppContext = React.lazy(() => import('./App/ApplicationApp/AppContext'));

const customHistory: History = createBrowserHistory();

const queryClient = new QueryClient();
const sessionStoragePersister = createSyncStoragePersister({
  storage: window.sessionStorage,
});

function Index() {
  const allRoutes = Object.values(ApplicationRoutes).concat(Object.values(InfoPagesRoutes));
  const location = useLocation();

  const selectedroute = allRoutes.find((b) =>
    location.pathname.split('/').length > 2
      ? '/' + location.pathname.split('/')[1] === b.path
      : location.pathname === b.path
  );
  useCustomers(); // Want to load customers before using it.

  const existingUserState = loadStore('userStore') as UserState;
  const [userState, userDispatch] = useReducer(userReducer, existingUserState || initialUserState);

  const existingStatusAlertState = loadStore('statusAlertStore') as StatusAlertState;
  const [statusAlertState, statusAlertDispatch] = useReducer(
    statusAlertReducer,
    existingStatusAlertState || initialStatusAlertState
  );

  const existingMunicipalityState = loadStore('municipalityStore') as MunicipalityState;
  const [municipalityState, municipalityDispatch] = useReducer(
    municipalityReducer,
    existingMunicipalityState || initialMunicipalityState
  );

  //Fetch location to force rerender on location change
  const onInfoPage = !!(selectedroute && selectedroute.isInfoPage && location);

  return (
    <Suspense
      fallback={
        <div style={{margin: 'auto', textAlign: 'center', paddingTop: 80}}>
          <img
            width='160'
            height='160'
            alt='eByggesøk logo'
            src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1614161828/eBygges%C3%B8k%20UA/eBygges%C3%B8k.svg'
          />
          <br />
        </div>
      }
    >
      <UserContext.Provider value={{userState, userDispatch}}>
        <StatusAlertContext.Provider value={{statusAlertState, statusAlertDispatch}}>
          <MunicipalityContext.Provider value={{municipalityState, municipalityDispatch}}>
            {onInfoPage ? (
              <InfoPagesAppContext userDispatch={userDispatch} />
            ) : (
              <AppContext userDispatch={userDispatch} />
            )}
          </MunicipalityContext.Provider>
        </StatusAlertContext.Provider>
      </UserContext.Provider>
      <Chat />
    </Suspense>
  );
}

ReactDOM.render(
  <PersistQueryClientProvider client={queryClient} persistOptions={{persister: sessionStoragePersister}}>
    <Router history={customHistory}>
      <Index />
    </Router>
  </PersistQueryClientProvider>,
  document.getElementById('root') as HTMLElement
);
