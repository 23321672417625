import StartNewApplicationButton from '../../StartNewApplicationButton';
import SellingPointRow from './SellingPointRow';
import './SellingPointsList.scss';

type props = {onSokeResultPage?: boolean};
const SellingPointsList = ({onSokeResultPage}: props) => {
  return (
    <div className='SellingPointsList'>
      <>
        <ul className='SellingPointsList'>
          <SellingPointRow text='Digital innsending av byggesøknad, nabovarsel og ferdigattest ' />
          <SellingPointRow text='Veiledning - hva må du søke om?' />
          <SellingPointRow text='Stegvis utfylling av byggesøknaden' />
          <SellingPointRow text='Automatiske analyser av din eiendom' />
          <SellingPointRow text='Raskere behandlingstid ved komplett søknad' />
        </ul>
        {!onSokeResultPage && (
          <div style={{maxWidth: 330, marginTop: 30}}>
            <StartNewApplicationButton text={'Kom i gang med søknaden!'} buttonVersion='middle' />
          </div>
        )}
      </>
    </div>
  );
};
export default SellingPointsList;
