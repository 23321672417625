import Drawer from '@material-ui/core/Drawer';
import {ToiSpinner} from '@norkart/toi-components';
import MenuIcon from 'nka-icons/build/icons/Liste';
import LogOutIcon from 'nka-icons/build/icons/LoggUt';
import CloseIcon from 'nka-icons/build/icons/Lukk';
import React, {Suspense} from 'react';
import {useLocation} from 'react-router-dom';
import {UserContext} from '../../../state/userStore';
import {ApplicationRoutes} from '../../../utils/routes/ApplicationRoutes';
import {InfoPagesRoutes} from '../../../utils/routes/InfoPagesRoutes';
import {shouldDisplayStepper} from '../../../utils/routes/routesHelpers';
import NkButton from '../../Button/NkButton';
import NkLink from '../../NkLink/NkLink';
import './BurgerMenu.scss';

const ProgressStepper = React.lazy(() => import('../../Stepper/ProgressStepper'));

type Props = {logoutIdporten; goToIdPorten};

const BurgerMenu = (props: Props) => {
  const {
    userState: {isLoggedIn},
  } = React.useContext(UserContext);
  const location = useLocation();

  const displayStepper = shouldDisplayStepper(location.pathname);

  const [displayBurgeMenu, setDisplayBurgerMenu] = React.useState(false);
  return (
    <div className='BurgerMenu'>
      <NkButton
        aria-label='Open menu'
        className='openMenyButton'
        type='default'
        onClick={() => {
          setDisplayBurgerMenu(true);
        }}
        endIcon={<MenuIcon />}
        ariaExpanded={displayBurgeMenu}
        ariaHasPopup={'menu'}
      >
        Meny
      </NkButton>
      {displayBurgeMenu && (
        <>
          <Drawer
            anchor={'right'}
            open={displayBurgeMenu}
            onClose={() => setDisplayBurgerMenu(false)}
            className='BurgerMenu'
          >
            <div className='menu'>
              <button
                className='close-btn'
                onClick={() => {
                  setDisplayBurgerMenu(false);
                }}
              >
                <CloseIcon />
              </button>
              <hr style={{border: '1px solid #d2d2d2', margin: '2px 0'}} />
              <div className='mainButtonsArea'>
                <div style={{padding: '26px 0', display: 'flex', gap: 20}}>
                  <NkLink className='privat' href='/'>
                    Privat
                  </NkLink>
                  <NkLink className='bedrift' href='https://www.ebyggesokproff.no/velkommen'>
                    Bedrift
                  </NkLink>
                </div>
                {isLoggedIn ? (
                  <NkLink href={ApplicationRoutes.myApplications.path}>Mine Søknader</NkLink>
                ) : (
                  <NkButton type='link' onClick={props.goToIdPorten}>
                    Logg inn
                  </NkButton>
                )}
                <div style={{borderBottom: '1px solid #D2D2D2', margin: '15px 0'}} />
                <NkLink style={{marginTop: 15}} href={InfoPagesRoutes.article.path + '/pris'}>
                  Pris
                </NkLink>
                <div style={{borderBottom: '1px solid #D2D2D2', margin: '15px 0'}} />
                <NkLink style={{marginTop: 15}} href={InfoPagesRoutes.faq.path}>
                  Ofte stilte spørsmål
                </NkLink>
              </div>
              {displayStepper && (
                <div className='soknadStepper'>
                  <p style={{marginTop: 20, marginLeft: 30, fontWeight: 300}}>Søknadssteg:</p>
                  <Suspense fallback={<ToiSpinner />}>
                    <ProgressStepper />
                  </Suspense>
                </div>
              )}
              <div className='bottom-area'>
                {isLoggedIn && (
                  <>
                    <NkButton
                      className='logout'
                      type='none'
                      startIcon={<LogOutIcon />}
                      style={{margin: 'auto', marginBottom: 10, minWidth: 'fit-content', height: 'fit-content'}}
                      onClick={props.logoutIdporten}
                    >
                      Logg ut
                    </NkButton>
                    <div className='id-porten'>
                      <img
                        alt='Idporten logo'
                        width='24px'
                        height='24px'
                        src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1597735348/eBygges%C3%B8k%20UA/id-porten_logo.png'
                      />
                      <p style={{fontSize: 14, marginLeft: 10}}>ID-porten</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Drawer>
        </>
      )}
    </div>
  );
};
export default BurgerMenu;
