import {tiltakTypeDescription} from '../../../../utils/tiltak/tiltakstypeDescriptions';
import {TiltaksTypeOptions} from '../optionTypes';
import {allTiltakstypeId} from '../../../../utils/tiltak/tiltaktypeIds';
import {TiltakQuestionOption} from '../../../../services/utils/QuestionTypes';

const allBuildOptions: {[key in allTiltakstypeId]?: TiltakQuestionOption} = {
  nyttbyggunder70m2: {
    value: 'nyttbyggunder70m2',
    text: tiltakTypeDescription.nyttbyggunder70m2.displayToUser,
    helpText: 'Kan være garasje eller annet frittliggende bygg (eks. bod/drivhus)',
    imageUrl: '/images/cardIcons/garage.svg',
  },
  nyttbyggover70m2: {
    value: 'nyttbyggover70m2',
    text: 'Frittliggende bygg over 70m²',
    helpText: 'Kan være en garasje eller annet frittliggende bygg (eks. bod/drivhus)',
    imageUrl: '/images/cardIcons/garage.svg',
  },
  nyttbyggunder50m2: {
    value: 'nyttbyggunder50m2',
    text: 'Frittliggende bygg under 50m²',
    helpText: 'Kan være garasje eller annet frittliggende bygg (eks. bod/drivhus)',
    imageUrl: '/images/cardIcons/garage.svg',
  },
  annet: {
    value: 'annet',
    text: tiltakTypeDescription.annet.displayToUser,
    imageUrl: '/images/cardIcons/annet.svg',
  },
  plasseringmidlertidig: {
    value: 'plasseringmidlertidig',
    text: tiltakTypeDescription.plasseringmidlertidig.displayToUser,
    imageUrl: '/images/cardIcons/midlertidig_plassering.svg',
  },
  tilbyggunder50m2: {
    value: 'tilbyggunder50m2',
    text: tiltakTypeDescription.tilbyggunder50m2.displayToUser,
    helpText:
      'Utvendig tilbygg der utvidelse av grunnflaten blir mellom 15m² og 50m² (eks. ved- sykkelbod, balkong, terrasse)',
    imageUrl: '/images/cardIcons/tilbygg.svg',
  },
  tilbyggOver50m2: {
    value: 'tilbyggOver50m2',
    text: 'Tilbygg over 50m²', // Trigger ansvarsrett.
    helpText: 'Utvendig tilbygg der utvidelse av grunnflaten blir mer enn 50m² (eks. bod/garasje)',
    imageUrl: '/images/cardIcons/tilbygg.svg',
  },
  tilbyggUnder15m2: {
    value: 'tilbyggUnder15m2',
    text: 'Tilbygg under 15m²', // Trigger melding.
    helpText:
      'Utvendig tilbygg der utvidelse av grunnflaten er under 15m²,  f.eks balkong, veranda, terrasse, åpent overbygget inngangsparti eller ved- sykkelbod',
    imageUrl: '/images/cardIcons/tilbygg.svg',
  },
  nyttbyggdriftsbygningunder1000m2: {
    value: 'nyttbyggdriftsbygningunder1000m2',
    text: tiltakTypeDescription.nyttbyggdriftsbygningunder1000m2.displayToUser,
    helpText: 'Kan være driftsbygning for f.eks  jordbruk, husdyrbruk, seterdrift, hagebruk, gartneri, skogsdrift',
    imageUrl: '/images/cardIcons/naeringsbygg.svg',
  },
  nyttbyggdriftsbygningover1000m2: {
    value: 'nyttbyggdriftsbygningover1000m2',
    text: 'Driftsbygning i landbruk over 1000m²',
    helpText: 'Kan være driftsbygning for f.eks  jordbruk, husdyrbruk, seterdrift, hagebruk, gartneri, skogsdrift',
    imageUrl: '/images/cardIcons/naeringsbygg.svg',
  },
  driftsbygningtilbyggunder1000m2: {
    value: 'driftsbygningtilbyggunder1000m2',
    text: tiltakTypeDescription.driftsbygningtilbyggunder1000m2.displayToUser,
    helpText: '',
    imageUrl: '/images/cardIcons/driftsbygning.svg',
  },
  driftsbygningtilbyggOver1000m2: {
    value: 'driftsbygningtilbyggOver1000m2',
    text: 'Tilbygg til driftsbygning i landbruket på over 1000m²',
    helpText: '',
    imageUrl: '/images/cardIcons/driftsbygning.svg',
  },
  skiltreklamefrittstaendeliten: {
    value: 'skiltreklamefrittstaendeliten',
    text: tiltakTypeDescription.skiltreklamefrittstaendeliten.displayToUser,
    imageUrl: '/images/cardIcons/reklame_frittstående.svg',
  },
  skiltreklamefasadeliten: {
    value: 'skiltreklamefasadeliten',
    text: tiltakTypeDescription.skiltreklamefasadeliten.displayToUser,
    imageUrl: '/images/cardIcons/reklame_fasade.svg',
  },
};

const allOptions = [
  allBuildOptions.nyttbyggover70m2,
  allBuildOptions.nyttbyggunder70m2,
  allBuildOptions.nyttbyggunder50m2,
  allBuildOptions.tilbyggOver50m2,
  allBuildOptions.tilbyggunder50m2,
  allBuildOptions.tilbyggUnder15m2,
  allBuildOptions.plasseringmidlertidig,
  allBuildOptions.annet,
  allBuildOptions.nyttbyggdriftsbygningunder1000m2,
  allBuildOptions.nyttbyggdriftsbygningover1000m2,
  allBuildOptions.driftsbygningtilbyggunder1000m2,
  allBuildOptions.driftsbygningtilbyggOver1000m2,
  allBuildOptions.skiltreklamefrittstaendeliten,
  allBuildOptions.skiltreklamefasadeliten,
];

const garageOptions = [
  allBuildOptions.nyttbyggover70m2,
  allBuildOptions.nyttbyggunder70m2,
  allBuildOptions.nyttbyggunder50m2,
  allBuildOptions.tilbyggOver50m2,
  allBuildOptions.tilbyggunder50m2,
  allBuildOptions.tilbyggUnder15m2,
];

const boligOptions = [
  allBuildOptions.nyttbyggover70m2,
  allBuildOptions.nyttbyggunder70m2,
  allBuildOptions.nyttbyggunder50m2,
  allBuildOptions.tilbyggOver50m2,
  allBuildOptions.tilbyggunder50m2,
  allBuildOptions.tilbyggUnder15m2,
];

const fritidsboligOptions = [
  allBuildOptions.nyttbyggover70m2,
  allBuildOptions.nyttbyggunder70m2,
  allBuildOptions.nyttbyggunder50m2,
  allBuildOptions.tilbyggOver50m2,
  allBuildOptions.tilbyggunder50m2,
  allBuildOptions.tilbyggUnder15m2,
];

const annetOptions = [
  allBuildOptions.nyttbyggover70m2,
  allBuildOptions.nyttbyggunder70m2,
  allBuildOptions.nyttbyggunder50m2,
  allBuildOptions.nyttbyggdriftsbygningunder1000m2,
  allBuildOptions.nyttbyggdriftsbygningover1000m2,
  allBuildOptions.tilbyggOver50m2,
  allBuildOptions.tilbyggunder50m2,
  allBuildOptions.tilbyggUnder15m2,
  allBuildOptions.driftsbygningtilbyggunder1000m2,
  allBuildOptions.driftsbygningtilbyggOver1000m2,
];

const frittliggendeByggOptions = [
  allBuildOptions.nyttbyggover70m2,
  allBuildOptions.nyttbyggunder70m2,
  allBuildOptions.nyttbyggunder50m2,
  allBuildOptions.nyttbyggdriftsbygningover1000m2,
  allBuildOptions.nyttbyggdriftsbygningunder1000m2,
];

const tilbyggOptions = [
  allBuildOptions.tilbyggOver50m2,
  allBuildOptions.tilbyggunder50m2,
  allBuildOptions.tilbyggUnder15m2,
  allBuildOptions.driftsbygningtilbyggunder1000m2,
  allBuildOptions.driftsbygningtilbyggOver1000m2,
];

export const buildOptions = {
  questionId: 'TILTAK_TYPE_BUILD',
  bolig_options: boligOptions,
  fritidsbolig_options: fritidsboligOptions,
  annet_options: annetOptions,
  garasje_options: garageOptions,
  all_options: allOptions,
  tilbyggOptions: tilbyggOptions,
  frittliggendeByggOptions: frittliggendeByggOptions,
} as TiltaksTypeOptions;
