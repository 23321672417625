import MediaQuery from 'react-responsive';
import {BREAK_MEDIUM, BREAK_SMALL} from './BreakPoints';

export const TabletAndMobileContainer = (props) => (
  <MediaQuery maxWidth={BREAK_MEDIUM - 1}>{props.children}</MediaQuery>
);
export const MobileContainer = (props) => <MediaQuery maxWidth={BREAK_SMALL}>{props.children}</MediaQuery>;

export const DesktopContainer = (props) => <MediaQuery minWidth={BREAK_MEDIUM}>{props.children}</MediaQuery>;

export const DesktopAndTabletContainer = (props) => (
  <MediaQuery minWidth={BREAK_SMALL + 1}>{props.children}</MediaQuery>
);
