import React from 'react';
import './PageAnnouncer.scss';

const PageAnnouncer = () => {
  const myRef = React.createRef<HTMLParagraphElement>();

  React.useEffect(() => {
    myRef.current!.focus();
  }, []);

  return (
    <p className='screenreader-summary' tabIndex={-1} ref={myRef}>
      {document.title}
    </p>
  );
};

export default PageAnnouncer;
