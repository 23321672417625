import NkTypography from '../../../components/NkTypography/NkTypography';
import SellingPointsList from './SellingPointsList/SellingPointsList';
import './ThisIsEbyggesok.scss';

type props = {onSokeResultPage?: boolean; background: 'white' | 'green'};

const ThisIsEbyggesok = (props: props) => {
  return (
    <section className={'ThisIsEbyggesok ' + props.background}>
      <div className='content'>
        <div className='text-left'>
          <NkTypography type='smallGreenHeader' style={{marginBottom: 32}}>
            Enklere søknad
          </NkTypography>
          <NkTypography type='heading2'>Dette gjør eByggesøk for deg</NkTypography>
          <NkTypography type='largeParagraph' style={{maxWidth: 450, marginTop: 32, marginBottom: 32}}>
            eByggesøk er en veileder som hjelper deg med både utfylling og innsending av din digitale søknad. Vi guider
            deg igjennom en jungel av begreper og skjemaer og veileder deg helt fra du lurer på om du trenger å søke,
            til en ferdig innsendt byggesøknad og ferdigattest.
          </NkTypography>
        </div>
        <div style={{marginTop: 10}}>
          <SellingPointsList onSokeResultPage={props.onSokeResultPage} />
        </div>
      </div>
    </section>
  );
};
export default ThisIsEbyggesok;
