import React from 'react';
import {Helmet} from 'react-helmet-async';
import DOMPurify from 'dompurify';
import {appInsightsInstance} from '../../../AppInsightsSetup';
import {nkColors} from '../../../colors';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import {DesktopContainer} from '../../../components/containers/DeviceContainers';
import FloatingCard from '../../../components/FloatingCard/FloatingCard';
import NkLink from '../../../components/NkLink/NkLink';
import NkTypography from '../../../components/NkTypography/NkTypography';
import {getTextObject} from '../../../texts/textObjectHelpers';
import isProd from '../../../utils/isProd';
import {InfoPagesRoutes} from '../../../utils/routes/InfoPagesRoutes';
import {setTitle} from '../../../utils/setTitle';
import ProcessIllustration from '../../LandingPage/ProcessIllustration/ProcessIllustration';
import StartNewApplicationButton from '../../LandingPage/StartNewApplicationButton';
import AddressSearchPrice from '../AddressSearch/AddressSearchPrice';
import './ArticlePage.scss';
import DisplayArticleMetadata from './DisplayArticleMetadata';
import {defaultArticleSEOTags, getArticleMetaData} from './helpers';
import TransparentFooterWrapper from './TransparentFooterWrapper';
import WhiteFooterWrapper from './WhiteFooterWrapper';

const ArticlePage = () => {
  const article_name = window.location.pathname.split('/').pop() as string;

  React.useEffect(() => {
    const title = getArticleMetaData('title', article_name);
    setTitle(title);
  }, []);

  const getArticleContent = () => {
    const article_content = article_name && getTextObject().articles[article_name].split('[start]')[1];
    const sections = article_content.split('-row-') as string[];
    const rowImages: {url: string; alt?: string}[] = [{url: ''}]; //init empty image, since first row never has image
    const rowTextContents: string[] = [];

    sections.forEach((section, index) => {
      index % 2 !== 0 &&
        rowImages.push(
          section.includes('url:') ? {url: section.split('url:')[1], alt: section.split('url:')[0]} : {url: section}
        );
      index % 2 === 0 && rowTextContents.push(section);
    });

    // Every other section is text and image.
    return rowTextContents.map((text, index) => ({
      text: text.replace('[kom-i-gang]', '').replace('[adresse-sok]', '').replace('[soknad-prossess]', ''),
      image: rowImages[index],
      startButton: text.includes('[kom-i-gang]'),
      addressSearch: text.includes('[adresse-sok]'),
      soknadProcess: text.includes('[soknad-prossess]'),
    }));
  };

  const trackReadMore = () => {
    appInsightsInstance?.trackEvent({name: 'LesMerOmEbyggesokArticlePage'});
  };

  const domPurifyImageUrl = (url?: string) => {
    return DOMPurify.sanitize(url || '');
  };

  const ArticlePageContent = () => {
    return (
      <div className='article-page article-content'>
        <DesktopContainer>
          {!isProd() && article_name && <DisplayArticleMetadata article_name={article_name} />}
        </DesktopContainer>
        <Helmet>
          <meta
            name='keywords'
            content={getArticleMetaData('tags', article_name) + defaultArticleSEOTags}
            data-rh='true'
          />
          <meta name='description' content={getArticleMetaData('description', article_name)} />
          <link rel='canonical' href={DOMPurify.sanitize('https://www.ebyggesok.no/artikkel/' + article_name)} />
        </Helmet>

        <div
          className='back-div questionHelper'
          style={{maxWidth: 1200, margin: 'auto', marginTop: 80, position: 'relative'}}
        >
          <Breadcrumb
            traces={[
              {name: 'Forside', path: InfoPagesRoutes.base.path},
              {name: 'Artikler', path: InfoPagesRoutes.articles.path},
            ]}
            currentPage={article_name!}
          />
          <DesktopContainer>
            <div style={{position: 'absolute', right: 20, marginTop: 20}}>
              <FloatingCard>
                <NkTypography type='heading3' tag='p' color='white'>
                  Hva er eByggesøk?
                </NkTypography>
                <NkTypography type='normalParagraph' color='white' style={{marginBottom: 10}}>
                  eByggesøk er en veileder som hjelper deg med både utfylling og innsending av din digitale søknad.
                </NkTypography>
                <NkLink href={InfoPagesRoutes.base.path} darkmode onClick={trackReadMore}>
                  Les mer
                </NkLink>
              </FloatingCard>
            </div>
          </DesktopContainer>
        </div>
        {getArticleContent().map((section, index) => (
          <div
            key={index}
            style={{width: '100%', backgroundColor: index % 2 === 0 ? nkColors.background_green : 'white'}}
          >
            <div
              className='section-row questionHelper'
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                maxWidth: 1200,
                paddingBottom: 80,
                paddingTop: 40,
                justifyContent: 'space-between',
                margin: 'auto',
              }}
            >
              {index % 2 === 0 ? (
                <>
                  <div>
                    {section.text.length > 0 && (
                      <NkTypography
                        type='markdown'
                        markdownParagraphStyle={index === 0 ? 'largeParagraph' : 'normalParagraph'}
                        style={{maxWidth: 700}}
                      >
                        {section.text}
                      </NkTypography>
                    )}
                    {section.startButton && (
                      <div style={{marginTop: 50}}>
                        <StartNewApplicationButton text={'Start veileder'} buttonVersion='top' />
                      </div>
                    )}
                    {section.soknadProcess && (
                      <ProcessIllustration background={'white'} style={{marginTop: 100, width: '100%'}} />
                    )}
                    {section.addressSearch && <AddressSearchPrice />}
                  </div>
                  {section.image && section.image.url.length > 1 && (
                    <DesktopContainer>
                      {<img width={260} src={domPurifyImageUrl(section.image.url)} alt={section.image.alt || ''} />}
                    </DesktopContainer>
                  )}
                </>
              ) : (
                <>
                  {section.image && section.image.url.length > 1 && (
                    <DesktopContainer>
                      {<img width={260} src={domPurifyImageUrl(section.image.url)} alt={section.image.alt || ''} />}
                    </DesktopContainer>
                  )}

                  <div>
                    {section.text.length > 0 && (
                      <NkTypography type='markdown' style={{maxWidth: 700}}>
                        {section.text}
                      </NkTypography>
                    )}
                    {section.startButton && (
                      <div style={{marginTop: 50}}>
                        <StartNewApplicationButton text={'Start veileder'} buttonVersion='top' />
                      </div>
                    )}
                    {section.soknadProcess && (
                      <ProcessIllustration
                        background={'white'}
                        style={{marginTop: 100, backgroundColor: 'white', width: '100%'}}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const whiteFooterBackground = getArticleContent().length % 2 === 0;
  if (whiteFooterBackground) {
    return <WhiteFooterWrapper>{ArticlePageContent()}</WhiteFooterWrapper>;
  } else {
    return <TransparentFooterWrapper>{ArticlePageContent()}</TransparentFooterWrapper>;
  }
};
export default ArticlePage;
