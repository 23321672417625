import React from 'react';
import NkLink from '../NkLink/NkLink';
import NkTypography from '../NkTypography/NkTypography';
import HjemIcon from 'nka-icons/build/icons/Hjem';
import {InfoPagesRoutes} from '../../utils/routes/InfoPagesRoutes';
import {getArticleMetaData} from '../../scenes/Articles/ArticlePage/helpers';
type Props = {traces: {path: string; name: string}[]; currentPage: string};

const Breadcrumb = ({traces, currentPage}: Props) => {
  const getArticleName = (currentPage) => {
    const article_name = currentPage.split('/').pop();
    const title = getArticleMetaData('title', article_name);
    return title;
  };

  return (
    <nav style={{display: 'flex'}}>
      {traces.map((trace, index) => {
        return (
          <React.Fragment key={index}>
            {index === 0 && (
              <div style={{marginRight: 10}}>
                <HjemIcon color='black' size={0.8} />
              </div>
            )}
            <NkLink style={{marginRight: 5}} href={trace.path!}>
              {trace.name}
            </NkLink>
            <NkTypography style={{marginRight: 5}} type='normalParagraph'>
              /
            </NkTypography>
          </React.Fragment>
        );
      })}
      <NkTypography type='normalParagraph' style={{marginRight: 5}}>
        {window.location.pathname.includes(InfoPagesRoutes.article.path)
          ? getArticleName(currentPage)
          : currentPage.charAt(0).toUpperCase() + currentPage.slice(1)}
      </NkTypography>
    </nav>
  );
};
export default Breadcrumb;
