import {useContext} from 'react';
import {StatusAlertContext} from '../../state/statusAlertState';
import StatusAlertBanner from './StatusAlertBanner';

const StatusAlert = () => {
  const {statusAlertState} = useContext(StatusAlertContext);

  return (
    <>
      <div>
        {statusAlertState.statusAlerts.map((alert) => {
          if (!alert.hideAlert) {
            return (
              <StatusAlertBanner
                statusType={alert.MessageType}
                message={alert.Message}
                key={alert.Id}
                statusAlertId={alert.Id}
              />
            );
          }
          return null;
        })}
      </div>
    </>
  );
};
export default StatusAlert;
