import React from 'react';
import {DesktopContainer, TabletAndMobileContainer} from '../../../components/containers/DeviceContainers';
import NkTypography from '../../../components/NkTypography/NkTypography';
import StartNewApplicationButton from '../StartNewApplicationButton';
import './Header.scss';
import {Stack} from '@mui/material';

const Header = ({showVeilederBtn}: {showVeilederBtn: boolean}) => {
  return (
    <section className='Header'>
      <div className={`content-container ${showVeilederBtn ? '' : 'noButtonVersion'}`}>
        <TabletAndMobileContainer>
          <div className='text small'>
            <NkTypography type='heading1' center>
              {'Enklere start på ditt byggeprosjekt!'}
            </NkTypography>
            <NkTypography
              center
              style={{margin: 'auto', marginTop: 40, marginBottom: 40, maxWidth: 445}}
              type='largeParagraph'
            >
              {
                'Skal du gjøre noe på egen eiendom og er usikker på om du må søke tillatelse, hva du må søke om eller hvordan du fyller ut en søknad? eByggesøk hjelper deg med alt dette!'
              }
            </NkTypography>
            <Stack gap={2} className='mobile-tablet-btns-wrapper'>
              {showVeilederBtn && <StartNewApplicationButton buttonVersion='top' text='Kom i gang med søknaden' />}
              {showVeilederBtn && (
                <StartNewApplicationButton
                  text={'Start veileder'}
                  buttonVersion='top'
                  type='outlined'
                  style={{width: '100%'}}
                />
              )}
            </Stack>
            <img
              style={{
                width: '100%',
                height: '100%',
              }}
              className='mobileTabletImg'
              alt='Enklere start byggeprosjekt'
              src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1617979344/eBygges%C3%B8k%20UA/front-min.png'
            ></img>
          </div>
        </TabletAndMobileContainer>
        <DesktopContainer>
          <div className='text'>
            <NkTypography type='heading1'>{'Enklere start på ditt byggeprosjekt!'}</NkTypography>
            <NkTypography style={{marginTop: 40, marginBottom: 40, maxWidth: 445}} type='largeParagraph'>
              {
                'Skal du gjøre noe på egen eiendom og er usikker på om du må søke tillatelse, hva du må søke om eller hvordan du fyller ut en søknad? eByggesøk hjelper deg med alt dette!'
              }
            </NkTypography>
            <Stack gap={2} direction='row'>
              {showVeilederBtn && <StartNewApplicationButton buttonVersion='top' text='Kom i gang med søknaden' />}
              {showVeilederBtn && (
                <StartNewApplicationButton text={'Start veileder'} buttonVersion='top' type='outlined' />
              )}
            </Stack>
          </div>
          <div className={`headerImgContainer ${showVeilederBtn ? '' : 'noButtonVersion'}`}>
            <img
              style={{
                width: '100%',
                height: '100%',
              }}
              alt='Illustrasjon. Hjelp'
              src='https://res.cloudinary.com/dgbxah2xy/image/upload/v1617979344/eBygges%C3%B8k%20UA/front-min.png'
            ></img>
          </div>
        </DesktopContainer>
      </div>
    </section>
  );
};
export default Header;
