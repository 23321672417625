import {ProcessStep} from './ProcessIllustration';
export const getProcessText = (): ProcessStep[] => {
  return [
    {
      text: 'Veiledning – hva er reglene for din eiendom?',
      illustration: 'https://res.cloudinary.com/dgbxah2xy/image/upload/v1604517228/eBygges%C3%B8k%20UA/step1.png',
      moreInfoText:
        'Kommuner har lokale regler og begrensninger for hva man kan gjøre på en eiendom som må hensyntas. Vi starter derfor med å finne hvilke regler som gjelder for din eiendom, og hva disse betyr for deg.',
    },
    {
      text: 'Hjelp til å finne ut hvor stort du kan bygge',
      illustration:
        'https://res.cloudinary.com/dgbxah2xy/image/upload/v1605793195/eBygges%C3%B8k%20UA/illustrations/boy_alphabet_learning_study_education_child.svg',
      moreInfoText:
        'Dersom du ønsker å bygge noe på eiendommen din er det begrensninger for hvor stort du kan bygge basert på kommunens regler og hvor mye som er allerede er bygget. Vi hjelper deg med denne beregningen, og finner ut hvor stort du kan bygge!',
    },
    {
      text: 'Tegning og plassering i kart',
      illustration:
        'https://res.cloudinary.com/dgbxah2xy/image/upload/v1605796186/eBygges%C3%B8k%20UA/illustrations/woman_design_painter_profession_paintbrush_paint.svg',
      moreInfoText:
        'Under utfylling tegner du hvor du ønsker å bygge/rive/endre og vi gjør automatiske analyser for å sikre at du får tatt nødvendige hensyn til dine omgivelser. ',
    },
    {
      text: 'Varsling av naboer',
      illustration:
        'https://res.cloudinary.com/dgbxah2xy/image/upload/v1605796182/eBygges%C3%B8k%20UA/illustrations/smartphone_man_news_announcement_marketing_notification_megaphone.svg',
      moreInfoText:
        'I de fleste byggesaker er det behov for nabovarsling. Dette er inkludert i løsningen, og sendes digitalt via Altinn. Vi hjelper deg med å finne frem til riktige naboer, og sørger for at naboene får den informasjonen de trenger.',
    },
    {
      text: 'Vedlegg – hva må legges ved og hva skal de inneholde',
      illustration:
        'https://res.cloudinary.com/dgbxah2xy/image/upload/v1605793203/eBygges%C3%B8k%20UA/illustrations/man_boy_calculate_calcuation_abacus_math.svg',
      moreInfoText:
        'Når fristen for å komme med nabomerknader er ute, kan du sende søknaden videre til kommunen. Vi hjelper deg med en siste sjekk på nødvendige vedlegg og tegninger, slik at du får sendt en komplett søknad til kommunen via Altinn.',
    },
    {
      text: 'Innsending av søknad',
      illustration:
        'https://res.cloudinary.com/dgbxah2xy/image/upload/v1605796189/eBygges%C3%B8k%20UA/illustrations/woman_movement_celebration_celebrate_dance_dancing.svg',
      moreInfoText:
        'Før du kan begynne å bygge/rive må du vente på svar fra kommunen på din søknad. Normal saksbehandlingstid av en komplett søknad er 3 uker. Hvis du må ha dispensasjon, eller andre tillatelser, er saksbehandlingstiden 12 uker – du vil få beskjed underveis i utfyllingen dersom dette gjelder for din søknad.',
    },
    {
      text: 'Innsending av ferdigattest',
      illustration:
        'https://res.cloudinary.com/dgbxah2xy/image/upload/v1605793214/eBygges%C3%B8k%20UA/illustrations/man_tv_television_entertainment_electronic_device_home.svg',
      moreInfoText:
        'For at kommunen skal godkjenne endringene på din eiendom, må du søke om ferdigattest. Vi vil sende deg en påminnelse om dette 6 måneder etter din søknad er sendt.',
    },
  ];
};
