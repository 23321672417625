import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {AxiosError} from 'axios';
import {MultiPolygon, Polygon} from 'geojson';
import {appInsightsInstance} from '../AppInsightsSetup';
import {getConfigValue} from '../config';
import {MatrikkelNummer, NaboTeig, Teig} from '../utils/dibkSchemas/models/models';
import getErrorMsg from '../utils/getErrorMsg';
import {getAxiosInstanceWithApiKeyHeader} from './ebyggesokServices/axiosService';

function getMatrikkelkartAxiosInstance() {
  const url = getConfigValue('matrikkelKartUrl');
  return getAxiosInstanceWithApiKeyHeader(url);
}

// Subset of server response
export interface TeigFromServer {
  GIDTekst: string;
  Geometri: string;
  Matrikkelnummer: string;
  Matrikkelenheter: {
    Matrikkelnummer: string;
  }[];
  TeigMedFlereMatrikkelEnheter: boolean;
  Id: number;
  SeksjonsnummerTeig: number;
}

interface ServerResponse {
  GeometryFormat: string;
  SamletGeometri?: string;
  SRS: number;
  Teiger: TeigFromServer[];
}

export interface TeigsInPropertyIdSuccess {
  teigs: Teig[];
}

// 0301 - 2/1072/0/1
// Beverskaret 18, Haugesund
// Check to see if a property is seksjon is moved to isSeksjonertEiendom().
export const teigsInPropertyId = (propertyId: MatrikkelNummer): Promise<TeigsInPropertyIdSuccess> => {
  const path = `/teig/matrikkelnummer/?matrikkelnummer=${propertyId}&GeometryTextFormat=GeoJson&CombineGeometries=True`;
  return getMatrikkelkartAxiosInstance()
    .get<ServerResponse>(path)
    .then<TeigsInPropertyIdSuccess>(
      (response) => {
        let res: TeigsInPropertyIdSuccess = {teigs: []};
        const isSeksjonert = response.data.Teiger.find((teig) => teig.SeksjonsnummerTeig !== 0)!!;
        if (isSeksjonert) {
          if (!response.data.SamletGeometri)
            throw new Error(
              'Din eiendom inneholder seksjoner med komplekse geometrier som dessverre ikke støttes i eByggesøk enda.'
            );

          const teigGeom: Polygon | MultiPolygon = JSON.parse(response.data.SamletGeometri);

          if (teigGeom.type !== 'Polygon') {
            appInsightsInstance?.trackException(
              {
                error: new Error('multiPolygonTeig'),
                severityLevel: SeverityLevel.Error,
              },
              {
                errorReason: 'Teiger should not be multipolygon and is therefor not supported in code.',
                property: propertyId,
              }
            );
            throw new Error(
              'Din eiendom inneholder seksjoner som ikke ligger inntil hverandre. Dette kompliserer de automatiske analysene som skal gjøres for din eiendom, og det er derfor foreløpig ikke støtte for disse eiendommene i eByggesøk.'
            );
          } else {
            res.teigs = [{geometry: teigGeom, propertyId: propertyId}];
          }
        } else {
          res.teigs = response.data.Teiger.map((teig) => {
            return {
              geometry: JSON.parse(teig.Geometri),
              propertyId: teig.Matrikkelnummer,
              propertyIds: teig.Matrikkelenheter.map((item) => item.Matrikkelnummer),
              teigId: teig.Id,
            };
          });
        }
        return res;
      },
      (err: AxiosError) => {
        appInsightsInstance?.trackException(
          {
            error: new Error('teigsInPropertyError'),
            severityLevel: SeverityLevel.Error,
          },
          {property: propertyId, error: getErrorMsg(err)}
        );
        throw new Error('Det oppsto en feil ved henting av informasjon om din eiendom.');
      }
    );
};

export interface TeigsInPointSuccess {
  teigs: NaboTeig[];
}
export const teigsInPoint = (north: number, east: number) => {
  const path = `/teig/lokasjon/?Breddegrad=${north}&Lengdegrad=${east}&GeometryTextFormat=GeoJson`;
  return getMatrikkelkartAxiosInstance()
    .get<ServerResponse>(path)
    .then<TeigsInPointSuccess>((response) => {
      const res: TeigsInPointSuccess = {
        teigs: [],
      };

      if (response.data.Teiger.length === 0) {
        return res;
      }

      res.teigs = response.data.Teiger.map((teig) => {
        return {
          geometry: JSON.parse(teig.Geometri),
          propertyIds: teig.Matrikkelenheter.map((item) => item.Matrikkelnummer),
          teigId: teig.Id,
          gidText: teig.GIDTekst,
        };
      });
      return {...res, teigs: res.teigs};
    });
};
