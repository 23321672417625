import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {appInsightsInstance} from '../../AppInsightsSetup';
import {getMunicipalityProduct, Product, ProduktKode} from '../../state/productState';
import getErrorMsg from '../../utils/getErrorMsg';
import {getAxiosInstanceWithApiKeyHeader} from './axiosService';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {KommuneNummer} from '../../utils/dibkSchemas/models/models';
import {Context} from '../../state/applicationStore';
import React from 'react';
import {getKnr} from '../../state/buildingSiteState';
import {getConfigValue} from '../../config';

export const productsQueryKey = ['productsQueryKey'];
function useProducts() {
  const getProducts = async () => {
    try {
      const response = await getAxiosInstanceWithApiKeyHeader().get<{Produkter: Product[]}>('/hentProdukter');
      return response.data.Produkter || [];
    } catch (error: any) {
      appInsightsInstance?.trackException(
        {
          error: new Error('fetchproductsFailed'),
          severityLevel: SeverityLevel.Error,
        },
        {error: getErrorMsg(error), path: window.location.href}
      );
      throw error;
    }
  };
  const query = useQuery(productsQueryKey, getProducts, {refetchOnWindowFocus: false, refetchOnMount: false});
  return {...query, availableProducts: query.data};
}

type UseProductType = {
  price: number | undefined;
  productCode: ProduktKode | undefined;
  isSuccess: boolean;
  isError: boolean;
  resetQuery: () => void;
};

export const useProduct = (): UseProductType => {
  const {availableProducts, isError} = useProducts();
  const queryClient = useQueryClient();
  const resetQuery = () => queryClient.resetQueries(productsQueryKey);

  const {
    state: {
      buildingSite: {chosenTeig},
    },
  } = React.useContext(Context);

  const knr = getKnr(chosenTeig);
  if (availableProducts === undefined || knr === undefined || isError) {
    return {productCode: undefined, price: undefined, isSuccess: false, isError: isError, resetQuery};
  }

  const product = getMunicipalityProduct(availableProducts, knr as KommuneNummer);
  const defaultPrice = getConfigValue('ebyggesokPrice');
  return {
    price: product?.Pris ?? Number(defaultPrice),
    productCode: product?.ProduktKode,
    isSuccess: true,
    isError: false,
    resetQuery,
  };
};

export default useProducts;
