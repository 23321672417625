import {QuestionId} from '../../services/utils/questionIds';
import {AnswerValues} from '../../state/questionTypes';
import {tiltakstyperThatCanCauseSoknad} from '../supportedTiltakstyper';

export function requiresMelding(answers: AnswerValues) {
  return getRequieredMeldingFromQuestions(answers).length > 0;
}

function getRequieredMeldingFromQuestions(answerValues: AnswerValues) {
  let questionsRequiringMelding: {id: QuestionId}[] = [];

  if (answerValues.PLACE_ABOVE_VANN_AVLOP === false) {
    //Flyt for frittliggende bygg
    questionsRequiringMelding.push({
      id: 'PLACE_ABOVE_VANN_AVLOP',
    });
  }
  if (answerValues.TERRASSE_NEIGHBOUR_DISTANCE === true) {
    questionsRequiringMelding.push({
      id: 'TERRASSE_NEIGHBOUR_DISTANCE',
    });
  }
  if (
    (answerValues.TILBYGG_NEIGHBOUR_DISTANCE_OK === true ||
      answerValues.TILBYGG_NEIGHBOUR_DISTANCE_OK === 'within_building_border') &&
    (answerValues.TILTAK_TYPE_BUILD as tiltakstyperThatCanCauseSoknad) === 'tilbyggUnder15m2' &&
    answerValues.TILBYGG_ABOVE_EXISTING_HEIGHT === false &&
    answerValues.TILBYGG_MORE_THAN_TWO_FLOORS === false
  ) {
    questionsRequiringMelding.push({
      id: 'TILBYGG_NEIGHBOUR_DISTANCE_OK',
    });
  }

  if (
    answerValues.MONEHOYDE_GESIMSHOYDE_BELOW_LIMIT === true &&
    answerValues.BASEMENT_DEMOLISH_OVER_50 !== true &&
    answerValues.BASEMENT_DEMOLISH_UNDER_50 !== true &&
    answerValues.ATTIC_DEMOLISH_OVER_50 !== true &&
    answerValues.ATTIC_DEMOLISH_UNDER_50 !== true
  ) {
    questionsRequiringMelding.push({
      id: 'MONEHOYDE_GESIMSHOYDE_BELOW_LIMIT',
    });
  }
  if (
    answerValues.TILBYGG_DEMOLISH_NEIGHBOUR_DISTANCE_OK_BOLIG_OR_FRITIDSBOLIG === true &&
    answerValues.TILBYGG_DEMOLISH_OVER_TWO_FLOORS_BOLIG_OR_FRITIDSBOLIG !== true &&
    answerValues.TILBYGG_DEMOLISH_OVER_TWO_FLOORS_GARASJE_OR_OTHER !== true &&
    answerValues.TILBYGG_DEMOLISH_HIGHER_THAN_ADJACENT_BUILDING !== true &&
    answerValues.TILTAK_TYPE_DEMOLISH === 'rivetilbyggunder15kvm'
  ) {
    questionsRequiringMelding.push({
      id: 'TILBYGG_DEMOLISH_NEIGHBOUR_DISTANCE_OK_BOLIG_OR_FRITIDSBOLIG',
    });
  }
  if (
    answerValues.TILBYGG_DEMOLISH_NEIGHBOUR_DISTANCE_OK_GARASJE_OR_OTHER === true &&
    answerValues.TILBYGG_DEMOLISH_OVER_TWO_FLOORS_BOLIG_OR_FRITIDSBOLIG !== true &&
    answerValues.TILBYGG_DEMOLISH_OVER_TWO_FLOORS_GARASJE_OR_OTHER !== true &&
    answerValues.TILBYGG_DEMOLISH_HIGHER_THAN_ADJACENT_BUILDING !== true &&
    answerValues.TILTAK_TYPE_DEMOLISH === 'rivetilbyggunder15kvm'
  ) {
    questionsRequiringMelding.push({
      id: 'TILBYGG_DEMOLISH_NEIGHBOUR_DISTANCE_OK_GARASJE_OR_OTHER',
    });
  }

  return questionsRequiringMelding;
}

export function requiresMeldingForQuestion(answers: AnswerValues, question: QuestionId): boolean {
  return getRequieredMeldingFromQuestions(answers).filter((item) => item.id === question).length > 0;
}
