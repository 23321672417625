import {extraInfoDocumentName} from '../components/ExtraInfoPdfHandler/ExtraInfoPdfHandler';
import {QuestionId} from '../services/utils/questionIds';
import {Brand, ValidAttachmentType} from '../utils/dibkSchemas/models/models';
import {ResetSuppleringIdAction, SoknadType} from './applicationState';
import {DatavarehusDatasetName} from './datavarehusDatasetsState';
import {CustomDatasetName} from './distancesState';
import {Answers} from './questionTypes';

export type AttachmentId = Brand<string, 'AttachmentId'>;

export type Attachment = {
  name: string;
  type: ValidAttachmentType;
  url: string;
  includeInNabovarsel: boolean;
  fileSize: number;
  documentationFor?: DatavarehusDatasetName | CustomDatasetName;
  attachmentId?: AttachmentId;
  addedAutomatic?: boolean;
  uploadReason?: string;
  required?: string;
  questionId?: QuestionId;
  soknadType: SoknadType;
};

export type Sitplan = {
  applicationId: number;
  orderId?: string;
  loading: boolean;
  productionFailed: boolean;
  url?: string;
};
export type VedleggResult = {
  recommendedAttachments: ValidAttachmentType[];
  requiredAttachments: ValidAttachmentType[];
  oneOfRequiredAttachments: ValidAttachmentType[];
  nonTranslatableMessages: string[];
};

export type State = {
  attachments: Attachment[];
  sitkartProduction?: Sitplan;
  updateRequiredAttachments: boolean;
  attachmentRequirements?: VedleggResult;
};

export const initialState: State = {
  attachments: [],
  sitkartProduction: undefined,
  updateRequiredAttachments: false,
};

export const removeAttachmentsBelongingToDeactivatedAnswers = (attachments: Attachment[], answers: Answers) => {
  return attachments.filter((att) => {
    const relatedQuestion = att.questionId && answers[att.questionId];
    return (att.questionId && !relatedQuestion) || relatedQuestion?.deactivated ? false : true;
  });
};
export const removeExtraInfoDocument = (attachments: Attachment[]) => {
  return attachments.filter((att) => {
    const isExtraInfoDocument = att.name === extraInfoDocumentName && att.type === 'Annet';
    return !isExtraInfoDocument;
  });
};

export type AddAttachmentAction = {
  type: 'addAttachment';
  value: Attachment;
};
export type ReplaceAttachmentAction = {
  type: 'replaceExtraInfoAttachment';
  value: Attachment;
};
export type RemoveAttachmentAction = {
  type: 'removeAttachment';
  value: AttachmentId;
};

export type Action =
  | AddAttachmentAction
  | ReplaceAttachmentAction
  | RemoveAttachmentAction
  | {
      type: 'removeExtraInfoAttachment';
    }
  | {
      type: 'setAttachmentRequirements';
      value: VedleggResult | undefined;
    }
  | {
      type: 'addOrUpdateSitkartProductions';
      value: State['sitkartProduction'];
    }
  | {
      type: 'setUpdateRequiredAttachments';
      value: boolean;
    }
  | {type: 'clearAttachments'}
  | ResetSuppleringIdAction;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'addAttachment':
      return {
        ...state,
        attachments: [...state.attachments, action.value],
      };
    case 'replaceExtraInfoAttachment':
      const nonReplaceableAtts = state.attachments.filter(
        (att) => !(att.name === action.value.name && att.type === action.value.type)
      );
      return {
        ...state,
        attachments: [...nonReplaceableAtts, action.value],
      };
    case 'removeExtraInfoAttachment':
      const nonExtraInfoAtt = state.attachments.filter(
        (att) => !(att.name === extraInfoDocumentName && att.type === 'Annet')
      );
      return {
        ...state,
        attachments: [...nonExtraInfoAtt],
      };
    case 'removeAttachment':
      if (!action.value) {
        alert('Feil ved sletting av fil');
        return {...state};
      } else {
        return {
          ...state,
          attachments: state.attachments.filter((a) => a.attachmentId !== action.value),
        };
      }
    case 'setAttachmentRequirements': {
      return {
        ...state,
        attachmentRequirements: action.value,
      };
    }
    case 'addOrUpdateSitkartProductions': {
      return {
        ...state,
        sitkartProduction: action.value as State['sitkartProduction'],
      };
    }
    case 'setUpdateRequiredAttachments': {
      return {
        ...state,
        updateRequiredAttachments: action.value,
      };
    }
    case 'resetSuppleringId': {
      return initialState;
    }
    case 'clearAttachments': {
      return initialState;
    }
    default:
      return state;
  }
};
