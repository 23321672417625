import {NaboTeig, Dict, MatrikkelNummer, Brand} from '../utils/dibkSchemas/models/models';
import {uniquify} from '../utils/utils';
import {
  IncompleteNeighbour,
  NabovarselStatus,
  ManualNeighbour,
  NabovarselFrist,
} from '../services/ebyggesokServices/nabovarselService';
import {Dispatch} from './applicationStore';
import {AltinnDraftReference} from './applicationState';
import {AltinnReference, ApplicationId} from '../services/ebyggesokServices/userOrderService';

export type NabovarselXmlId = Brand<string, 'NabovarselXmlId'>;

export interface NabovarselState {
  neighbours: NaboTeig[];
  neighboursInitialized: boolean;
  nabovarselXmlId?: NabovarselXmlId; // XML
  arReference?: AltinnReference; // Altinn
  draftReference?: AltinnDraftReference;
  pdfDraft?: string;
  incompleteNeighbours: IncompleteNeighbour[];
  numberOfReceivers?: number;
  neighbourAnswers?: NabovarselStatus;
  manualNeighbours?: ManualNeighbour[]; // Includes both failed in altinn + incomplete receivers.
  cannotProduce?: boolean;
  nabovarselFrist?: NabovarselFrist;
  nabovarselSentDate?: string;
}

export const initialState: NabovarselState = {
  neighbours: [],
  neighboursInitialized: false,
  incompleteNeighbours: [],
};

export type Action =
  | {
      type: 'setNeighbours';
      value: NaboTeig[];
    }
  | {
      type: 'setNabovarselFrist';
      value: NabovarselFrist | undefined;
    }
  | {
      type: 'addNeighbours';
      value: NaboTeig[];
    }
  | {
      type: 'removeNeighbour';
      teigId: number;
    }
  | {
      type: 'removeNeighbourByPropertyId';
      value: MatrikkelNummer;
    }
  | {
      type: 'setNabovarselXmlId';
      value: NabovarselXmlId;
    }
  | {
      type: 'removeNabovarselXmlId';
    }
  | {
      type: 'setArReference';
      value: AltinnReference;
    }
  | {
      type: 'setDraftReference';
      value: AltinnDraftReference;
    }
  | {
      type: 'setPdfDraft';
      value: string | undefined;
    }
  | {
      type: 'removePdfDraft';
    }
  | {
      type: 'setIncompleteNeighbours';
      value: IncompleteNeighbour[];
    }
  | {
      type: 'setNumberOfReceivers';
      value: number;
    }
  | {
      type: 'setNeighbourAnswers';
      value: NabovarselStatus;
    }
  | {
      type: 'setManuelNeighbours';
      value: ManualNeighbour[];
    }
  | {
      type: 'updateManualNeighbour';
      value: {
        arCode: string;
        applicationId: ApplicationId;
        updatedNeighbour: ManualNeighbour;
        dispatch: Dispatch;
      };
    }
  | {
      type: 'setCannotProduce';
    }
  | {
      type: 'setNabovarselSentDate';
      value: string;
    };

export const reducer = (state: NabovarselState, action: Action): NabovarselState => {
  switch (action.type) {
    case 'setNeighbours': {
      // Handle flerbruksteigs. Not necessary here, but used for concistency
      let uniqueTeigs = uniquifyTeigs(action.value);

      return {
        ...state,
        neighboursInitialized: true,
        neighbours: uniqueTeigs,
        nabovarselXmlId: undefined,
      };
    }
    case 'addNeighbours': {
      let uniqueTeigs = uniquifyTeigs([...state.neighbours, ...action.value]);

      return {
        ...state,
        neighbours: uniqueTeigs,
        nabovarselXmlId: undefined,
      };
    }
    case 'removeNeighbour':
      return {
        ...state,
        neighbours: state.neighbours.filter((neighbour) => {
          return neighbour.teigId !== action.teigId;
        }),
        nabovarselXmlId: undefined,
      };
    case 'removeNeighbourByPropertyId':
      return {
        ...state,
        neighbours: removeNeighbourByPropertyIdHelper(state.neighbours, action.value),
      };
    case 'setNabovarselXmlId':
      return {
        ...state,
        nabovarselXmlId: action.value,
      };
    case 'removeNabovarselXmlId':
      return {
        ...state,
        nabovarselXmlId: undefined,
      };
    case 'setArReference':
      return {
        ...state,
        arReference: action.value,
      };
    case 'setDraftReference':
      return {
        ...state,
        draftReference: action.value,
      };
    case 'setPdfDraft':
      return {
        ...state,
        pdfDraft: action.value,
      };
    case 'removePdfDraft':
      return {
        ...state,
        pdfDraft: undefined,
      };
    case 'setIncompleteNeighbours': {
      return {
        ...state,
        incompleteNeighbours: action.value,
      };
    }
    case 'setNumberOfReceivers': {
      return {
        ...state,
        numberOfReceivers: action.value,
      };
    }
    case 'setNeighbourAnswers': {
      return {
        ...state,
        neighbourAnswers: action.value,
      };
    }
    case 'setManuelNeighbours': {
      return {
        ...state,
        manualNeighbours: action.value,
      };
    }
    case 'updateManualNeighbour': {
      return {
        ...state,
        manualNeighbours: [
          ...(state.manualNeighbours?.filter((n) => n.Id !== action.value.updatedNeighbour.Id) || []),
          action.value.updatedNeighbour,
        ],
      };
    }
    case 'setCannotProduce': {
      return {
        ...state,
        cannotProduce: true,
      };
    }
    case 'setNabovarselFrist': {
      return {
        ...state,
        nabovarselFrist: action.value,
      };
    }
    case 'setNabovarselSentDate': {
      return {
        ...state,
        nabovarselSentDate: action.value,
      };
    }
    default:
      return state;
  }
};

export function removeNeighbourByPropertyIdHelper(neighbourList: NaboTeig[], value: MatrikkelNummer): NaboTeig[] {
  const foundNeighbours = neighbourList.filter((n) => n.propertyIds.indexOf(value) > -1);
  let newList: NaboTeig[] = [];
  newList = neighbourList.filter((neighbour) => {
    if (neighbour.propertyIds.length === 1) {
      const shouldDeleteNeihbour = foundNeighbours.filter((f) => f.teigId === neighbour.teigId).length > 0;
      return shouldDeleteNeihbour ? false : true;
    }
    return true;
  });
  if (newList.length > 0) {
    return newList;
  }
  return neighbourList;
}

function uniquifyTeigs(teigs: NaboTeig[]): NaboTeig[] {
  var dict: Dict<NaboTeig> = {};
  teigs.forEach((teig) => (dict[teig.teigId] = teig));

  return Object.keys(dict).map((key) => dict[key]);
}

export function getAllGids(neighbours: NaboTeig[]): MatrikkelNummer[] {
  var gids: string[] = [];
  neighbours
    .map((n) => n.propertyIds)
    .forEach((ids) => {
      gids = [...gids, ...ids];
    });

  return uniquify(gids);
}
export default reducer;
